import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { TransportActions } from "../store/reducers/transportSlice";

export const useTransport = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(TransportActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(TransportActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(TransportActions.applyFilter(criteria));
    };

    const stepRefresh = () => {
        dispatch(TransportActions.stepRefresh());
    };

    const vehicleGridRefresh = () => {
        dispatch(TransportActions.vehicleGridRefresh());
    };

    return { resetState, gridRefresh, applyFilter, stepRefresh, vehicleGridRefresh } as const;
};