import { Backdrop, CircularProgress } from "@mui/material";
import { ReactNode } from "react";

interface IProps {
    open: boolean;
    children: ReactNode;
}
const BackdropProgress = (props: IProps) => {
    const { children, open } = props;

    return (
        <div style={{ 'position': 'relative' }}>
            <Backdrop
                sx={{
                    backgroundColor: 'rgb(0 0 0 / 15%)',
                    color: '#FFFFFF',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    'position': 'absolute'
                }}
                open={open}
            >
                <CircularProgress color='inherit' />
            </Backdrop>

            {children}
        </div>
    );
}
export default BackdropProgress;