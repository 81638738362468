import { Box, Skeleton, Stack } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationUrl, removeEmptyFields } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { useLoad } from "../../../hooks/useLoad";
import { useNotification } from "../../../hooks/useNotification";
import { ICustomerAutocompleteResponseDto } from "../../../models/CustomerModels";
import { ILoadOverviewResponseDto, ILoadRequestDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DateField from "../../Base/DateComponent/DateField";
import IdnoGenerator from "../../Base/IdnoGeneratorComponent/IdnoGenerator";
import RatingView from "../../Base/RatingComponent/RatingView";
import ReputationView from "../../Base/ReputationComponent/ReputationView";
import CustomerAutocomplete from "../../CustomerModule/CustomerAutocomplete";

interface IProps {
    open: boolean;
    load?: ILoadOverviewResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadDialog = (props: IProps) => {
    const { open, load, onCloseBtnClick, onSubmitBtnClick } = props;

    const isEdit = useRef<boolean>(load !== undefined);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useLoad();
    const formId: string = 'load-form';

    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState<ICustomerAutocompleteResponseDto | null>();

    const { register, setValue, getValues, setError, clearErrors, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<ILoadRequestDto>({
        defaultValues: {
            customerId: isEdit.current ? load?.customer.uuid : '',
            idno: isEdit.current ? load?.idno : '',
            deliveredBy: isEdit.current ? load?.deliveredBy : NaN
        }
    });

    const onCheckWithServerApi = useCallback((value: string) => {
        return LoadService.isIdnoAvailable(value);
    }, []);

    const updateData = useCallback((uuid: string, data: ILoadRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('Load was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: ILoadRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.create(data);
            if (response) {
                displayNotification({ message: t('Load was successfully created.') });

                const uuid = response.data.response.entityId;
                const newUrl: string = normalizeNavigationUrl(uuid);
                navigate(`/${newUrl}`);

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: ILoadRequestDto) => {
        const normalisedData: ILoadRequestDto = removeEmptyFields(data) as unknown as ILoadRequestDto;
        if (load) {
            updateData(load.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, load, updateData]);

    const validateCustomerField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    const onCustomerInitHandler = useCallback((value: ICustomerAutocompleteResponseDto | null) => {
        setCustomer(value);
    }, []);

    register('customerId', { validate: validateCustomerField });
    const onCustomerChangeHandler = useCallback((value: ICustomerAutocompleteResponseDto | null) => {
        setValue('customerId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
        setCustomer(value);
    }, [setValue]);

    const validateIdnoField = useCallback((value: string) => {
        return !isBlank(value) && !errors.idno;
    }, [errors.idno]);

    register('idno', { validate: validateIdnoField });
    const onChangeIdnoHandler = useCallback((value: string, error?: string) => {
        if (error) {
            setError('idno', { message: error });
        } else {
            clearErrors('idno');
        }

        setValue('idno', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [clearErrors, setError, setValue]);

    register('deliveredBy');
    const onChangeDeliveredByHandler = useCallback((timestamp?: number) => {
        setValue('deliveredBy', timestamp, { shouldDirty: true });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <Stack spacing={1} direction='row'>
                        <Box flex={3}>
                            <CustomerAutocomplete
                                required
                                label={t('CUSTOMER')}
                                showStatus
                                disableInactiveItems
                                value={getValues('customerId')}
                                onInit={onCustomerInitHandler}
                                onChange={onCustomerChangeHandler}
                            />
                        </Box>
                        <Box flex={1}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}>
                                <RatingView value={customer ? customer.reviewMark : 0} />
                                {customer ? <ReputationView value={customer.reputationLevel} /> : <Skeleton width='100px' animation={false} />}
                            </div>
                        </Box>
                    </Stack>

                    <IdnoGenerator
                        required
                        value={getValues('idno')}
                        label={t('ID#')}
                        generateBtnTooltip={t('GENERATE')}
                        errorMessage={t('The value is used. Choose another value.')}
                        checkWithServerApi={onCheckWithServerApi}
                        onChange={onChangeIdnoHandler}
                    />

                    <DateField
                        label={t('DUE DATE')}
                        size='medium'
                        value={getValues('deliveredBy')}
                        onChange={onChangeDeliveredByHandler}
                    />
                </Stack>
            </form>
        );
    }, [customer, getValues, handleSubmit, onChangeDeliveredByHandler, onChangeIdnoHandler,
        onCheckWithServerApi, onCustomerChangeHandler, onCustomerInitHandler, onSubmit, t]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} LOAD`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadDialog;