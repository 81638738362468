import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import GeneralService from "../../services/GeneralService";
import { RootState } from "../../store/store";

interface IFileProps {
  snckMessage: (s: string) => void;
}
export default function SettingsFilesPrivacyAndHelpComponent(props: IFileProps) {

  const [open, setOpen] = useState<boolean>(false);
  const [base64File, setBase64File] = useState<string>('')

  const handleClose = () => {
    setOpen(false);
  };

  const viewFile = (content: string) => {
    setBase64File(content);
    setOpen(true);
  }
  const snackMess = (s: string) => {
    props.snckMessage(s);
  }

  return (
    <>
      <div style={{ marginBottom: "30px", paddingLeft: "30px" }}>
        {['HELP', 'PRIVACY'].map((type, i) =>
          <FileEditComponent snackMessage={snackMess} viewFile={viewFile} type={type} key={i} />
        )}
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>

        </DialogTitle>
        <DialogContent>
          {base64File !== '' &&
            <object type="application/pdf" width={"1150px"} height={"700px"}
              data={"data:application/pdf;base64," + base64File}></object>
          }
        </DialogContent>
        <DialogActions
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "20px",
            paddingRight: "20px"
          }}>

          <div>

          </div>
          <Button onClick={handleClose}>Close</Button>

        </DialogActions>
      </Dialog>
    </>
  );
}

interface IProps {
  type: string;
  viewFile: (type: string) => void;
  snackMessage: (s: string) => void;
}

const FileEditComponent = (props: IProps) => {
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const { type, viewFile } = props;
  const [existFile, setExistFile] = useState(false);
  const [base64File, setBase64File] = useState<string>('');
  const [download, setDownload] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);

  const filePick = useRef<HTMLInputElement | null>(null);
  const handlePick = () => { filePick.current?.click() }

  const hendlerView = () => {
    if (base64File.length > 0) {
      viewFile(base64File);
    }
    else {
      setView(true);
      setDownload(true);
    }
  }

  const uploadHandler = (event: any) => {

    let file = event.target.files[0];
    if (!file) return;
    (async () => {
      const formData = new FormData();
      formData.append('type', type.toUpperCase());
      formData.append('file', file);

      const [error, response] = await GeneralService.postLinkOfPublicAttachment(formData);
      if (response) {
        setExistFile(true); event.target.value = null; setBase64File('');
        props.snackMessage("The help and privacy was successfully updated.");
      }
      if (error) { }
    })()
  }

  useEffect(() => {
    if (view) {
      (async () => {
        const [error, response] = await GeneralService.getBase64OfPublicAttachment(type.toUpperCase());
        if (response) {
          setBase64File(response.data);
          viewFile(response.data);
          setDownload(false);
        }
        if (error) { }
      })()
      setView(false);
    }
  }, [view])


  const hendlerDeleteFile = () => {
    (async () => {
      const formData = new FormData();
      formData.append('type', type.toUpperCase());
      const [error, response] = await GeneralService.deletePublicAttachment(formData);
      if (response) {
        setBase64File('');
        setDownload(false);
        setExistFile(false);
        props.snackMessage("The help and privacy was successfully updated.");
      }
      if (error) { }
    })()
  }

  useEffect(() => {
    (async () => {
      const [error, response] = await GeneralService.getLinkOfPublicAttachment(type.toUpperCase());
      if (response.data.body) { setExistFile(response.data.body.value !== ''); }
      if (error) { }
    })()
  }, [])

  return (
    <>
      <div style={{ display: "flex", gap: "10px", marginTop: "6px", alignItems: "center" }}>
        <div style={{ paddingRight: "20px", width: "80px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Typography>{type.toUpperCase()}</Typography>
        </div>

        <div>

          <div style={{ display: "flex" }}>
            <Tooltip
              title={"Select the file that contains information that will be displayed in the " + type.split(" ", 1) + " section."}
              arrow={true}
              placement="top"
            >
              <Box>
                {/* {(!(!existFile || download)) ?
                  <SecureDialogComponent
                    answerYES={() => handlePick()}
                    text={''}
                    icon={<AttachFileIcon fontSize='small' />}
                    color={EButtonColor.warning}
                    iconButton={false}
                  /> : <Button
                    onClick={handlePick}
                    color="warning"
                    variant="contained"
                  >
                    <AttachFileIcon fontSize='small' />
                  </Button>
                } */}
              </Box>

            </Tooltip>

            <input type="file" onChange={uploadHandler}
              accept=".pdf"
              ref={filePick}
              style={{
                width: "0px",
                height: "0px",
                margin: "0px",
                padding: "0px",
                overflow: "hidden"
              }} />
          </div>
        </div>

        <div>
          <Tooltip
            title={(hints && (!(!existFile || download))) ? "Show the contents of the file." : ""}
            arrow={true}
            placement="top"
          >
            <Button
              variant={'contained'}
              color="primary"
              disabled={!existFile || download}
              onClick={hendlerView}
            >
              {download
                ? <CircularProgress size={20} color={'warning'} />
                : <VisibilityIcon fontSize='small' />
              }
            </Button>
          </Tooltip>
        </div>

        {/* {existFile &&
          <SecureDialogComponent
            answerYES={() => hendlerDeleteFile()}
            text={''}
            icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
            color={EButtonColor.error}
            iconButton={true}
          />
        } */}
      </div>


    </>
  )
}