import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { Box, Button, Divider, Drawer } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { encodeToBase64 } from '../../../helpers/EncoderDecoderHelper';
import { isEmpty } from "../../../helpers/generalHelper";
import { ICriteria } from '../../../models/CommonModels';

interface IProps {
    open: boolean;
    onClose: () => void;
    title?: string;

    initContent: () => void;
    buildCriteria: () => ICriteria[];
    buildContent: () => JSX.Element;

    applyBtnLabel?: string;
    onApply: (newCriteria: ICriteria[]) => void;

    resetBtnLabel?: string;
    onReset?: () => void;
}
const BaseFilter = (props: IProps) => {
    const {
        open, initContent, buildCriteria, buildContent, onApply, title = 'FILTERS',
        onClose, applyBtnLabel = 'APPLY', resetBtnLabel = 'RESET', onReset
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        initContent();
    }, [initContent]);

    const resetFilter = useCallback(() => {
        searchParams.delete('filter');
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    const onApplyHandler = useCallback(() => {
        const newCriterias: ICriteria[] = buildCriteria();
        if (isEmpty(newCriterias)) {
            resetFilter();
        } else {
            const filterDecoded: string = JSON.stringify(newCriterias);
            const filterEncoded: string = encodeToBase64(filterDecoded);
            setSearchParams({ filter: filterEncoded });
        }

        onApply(newCriterias);

        onClose();
    }, [buildCriteria, onApply, onClose, resetFilter, setSearchParams]);

    const onResetHandler = useCallback(() => {
        resetFilter();

        if (onReset) {
            onReset();
        }

        onApply([]);

        onClose();
    }, [onApply, onClose, onReset, resetFilter]);

    const onEnterPressHandler = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            onApplyHandler();
        }
    }, [onApplyHandler]);

    return (
        <Drawer anchor='top' variant='temporary' open={open} onClose={onClose}>
            <Box sx={{ margin: 2, display: 'flex', justifyContent: 'center' }}>
                <Box component='fieldset' onKeyDown={onEnterPressHandler} sx={{
                    borderColor: 'rgb(239 239 239 / 60%)',
                    borderRadius: '3px',
                    padding: '10px 15px 15px 15px',
                    width: '50%'
                }}>
                    <legend style={{
                        fontSize: '0.8rem',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: '400',
                        letterSpacing: '0.00938em',
                        cursor: 'default'
                    }}>
                        {title}
                    </legend>

                    {buildContent()}

                    <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button size='small' variant='contained' onClick={onApplyHandler}>
                            <CheckCircleOutlineIcon fontSize='small' />&nbsp;{applyBtnLabel}
                        </Button>
                        <Button size='small' variant='outlined' onClick={onResetHandler}>
                            <RotateLeftIcon fontSize='small' />&nbsp;{resetBtnLabel}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Drawer >
    );
}
export default BaseFilter;