import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../../hooks/useNotification';
import WarehouseService from "../../../../services/WarhouseService";
import BaseDescription from '../../../Base/BaseDescriptionComponent/BaseDescription';
import WarehouseDescriptionDialog from "./WarehouseDescriptionDialog";

interface IProps {
    warehouseId: string;
}
const WarehouseDescription = (props: IProps) => {
    const { warehouseId } = props;

    const maxHeight = useRef<string | number>('300px');

    const [refresh, setRefresh] = useState<boolean>(false);
    const [dialog, setDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<string>();

    const { displayNotification } = useNotification();
    const { t } = useTranslation();

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await WarehouseService.fetchDescription(warehouseId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [warehouseId]);

    useEffect(() => {
        retrieveData();
    }, [retrieveData, refresh]);

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BaseDescription
                data={data}
                loading={loading}
                maxHeight={maxHeight.current}
                editBtnLabel={t('EDIT')}
                editBtnTooltip={t('EDIT')}
                onEditBtnClick={dialogToggleHandler}
            />

            {dialog &&
                <WarehouseDescriptionDialog
                    open={dialog}
                    onCloseBtnClick={dialogToggleHandler}
                    onSubmitBtnClick={refreshHandler}
                    warehouseId={warehouseId}
                    value={data}
                />
            }
        </>
    );
}
export default WarehouseDescription;