import to from 'await-to-js';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppConfig } from '../configs/AppConfig';
import { IToken } from '../models/AuthModels';
import AuthService from './AuthService';

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD';
type ResponseType = 'json' | 'blob' | 'text';
type ContentType = 'application/json' | 'multipart/form-data';

export const API = async (method: MethodType, url: string, body?: any, params?: any, contentType?: ContentType,
  responseType?: ResponseType, accessToken?: string): Promise<[Error | null, AxiosResponse<any> | undefined]> => {

  const token: IToken | null = AuthService.getTokenFromLocalStorage();
  const tokenValue: String = accessToken ? accessToken : token ? token?.accessToken : '';

  const options: AxiosRequestConfig = {
    method: method,
    baseURL: AppConfig.baseURL,
    url: url,
    headers: {
      "Authorization": `Bearer ${tokenValue}`,
      "Content-Type": contentType ? contentType : 'application/json'
    },
    data: body ? body : '',
    params: params ? params : '',
    responseType: responseType || 'json'
  };

  const [error, response] = await to(axios(options));
  if (error) {
    const axiosError: AxiosError = error as AxiosError;
    if (axiosError.response?.status === 401) {
      AuthService.logout();
    }
  }

  return [error, response];
}

export const FetchApi = async (method: MethodType, url: string, body?: any, params?: any, responseType?: ResponseType, accessToken?: string)
  : Promise<[Error | null, any]> => {
  const token: IToken | null = AuthService.getTokenFromLocalStorage();
  const tokenValue: String = accessToken ? accessToken : token ? token?.accessToken : '';

  const headers: Headers = new Headers({
    'Authorization': `Bearer ${tokenValue}`,
    'Content-Type': 'application/json'
  });

  const options: RequestInit = {
    method: method,
    headers: headers,
    body: body || ''
  };

  let fetchUrl = `${AppConfig.baseURL}${url}`;
  if (params) {
    fetchUrl += `?${new URLSearchParams(params)}`;
  }

  const [error, response] = await to(fetch(fetchUrl, options));
  return [error, response];
}