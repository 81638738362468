import { useCallback, useRef } from "react";
import { useCustomer } from "../../../../hooks/useCustomer";
import { IReviewRequestDto } from "../../../../models/ReviewModels";
import CustomerService from "../../../../services/CustomerService";
import BaseReview from "../../../Base/BaseReviewComponent/BaseReview";

interface IProps {
    customerId: string;
}
const CustomerReview = (props: IProps) => {
    const { customerId } = props;
    const { gridRefresh, stepRefresh } = useCustomer();
    const maxHeight = useRef<string | number>('300px');

    const fetchApi = useCallback(() => {
        return CustomerService.fetchReviews(customerId);
    }, [customerId]);

    const createApi = useCallback((data: IReviewRequestDto) => {
        return CustomerService.createReview(customerId, data);
    }, [customerId]);

    const updateApi = useCallback((uuid: string, data: IReviewRequestDto) => {
        return CustomerService.updateReview(customerId, uuid, data);
    }, [customerId]);

    const deleteApi = useCallback((uuid: string) => {
        return CustomerService.removeReview(customerId, uuid);
    }, [customerId]);

    const onRefreshHandler = useCallback(() => {
        gridRefresh();
        stepRefresh();
    }, [gridRefresh, stepRefresh]);

    return (
        <BaseReview
            maxHeight={maxHeight.current}
            fetchApi={fetchApi}
            createApi={createApi}
            updateApi={updateApi}
            deleteApi={deleteApi}
            onRefresh={onRefreshHandler}
        />
    );
}
export default CustomerReview;