import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useCallback, useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { isStringJSON } from '../../../helpers/textHelper';

const toolbarConfig = {
    options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'list', 'textAlign', 'remove', 'history'],
    inline: {
        inDropdown: true,
        options: ['bold', 'italic', 'underline', 'strikethrough']
    },
    textAlign: {
        inDropdown: true
    },
    list: {
        inDropdown: true
    },
    history: {
        inDropdown: true
    }
};

interface IProps {
    value: string | undefined;
    readOnly?: boolean;
    autofocus?: boolean;
    onChange?: (value: string) => void;
}
const RichTextEditor = (props: IProps) => {
    const { value, readOnly = false, autofocus = false, onChange } = props;
    const [editorState, setEditorState] = useState<EditorState>(() => {
        if (value && isStringJSON(value)) {
            const contentAsJson = JSON.parse(value);
            const rawContent = convertFromRaw(contentAsJson);
            return EditorState.createWithContent(rawContent);
        } else {
            return EditorState.createEmpty()
        }
    });

    useEffect(() => {
        if (value && isStringJSON(value)) {
            const contentAsJson = JSON.parse(value);
            const rawContent = convertFromRaw(contentAsJson);
            const content: EditorState = EditorState.createWithContent(rawContent);
            setEditorState(content);
        }
    }, [value]);

    const onEditorStateChangeHandler = useCallback((editorState: EditorState) => {
        if (onChange) {
            const rawContent = convertToRaw(editorState.getCurrentContent());
            const contentAsJson = JSON.stringify(rawContent);
            onChange(contentAsJson);
        }
        setEditorState(editorState);
    }, [onChange]);

    const editorRefHandler = useCallback((ref: any) => {
        if (ref && autofocus) {
            ref.focus();
        }
    }, [autofocus]);

    return (
        <Editor
            editorRef={editorRefHandler}
            toolbarHidden={readOnly}
            editorState={editorState}
            toolbar={toolbarConfig}
            editorStyle={{ minHeight: '100px' }}
            onEditorStateChange={onEditorStateChangeHandler}
            readOnly={readOnly}
        />
    );
}
export default RichTextEditor;