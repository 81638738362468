import BasePage from '../components/Base/BasePageComponent/BasePage';
import LoadGrid from '../components/LoadModule/Grid/LoadGrid';
import LoadStep from '../components/LoadModule/Step/LoadStep';
import { useLoad } from '../hooks/useLoad';

const LoadPage = () => {
    const { resetState, applyFilter } = useLoad();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<LoadGrid />}
            stepSection={<LoadStep />}
        />
    );
}
export default LoadPage;