import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { CustomerActions } from "../store/reducers/customerSlice";

export const useCustomer = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(CustomerActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(CustomerActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(CustomerActions.applyFilter(criteria));
    };

    const stepRefresh = () => {
        dispatch(CustomerActions.stepRefresh());
    };

    return { resetState, gridRefresh, applyFilter, stepRefresh } as const;
};