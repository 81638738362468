import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GlobalDataState {
    online: boolean;
};

const initialState: GlobalDataState = {
    online: true
};

export const GlobalDataSlice = createSlice({
    name: 'globalDataSlice',
    initialState: initialState,
    reducers: {
        updateOnline(state: GlobalDataState, action: PayloadAction<boolean>) {
            state.online = action.payload;
        }
    }
});

export const GlobalDataActions = GlobalDataSlice.actions;
export const GlobalDataReducer = GlobalDataSlice.reducer;