import { Box, Stack } from "@mui/material";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateAndTimeFormat } from "../../../../helpers/dateHelper";
import { currencyFormat } from "../../../../helpers/NumberHelper";
import { IItemDetails } from "../../../../models/CommonModels";
import { ELoadAddressReference, ILoadAddressShortResponseDto, ILoadItemResponseDto } from "../../../../models/LoadModels";
import { RootState } from "../../../../store/store";
import BaseDialog from "../../../Base/BaseDialogComponent/BaseDialog";
import ItemsDetails from "../../../Base/ItemsDetailsComponent/ItemsDetails";

interface IProps {
    entity: ILoadItemResponseDto;
    open: boolean;
    onCloseBtnClick: () => void;
}
const LoadItemsDetailsDialog = (props: IProps) => {
    const { open, entity, onCloseBtnClick } = props;

    const { t } = useTranslation();
    const { measureMass } = useSelector((state: RootState) => state.userProfileSlice.profile.userPreference);
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const itemsLeft = useRef<IItemDetails[]>([{
        id: `${entity.uuid}-idno`,
        primary: t('ID#'),
        secondary: entity.idno
    }, {
        id: `${entity.uuid}-name`,
        primary: t('NAME'),
        secondary: entity.name
    }, {
        id: `${entity.uuid}-pallet`,
        primary: t('PALLET TYPE'),
        secondary: entity.palletType.name
    }, {
        id: `${entity.uuid}-units`,
        primary: t('UNITS'),
        secondary: entity.units.toString()
    }, {
        id: `${entity.uuid}-weight`,
        primary: t('WEIGHT'),
        secondary: `${entity.unitWeight.toString()} ${measureMass}`
    }, {
        id: `${entity.uuid}-fragility-type`,
        primary: t('FRAGILITY TYPE'),
        secondary: entity.fragilityType.name
    }, {
        id: `${entity.uuid}-fragility-level`,
        primary: t('FRAGILITY LEVEL'),
        secondary: entity.fragilityLevel.toString()
    }, {
        id: `${entity.uuid}-priority`,
        primary: t('PRIORITY'),
        secondary: t(`${entity.priority ? 'YES' : 'NO'}`)
    }, {
        id: `${entity.uuid}-amount`,
        primary: t('AMOUNT'),
        secondary: currencyFormat(entity.amount)
    }]);

    const getAddressDetails = useCallback((address: ILoadAddressShortResponseDto): string => {
        let value: string | undefined;
        switch (address.addressReference) {
            case ELoadAddressReference.WAREHOUSE: value = `${address.warehouse?.name} [ ${address.warehouse?.address.value} ]`; break;
            case ELoadAddressReference.CUSTOMER: value = `${address.customer?.name} [ ${address.customer?.address.value} ]`; break;
            case ELoadAddressReference.ADDRESS: value = address.address?.value; break;
        };
        return value || '';
    }, []);

    const itemsRight = useRef<IItemDetails[]>([{
        id: `${entity.uuid}-pickup`,
        primary: t(`PICKUP ADDRESS [ ${entity.pickupAddress.addressReference} ]`),
        secondary: getAddressDetails(entity.pickupAddress)
    }, {
        id: `${entity.uuid}-delivery`,
        primary: t(`DELIVERY ADDRESS [ ${entity.pickupAddress.addressReference} ]`),
        secondary: getAddressDetails(entity.deliveryAddress)
    }, {
        id: `${entity.uuid}-description`,
        primary: t('NOTES'),
        secondary: entity.description,
        secondaryTextAlign: 'justify'
    }, {
        id: `${entity.uuid}-status`,
        primary: t('STATUS'),
        secondary: entity.status.replaceAll('_', ' ')
    }, {
        id: `${entity.uuid}-created`,
        primary: t('CREATED'),
        secondary: `${entity.createdBy} [ ${getDateAndTimeFormat(entity.createdDate, dateFormat, timeFormat)} ]`
    }]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='row' spacing={3}>
                <Box width={'250px'}>
                    <ItemsDetails data={itemsLeft.current} />
                </Box>
                <Box flex={2}>
                    <ItemsDetails data={itemsRight.current} />
                </Box>
            </Stack>
        );
    }, []);

    return (
        <BaseDialog
            open={open}
            title={t('LOAD ITEM DETAILS')}
            maxWidth={'md'}
            buildContent={onBuildContent}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
            closeBtnColor={'primary'}
            closeBtnVariant={'text'}
            closeBtnIconHide={true}
        />
    );
}
export default LoadItemsDetailsDialog;