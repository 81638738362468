import { SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { useActiveLoad } from "../../../../hooks/useActiveLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { IActiveLoadPendingUserResponseDto, IActiveLoadUserRequestDto, IActiveLoadUserResponseDto } from "../../../../models/ActiveLoadModels";
import { IAuthority } from "../../../../models/PermissionModels";
import ActiveLoadService from "../../../../services/ActiveLoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import RoleSelect from "../../../RoleModule/RoleSelect";
import ActiveLoadUserAutocomplete from "./ActiveLoadUsersAutocomplete";

interface IProps {
    activeLoadId: string;
    activeLoadUser?: IActiveLoadUserResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const ActiveLoadUsersDialog = (props: IProps) => {
    const { open, activeLoadId, activeLoadUser, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useActiveLoad();
    const formId: string = 'active-load-users-form';

    const isEdit = useRef<boolean>(activeLoadUser !== undefined);
    const [type, setType] = useState<IAuthority>(activeLoadUser && activeLoadUser.user.role
        ? activeLoadUser.user.role
        : IAuthority.NONE
    );
    const [loading, setLoading] = useState(false);
    const [lastUserId, setLastUserId] = useState<string | undefined>(undefined);

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid } } = useForm<IActiveLoadUserRequestDto>({
        defaultValues: {
            userId: isEdit.current ? activeLoadUser?.user.uuid : ''
        }
    });

    const updateData = useCallback((uuid: string, userId: string) => {
        (async () => {
            const [error, response] = await ActiveLoadService.updateUser(activeLoadId, uuid, userId);
            if (response) {
                displayNotification({ message: t('Active load user was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLoadId, t]);

    const createData = useCallback((userId: string) => {
        (async () => {
            const [error, response] = await ActiveLoadService.createUser(activeLoadId, userId);
            if (response) {
                displayNotification({ message: t('Active load user was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                stepRefresh();
                gridRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLoadId, t]);

    const onSubmit = useCallback((data: IActiveLoadUserRequestDto) => {
        setLoading(true);
        if (activeLoadUser) {
            updateData(activeLoadUser.uuid, data.userId);
        } else {
            createData(data.userId);
        }
    }, [activeLoadUser, createData, updateData]);

    const validateUserField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('userId', { validate: validateUserField });
    const onUserChangeHandler = useCallback((value: IActiveLoadPendingUserResponseDto | null) => {
        setValue('userId', value ? value.user.uuid : '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        const type: IAuthority = event.target.value as IAuthority;

        if (!isEdit.current) {
            (async () => {
                const [, response] = await ActiveLoadService.fetchLastUser(activeLoadId, type);
                if (response) {
                    setLastUserId(response.data.body);
                }
            })();
        }

        setType(type);
    }, [activeLoadId]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <RoleSelect
                        required
                        label={t('TYPE')}
                        value={type}
                        onChange={onTypeChangeHandler}
                    />

                    <ActiveLoadUserAutocomplete
                        required
                        label={t('USER')}
                        lastUserId={lastUserId}
                        activeLoadId={activeLoadId}
                        type={type}
                        value={getValues('userId')}
                        activeLoadUser={activeLoadUser}
                        onChange={onUserChangeHandler}
                    />
                </Stack>
            </form>
        );
    }, [activeLoadId, activeLoadUser, getValues, handleSubmit, lastUserId, onSubmit, onTypeChangeHandler, onUserChangeHandler, t, type]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} USER`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default ActiveLoadUsersDialog;