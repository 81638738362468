import BasePage from '../components/Base/BasePageComponent/BasePage';

const RoutePage = () => {
    return (
        <BasePage
            onApplyFilter={() => console.log('')}
            onResetState={() => console.log('')}
            mainSection={<></>}
            stepSection={<></>}
        />
    );
}
export default RoutePage;