import { Box, Icon, Tooltip, Typography } from "@mui/material";

import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IMenuElement } from "../../../models/IMenuElement";
import st from './MenuComponent.module.scss';

interface IProps {
    disable: boolean;
    hints: boolean;
    active: boolean;
    bigSize: number;
    menu: IMenuElement;
    onClick: (path: string) => void;
}
export default function MenuComponent(props: IProps) {
    const { active, bigSize, menu, hints, onClick, disable } = props;
    const { t } = useTranslation();

    return (
        <>
            <div>
                <Tooltip title={hints ? t(menu.description ? menu.description : '') + ': CTRL+SHIFT+' + menu.short : ''} placement={'left'} arrow>
                    {
                        disable
                            ?
                            <Box className={st.state}>
                                <Icon sx={{ color: "silver" }} >{menu.icon}</Icon>
                                {(bigSize === 1) && <Typography sx={{ fontSize: "12px" }} color={"silver"} >{`${t(menu.name)}`}</Typography>}
                            </Box>
                            :
                            <Box className={active ? st.active : st.wrap} onClick={() => { onClick(menu.to) }}  >
                                <Icon color={"primary"} >{menu.icon}</Icon>
                                {(props.bigSize === 1) && <Typography sx={{ fontSize: "12px" }} color={"primary"} >{`${t(menu.name)}`}</Typography>}
                            </Box>
                    }
                </Tooltip>
                <Divider />
            </div>
        </>
    );
}