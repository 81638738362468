import { Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isEmpty, normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { useWarehouse } from "../../../hooks/useWarehouse";
import { IAddressShortResponseDto } from "../../../models/AddressModels";
import { IWarehouseOverviewResponseDto, IWarehouseRequestDto, IWarehouseTypeResponseDto } from "../../../models/WarehouseModels";
import WarehouseService from "../../../services/WarhouseService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import Address from "../../Base/GeolocationComponent/Address";
import WarehouseTypeAutocomplete from "../WarehouseTypeAutocomplete";

interface IProps {
    open: boolean;
    entity?: IWarehouseOverviewResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const WarehouseDialog = (props: IProps) => {
    const { open, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const isEdit = useRef<boolean>(entity !== undefined);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useWarehouse();
    const formId: string = 'warehouse-form';

    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IWarehouseRequestDto>({
        defaultValues: {
            name: isEdit.current ? entity?.name : '',
            contactName: isEdit.current ? entity?.contactName : '',
            typeIds: isEdit.current ? entity?.types.map(item => item.uuid) : [],
            addressId: isEdit.current ? entity?.address.uuid : ''
        }
    });

    const updateData = useCallback((uuid: string, data: IWarehouseRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await WarehouseService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('Warehouse was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [t]);

    const createData = useCallback((data: IWarehouseRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await WarehouseService.create(data);
            if (response) {
                displayNotification({ message: t('Warehouse was successfully created.') });

                const uuid = response.data.response.entityId;
                const newUrl: string = normalizeNavigationUrl(uuid);
                navigate(`/${newUrl}`);

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [t]);

    const onSubmit = useCallback((data: IWarehouseRequestDto) => {
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, entity, updateData]);

    const validateForIsBlankField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    const validateTypeField = useCallback((value: string[]) => {
        return !isEmpty(value);
    }, []);

    register('typeIds', { validate: validateTypeField });
    const onTypeChangeHandler = useCallback((value: IWarehouseTypeResponseDto[]) => {
        setValue('typeIds', value.map(item => item.uuid), {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('addressId', { validate: validateForIsBlankField });
    const onChangeAddressHandler = useCallback((address: IAddressShortResponseDto) => {
        setValue('addressId', address.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <TextField
                        {...register('name', {
                            required: true,
                            validate: validateForIsBlankField
                        })}
                        required
                        autoComplete='off'
                        label={t('NAME')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.name}
                    />

                    <TextField
                        {...register('contactName', {
                            required: true,
                            validate: validateForIsBlankField
                        })}
                        required
                        autoComplete='off'
                        label={t('CONTACT NAME')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.name}
                    />

                    <WarehouseTypeAutocomplete
                        required
                        label={t('TYPE')}
                        value={getValues('typeIds')}
                        onChange={onTypeChangeHandler}
                    />

                    <Address
                        value={entity?.address}
                        required
                        onChange={onChangeAddressHandler}
                    />
                </Stack>
            </form>
        );
    }, [
        entity?.address, errors.name, getValues, handleSubmit, onChangeAddressHandler,
        onSubmit, onTypeChangeHandler, register, t, validateForIsBlankField
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} WAREHOUSE`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default WarehouseDialog;