import AddIcon from '@mui/icons-material/Add';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridDensity, GridPaginationModel, GridRowSelectionModel, GridToolbarContainer } from "@mui/x-data-grid";
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useCallback, useEffect, useRef, useState } from "react";
import { isEmpty } from '../../../helpers/generalHelper';
import { ECriteriaExpression, IBaseGridActionBtn, ICriteria, IFilter, IPaging } from '../../../models/CommonModels';
import { EButtonColor } from "../../../models/EnumGeneral";
import ButtonConfirmation from '../ActionConfirmationComponent/ButtonConfirmation';
import BaseDownloadBtn from '../BaseDownloadBtnComponent/BaseDownloadBtn';

interface IProps {
    refresh: boolean;
    unselectRows: boolean;
    criterias: ICriteria[];

    columns: GridColDef<any>[];
    apiRef?: React.MutableRefObject<GridApiCommunity>;
    density: GridDensity;
    checkboxSelection: boolean;
    retrieveDataApi: (filter: IFilter) => Promise<any>;
    onSelectionModelChange?: (selectedRows: any[]) => void
    onInitData?: (data: []) => void;

    addActionBtnIcon?: boolean;
    onAddActionBtnClick?: () => void;
    addActionBtnHide?: boolean;
    addActionBtnLabel?: string;
    addActionBtnTooltip?: string;

    onEditActionBtnClick?: (row: any) => void;
    editActionBtnHide?: boolean;
    editActionBtnLabel?: string;
    editActionBtnTooltip?: string;
    editActionBtnDisabled?: boolean;

    onDeleteActionBtnClick?: (rowIds: string[]) => void;
    deleteActionBtnHide?: boolean;
    deleteActionBtnLabel?: string;
    deleteActionBtnTooltip?: string;
    deleteActionBtnDisabled?: boolean;

    onFilterActionBtnClick?: () => void;
    filterActionBtnShow?: boolean;
    filterActionBtnTooltip?: string;

    downloadActionBtnApi?: (filter: IFilter) => Promise<any>;
    downloadActionBtnShow?: boolean;
    downloadActionBtnTooltip?: string;

    actionBtns?: IBaseGridActionBtn[];

    refreshActionBtnTooltip?: string;
}
const BaseCrudGrid = (props: IProps) => {
    const {
        retrieveDataApi, actionBtns, onSelectionModelChange, onInitData, addActionBtnIcon = true,
        onAddActionBtnClick, onEditActionBtnClick, onDeleteActionBtnClick,
        onFilterActionBtnClick, refresh, unselectRows, criterias, apiRef, columns, density,
        checkboxSelection, addActionBtnHide = false, addActionBtnLabel = 'ADD', addActionBtnTooltip = 'Add item',
        editActionBtnHide = false, editActionBtnLabel = 'EDIT', editActionBtnTooltip = 'Edit item',
        deleteActionBtnHide = false, deleteActionBtnLabel = 'DELETE', deleteActionBtnTooltip = 'Delete item(s)',
        refreshActionBtnTooltip = 'Refresh data', filterActionBtnShow = false,
        filterActionBtnTooltip = 'Search', downloadActionBtnApi,
        downloadActionBtnShow = false, downloadActionBtnTooltip = 'Download'
    } = props;

    const isFilterApplied: boolean = !isEmpty(criterias);
    const rowsPerPageOptions: number[] = [10, 20, 50];

    const toolbarActionBtnsDividerShow = useRef<boolean>(
        (
            !addActionBtnHide
            || !editActionBtnHide
            || !deleteActionBtnHide
            || filterActionBtnShow
            || downloadActionBtnShow
        ) && actionBtns !== undefined
    );

    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [paging, setPaging] = useState<IPaging>({ page: 0, size: rowsPerPageOptions[2] });
    const [editActionBtnDisabled, setEditActionBtnDisabled] = useState<boolean>(true);
    const [deleteActionBtnDisabled, setDeleteActionBtnDisabled] = useState<boolean>(true);
    const [privateRefresh, setPrivateRefresh] = useState<boolean>(false);

    const fetchData = useCallback(() => {
        setLoading(true);

        const filter: IFilter = {
            criteria: criterias,
            paging: paging
        };

        (async () => {
            const [error, response] = await retrieveDataApi(filter);
            if (response) {
                const data: [] = response.data.body;

                setData(data);
                setTotalRows(response.data.total);

                if (onInitData) {
                    onInitData(data);
                }

                setLoading(false);
            }

            if (error) {
                setLoading(false);
            }
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging, criterias, retrieveDataApi]);

    useEffect(() => {
        fetchData();
    }, [fetchData, refresh, privateRefresh]);

    useEffect(() => {
        setSelectionModel([]);
        setEditActionBtnDisabled(true);
        setDeleteActionBtnDisabled(true);

        if (onSelectionModelChange) {
            onSelectionModelChange([]);
        }
    }, [onSelectionModelChange, unselectRows]);

    const generateRowId = useCallback((row: any) => {
        return row.uuid;
    }, []);

    const onPaginationModelChangeHandler = useCallback((model: GridPaginationModel) => {
        const newPaging: IPaging = { ...paging, size: model.pageSize, page: model.page };
        setPaging(newPaging);
    }, [paging]);

    const onRowSelectionModelChangeHandler = useCallback((selectionModel: GridRowSelectionModel) => {
        const selectedRowsCount: number = selectionModel.length;
        setEditActionBtnDisabled(selectedRowsCount === 0 || selectedRowsCount > 1);
        setDeleteActionBtnDisabled(selectedRowsCount === 0);

        if (onSelectionModelChange) {
            const selectedData: any[] = data.filter((row: any) => selectionModel.includes(row.uuid));
            onSelectionModelChange(selectedData);
        }

        setSelectionModel(selectionModel);
    }, [data, onSelectionModelChange]);

    const onAddActionBtnClickHandler = useCallback(() => {
        if (onAddActionBtnClick) {
            onAddActionBtnClick();
        }
    }, [onAddActionBtnClick]);

    const buildAddActionBtn = useCallback(() => {
        return (
            < Tooltip title={addActionBtnTooltip} placement='top' >
                <Button size='small' variant='outlined' onClick={onAddActionBtnClickHandler} >
                    <AddIcon fontSize='small' />&nbsp;{addActionBtnLabel}
                </Button>
            </Tooltip >
        );
    }, [addActionBtnLabel, addActionBtnTooltip, onAddActionBtnClickHandler]);

    const buildAddActionIconBtn = useCallback(() => {
        return (
            < Tooltip title={addActionBtnTooltip} placement='top' >
                <IconButton size='small' color='primary' onClick={onAddActionBtnClickHandler}>
                    <DashboardCustomizeIcon />
                </IconButton>
            </Tooltip >
        );
    }, [addActionBtnTooltip, onAddActionBtnClickHandler]);

    const onEditActionBtnClickHandler = useCallback(() => {
        if (onEditActionBtnClick) {
            const selectedRowData: any = data.find((row: any) => selectionModel.includes(row.uuid));
            onEditActionBtnClick(selectedRowData);
        }
    }, [data, onEditActionBtnClick, selectionModel]);

    const buildEditActionBtn = useCallback(() => {
        return (
            < Tooltip title={editActionBtnTooltip} placement='top' >
                <span>
                    <Button
                        disabled={editActionBtnDisabled || props.editActionBtnDisabled}
                        size='small'
                        variant={'outlined'}
                        onClick={onEditActionBtnClickHandler} >
                        <EditIcon fontSize='small' />&nbsp;{editActionBtnLabel}
                    </Button>
                </span>
            </Tooltip >
        );
    }, [editActionBtnTooltip, onEditActionBtnClickHandler, editActionBtnLabel, editActionBtnDisabled, props.editActionBtnDisabled]);

    const onDeleteActionBtnClickHandler = useCallback(() => {
        if (onDeleteActionBtnClick) {
            onDeleteActionBtnClick(selectionModel as string[]);
        }
    }, [onDeleteActionBtnClick, selectionModel]);

    const buildDeleteActionBtn = useCallback(() => {
        return (
            <ButtonConfirmation
                variant='outlined'
                onConfirm={onDeleteActionBtnClickHandler}
                label={deleteActionBtnLabel}
                icon={<DeleteIcon fontSize='small' />}
                color={EButtonColor.error}
                disabled={deleteActionBtnDisabled || props.deleteActionBtnDisabled}
                tooltip={deleteActionBtnTooltip}
            />
        );
    }, [deleteActionBtnDisabled, deleteActionBtnLabel, deleteActionBtnTooltip, onDeleteActionBtnClickHandler, props.deleteActionBtnDisabled]);

    const onPrivateRefreshHandler = useCallback(() => {
        setPrivateRefresh(privateRefresh => !privateRefresh);
    }, []);

    const buildRefreshActionBtn = useCallback(() => {
        return (
            < Tooltip title={refreshActionBtnTooltip} placement='top' >
                <IconButton color={'primary'} size='small' onClick={onPrivateRefreshHandler}>
                    <RefreshIcon fontSize='small' />
                </IconButton>
            </Tooltip >
        );
    }, [onPrivateRefreshHandler, refreshActionBtnTooltip]);

    const onFilterActionBtnClickHandler = useCallback(() => {
        if (onFilterActionBtnClick) {
            onFilterActionBtnClick();
        }
    }, [onFilterActionBtnClick]);

    const buildFilterActionIconBtn = useCallback(() => {
        return (
            < Tooltip title={filterActionBtnTooltip} placement='top' >
                <IconButton size='small' color='primary' onClick={onFilterActionBtnClickHandler} >
                    {isFilterApplied ? <SearchOffIcon /> : <SearchIcon />}
                </IconButton>
            </Tooltip >
        );
    }, [filterActionBtnTooltip, isFilterApplied, onFilterActionBtnClickHandler]);

    const downloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        if (downloadActionBtnApi) {
            const selectedRowsCount: number = selectionModel.length;
            let downloadCriterias: ICriteria[];
            if (selectedRowsCount > 0) {
                const ids: string[] = selectionModel as string[];
                const idsAsCSV: string = ids.join();

                downloadCriterias = [{
                    property: "uuid",
                    value: idsAsCSV,
                    expression: ECriteriaExpression.IN
                }];
            } else {
                downloadCriterias = criterias;
            }

            filter.criteria = downloadCriterias;
            filter.paging = {
                page: paging.page,
                size: paging.size
            };
            return downloadActionBtnApi(filter);
        }
        return Promise.resolve();
    }, [criterias, downloadActionBtnApi, paging.page, paging.size, selectionModel]);

    const buildDownloadActionBtn = useCallback(() => {
        return (
            <BaseDownloadBtn
                downloadApi={downloadActionBtnApiHandler}
                tooltipPlacementTop
                tooltipEnabled
                tooltip={downloadActionBtnTooltip}
                iconBtnColor='primary'
                disabled={isEmpty(data)}
            />
        );
    }, [data, downloadActionBtnApiHandler, downloadActionBtnTooltip]);

    const onActionBtnClickHandler = useCallback((onClick: (row: any) => void): void => {
        const selectedRowData: any = data.find((row: any) => selectionModel.includes(row.uuid));
        onClick(selectedRowData);
    }, [data, selectionModel]);

    const toolbar = useCallback(() => {
        return (
            <GridToolbarContainer style={{ paddingBottom: '5px', borderBottom: '1px solid #d4d4d4' }}>
                {!addActionBtnHide && addActionBtnIcon ? buildAddActionIconBtn() : buildAddActionBtn()}
                {!editActionBtnHide && buildEditActionBtn()}
                {!deleteActionBtnHide && buildDeleteActionBtn()}

                {filterActionBtnShow && buildFilterActionIconBtn()}
                {downloadActionBtnShow && buildDownloadActionBtn()}

                {toolbarActionBtnsDividerShow.current &&
                    < Divider sx={{ marginRight: '5px', marginLeft: '5px' }} orientation='vertical' />
                }

                {actionBtns && actionBtns.map((btn) =>
                    btn.confirmation
                        ?
                        <ButtonConfirmation
                            key={btn.key}
                            variant='outlined'
                            onConfirm={() => onActionBtnClickHandler(btn.onClick)}
                            label={btn.label}
                            icon={btn.icon}
                            disabled={btn.disabled}
                            tooltip={btn.tooltip}
                            iconBtn={btn.iconBtn}
                        />
                        :
                        < Tooltip key={btn.key} title={btn.tooltip} placement='top' >
                            <span>
                                {btn.iconBtn
                                    ? <IconButton
                                        disabled={btn.disabled}
                                        size='small'
                                        color='primary'
                                        onClick={() => onActionBtnClickHandler(btn.onClick)}
                                    >
                                        {btn.icon}&nbsp;{btn.label}
                                    </IconButton>
                                    : <Button
                                        disabled={btn.disabled}
                                        size='small'
                                        variant='outlined'
                                        onClick={() => onActionBtnClickHandler(btn.onClick)}
                                    >
                                        {btn.icon}&nbsp;{btn.label}
                                    </Button>
                                }
                            </span>
                        </Tooltip>
                )}

                <div style={{ flex: '1 0 0' }} />

                {buildRefreshActionBtn()}
            </GridToolbarContainer>
        );
    }, [actionBtns, addActionBtnHide, addActionBtnIcon, buildAddActionBtn, buildAddActionIconBtn, buildDeleteActionBtn, buildDownloadActionBtn,
        buildEditActionBtn, buildFilterActionIconBtn, buildRefreshActionBtn, deleteActionBtnHide, downloadActionBtnShow, editActionBtnHide,
        filterActionBtnShow, onActionBtnClickHandler
    ]);

    return (
        <DataGrid
            density={density}
            paginationMode='server'
            loading={loading}
            rows={data}
            getRowId={generateRowId}
            columns={columns}
            rowCount={totalRows}
            slots={{ toolbar: toolbar }}
            apiRef={apiRef}
            paginationModel={{ pageSize: paging.size, page: paging.page }}
            onPaginationModelChange={onPaginationModelChangeHandler}
            pageSizeOptions={rowsPerPageOptions}
            checkboxSelection={checkboxSelection}
            disableRowSelectionOnClick
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={onRowSelectionModelChangeHandler}
        />
    );
}
export default BaseCrudGrid;