import { Box, SelectChangeEvent, Skeleton, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { IAddressShortResponseDto } from "../../../../models/AddressModels";
import { ICustomerAutocompleteResponseDto } from "../../../../models/CustomerModels";
import { ELoadAddressReference, ELoadAddressType, ILoadAddressRequestDto, ILoadAddressResponseDto } from "../../../../models/LoadModels";
import { IWarehouseAutocompleteResponseDto } from "../../../../models/WarehouseModels";
import LoadService from "../../../../services/LoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import Address from "../../../Base/GeolocationComponent/Address";
import CustomerAutocomplete from "../../../CustomerModule/CustomerAutocomplete";
import WarehouseAutocomplete from "../../../WarehouseModule/WarehouseAutocomplete";
import LoadAddressCategorySelect from "./LoadAddressCategorySelect";
import LoadAddressTypeSelect from "./LoadAddressTypeSelect";

interface IProps {
    loadId: string;
    entity?: ILoadAddressResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadAddressesDialog = (props: IProps) => {
    const { open, loadId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'load-address-form';
    const { gridRefresh } = useLoad();

    const isEdit = useRef<boolean>(entity !== undefined);
    const defaultAddress = useRef<string>(t('No address has been selected.'));
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState<string>(defaultAddress.current);
    const [addressReference, setAddressReference] = useState<ELoadAddressReference>(entity ? entity.addressReference : ELoadAddressReference.NONE);

    const getAddressId = useCallback(() => {
        if (!entity) {
            return '';
        }

        const reference: ELoadAddressReference = entity.addressReference;
        switch (reference) {
            case ELoadAddressReference.WAREHOUSE: return entity.warehouse ? entity.warehouse.uuid : '';
            case ELoadAddressReference.CUSTOMER: return entity.customer ? entity.customer.uuid : '';
            case ELoadAddressReference.ADDRESS: return entity.address ? entity.address.uuid : '';
            default: return '';
        }
    }, [entity]);

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid } } = useForm<ILoadAddressRequestDto>({
        defaultValues: {
            type: isEdit.current ? entity?.type : ELoadAddressType.NONE,
            alias: isEdit.current ? entity?.alias : '',
            addressReference: isEdit.current ? entity?.addressReference : ELoadAddressReference.NONE,
            addressId: isEdit.current ? getAddressId() : ''
        }
    });

    const updateData = useCallback((uuid: string, data: ILoadAddressRequestDto) => {
        (async () => {
            const [error, response] = await LoadService.updateAddress(loadId, uuid, data);
            if (response) {
                displayNotification({ message: t('Load address was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                gridRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, onCloseBtnClick, onSubmitBtnClick, t]);

    const createData = useCallback((data: ILoadAddressRequestDto) => {
        (async () => {
            const [error, response] = await LoadService.createAddress(loadId, data);
            if (response) {
                displayNotification({ message: t('Load address was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                gridRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, onCloseBtnClick, onSubmitBtnClick, t]);

    const onSubmit = useCallback((data: ILoadAddressRequestDto) => {
        setLoading(true);
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, entity, updateData]);

    const validateTypeField = useCallback((value: ELoadAddressType) => {
        return ELoadAddressType[value] !== undefined && value !== ELoadAddressType.NONE;
    }, []);

    register('type', { validate: validateTypeField });
    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as ELoadAddressType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateCategoryField = useCallback((value: ELoadAddressReference) => {
        return ELoadAddressReference[value] !== undefined && value !== ELoadAddressReference.NONE;
    }, []);

    register('addressReference', { validate: validateCategoryField });
    const onCategoryChangeHandler = useCallback((event: SelectChangeEvent) => {
        const addressReference: ELoadAddressReference = event.target.value as ELoadAddressReference;
        setValue('addressReference', addressReference, {
            shouldValidate: true,
            shouldDirty: true
        });
        setAddressReference(addressReference);
    }, [setValue]);

    const validateForIsBlankField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);
    register('addressId', { validate: validateForIsBlankField });

    const onCustomerChangeHandler = useCallback((value: ICustomerAutocompleteResponseDto | null) => {
        setValue('addressId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
        setAddress(value?.address?.value || defaultAddress.current);
    }, [setValue]);

    const onCustomerInitHandler = useCallback((value: ICustomerAutocompleteResponseDto | null) => {
        setAddress(value?.address?.value || defaultAddress.current);
    }, []);

    const onWarehouseChangeHandler = useCallback((value: IWarehouseAutocompleteResponseDto | null) => {
        setValue('addressId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
        setAddress(value?.address.value || defaultAddress.current);
    }, [setValue]);

    const onWarehouseInitHandler = useCallback((value: IWarehouseAutocompleteResponseDto | null) => {
        setAddress(value?.address.value || defaultAddress.current);
    }, []);

    const onAddressInitHandler = useCallback((value: IAddressShortResponseDto | undefined) => {
        setAddress(value?.value || defaultAddress.current);
    }, []);

    const onAddressChangeHandler = useCallback((value: IAddressShortResponseDto | null) => {
        setValue('addressId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
        setAddress(value?.value || defaultAddress.current);
    }, [setValue]);

    const getAddressTypeComponentByReference = useCallback((): JSX.Element => {
        switch (addressReference) {
            case ELoadAddressReference.WAREHOUSE: return (
                < WarehouseAutocomplete
                    required
                    label={t('WAREHOUSE')}
                    showStatus
                    showAddress
                    showMark
                    disableInactiveItems
                    value={getValues('addressId')}
                    onChange={onWarehouseChangeHandler}
                    onInit={onWarehouseInitHandler}
                />
            );
            case ELoadAddressReference.CUSTOMER: return (
                < CustomerAutocomplete
                    required
                    label={t('CUSTOMER')}
                    showStatus
                    showMark
                    showAddress
                    disableInactiveItems
                    value={getValues('addressId')}
                    onChange={onCustomerChangeHandler}
                    onInit={onCustomerInitHandler}
                />
            );
            case ELoadAddressReference.ADDRESS: return (
                <Address
                    required
                    value={entity?.address}
                    onInit={onAddressInitHandler}
                    onChange={onAddressChangeHandler}
                />
            );
            default: return (
                <Skeleton
                    variant='rounded'
                    height={56}
                    width={'100%'}
                />
            );
        }
    }, [addressReference, entity?.address, getValues, onAddressChangeHandler, onAddressInitHandler,
        onCustomerChangeHandler, onCustomerInitHandler, onWarehouseChangeHandler,
        onWarehouseInitHandler, t
    ]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <LoadAddressTypeSelect
                        label={t('TYPE')}
                        required
                        onChange={onTypeChangeHandler}
                        value={getValues('type')}
                    />

                    <TextField
                        {...register('alias')}
                        autoComplete='off'
                        fullWidth
                        label={t('ALIAS')}
                        slotProps={{htmlInput: {minLength: 1, maxLength: 50}}}
                    />

                    <LoadAddressCategorySelect
                        label={t('ADDRESS TYPE')}
                        required
                        onChange={onCategoryChangeHandler}
                        value={getValues('addressReference')}
                    />

                    {getAddressTypeComponentByReference()}

                    <Box
                        height={50}
                        border='2px dashed #808080'
                        borderRadius={'5px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        padding={1}
                        sx={{ fontSize: '0.840rem' }}
                    >
                        {address}
                    </Box>
                </Stack>
            </form>
        );
    }, [address, getAddressTypeComponentByReference, getValues, handleSubmit,
        onCategoryChangeHandler, onSubmit, onTypeChangeHandler, register, t
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} LOAD ADDRESS`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadAddressesDialog;