import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { UserActions } from "../store/reducers/userSlice";

export const useUser = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(UserActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(UserActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(UserActions.applyFilter(criteria));
    };

    const stepRefresh = () => {
        dispatch(UserActions.stepRefresh());
    };

    const paysGridRefresh = () => {
        dispatch(UserActions.paysGridRefresh());
    };

    const incomeGridRefresh = () => {
        dispatch(UserActions.incomeGridRefresh());
    };

    return { gridRefresh, applyFilter, stepRefresh, resetState, paysGridRefresh, incomeGridRefresh } as const;
};