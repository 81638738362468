import { Box, Paper } from "@mui/material";
import LoadDescription from "./LoadDescription";

interface IProps {
    loadId: string;
}
const LoadDescriptionStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <LoadDescription loadId={props.loadId} />
            </Box>
        </Paper>
    );
}
export default LoadDescriptionStep;