import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISocketConversationMessage } from "../../models/PersonalChatModels";
import { EDateFormat, ETimeFormat } from "../../models/ProfileModels";

interface CnfState {
    interfaceState: any;
    chat: {
        refresh: boolean,
        refreshRead: boolean,
        readMessageSocket: string,
        lastMessage: ISocketConversationMessage | null,
        partnerId: string,
        conversationId: string,
        autoRead: boolean
    }
}

const initialState: CnfState = {
    interfaceState: {
        language: 'en',
        notificationEmailEnabled: false,
        notificationPushEnabled: true,
        pathURL: '/settings',
        hints: true,
        darkMode: false,
        menuState: 1,
        presentMenu: true,
        presentFlip: false,
        rightFlip: false,
        leftFlip: true,
        dateFormat: EDateFormat.d_mm_dd_yyyy,
        timeFormat: ETimeFormat.h_12
    },
    chat: {
        refresh: false,
        refreshRead: false,
        lastMessage: null,
        partnerId: '',
        conversationId: '',
        readMessageSocket: '',
        autoRead: false
    }
}

export const cnfSlice = createSlice({
    name: 'cnf',
    initialState,
    reducers: {
        modifyChatLastMessage(state, action: PayloadAction<ISocketConversationMessage | null>) {
            if (state.chat.conversationId === '*') {
                state.chat.lastMessage = action.payload;
                return
            }

            if (action.payload?.conversationId !== state.chat.conversationId) {
                state.chat.lastMessage = null;
            } else {
                state.chat.lastMessage = action.payload;
            }
        },
        modifyChatRefresh(state, action: PayloadAction<string>) {
            if (action.payload === '*') {
                state.chat.refresh = !state.chat.refresh;
                state.chat.partnerId = state.chat.conversationId;
            } else if (state.chat.conversationId !== action.payload || !state.chat.autoRead) {
                state.chat.refresh = !state.chat.refresh;
                state.chat.partnerId = action.payload
            }
        },
        modifyChatConversationId(state, action: PayloadAction<string>) {
            state.chat.conversationId = action.payload;
        },
        modifyChatAutoRead(state, action: PayloadAction<boolean>) {
            state.chat.autoRead = action.payload;
        },
        modifyChatPartnerId(state, action: PayloadAction<string>) {
            state.chat.partnerId = action.payload;
        },
        modifyChatMessageReadRefresh(state, action: PayloadAction<string>) {
            state.chat.refreshRead = !state.chat.refreshRead;
            state.chat.readMessageSocket = action.payload
        },
        modifyModeTheme(state, action: PayloadAction<boolean>) {
            state.interfaceState.darkMode = action.payload;
        },
        modifyDateFormat(state, action: PayloadAction<string>) {
            state.interfaceState.dateFormat = action.payload as EDateFormat;
        },
        modifyTimeFormat(state, action: PayloadAction<string>) {
            state.interfaceState.timeFormat = action.payload as ETimeFormat;
        },
        modifyLanguage(state, action: PayloadAction<string>) {
            state.interfaceState.language = action.payload;
        },
        modifyEmailNotifications(state, action: PayloadAction<boolean>) {
            state.interfaceState.notificationEmailEnabled = action.payload;
        },
        modifyPushNotifications(state, action: PayloadAction<boolean>) {
            state.interfaceState.notificationPushEnabled = action.payload;
        },
        modifyShowHits(state, action: PayloadAction<boolean>) {
            state.interfaceState.hints = action.payload;
        },
        modifyMenuState(state, action: PayloadAction<number>) {
            state.interfaceState.menuState = action.payload;
        },
        modifyPresentMenu(state) {
            state.interfaceState.presentMenu = !state.interfaceState.presentMenu;
        },
        modifyPresentFlip(state) {
            state.interfaceState.presentFlip = !state.interfaceState.presentFlip;
        },
        modifyUserInterfaceState(state, action: PayloadAction<any>) {
            state.interfaceState = action.payload;
        },
        modifyURLState(state, action: PayloadAction<string>) {
            state.interfaceState.pathURL = action.payload;
        },
        modifyLeftFlip(state) {
            if (!state.interfaceState.rightFlip) {
                state.interfaceState.leftFlip = false;
            } else {
                state.interfaceState.leftFlip = !state.interfaceState.leftFlip;
            }
        },
        modifyRightFlip(state, action: PayloadAction<boolean>) {
            state.interfaceState.rightFlip = action.payload;
            if (!action.payload) {
                state.interfaceState.leftFlip = false;
            }
        }
    }
})

export const {
    modifyChatMessageReadRefresh,
    modifyChatAutoRead,
    modifyChatLastMessage,
    modifyChatConversationId,
    modifyChatPartnerId,
    modifyPushNotifications,
    modifyEmailNotifications,
    modifyLanguage,
    modifyChatRefresh,
    modifyLeftFlip,
    modifyRightFlip,
    modifyURLState,
    modifyUserInterfaceState,
    modifyPresentFlip,
    modifyPresentMenu,
    modifyShowHits,
    modifyMenuState,
    modifyModeTheme,
    modifyDateFormat,
    modifyTimeFormat,
} = cnfSlice.actions;
export default cnfSlice.reducer;