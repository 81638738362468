import { Box, Paper } from "@mui/material";
import CustomerDescription from "./CustomerDescription";

interface IProps {
    customerId: string;
}
const CustomerDescriptionStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <CustomerDescription customerId={props.customerId} />
            </Box>
        </Paper>
    );
}
export default CustomerDescriptionStep;