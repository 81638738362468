import AutoModeIcon from '@mui/icons-material/AutoMode';
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { generateNumber } from '../../../helpers/generalHelper';
import { isBlank } from '../../../helpers/textHelper';

interface IProps {
    label?: string;
    value?: string;
    required?: boolean;
    autoFocus?: boolean;
    generateBtnTooltip?: string;
    onChange?: (value: string, error?: string) => void;
    checkWithServerApi?: (value: string) => Promise<any>;
    errorMessage?: string;
}
const IdnoGenerator = (props: IProps) => {
    const {
        label = 'Value', required = false, autoFocus = false, generateBtnTooltip = 'GENERATE',
        errorMessage = 'The value is used.', checkWithServerApi, onChange
    } = props;

    const oldValue = useRef<string>(props.value || '');

    const [error, setError] = useState<string | undefined>(undefined);
    const [value, setValue] = useState<string>(props.value || '');

    const onChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setValue(value);

        if (oldValue.current !== value && checkWithServerApi && !isBlank(value)) {
            (async () => {
                const [, response] = await checkWithServerApi(value);
                if (response) {
                    const error: string | undefined = response.data.body ? undefined : errorMessage;
                    setError(error);
                    if (onChange) {
                        onChange(value, error);
                    }
                }
            })();
        } else if (onChange) {
            setError(undefined);
            onChange(value);
        }
    }, [checkWithServerApi, errorMessage, onChange]);

    const onGenerateBtnClick = useCallback(() => {
        let generatedValue: string = '';
        setError(undefined);

        if (checkWithServerApi) {
            (async () => {
                let isValidNumber: boolean = false;
                while (!isValidNumber) {
                    generatedValue = generateNumber(1, 9999999).toString();
                    const [, response] = await checkWithServerApi(generatedValue);
                    if (response && response.data.body) {
                        isValidNumber = true;
                    }
                }
            })();
        } else {
            generatedValue = generateNumber(1, 9999999).toString();
        }

        setValue(generatedValue);

        if (onChange) {
            onChange(generatedValue);
        }
    }, [checkWithServerApi, onChange]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
            <TextField
                fullWidth
                autoComplete='off'
                required={required}
                autoFocus={autoFocus}
                label={label}
                value={value}
                slotProps={{htmlInput: {minLength: 1, maxLength: 50}}}
                error={error !== undefined}
                helperText={error}
                onChange={onChangeHandler}
            />

            <Tooltip title={generateBtnTooltip}>
                <IconButton onClick={onGenerateBtnClick}>
                    <AutoModeIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}
export default IdnoGenerator;