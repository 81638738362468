import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import OAuthPage from "../pages/OAuthPage";

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/oauth2/done" element={<OAuthPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
}
export default PublicRoutes;