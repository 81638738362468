import { isBlank } from "./textHelper";

export const compareValue = (a1: any, b1: any): boolean => {
    return JSON.stringify(a1) === JSON.stringify(b1);
}

export const isValidPhone = (phoneNumber: string): boolean => {
    if (phoneNumber.length < 8 || phoneNumber.length > 16) {
        return false;
    }

    if (phoneNumber.startsWith('00')) {
        return false;
    }

    const prefixPhoneRegex = /^\+\d+$/,
        isPrefixZeroFirstCharacters = phoneNumber.startsWith('+0');
    if (prefixPhoneRegex.test(phoneNumber) && !isPrefixZeroFirstCharacters) {
        return true;
    }

    const onlyDigitsRegex = /^\d+$/,
        isZeroFirstCharacter = phoneNumber.startsWith('0');
    if (isZeroFirstCharacter && onlyDigitsRegex.test(phoneNumber)) {
        return true;
    }

    return false;
}

export const isRealNumber = (n: string) => {
    let pattern = /^\d*[.]?\d*$/;
    return pattern.test(n);
}

export const isSSN = (str: string) => {
    let pattern = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
    return pattern.test(str);
}

export const isNumber = (str: string) => {
    let pattern = /^\d+\.?\d*$/;
    return pattern.test(str);
}

export const isValidEmail = (email: string) => {
    return /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(email);
}

export const isValidUrl = (url: string) => {
    return /^((https?|ftp):\/\/)?([a-z0-9]{1})((\.[a-z0-9-])|([a-z0-9-]))*\.([a-z]{2,6})(\/?)$/.test(url);
}

export const ObjectToValueArray = (obj: any): any[] => {
    var arr = [];
    for (var i in obj) {
        var v = obj[i];
        arr.push(v);
    }
    return arr;
}

export const ObjectToValueArrayMod = (obj: any): any[] => {
    var arr = [];
    for (var i in obj) {
        var v = obj[i];
        if (v) {
            arr.push(v);
        }
    }
    return arr;
}

export const randomColor = () => {

    let n1 = Math.floor(Math.random() * 255);
    let h1 = n1.toString(16);
    if (h1.length < 2) { h1 = '0' + h1 }

    let n2 = Math.floor(Math.random() * 255);
    let h2 = n2.toString(16);
    if (h2.length < 2) { h2 = '0' + h2 }

    let n3 = Math.floor(Math.random() * 255);
    let h3 = n3.toString(16);
    if (h3.length < 2) { h3 = '0' + h3 }

    return '#' + h1 + h2 + h3;
}

export const randomPhoto = () => {
    const s = [
        'https://media.kohlsimg.com/is/image/kohls/Mens-dp-20220106-visnav-02?scl=1&fmt=pjpeg',
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9kd_4vnaHacSYA4Y4yiFAgM8MQRPsf3ZPWg&usqp=CAU",
        "https://www.vn.nl/wp-content/uploads/fly-images/447349/Sander_Panic-1-2-gesleept-520x520-cc.jpg",
        "https://www.vn.nl/wp-content/uploads/fly-images/452913/Mens_FrankWammes_square-520x520-cc.jpg"
    ]
    return s[Math.floor(Math.random() * s.length)];
}

/**
 * This method generates each time an unique ID
 */
export const generateUniqueId = (): string => {
    return crypto.randomUUID();
}

/**
 * This method checks if array is empty or not.
 */
export const isEmpty = (value: any[]): boolean => {
    return value.length === 0;
}

/**
 * This method generate random numbers;
 */
export const generateNumber = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const removeEmptyFields = (obj: any) => {
    return Object.fromEntries(Object.entries(obj)
        .filter(([_, value]) => value !== null)
        .filter(([_, value]) => value !== undefined)
        .filter(([_, value]) => typeof value === 'string' ? !isBlank(value) : true)
    );
}

export const getUrlParams = (): string[] => {
    const urlPathname: string = window.location.pathname;
    return urlPathname.split('/');
};

export const getQueryParams = (): string => {
    const urlSearch: string = window.location.search;
    return urlSearch;
};

export const normalizeNavigationUrl = (newItemId: string, newStep?: number): string => {
    const urlParams: string[] = getUrlParams();
    const urlQueryParams: string = getQueryParams();
    const unselectedStep: number = -1;

    const urlParamsSize: number = urlParams.length;
    if (urlParamsSize === 0) {
        return '/';
    }

    const rootParamIndex: number = 1;
    const stepParamIndex: number = 3;
    const rootPath: string = urlParams[rootParamIndex];
    const stepPath = urlParams[stepParamIndex];

    let newUrl: string;
    if (stepPath) {
        newUrl = `${rootPath}/${newItemId}/${!newStep ? stepPath : newStep}`;
    } else {
        newUrl = `${rootPath}/${newItemId}${!newStep ? '' : `/${newStep}`}`;
    }

    if (newStep && newStep === unselectedStep) {
        newUrl = `${rootPath}/${newItemId}`;
    }

    return isBlank(urlQueryParams) ? newUrl : `${newUrl}${urlQueryParams}`;
};

export const normalizeNavigationHomeUrl = (filter: boolean): string => {
    const urlParams: string[] = getUrlParams();
    const urlQueryParams: string = getQueryParams();

    const urlParamsSize: number = urlParams.length;
    if (urlParamsSize === 0) {
        return '/';
    }

    const rootParamIndex: number = 1;
    const rootPath: string = urlParams[rootParamIndex];

    return (isBlank(urlQueryParams) || !filter) ? rootPath : `${rootPath}${urlQueryParams}`;
};