import { IAddressShortResponseDto } from "./AddressModels";
import { EFragilityLevel } from "./CommonModels";
import { ICustomerIdNameAddressResponseDto, ICustomerIdNameResponseDto } from "./CustomerModels";
import { IWarehouseIdNameAddressResponseDto } from "./WarehouseModels";

export enum ELoadDocumentType {
  NONE,
  DOCUMENT = 'DOCUMENT'
}

export enum ELoadStatus {
  NONE,
  INCOMPLETE = 'INCOMPLETE',
  PENDING = 'PENDING',
  LOADED = 'LOADED',
  IN_ROUTE = 'IN_ROUTE',
  DELIVERED = 'DELIVERED',
  INVOICE = 'INVOICE',
  PAYED = 'PAYED'
}

export enum ELoadAddressType {
  NONE,
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY'
}

export enum ELoadAddressReference {
  NONE,
  WAREHOUSE = 'WAREHOUSE',
  CUSTOMER = 'CUSTOMER',
  ADDRESS = 'ADDRESS'
}

export enum ELoadItemStatus {
  PENDING = 'PENDING',
  LOADED = 'LOADED',
  IN_ROUTE = 'IN_ROUTE',
  DELIVERED = 'DELIVERED',
  INVOICE = 'INVOICE',
  PAYED = 'PAYED'
}

export interface ILoadCoverFaceResponseDto {
  uuid: string;
  idno: string;
  deliveredBy?: number;
  status: ELoadStatus;
  readonly: boolean;
  favorite: boolean;
}

export interface ILoadResponseDto {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  idno: string;
  status: ELoadStatus;
  totalNumberOfItems: number;
  numberOfLoadedItems: number;
  numberOfDeliveredItems: number;
  numberOfPayedItems: number;
  totalAmount: number;
  payedAmount: number;
  pickupAddresses?: string[];
  deliveryAddresses?: string[];
  deliveredBy?: number;
  favorite: boolean;
  createdBy: string;
  createdDate: number;
  updatedBy?: string;
  updatedDate?: number;
}

export interface ILoadDocumentRequestDto {
  type: ELoadDocumentType;
  expirationDate?: number;
  number?: string;
  caption?: string;
}

export interface ILoadRequestDto {
  customerId: string;
  idno: string;
  deliveredBy?: number;
}

export interface ILoadOverviewResponseDto {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  idno: string;
  deliveredBy?: number;
}

export interface IPalletTypeResponseDto {
  uuid: string;
  name: string;
  height: number;
  width: number;
  length: number;
  weight: number;
}

export interface IPalletTypeAutocompleteResponseDto {
  uuid: string;
  name: string;
  height: number;
  width: number;
  length: number;
  weight: number;
}

export interface IPalletTypeRequestDto {
  name: string;
  height: number;
  width: number;
  length: number;
  weight: number;
}

export interface IFragilityTypeResponseDto {
  uuid: string;
  name: string;
  caption: string;
}

export interface IFragilityTypeRequestDto {
  name: string;
  caption: string;
}

export interface IFragilityTypeAutocompleteResponseDto {
  uuid: string;
  name: string;
  caption: string;
}

export interface ILoadAddressResponseDto {
  uuid: string;
  type: ELoadAddressType;
  alias?: string;
  addressReference: ELoadAddressReference;
  warehouse?: IWarehouseIdNameAddressResponseDto;
  customer?: ICustomerIdNameAddressResponseDto;
  address?: IAddressShortResponseDto;
}

export interface ILoadAddressShortResponseDto {
  uuid: string;
  alias?: string;
  addressReference: ELoadAddressReference;
  warehouse?: IWarehouseIdNameAddressResponseDto;
  customer?: ICustomerIdNameAddressResponseDto;
  address?: IAddressShortResponseDto;
}

export interface ILoadAddressRequestDto {
  type: ELoadAddressType;
  alias?: string;
  addressReference: ELoadAddressReference;
  addressId: string;
}

export interface ILoadAddressAutocompleteResponseDto {
  uuid: string;
  name: string;
  addressReference: ELoadAddressReference;
}

export interface ILoadItemResponseDto {
  uuid: string;
  idno: string;
  name: string;
  palletType: IPalletTypeResponseDto;
  units: number;
  unitWeight: number;
  fragilityType: IFragilityTypeResponseDto;
  fragilityLevel: EFragilityLevel;
  priority: boolean;
  amount: number;
  pickupAddress: ILoadAddressShortResponseDto;
  deliveryAddress: ILoadAddressShortResponseDto;
  description?: string;
  status: ELoadItemStatus;
  createdBy: string;
  createdDate: number;
  updatedBy?: string;
  updatedDate?: number;
}

export interface ILoadItemRequestDto {
  idno: string;
  name: string;
  palletTypeId: string;
  units: number;
  unitWeight: number;
  fragilityTypeId: string;
  fragilityLevel: EFragilityLevel;
  priority: boolean;
  amount: number;
  pickupAddressId: string;
  deliveryAddressId: string;
  description?: string;
}











export interface IFavouritesLoad {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  idno: string;
  reviewMark: number;
  status: ELoadStatus;
}