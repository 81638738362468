import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { IssueActions } from "../store/reducers/issueSlice";

export const useIssue = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(IssueActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(IssueActions.gridRefresh());
    };

    const stepRefresh = () => {
        dispatch(IssueActions.stepRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(IssueActions.applyFilter(criteria));
    };

    const businessGridRefresh = () => {
        dispatch(IssueActions.businessGridRefresh());
    };

    const businessGridUnselectRows = () => {
        dispatch(IssueActions.businessGridUnselectRows());
    };

    return { resetState, gridRefresh, stepRefresh, applyFilter, businessGridRefresh, businessGridUnselectRows } as const;
};