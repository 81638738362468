import { Divider, Stack } from "@mui/material";
import { useRef } from "react";
import TransportImagesAdditional from "./TransportImagesAdditional";
import TransportImagesMain from "./TransportImagesMain";

interface IProps {
    transportId: string;
    readonly: boolean;
}
const TransportImages = (props: IProps) => {
    const maxHeight = useRef<string | number>('550px');

    return (
        <Stack
            maxHeight={maxHeight.current}
            height={maxHeight.current}
            direction='column'
            alignItems='stretch'
            spacing={2}
        >
            <TransportImagesMain {...props} />
            <Divider />
            <TransportImagesAdditional {...props} />
        </Stack>
    );
}
export default TransportImages;