import { useDispatch } from "react-redux";
import { GlobalDataActions } from "../store/reducers/globalDataSlice";

export const useGlobalData = () => {
    const dispatch = useDispatch();

    const updateOnline = (online: boolean) => {
        dispatch(GlobalDataActions.updateOnline(online));
    };

    return { updateOnline } as const;
};