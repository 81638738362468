import { AlertColor } from "@mui/material/Alert";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface NotificationState {
  open?: boolean;
  type?: AlertColor;
  message?: string;
  timeout?: number | null;
};

const initialState: NotificationState = {
  open: false,
  type: 'success',
  message: '',
  timeout: 5000
};

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    addNotification: (_state, action: PayloadAction<NotificationState>) => ({
      ...initialState,
      ...action.payload,
      open: true
    }),
    clearNotification: (state) => ({ ...state, open: false })
  }
});

export const NotificationActions = NotificationSlice.actions;
export const NotificationReducer = NotificationSlice.reducer;