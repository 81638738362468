import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import AddressService from "../../../services/AddressService";

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    size?: 'medium' | 'small';
    onInit?: (value: string | null) => void;
    onChange?: (newValue: string | null) => void;
}
const LocationAutocomplete = (props: IProps) => {
    const { size = 'medium', label = 'Location', required = false, onChange, onInit, value } = props;

    const [data, setData] = useState<string[]>([]);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    useEffect(() => {
        if (isEmpty(data)) {
            (async () => {
                const [, response] = await AddressService.fetchAllLocations();
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [data]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value && !isBlank(value)) {
                const selectedValue: string | null = data.find((item) => item === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }

            if (onInit) {
                onInit(selectedValue);
            }
        }
    }, [value, data, onInit, selectedValue]);

    const onChangeHandler = useCallback((event: any, newValue: string | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: string | null) => {
        return (
            <div {...props} key={item}>
                <Typography variant='body2'>{item}</Typography>
            </div>
        );
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option === value;
    }, []);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default LocationAutocomplete;