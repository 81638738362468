import { ELanguage } from "./CommonModels";
import { IAuthority, PrivilegeType } from "./PermissionModels";

export enum EMeasureSize {
    INCH = 'in',
    FOOT = 'ft',
    CENTIMETER = 'cm',
    MILLIMETER = 'mm'
}

export enum EMeasureDistance {
    NAUTICAL_MILE = 'nm',
    MILE = 'mi',
    KILOMETER = 'km',
    METER = 'm'
}

export enum EMeasureConsumption {
    MILES_PER_GALLON = 'MPG',
    LITRES_100_KM = 'L/100km'
}

export enum EMeasureMass {
    POUND = 'ib',
    KILOGRAM = 'kg',
    TON = 't'
}

export enum EMeasureEngineVolume {
    LITER = 'l'
}

export enum EMeasureEnginePower {
    HORSE_POWER = 'hp'
}

export interface IUserProfilePreferenceResponseDto {
    userProfile: IUserProfileResponseDto;
    userPreference: IUserPreferenceResponseDto;
    systemPreference: ISystemPreferenceResponseDto;
    socketChannels: any; //todo
}

export interface IUserProfileResponseDto {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    avatar?: string;
    color: string;
    role: IUserProfileRoleResponseDto;
}

export interface IUserPreferenceResponseDto {
    language: ELanguage;
    webAppPreferences: string;
    measureSize: EMeasureSize;
    measureDistance: EMeasureDistance;
    measureConsumption: EMeasureConsumption;
    measureMass: EMeasureMass;
    measureEngineVolume: EMeasureEngineVolume;
    measureEnginePower: EMeasureEnginePower;
    notificationPushEnabled: boolean;
    notificationEmailEnabled: boolean;
}

export interface ISystemPreferenceResponseDto {
    hostWithDomain: string;
    corporateEmailHostDomain: string;
    mainAddress: string;
    keys: IKeyResponseDto[];
}

export interface IUserProfileRoleResponseDto {
    name: IAuthority;
    privileges: IUserProfilePrivilegeResponseDto[];
}

interface IUserProfilePrivilegeResponseDto {
    name: string;
    caption: string;
    type: PrivilegeType;
}

interface IKeyResponseDto {
    name: string;
    value: string;
}