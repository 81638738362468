import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Divider, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isBlank } from '../../../helpers/textHelper';
import { IAddressRequestDto, IAddressShortResponseDto } from "../../../models/AddressModels";
import AddressService from '../../../services/AddressService';
import BaseDialog from "../BaseDialogComponent/BaseDialog";
import AddressAutocomplete from "./AddressAutocomplete";
import GoogleMap from './GoogleMap';

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
    onApplyBtnClick: (address: IAddressShortResponseDto) => void;
}
const AddressDialog = (props: IProps) => {
    const { open, onCloseBtnClick, onApplyBtnClick } = props;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const defaultAddress = useRef<IAddressRequestDto>({
        location: {
            country: '',
            state: '',
            county: '',
            city: '',
            village: ''
        },
        street: '',
        zipcode: '',
        latitude: NaN,
        longitude: NaN
    });

    const [address, setAddress] = useState<IAddressRequestDto>(defaultAddress.current);
    const [street2, setStreet2] = useState<string>('');

    const onApplyBtnClickHandler = useCallback(() => {
        if (address) {
            (async () => {
                setLoading(true);

                let normalizedAddress: IAddressRequestDto = address;
                if (!isBlank(street2)) {
                    const street = `${address.street}, ${street2}`;
                    normalizedAddress = { ...address, street: street };
                }

                const [error, response] = await AddressService.fetchOrCreate(normalizedAddress);
                if (response) {
                    onApplyBtnClick({ uuid: response.uuid, value: response.fullAddress });

                    setLoading(false);
                    onCloseBtnClick();
                }

                if (error) {
                    setLoading(false);
                }
            })();
        }
    }, [address, onApplyBtnClick, onCloseBtnClick, street2]);

    const onChangeAddressHandler = useCallback((address: IAddressRequestDto | null) => {
        setAddress(address || defaultAddress.current);
    }, []);

    const onChangeStreet2Handler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setStreet2(event.target.value);
    }, []);

    const onChangeZipcodeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress({ ...address, zipcode: event.target.value });
    }, [address]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={1}>
                <AddressAutocomplete
                    onChange={onChangeAddressHandler}
                />

                <Divider />

                <Stack direction='row' spacing={2}>
                    {!isBlank(address.location.country) &&
                        <Stack flex={1} direction='column' spacing={1.5}>
                            <TextField
                                autoComplete='off'
                                disabled
                                fullWidth
                                label={t('COUNTRY')}
                                value={address?.location.country}
                            />

                            <TextField
                                autoComplete='off'
                                disabled
                                fullWidth
                                label={t('STATE')}
                                value={address?.location.state}
                            />

                            <TextField
                                autoComplete='off'
                                disabled
                                fullWidth
                                label={t('COUNTY')}
                                value={address?.location.county}
                                error={isBlank(address.location.country)}
                            />

                            <TextField
                                autoComplete='off'
                                disabled
                                fullWidth
                                label={t('CITY')}
                                value={address?.location.city}
                            />

                            <TextField
                                autoComplete='off'
                                disabled
                                fullWidth
                                label={t('VILLAGE')}
                                value={address?.location.village}
                            />

                            <TextField
                                autoComplete='off'
                                disabled
                                fullWidth
                                label={t('STREET')}
                                value={address?.street}
                                error={isBlank(address.street)}
                            />

                            <Stack direction='row' spacing={1}>
                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    label={t('***')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                    value={street2}
                                    onChange={onChangeStreet2Handler}
                                />

                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    label={t('ZIPCODE')}
                                    value={address?.zipcode}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                    onChange={onChangeZipcodeHandler}
                                    error={isBlank(address.zipcode)}
                                />
                            </Stack>
                        </Stack>
                    }

                    <Box flex={2} minHeight={465}>
                        <GoogleMap
                            markers={(!isNaN(address.latitude) && !isNaN(address.longitude)) ? [{ lat: address.latitude, lng: address.longitude }] : []}
                        />
                    </Box>
                </Stack>
            </Stack >
        );
    }, [
        address.latitude, address.location.city, address.location.country, address.location.county,
        address.location.state, address.location.village, address.longitude, address?.street,
        address?.zipcode, onChangeAddressHandler, onChangeStreet2Handler, onChangeZipcodeHandler,
        street2, t
    ]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('APPLY')} arrow>
                <span>
                    <Button
                        variant='contained'
                        disabled={isBlank(address.location.country) || isBlank(address.street) || isBlank(address.zipcode)}
                        onClick={onApplyBtnClickHandler}
                        size='small'
                        startIcon={<DoneIcon />}
                    >
                        {t('APPLY')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [address, onApplyBtnClickHandler, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('ADDRESS MANAGEMENT')}
            maxWidth={'md'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default AddressDialog;