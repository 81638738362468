import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { IItemDetails } from '../../../../models/CommonModels';
import { ITransportOverviewResponseDto } from '../../../../models/TransportModels';
import TransportService from '../../../../services/TransportService';
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import ItemsDetails from "../../../Base/ItemsDetailsComponent/ItemsDetails";
import TransportDialog from '../../Grid/TransportDialog';

interface IProps {
    transportId: string;
    readonly: boolean;
}
const TransportOverview = (props: IProps) => {
    const { transportId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [data, setData] = useState<ITransportOverviewResponseDto>();

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [dialog, setDialog] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.fetchOverview(transportId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [transportId]);

    useEffect(() => {
        retrieveData();
    }, [refresh, retrieveData]);

    const items: IItemDetails[] = [{
        id: `${data?.uuid}-type`,
        primary: t('TYPE'),
        secondary: data?.vehicle.type.toString().replaceAll('_', ' ')
    }, {
        id: `${data?.uuid}-vehicle`,
        primary: t('VEHICLE'),
        secondary: `${data?.vehicle.name} ${data?.vehicle.year}`
    }, {
        id: `${data?.uuid}-vin`,
        primary: t('VIN'),
        secondary: data?.vin
    }, {
        id: `${data?.uuid}-license-plate`,
        primary: t('LICENSE PLATE'),
        secondary: data?.licensePlate
    }, {
        id: `${data?.uuid}-number`,
        primary: t('NUMBER'),
        secondary: data?.transportNumber
    }, {
        id: `${data?.uuid}-color`,
        primary: t('COLOR'),
        secondary: data?.color
    }];

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BackdropProgress open={loading || false}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={t('EDIT')} placement='top' >
                        <span>
                            <Button
                                onClick={dialogToggleHandler}
                                size='small'
                                variant='outlined'>
                                <EditIcon fontSize='small' />&nbsp;{t('EDIT')}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <ItemsDetails data={items} />
            </BackdropProgress>

            {dialog &&
                <TransportDialog
                    open={dialog}
                    onCloseBtnClick={dialogToggleHandler}
                    entity={data}
                    onSubmitBtnClick={refreshHandler}
                />
            }
        </>
    );
}
export default TransportOverview;