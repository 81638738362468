import { Checkbox, FormControlLabel, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../../helpers/textHelper";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { EFragilityLevel } from "../../../../models/CommonModels";
import { ELoadAddressType, IFragilityTypeAutocompleteResponseDto, ILoadAddressAutocompleteResponseDto, ILoadItemRequestDto, ILoadItemResponseDto, IPalletTypeAutocompleteResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import CurrencyField from "../../../Base/CurrencyFieldComponent/CurrencyField";
import IdnoGenerator from "../../../Base/IdnoGeneratorComponent/IdnoGenerator";
import NumberField from "../../../Base/NumberFieldComponent/NumberField";
import TextareaField from "../../../Base/TextareaFieldComponent/TextareaField";
import FragilityLevelSelect from "../../FragilityType/FragilityLevelSelect";
import FragilityTypeAutocomplete from "../../FragilityType/FragilityTypeAutocomplete";
import PalletTypeAutocomplete from "../../PalletType/PalletTypeAutocomplete";
import LoadAddressAutocomplete from "../Addresses/LoadAddressAutocomplete";

interface IProps {
    loadId: string;
    entity?: ILoadItemResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadItemsDialog = (props: IProps) => {
    const { open, loadId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'load-item-form';
    const { gridRefresh, stepRefresh } = useLoad();

    const { measureSize, measureMass } = useSelector((state: RootState) => state.userProfileSlice.profile.userPreference);

    const isEdit = useRef<boolean>(entity !== undefined);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, clearErrors, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<ILoadItemRequestDto>({
        defaultValues: {
            idno: isEdit.current ? entity?.idno : '',
            name: isEdit.current ? entity?.name : '',
            palletTypeId: isEdit.current ? entity?.palletType.uuid : '',
            units: isEdit.current ? entity?.units : NaN,
            unitWeight: isEdit.current ? entity?.unitWeight : NaN,
            fragilityTypeId: isEdit.current ? entity?.fragilityType.uuid : '',
            fragilityLevel: isEdit.current ? entity?.fragilityLevel : EFragilityLevel.NONE,
            priority: isEdit.current ? entity?.priority : false,
            amount: isEdit.current ? entity?.amount : NaN,
            pickupAddressId: isEdit.current ? entity?.pickupAddress.uuid : '',
            deliveryAddressId: isEdit.current ? entity?.deliveryAddress.uuid : '',
            description: isEdit.current ? entity?.description || '' : ''
        }
    });

    const onCheckWithServerApi = useCallback((value: string) => {
        return LoadService.isItemIdnoAvailable(value);
    }, []);

    const updateData = useCallback((uuid: string, data: ILoadItemRequestDto) => {
        (async () => {
            const [error, response] = await LoadService.updateItem(loadId, uuid, data);
            if (response) {
                displayNotification({ message: t('Load item was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                gridRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRefresh, loadId, onCloseBtnClick, onSubmitBtnClick, t]);

    const createData = useCallback((data: ILoadItemRequestDto) => {
        (async () => {
            const [error, response] = await LoadService.createItem(loadId, data);
            if (response) {
                displayNotification({ message: t('Load item was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                gridRefresh();
                stepRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridRefresh, loadId, onCloseBtnClick, onSubmitBtnClick, t]);

    const onSubmit = useCallback((data: ILoadItemRequestDto) => {
        setLoading(true);
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, entity, updateData]);

    const validateIdnoField = useCallback((value: string) => {
        return !isBlank(value) && !errors.idno;
    }, [errors.idno]);

    register('idno', { validate: validateIdnoField });
    const onChangeIdnoHandler = useCallback((value: string, error?: string) => {
        if (error) {
            setError('idno', { message: error });
        } else {
            clearErrors('idno');
        }

        setValue('idno', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [clearErrors, setError, setValue]);

    const validateNameField = useCallback((value?: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('name', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validatePalletTypeField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('palletTypeId', { validate: validatePalletTypeField });
    const onPalletTypeChangeHandler = useCallback((value: IPalletTypeAutocompleteResponseDto | null) => {
        setValue('palletTypeId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateNumberField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('units', { validate: validateNumberField });
    const onUnitsChangeHandler = useCallback((value: number) => {
        setValue('units', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('unitWeight', { validate: validateNumberField });
    const onWeightChangeHandler = useCallback((value: number) => {
        setValue('unitWeight', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateFragilityTypeField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('fragilityTypeId', { validate: validateFragilityTypeField });
    const onFragilityTypeChangeHandler = useCallback((value: IFragilityTypeAutocompleteResponseDto | null) => {
        setValue('fragilityTypeId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateFragilityLevelField = useCallback((value: EFragilityLevel) => {
        return EFragilityLevel[value] !== undefined && value !== EFragilityLevel.NONE;
    }, []);

    register('fragilityLevel', { validate: validateFragilityLevelField });
    const onFragilityLevelChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: EFragilityLevel = event.target.value as EFragilityLevel;
        setValue('fragilityLevel', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validatePriorityField = useCallback((value: boolean) => {
        return value !== undefined;
    }, []);

    register('priority', { validate: validatePriorityField });
    const onPriorityChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;
        setValue('priority', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAmountField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('amount', { validate: validateAmountField });
    const onAmountChangeHandler = useCallback((value: number) => {
        setValue('amount', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validatePickupOrDeliveryField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('pickupAddressId', { validate: validatePickupOrDeliveryField });
    const onPickupChangeHandler = useCallback((value: ILoadAddressAutocompleteResponseDto | null) => {
        setValue('pickupAddressId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('deliveryAddressId', { validate: validatePickupOrDeliveryField });
    const onDeliveryChangeHandler = useCallback((value: ILoadAddressAutocompleteResponseDto | null) => {
        setValue('deliveryAddressId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('description');
    const onNotesChangeHandler = useCallback((value: string) => {
        setValue('description', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack direction='row' spacing={2}>
                    <Stack direction='column' spacing={2} width='100%'>
                        <IdnoGenerator
                            required
                            value={getValues('idno')}
                            label={t('ID#')}
                            generateBtnTooltip={t('GENERATE')}
                            errorMessage={t('The value is used. Choose another value.')}
                            checkWithServerApi={onCheckWithServerApi}
                            onChange={onChangeIdnoHandler}
                        />

                        <TextField
                            {...register('name', { validate: validateNameField })}
                            label={t('NAME')}
                            required
                            slotProps={{htmlInput: {minLength: 1, maxLength: 50}}}
                            autoComplete='off'
                        />

                        <PalletTypeAutocomplete
                            label={t('PALLET TYPE')}
                            required
                            value={getValues('palletTypeId')}
                            size='medium'
                            showDetails
                            sizeType={measureSize}
                            massType={measureMass}
                            onChange={onPalletTypeChangeHandler}
                        />

                        <Stack direction='row' spacing={2}>
                            <NumberField
                                required
                                label={t('UNITS')}
                                value={getValues('units')}
                                scale={0}
                                allowNegative={false}
                                onChange={onUnitsChangeHandler}
                            />

                            <NumberField
                                required
                                label={t('WEIGHT')}
                                value={getValues('unitWeight')}
                                allowNegative={false}
                                units={measureMass}
                                onChange={onWeightChangeHandler}
                            />
                        </Stack>

                        <FragilityTypeAutocomplete
                            label={t('FRAGILITY TYPE')}
                            required
                            value={getValues('fragilityTypeId')}
                            size='medium'
                            showDetails
                            onChange={onFragilityTypeChangeHandler}
                        />

                        <FragilityLevelSelect
                            label={t('FRAGILITY LEVEL')}
                            required
                            value={getValues('fragilityLevel')}
                            onChange={onFragilityLevelChangeHandler}
                        />
                    </Stack>

                    <Stack direction='column' spacing={2} width='100%'>
                        <CurrencyField
                            label={t('AMOUNT')}
                            required
                            allowNegative={false}
                            value={getValues('amount')}
                            onChange={onAmountChangeHandler}
                        />

                        <FormControlLabel
                            control={<Checkbox
                                checked={getValues('priority')}
                                onChange={onPriorityChangeHandler}
                                sx={{ marginTop: '7px', marginBottom: '7px' }}
                            />}
                            label={t('PRIORITY')}
                        />

                        <LoadAddressAutocomplete
                            label={t('PICKUP ADDRESS')}
                            required
                            loadId={loadId}
                            type={ELoadAddressType.PICKUP}
                            value={getValues('pickupAddressId')}
                            showDetails
                            onChange={onPickupChangeHandler}
                        />

                        <LoadAddressAutocomplete
                            label={t('DELIVERY ADDRESS')}
                            required
                            loadId={loadId}
                            type={ELoadAddressType.DELIVERY}
                            value={getValues('deliveryAddressId')}
                            showDetails
                            onChange={onDeliveryChangeHandler}
                        />

                        <TextareaField
                            label={t('NOTES')}
                            minLength={1}
                            maxLength={200}
                            rows={4.17}
                            value={getValues('description')}
                            showCount
                            onChange={onNotesChangeHandler}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        getValues, handleSubmit, loadId, measureMass, measureSize, onAmountChangeHandler,
        onChangeIdnoHandler, onCheckWithServerApi, onDeliveryChangeHandler,
        onFragilityLevelChangeHandler, onFragilityTypeChangeHandler,
        onNotesChangeHandler, onPalletTypeChangeHandler, onPickupChangeHandler,
        onPriorityChangeHandler, onSubmit, onUnitsChangeHandler, onWeightChangeHandler,
        register, t, validateNameField
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} LOAD ITEM`)}
            maxWidth={'md'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadItemsDialog;