import { CSSProperties } from "react";

export const roundBorderWithColorRed: CSSProperties = {
    border: '1px solid rgb(198, 40, 40)',
    color: 'rgb(198, 40, 40)',
    padding: '2px 10px 2px 10px',
    borderRadius: '16px'
};

export const roundBorderWithColorGreen: CSSProperties = {
    border: '1px solid rgb(27, 94, 32)',
    color: 'rgb(27, 94, 32)',
    padding: '2px 10px 2px 10px',
    borderRadius: '16px'
};

export const roundBorderWithColorBlue: CSSProperties = {
    border: '1px solid rgb(25, 118, 210)',
    color: 'rgb(25, 118, 210)',
    padding: '2px 10px 2px 10px',
    borderRadius: '16px'
};

export const roundBorderWithColorOrange: CSSProperties = {
    border: '1px solid #ed6c02',
    color: '#ed6c02',
    padding: '2px 10px 2px 10px',
    borderRadius: '16px'
};

export const textColorOrange: CSSProperties = {
    color: '#ed6c02'
};

export const textColorGreen: CSSProperties = {
    color: 'rgb(27, 94, 32)'
};

export const textColorRed: CSSProperties = {
    color: 'rgb(198, 40, 40)'
};