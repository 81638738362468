import { Box } from "@mui/material";
import { EExtension } from "../../../models/FileModel";

interface IProps {
    value: string;
    width: number;
    heigth: number;
    extension: EExtension;
}
const FileImageView = (props: IProps) => {
    const { value, width, heigth, extension } = props;

    return (
        <Box
            component='img'
            src={`data:image/${extension.toString()};base64,${value}`}
            alt={''}
            sx={{
                height: `${heigth * 0.7}px`,
                width: `${width * 0.7}px`,
                objectFit: 'scale-down'
            }}
        />
    );
}
export default FileImageView;