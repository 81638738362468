import { Box, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { compareValue } from "../../../helpers/generalHelper";
import { RootState } from "../../../store/store";
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";

interface IProps {
    getNewInterfaceState: (state: any) => void;
    state: any;
}

const InterfaceComponent = (props: IProps) => {
    const { getNewInterfaceState, state } = props
    const { t } = useTranslation();

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [localState, setLocalState] = React.useState<any>(state);

    useEffect(() => {
        setLocalState(state);
    }, [state]);

    const handleshowHints = (event: any, newShowHints: boolean) => {
        // setLocalState((st) => { return { ...st, hints: newShowHints } });
    };

    const handlerChecked = (event: any, newDarkProfile: boolean) => {
        // setLocalState((st) => { return { ...st, darkMode: newDarkProfile } });

    }
    const handleSizeMenu = (event: any, newSizeMenu: number) => {
        // setLocalState((st) => { return { ...st, menuState: newSizeMenu } });

    };
    const handleSaveButton = () => {
        getNewInterfaceState(localState);
    }

    useEffect(() => {
        setDisabled(compareValue(localState, state));
    }, [localState, state]);

    return (
        <>
            <SCViewList column gap={30} center>

                <SCViewList gap={40} center>

                    <SCViewList column gap={35} width={20} center>

                        <Typography color={"black"} variant="subtitle2"> THEME</Typography>
                        <Typography color={"black"} variant="subtitle2"> MENU SIZE</Typography>
                        <Typography color={"black"} variant="subtitle2"> HINTS</Typography>

                    </SCViewList>


                    <SCViewList column gap={10} width={60}>
                        <ToggleButtonGroup
                            color="primary"
                            value={localState.darkMode}
                            exclusive
                            onChange={handlerChecked}
                            fullWidth={true}
                        >
                            <ToggleButton value={false}>{t("LIGHT")}</ToggleButton>
                            <ToggleButton value={true}>{t("DARK")}</ToggleButton>
                        </ToggleButtonGroup>


                        <ToggleButtonGroup
                            color="primary"
                            value={localState.menuState}
                            exclusive
                            onChange={handleSizeMenu}
                            fullWidth={true}
                        >
                            <ToggleButton value={1}>{t("BIG")}</ToggleButton>
                            <ToggleButton value={0}>{t("SMALL")}</ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            color="primary"
                            value={localState.hints}
                            exclusive
                            onChange={handleshowHints}
                            fullWidth={true}
                        >
                            <ToggleButton value={true}>{t("SHOW")}</ToggleButton>
                            <ToggleButton value={false}>{t("HIDE")}</ToggleButton>
                        </ToggleButtonGroup>
                    </SCViewList>


                </SCViewList>
                <SCViewList flexEnd width={90}>
                    <Tooltip
                        title={(hints && !disabled) ? "Save global profile configurations" : ""}
                        placement="top"
                        arrow={true}
                    >
                        <Box>
                            {/* <SecureDialogComponent
                                answerYES={() => handleSaveButton()}
                                text={'SAVE'}
                                iconButton={false}
                                color={EButtonColor.primary}
                                disabled={disabled}
                            /> */}
                        </Box>
                    </Tooltip>
                </SCViewList>
            </SCViewList>

        </>)
}
export default InterfaceComponent;