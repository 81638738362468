import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { filterMapByProperty } from "../../../../helpers/CollectionHelper";
import { getDateAndTimeFormat } from "../../../../helpers/dateHelper";
import { isEmpty } from "../../../../helpers/generalHelper";
import { roundBorderWithColorBlue, roundBorderWithColorOrange } from "../../../../helpers/gridHelper";
import { useActiveLoad } from "../../../../hooks/useActiveLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { EActiveLoadStatus, EActiveLoadUserStatus, IActiveLoadUserResponseDto } from "../../../../models/ActiveLoadModels";
import { IFilter } from "../../../../models/CommonModels";
import ActiveLoadService from "../../../../services/ActiveLoadService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import ActiveLoadUsersDialog from "./ActiveLoadUsersDialog";
import { IUserShortResponseDto } from "../../../../models/UserModels";

interface IProps {
    activeLoadId: string;
    activeLoadStatus: EActiveLoadStatus;
}
const ActiveLoadUsersGrid = (props: IProps) => {
    const { activeLoadId, activeLoadStatus } = props;

    const { t } = useTranslation();
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.activeLoadSlice.usersGrid);
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh, usersGridRefresh, usersGridUnselectRows } = useActiveLoad();
    const apiRef = useGridApiRef();
    const readonlyStatuses = useRef<EActiveLoadStatus[]>(
        [EActiveLoadStatus.IN_ROUTE, EActiveLoadStatus.DELIVERED, EActiveLoadStatus.INVOICE, EActiveLoadStatus.PAYED]
    );

    const [dialogToggle, setDialogToggle] = useState(false);
    const [activeLoadUser, setActiveLoadUser] = useState<IActiveLoadUserResponseDto | undefined>();
    const [editActionBtnDisabled, setEditActionBtnDisabled] = useState<boolean>(false);
    const [deleteActionBtnDisabled, setDeleteActionBtnDisabled] = useState<boolean>(false);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return ActiveLoadService.fetchUsers(activeLoadId, filter);
    }, [activeLoadId]);

    const onAddActionBtnClickHandler = useCallback(() => {
        setActiveLoadUser(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClickHandler = useCallback((activeLoadUser: IActiveLoadUserResponseDto) => {
        setActiveLoadUser(activeLoadUser);
        setDialogToggle(true);
    }, []);

    const onSelectionModelChange = useCallback((selectedRows: IActiveLoadUserResponseDto[]) => {
        const selectedRowsCount: number = selectedRows.length;
        const areArchivedItems: boolean = selectedRows.find((item) => item.status === EActiveLoadUserStatus.ARCHIVED) !== undefined;
        const isReadonly: boolean = readonlyStatuses.current.includes(activeLoadStatus);

        setEditActionBtnDisabled(areArchivedItems);

        if (isReadonly) {
            const activeItems: Map<any, any> = filterMapByProperty(apiRef.current.getRowModels(), 'status', EActiveLoadUserStatus.ACTIVE);
            const areAllActiveItems: boolean = activeItems.size === selectedRowsCount;
            setDeleteActionBtnDisabled(areArchivedItems || areAllActiveItems);
        } else {
            setDeleteActionBtnDisabled(areArchivedItems);
        }
    }, [activeLoadStatus, apiRef]);

    const refreshUserGrid = useCallback(() => {
        usersGridUnselectRows();
        usersGridRefresh();
    }, [usersGridUnselectRows, usersGridRefresh]);

    const onDeleteActionBtnClickHandler = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await ActiveLoadService.removeUser(activeLoadId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Active load user${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    refreshUserGrid();
                    stepRefresh();
                    gridRefresh();
                }
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLoadId, t, refreshUserGrid]);

    const onFullNameColumnRender = useCallback((params: GridRenderCellParams) => {
        const user: IUserShortResponseDto = params.row.user;
        const value: string = user.fullName;
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onTypeColumnRender = useCallback((params: GridRenderCellParams) => {
        const user: IUserShortResponseDto = params.row.user;
        const value: string = user.role ? user.role.toString().replace('_', ' ') : ''
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onInvolvedActiveLoadsColumnRender = useCallback((params: GridRenderCellParams) => {
        // todo to color the value by severity
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onStatusColumnRender = useCallback((params: GridRenderCellParams) => {
        const status: EActiveLoadUserStatus = params.value;
        const style: {} = EActiveLoadUserStatus.ACTIVE === status
            ? roundBorderWithColorBlue
            : roundBorderWithColorOrange;

        return (<span style={style}>{params.value}</span>);
    }, []);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns: GridColDef[] = [{
        field: 'fullName',
        headerName: `${t('FULL NAME')}`,
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onFullNameColumnRender
    }, {
        field: 'type',
        headerName: `${t('TYPE')}`,
        width: 180,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onTypeColumnRender
    }, {
        field: 'activeRoutes',
        headerName: `${t('INVOLVED A.L.')}`,
        width: 115,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onInvolvedActiveLoadsColumnRender
    }, {
        field: 'status',
        headerName: `${t('STATUS')}`,
        width: 110,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onStatusColumnRender
    }, {
        field: "created",
        headerName: `${t('CREATED')}`,
        width: 140,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onCreatedColumnRender
    }];

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setActiveLoadUser(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        refreshUserGrid();
    }, [refreshUserGrid]);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                apiRef={apiRef}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClickHandler}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add user')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClickHandler}
                editActionBtnTooltip={t('Edit user')}
                editActionBtnLabel={t('EDIT')}
                editActionBtnDisabled={editActionBtnDisabled}
                onDeleteActionBtnClick={onDeleteActionBtnClickHandler}
                deleteActionBtnTooltip={t('Delete user(s)')}
                deleteActionBtnLabel={t('DELETE')}
                deleteActionBtnDisabled={deleteActionBtnDisabled}
                refreshActionBtnTooltip={t('Refresh data')}
                onSelectionModelChange={onSelectionModelChange}
            />

            {dialogToggle &&
                <ActiveLoadUsersDialog
                    open={dialogToggle}
                    activeLoadId={activeLoadId}
                    activeLoadUser={activeLoadUser}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default ActiveLoadUsersGrid;