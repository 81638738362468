import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Box, IconButton, ImageListItem, Tooltip } from '@mui/material';
import { ReactNode, useCallback, useEffect, useState } from "react";
import { isEmpty } from '../../../../helpers/generalHelper';
import { EButtonColor } from '../../../../models/EnumGeneral';
import { IFileDetailsResponseDto } from '../../../../models/FileModel';
import FileService from '../../../../services/FileService';
import ButtonConfirmation from '../../../Base/ActionConfirmationComponent/ButtonConfirmation';
import NoAvatarComponent from '../../AvatarComponent/NoAvatarComponent';

const actionBtnsStyle = {
    backgroundColor: 'rgb(136 136 136 / 12%)',
    display: 'flex',
    justifyContent: 'center',
    gap: '10px'
};

interface IProps {
    width?: string | number;
    square?: boolean;

    imageId?: string;
    image?: IFileDetailsResponseDto;
    original?: IFileDetailsResponseDto;

    addBtnShow?: boolean;
    addBtnClick?: () => void;
    addBtnTooltip?: string;

    deleteBtnShow?: boolean;
    deleteBtnClick?: () => void;
    deleteBtnTooltip?: string;

    viewBtnShow?: boolean;
    viewBtnClick?: () => void;
    viewBtnTooltip?: string;

    downloadBtnShow?: boolean;
    downloadBtnTooltip?: string;
}
const ImageItem = (props: IProps) => {
    const {
        width, square = false, imageId, image, original,
        addBtnShow = false, addBtnClick, addBtnTooltip = 'Add',
        deleteBtnShow = false, deleteBtnClick, deleteBtnTooltip = 'Delete',
        viewBtnShow = false, viewBtnClick, viewBtnTooltip = 'View',
        downloadBtnShow = false, downloadBtnTooltip = 'Download'
    } = props;

    const [actionBtns, setActionBtns] = useState<ReactNode[]>([]);

    const squareStyle = (width && square) ? { width: width, height: width, objectFit: 'fill' } : {};

    const onAddBtnClickHandler = useCallback(() => {
        if (addBtnClick) {
            addBtnClick();
        }
    }, [addBtnClick]);

    const buildAddBtn = useCallback((): ReactNode => {
        return (
            <Tooltip title={addBtnTooltip} placement='top' key={`add-btn-${imageId}`} >
                <IconButton size='small' color='primary' onClick={onAddBtnClickHandler}>
                    <AddCircleIcon />
                </IconButton>
            </Tooltip >
        );
    }, [addBtnTooltip, imageId, onAddBtnClickHandler]);

    const onDeleteBtnClickHandler = useCallback(() => {
        if (deleteBtnClick) {
            deleteBtnClick();
        }
    }, [deleteBtnClick]);

    const buildDeleteBtn = useCallback((): ReactNode => {
        return (
            <ButtonConfirmation
                key={`delete-btn-${imageId}`}
                iconBtn
                size='medium'
                tooltip={deleteBtnTooltip}
                onConfirm={onDeleteBtnClickHandler}
                icon={<DeleteIcon fontSize='small' />}
                color={EButtonColor.error}
            />
        );
    }, [deleteBtnTooltip, imageId, onDeleteBtnClickHandler]);

    const onViewBtnClickHandler = useCallback(() => {
        if (viewBtnClick) {
            viewBtnClick();
        }
    }, [viewBtnClick]);

    const buildViewBtn = useCallback((): ReactNode => {
        return (
            <Tooltip title={viewBtnTooltip} placement='top' key={`preview-btn-${imageId}`} >
                <IconButton size='small' color='primary' onClick={onViewBtnClickHandler}>
                    <PageviewIcon />
                </IconButton>
            </Tooltip >
        );
    }, [imageId, onViewBtnClickHandler, viewBtnTooltip]);

    const onDownloadBtnClickHandler = useCallback(() => {
        if (original) {
            (async () => {
                const elements = original.url.split('/');
                const uuid = elements[elements.length - 1];
                FileService.downloadByUrl(`file/download/${uuid}`);
            })();
        }
    }, [original]);

    const buildDownloadBtn = useCallback((): ReactNode => {
        return (
            <Tooltip title={downloadBtnTooltip} placement='top' key={`download-btn-${imageId}`} >
                <span>
                    <IconButton
                        disabled={original === undefined}
                        size='small' color='primary'
                        onClick={onDownloadBtnClickHandler}
                    >
                        <DownloadIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }, [downloadBtnTooltip, imageId, onDownloadBtnClickHandler, original]);

    const buildActionBtns = useCallback((): ReactNode[] => {
        const btns: ReactNode[] = [];

        if (addBtnShow && !image) {
            btns.push(buildAddBtn());
        }

        if (viewBtnShow && image) {
            btns.push(buildViewBtn());
        }

        if (downloadBtnShow && image) {
            btns.push(buildDownloadBtn());
        }

        if (deleteBtnShow && image) {
            btns.push(buildDeleteBtn());
        }

        return btns;
    }, [
        addBtnShow, buildAddBtn, buildDeleteBtn, buildDownloadBtn,
        buildViewBtn, deleteBtnShow, downloadBtnShow, image, viewBtnShow
    ]);

    useEffect(() => {
        setActionBtns(buildActionBtns());
    }, [buildActionBtns]);

    return (
        <ImageListItem key={image?.value}>
            {
                image
                    ?
                    square
                        ?
                        <Box
                            component='img'
                            src={`data:image/${image.extension.toString()};base64,${image.value}`}
                            alt={image.extension.toString()}
                            sx={squareStyle}
                        />
                        :
                        <img
                            src={`data:image/${image.extension.toString()};base64,${image.value}`}
                            alt={image.extension.toString()}
                        />
                    :
                    <NoAvatarComponent
                        square
                        width={width}
                        height={width}
                        key={Date.now()}
                    />
            }

            {!isEmpty(actionBtns) &&
                <Box sx={actionBtnsStyle}>
                    {actionBtns.map((button) => button)}
                </Box>
            }
        </ImageListItem>
    );
}
export default ImageItem;