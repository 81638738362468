import { TextField } from "@mui/material";

interface IProps {
    value: string;
    width: number;
    heigth: number;
}
const FileTextView = (props: IProps) => {
    const { value, width, heigth } = props;

    return (
        <TextField
            multiline
            value={atob(value)}
            sx={{
                height: `${heigth * 0.7}px`,
                width: `${width * 0.7}px`
            }}
            minRows={29}
        />
    );
}
export default FileTextView;