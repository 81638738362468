import { API } from "./APIService";

class FileService {

    public static fetchByUrl = async (url: string): Promise<any> => {
        const [error, response] = await API('GET', url);
        return [error, response];
    };

    public static downloadByUrl = async (url: string): Promise<any> => {
        const [, response] = await API('GET', url, undefined, undefined, undefined, 'blob');
        if (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const fileName: string = response.headers['content-disposition']
                .split('filename=')[1]
                .replaceAll('"', '');
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            link.click();
            link.remove();
        }
    };
}
export default FileService;