import { Box, Paper } from "@mui/material";
import UserContacts from "./UserContacts";

interface IProps {
    userId: string;
}
const UserContactsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <UserContacts {...props} />
            </Box>
        </Paper>
    );
}
export default UserContactsStep;