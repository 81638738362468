import { Box, Paper } from "@mui/material";
import LoadDocument from "./LoadDocument";

interface IProps {
    loadId: string;
}
const LoadDocumentsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '15px 2px 10px 10px', height: 400 }}>
                <LoadDocument loadId={props.loadId} />
            </Box>
        </Paper>
    );
}
export default LoadDocumentsStep;