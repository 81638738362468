import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ELanguage } from "../../models/CommonModels";
import { IAuthority } from "../../models/PermissionModels";
import { EMeasureConsumption, EMeasureDistance, EMeasureEnginePower, EMeasureEngineVolume, EMeasureMass, EMeasureSize, IUserProfilePreferenceResponseDto } from "../../models/UserProfileModels";

interface UserProfileState {
    profile: IUserProfilePreferenceResponseDto;
};

const initialState: UserProfileState = {
    profile: {
        userProfile: {
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            color: "",
            role: {
                name: IAuthority.NONE,
                privileges: []
            }
        },
        userPreference: {
            language: ELanguage.EN,
            webAppPreferences: "",
            measureSize: EMeasureSize.INCH,
            measureDistance: EMeasureDistance.MILE,
            measureConsumption: EMeasureConsumption.MILES_PER_GALLON,
            measureMass: EMeasureMass.POUND,
            measureEngineVolume: EMeasureEngineVolume.LITER,
            measureEnginePower: EMeasureEnginePower.HORSE_POWER,
            notificationPushEnabled: false,
            notificationEmailEnabled: false
        },
        systemPreference: {
            hostWithDomain: "",
            corporateEmailHostDomain: "",
            mainAddress: "",
            keys: []
        },
        socketChannels: undefined
    }
};

export const UserProfileSlice = createSlice({
    name: 'userProfileSlice',
    initialState: initialState,
    reducers: {
        updateProfile(state: UserProfileState, action: PayloadAction<IUserProfilePreferenceResponseDto>) {
            state.profile = action.payload;
        }
    }
});

export const UserProfileActions = UserProfileSlice.actions;
export const UserProfileReducer = UserProfileSlice.reducer;