import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../../helpers/generalHelper";
import { currencyFormat } from '../../../../helpers/NumberHelper';
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { IBaseGridActionBtn, IFilter } from "../../../../models/CommonModels";
import { ILoadItemResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import LoadItemsDetailsDialog from './LoadItemsDetailsDialog';
import LoadItemsDialog from "./LoadItemsDialog";

interface IProps {
    loadId: string;
}
const LoadItemsGrid = (props: IProps) => {
    const { loadId } = props;

    const { t } = useTranslation();
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.itemsGrid);
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh, itemsGridRefresh } = useLoad();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [entity, setEntity] = useState<ILoadItemResponseDto | undefined>();
    const [detailsDialogToggle, setDetailsDialogToggle] = useState(false);
    const [detailsActionBtnDisabled, setDetailsActionBtnDisabled] = useState<boolean>(true);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchItems(loadId, filter);
    }, [loadId]);

    const onAddActionBtnClickHandler = useCallback(() => {
        setEntity(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClickHandler = useCallback((entity: ILoadItemResponseDto) => {
        setEntity(entity);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClickHandler = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removeItem(loadId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Load item${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    stepRefresh();
                    itemsGridRefresh();
                    gridRefresh();
                }
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsGridRefresh, loadId, stepRefresh, t]);

    const onSelectionModelChange = useCallback((selectedRows: ILoadItemResponseDto[]) => {
        const selectedRowsCount: number = selectedRows.length;
        setDetailsActionBtnDisabled(selectedRowsCount !== 1);
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onPriorityColumnRender = useCallback((params: GridRenderCellParams) => {
        const priorityValue: string = params.value ? t('YES') : t('NO');
        return (
            <Tooltip title={priorityValue} color={params.value ? 'red' : 'black'}>
                <span>{priorityValue}</span>
            </Tooltip>
        );
    }, [t]);

    const onAmountColumnRender = useCallback((value: number) => {
        return currencyFormat(value);
    }, []);

    const onStatusColumnRender = useCallback((value: string) => {
        return value.toString().replace('_', ' ');
    }, []);

    const columns: GridColDef[] = [{
        field: 'idno',
        headerName: t('ID#'),
        width: 110,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onTooltipColumnRender
    }, {
        field: 'name',
        headerName: t('NAME'),
        flex: 1,
        minWidth: 200,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onTooltipColumnRender
    }, {
        field: 'priority',
        headerName: t('PRIORITY'),
        width: 90,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onPriorityColumnRender
    }, {
        field: "amount",
        headerName: t('AMOUNT'),
        width: 130,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        type: 'number',
        valueFormatter: onAmountColumnRender
    }, {
        field: 'status',
        headerName: t('STATUS'),
        width: 130,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueFormatter: onStatusColumnRender
    }];

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setEntity(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        itemsGridRefresh();
    }, [itemsGridRefresh]);

    const onDetailsActionBtnClickHandler = useCallback((entity: ILoadItemResponseDto) => {
        setEntity(entity);
        setDetailsDialogToggle(true);
    }, []);

    const actionBtns: IBaseGridActionBtn[] = [{
        tooltip: t('Item details'),
        onClick: onDetailsActionBtnClickHandler,
        icon: <InfoIcon fontSize='small' />,
        label: t('DETAILS'),
        disabled: detailsActionBtnDisabled,
        key: 'item-details-action-btn',
    }];

    const onCloseDetailsDialogHandler = useCallback(() => {
        setDetailsDialogToggle(false);
        setEntity(undefined);
    }, []);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClickHandler}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClickHandler}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClickHandler}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                actionBtns={actionBtns}
                onSelectionModelChange={onSelectionModelChange}
            />

            {dialogToggle &&
                <LoadItemsDialog
                    open={dialogToggle}
                    loadId={loadId}
                    entity={entity}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }

            {detailsDialogToggle && entity &&
                <LoadItemsDetailsDialog
                    open={detailsDialogToggle}
                    entity={entity}
                    onCloseBtnClick={onCloseDetailsDialogHandler}
                />
            }
        </>
    );
}
export default LoadItemsGrid;