import { Alert, Snackbar, SnackbarCloseReason, SnackbarOrigin } from "@mui/material";
import { useSelector } from "react-redux";
import { useNotification } from "../../../hooks/useNotification";
import { RootState } from "../../../store/store";

const Notification = () => {
    const notification = useSelector((state: RootState) => state.notification);
    const { clearNotification } = useNotification();
    const handleClose = (_: unknown, reason?: SnackbarCloseReason) => reason !== "clickaway" && clearNotification();

    const anchorOrigin: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'center'
    };

    return (
        <Snackbar
            open={notification.open}
            autoHideDuration={notification.timeout}
            anchorOrigin={anchorOrigin}
            onClose={handleClose}
            key={anchorOrigin.vertical + anchorOrigin.horizontal}
        >
            <Alert variant='filled' onClose={handleClose} severity={notification.type}>
                {notification.message}
            </Alert>
        </Snackbar>
    );
}
export default Notification;