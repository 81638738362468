import { Autocomplete, AutocompleteRenderInputParams, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../helpers/generalHelper";
import { IActiveLoadAutocompleteResponseDto } from "../../models/ActiveLoadModels";
import ActiveLoadService from "../../services/ActiveLoadService";

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    size?: 'medium' | 'small';
    onInit?: (value: IActiveLoadAutocompleteResponseDto | null) => void;
    onChange?: (newValue: IActiveLoadAutocompleteResponseDto | null) => void;
}
const ActiveLoadAutocomplete = (props: IProps) => {
    const { size = 'medium', label = 'Active load', required = false, onChange, onInit, value } = props;

    const [data, setData] = useState<IActiveLoadAutocompleteResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<IActiveLoadAutocompleteResponseDto | null>(null);

    useEffect(() => {
        if (isEmpty(data)) {
            (async () => {
                const [, response] = await ActiveLoadService.fetchAutocomplete();
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [data]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: IActiveLoadAutocompleteResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }

            if (onInit) {
                onInit(selectedValue);
            }
        }
    }, [value, data, onInit, selectedValue]);

    const onChangeHandler = useCallback((event: any, newValue: IActiveLoadAutocompleteResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }

    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: IActiveLoadAutocompleteResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography variant='body2'>{item.idno}</Typography>
                {item.name &&
                    <Typography variant='caption'>
                        <Divider />
                        {item.name}
                    </Typography>
                }
            </div>
        );
    }, []);

    const onBuildOptionLabel = useCallback((item: IActiveLoadAutocompleteResponseDto) => {
        return `${item.idno} [ ${item.name} ]`;
    }, []);

    const onBuildOptionKey = useCallback((item: IActiveLoadAutocompleteResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default ActiveLoadAutocomplete;