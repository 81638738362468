import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { removeEmptyFields } from "../../../../helpers/generalHelper";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { EActiveLoadDocumentType, IActiveLoadDocumentRequestDto } from "../../../../models/ActiveLoadModels";
import { IDocument } from "../../../../models/DocumentModels";
import ActiveLoadService from "../../../../services/ActiveLoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DocumentTypeSelect from "../../../Base/DocumentTypeSelectComponent/DocumentTypeSelect";

interface IProps {
    activeLoadId: string;
    document?: IDocument;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const ActiveLoadDocumentDialog = (props: IProps) => {
    const { open, activeLoadId, document, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'active-load-document-form';
    const isEdit = useRef<boolean>(document !== undefined);

    const [loading, setLoading] = useState(false);

    const { register, setError, clearErrors, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IActiveLoadDocumentRequestDto>({
        defaultValues: {
            type: isEdit.current ? document?.type as EActiveLoadDocumentType : undefined,
            number: isEdit.current ? document?.number : undefined,
            caption: isEdit.current ? document?.caption : undefined
        }
    });

    const updateData = useCallback((uuid: string, data: IActiveLoadDocumentRequestDto) => {
        (async () => {
            const [error, response] = await ActiveLoadService.updateDocument(activeLoadId, uuid, data);
            if (response) {
                displayNotification({ message: t('Document was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [activeLoadId]);

    const createData = useCallback((data: IActiveLoadDocumentRequestDto) => {
        (async () => {
            const [error, response] = await ActiveLoadService.createDocument(activeLoadId, data);
            if (response) {
                displayNotification({ message: t('Document was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [activeLoadId]);

    const onSubmit = useCallback((data: IActiveLoadDocumentRequestDto) => {
        setLoading(true);
        const normalisedData: IActiveLoadDocumentRequestDto = removeEmptyFields(data) as unknown as IActiveLoadDocumentRequestDto;
        if (document) {
            updateData(document.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [document]);

    const documentTypeData = useRef<string[]>(
        Object.keys(EActiveLoadDocumentType)
            .filter(key => isNaN(Number(key)))
            .filter(key => key !== EActiveLoadDocumentType[EActiveLoadDocumentType.NONE])
            .map(key => key.toString())
    );

    const validateType = useCallback((value: EActiveLoadDocumentType) => {
        return EActiveLoadDocumentType[value] !== undefined && value !== EActiveLoadDocumentType.NONE;
    }, []);

    register('type', { validate: validateType });
    const onDocumentTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as EActiveLoadDocumentType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateNumber = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('number', { message: message });
            return false;
        }
        clearErrors('number');
        return true;
    }, [clearErrors, setError, t]);

    const validateCaption = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('caption', { message: message });
            return false;
        }
        clearErrors('caption');
        return true;
    }, [clearErrors, setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <DocumentTypeSelect
                        label={t('TYPE')}
                        size='medium'
                        data={documentTypeData.current}
                        required
                        value={getValues('type')}
                        onChange={onDocumentTypeChangeHandler}
                    />

                    <TextField
                        {...register('number', { validate: validateNumber })}
                        autoComplete={'off'}
                        label={t('NUMBER')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.number}
                        helperText={errors.number?.message}
                    />

                    <TextField
                        {...register('caption', { validate: validateCaption })}
                        autoComplete={'off'}
                        label={t('CAPTION')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.caption}
                        helperText={errors.caption?.message}
                    />
                </Stack>
            </form>
        );
    }, [errors.caption, errors.number, getValues, handleSubmit, onDocumentTypeChangeHandler,
        onSubmit, register, t, validateCaption, validateNumber
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} DOCUMENT`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    )
}
export default ActiveLoadDocumentDialog;