import FlipIcon from '@mui/icons-material/Flip';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppBar, Badge, Box, Divider, IconButton, Paper, Popper, Skeleton, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import AvatarComponent from '../components/Base/AvatarComponent/AvatarComponent';
import Logo from '../components/Base/LogoComponent/Logo';
import { ChatComponent } from '../components/ChatComponent';
import CustomizedBreadcrumbs from "../components/NavigateModule/BreadcrumbsComponent";
import HeadMenuComponent from "../components/NavigateModule/HeadMenuComponent";
import HelperComponent from '../components/RightPartModule/HelperComponent';
import { modifyChatAutoRead, modifyChatConversationId, modifyChatPartnerId, modifyPresentFlip, modifyPresentMenu } from "../store/reducers/cnfSlice";
import { RootState } from '../store/store';
export default function LayoutPage() {
    const { userProfile } = useSelector((state: RootState) => state.userProfileSlice.profile);

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { menuState, presentMenu, presentFlip } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { refresh } = useSelector((state: RootState) => state.cnfSlice.chat);

    const [countBadge, setCountBadge] = useState<number>();
    const [countPersonalChat, setCountPersonalChat] = useState<number>(0);

    const navigate = useNavigate();
    const [viewMap, setViewMap] = useState(false);

    // useEffect(() => {
    //     if (modifyNotificationCount) {
    //         (async () => {
    //             const [, res] = await NotificationApiService.GetNumberOfUnreadNotifications()
    //             if (res) {
    //                 setCountBadge(res.data.body)
    //             }
    //             dispatch(modifyNotificationStateCount(false));
    //         })()
    //     }
    // }, [modifyNotificationCount])

    // useEffect(() => {
    //     dispatch(modifyNotificationState(true));
    // }, [])

    // useEffect(() => {
    //     (async () => {
    //         const [error, response] = await PersonalChatService.getCountOfUnreadMess();
    //         if (response) {
    //             setCountPersonalChat(response.data.body);
    //         }
    //         if (error) {
    //             dispatch(setLastError(error))
    //         }


    //     })()
    // }, [, refresh]);

    const hendlerShowMenu = () => {
        dispatch(modifyPresentMenu())
    }

    const hendlerShowFlip = () => {
        dispatch(modifyPresentFlip())
    }

    const hendlerView = () => {
        setViewMap(true);
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        dispatch(modifyChatPartnerId(''));
        dispatch(modifyChatAutoRead(false));
    };

    const handleClose = () => {
        setAnchorEl(null);
        dispatch(modifyChatConversationId(''));
        dispatch(modifyChatAutoRead(false));
    };


    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", alignContent: "stretch", gap: 20 }}>
            <AppBar position="static">
                <div style={{ marginLeft: "5%", marginRight: "5%", padding: 0 }}>
                    <Toolbar sx={{ marginLeft: "-24px", marginRight: "-48px", minWidth: "1028px" }}>

                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                            onClick={hendlerShowMenu}
                        >
                            {(presentMenu) ? <MenuOpenIcon sx={{ fontSize: "30px" }} /> : <MenuIcon sx={{ fontSize: "30px" }} />}
                        </IconButton>
                        <Box sx={{ marginTop: "10px", marginRight: "20px" }}>
                            <Logo heigth={55} width={55} color={'white'} />
                        </Box>
                        <CustomizedBreadcrumbs />
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <IconButton size="large" aria-label="show 4 new mails" color="inherit" disabled onClick={handleClick}>
                                <Badge badgeContent={countPersonalChat} color="error">
                                    <MailIcon />
                                </Badge>
                            </IconButton>

                            <Popper
                                open={open}
                                anchorEl={anchorEl}
                                placement='bottom-start'
                            >
                                <ChatComponent count={countPersonalChat} close={handleClose} />
                            </Popper>

                            <IconButton disabled color="inherit" size="large" onClick={() => { navigate('/notification'); }}>
                                <Badge badgeContent={countBadge} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>

                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <AvatarComponent data={userProfile.avatar} width='40px' height='40px' />
                            </IconButton>
                            <Box sx={{ marginTop: "12px", marginLeft: 2 }}>
                                <Typography variant={"body2"}>
                                    {userProfile.firstName && userProfile.lastName
                                        ? <span>{`${userProfile.firstName.toUpperCase()} ${userProfile.lastName.toUpperCase()}`}</span>
                                        : <Skeleton width='150px' />
                                    }
                                </Typography>
                                <Typography variant={"body2"}>
                                    {userProfile.role.name
                                        ? <span>{userProfile.role.name.toString().toUpperCase()}</span>
                                        : <Skeleton width='150px' />
                                    }
                                </Typography>
                            </Box>

                            <Box style={{ width: "15px", padding: "15px 5px 5px 5px" }}>
                            </Box>

                            <IconButton
                                disabled
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 2 }}
                                onClick={hendlerShowFlip}
                            >
                                <FlipIcon sx={{ fontSize: "30px" }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </div>
            </AppBar>
            <div style={{
                flex: "1 1 auto",
                marginLeft: "5%",
                marginRight: "5%",
                marginBottom: 20,
                display: "flex",
                alignContent: "stretch",
                minWidth: "1028px",
                gap: 20,
            }}>

                {(presentMenu) &&
                    <Paper sx={{ flex: 1, maxWidth: (menuState === 1) ? "90px" : "50px", padding: 2, textAlign: "center" }}>
                        <HeadMenuComponent></HeadMenuComponent>
                    </Paper>
                }

                <Paper sx={{ flex: 1, padding: 2 }}>
                    {/* {viewMap ? <WrapMap /> : <Button onClick={hendlerView}>view</Button>} */}
                    <div style={{
                        display: "flex",
                        alignContent: "stretch",
                    }} > <Outlet /> </div>
                </Paper>
                {(presentFlip) &&
                    <Paper sx={{ flex: 1, maxWidth: "300px", padding: 2 }}>
                        <HelperComponent />
                        <Divider />
                    </Paper>
                }
            </div>
        </div>

    );
}