import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ICriteria } from '../../../models/CommonModels';
import { decodeFromBase64 } from '../../../helpers/EncoderDecoderHelper';

interface IProps {
    onApplyFilter: (criterias: ICriteria[]) => void;
    onResetState: () => void;
    mainSection: JSX.Element;
    stepSection: JSX.Element;
}
const BasePage = (props: IProps) => {
    const { onApplyFilter, onResetState, mainSection, stepSection } = props;

    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [showComponent, setShowComponent] = useState<boolean>(false);

    useEffect(() => {
        if (searchParams.has('filter')) {
            const filterEncoded: string = searchParams.get('filter') || '';
            const filterDecoded: string = decodeFromBase64(filterEncoded);
            const criterias: ICriteria[] = JSON.parse(filterDecoded);
            onApplyFilter(criterias);
        }

        setShowComponent(true);

        return () => onResetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {showComponent &&
                <Box sx={{ height: '89vh', flex: 1 }}>
                    <Box sx={{ height: '100%', position: 'relative' }}>
                        <Box height={'100%'}>
                            {mainSection}
                        </Box>

                        {id &&
                            <Box sx={{ height: '100%', width: '900px', position: 'absolute', top: 0, right: 0 }}>
                                {stepSection}
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </>
    );
}
export default BasePage;