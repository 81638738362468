import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { IItemDetails } from '../../../../models/CommonModels';
import { IWarehouseStatusReputationResponseDto } from '../../../../models/WarehouseModels';
import WarehouseService from '../../../../services/WarhouseService';
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import ItemsDetails from "../../../Base/ItemsDetailsComponent/ItemsDetails";
import WarehouseStatusAndReputationDialog from './WarehouseStatusAndReputationDialog';

interface IProps {
    warehouseId: string;
    readonly: boolean;
}
const WarehouseStatusAndReputation = (props: IProps) => {
    const { warehouseId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [data, setData] = useState<IWarehouseStatusReputationResponseDto>();

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [dialog, setDialog] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await WarehouseService.fetchStatusAndReputation(warehouseId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId]);

    useEffect(() => {
        retrieveData();
    }, [refresh, retrieveData]);

    const items: IItemDetails[] = [{
        id: `${data?.status}-status`,
        primary: t('STATUS'),
        secondary: data?.status.toString().replaceAll('_', ' ')
    }, {
        id: `${data?.reputationLevel}-reputationLevel`,
        primary: t('REPUTATION'),
        secondary: data?.reputationLevel.toString()
    }];

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BackdropProgress open={loading || false}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={t('EDIT')} placement='top' >
                        <span>
                            <Button
                                onClick={dialogToggleHandler}
                                size='small'
                                variant='outlined'>
                                <EditIcon fontSize='small' />&nbsp;{t('EDIT')}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <ItemsDetails data={items} />
            </BackdropProgress>

            {dialog && data &&
                <WarehouseStatusAndReputationDialog
                    open={dialog}
                    entity={data}
                    onCloseBtnClick={dialogToggleHandler}
                    onSubmitBtnClick={refreshHandler}
                />
            }
        </>
    );
}
export default WarehouseStatusAndReputation;