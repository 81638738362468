import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { EFileType, IFileDetailsRequestDto, IImageRequest } from "../../../../models/FileModel";
import TransportService from "../../../../services/TransportService";
import FileUploadDialog from "../../../Base/FileComponent/FileUploadDialog";

const defaultFileDetailsRequestDto: IFileDetailsRequestDto = {};

interface IProps {
    transportId: string;
    type: EFileType;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const TransportImagesDialog = (props: IProps) => {
    const { open, transportId, type, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'transport-image-form';
    const [loading, setLoading] = useState(false);

    const { register, setValue, handleSubmit, formState: { isDirty, isValid } } = useForm<IImageRequest>({
        defaultValues: {
            image: undefined,
            request: {
                type: type,
                fileDetails: defaultFileDetailsRequestDto
            }
        }
    });

    const onSubmit = useCallback((data: IImageRequest) => {
        setLoading(true);
        (async () => {
            const formData = new FormData();
            formData.append('request', new Blob([JSON.stringify(data.request)], { type: 'application/json' }));
            formData.append('image', data.image);

            const [error, response] = await TransportService.createImage(transportId, formData);
            if (response) {
                displayNotification({ message: t('Image was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, transportId]);

    const validateImage = useCallback((value: File) => {
        return value !== undefined;
    }, []);

    register('image', { validate: validateImage });
    register('request.fileDetails');
    const onSelectHandler = useCallback((file: File, details: IFileDetailsRequestDto) => {
        setValue('image', file, {
            shouldValidate: true,
            shouldDirty: true
        });

        setValue('request.fileDetails', details, {
            shouldDirty: true
        });

    }, [setValue]);

    return (
        <FileUploadDialog
            loading={loading}
            open={open}
            title={t('CREATE IMAGE')}
            formId={formId}
            acceptedFiles='image/*'
            aspect={type === EFileType.MAIN ? 1 : 0}
            saveBtnDisabled={!isValid || !isDirty}
            onSelect={onSelectHandler}
            onSubmit={handleSubmit(onSubmit)}
            onCloseBtnClick={onCloseBtnClick}
        />
    )
}
export default TransportImagesDialog;