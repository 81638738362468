import { IConversationMessages } from "./PersonalChatModels";

export enum ESocketCommandType {
    USER_ONLINE_REFRESH = 'user-online-refresh',
    WAREHOUSE_CREATE = 'warehouse-create',
    WAREHOUSE_DESCRIPTION_UPDATE = 'warehouse-description-update',
    WAREHOUSE_OVERVIEW_UPDATE = 'warehouse-overview-update',
    WAREHOUSE_CONTACT_UPDATE = 'warehouse-contact-update',
    WAREHOUSE_STATUS_REPUTATION_UPDATE = 'warehouse-status-reputation--update',
    WAREHOUSE_DELETE = 'warehouse-delete',

    ACTIVE_LOAD_CREATE = 'active-load-create',
    ACTIVE_LOAD_DELETE = 'active-load-delete',
    ACTIVE_LOAD_OVERVIEW_UPDATE = 'active-load-overview-update',
    ACTIVE_LOAD_TRANSPORT_CREATE = 'active-load-transport-create',
    ACTIVE_LOAD_TRANSPORT_UPDATE = 'active-load-transport-update',
    ACTIVE_LOAD_TRANSPORT_DELETE = 'active-load-transport-delete',
    ACTIVE_LOAD_USER_CREATE = 'active-load-user-create',
    ACTIVE_LOAD_USER_UPDATE = 'active-load-user-update',
    ACTIVE_LOAD_USER_DELETE = 'active-load-user-delete',
    ACTIVE_LOAD_DOCUMENT_CREATE = 'active-load-document-create',
    ACTIVE_LOAD_DOCUMENT_UPDATE = 'active-load-document-update',
    ACTIVE_LOAD_DOCUMENT_DELETE = 'active-load-document-delete',
    ACTIVE_LOAD_DOCUMENT_ATTACHMENT_CREATE = 'active-load-document-attachment-create',
    ACTIVE_LOAD_DOCUMENT_ATTACHMENT_DELETE = 'active-load-document-attachment-delete',

    CUSTOMER_CREATE = 'customer-create',
    CUSTOMER_DESCRIPTION_UPDATE = 'customer-description-update',
    CUSTOMER_OVERVIEW_UPDATE = 'customer-overview-update',
    CUSTOMER_CONTACT_UPDATE = 'customer-contact-update',
    CUSTOMER_STATUS_REPUTATION_UPDATE = 'customer-status-reputation--update',
    CUSTOMER_DELETE = 'customer-delete',
    CUSTOMER_DOCUMENT_CREATE = 'customer-document-create',
    CUSTOMER_DOCUMENT_UPDATE = 'customer-document-update',
    CUSTOMER_DOCUMENT_DELETE = 'customer-document-delete',
    CUSTOMER_DOCUMENT_ATTACHMENT_CREATE = 'customer-document-attachment-create',
    CUSTOMER_DOCUMENT_ATTACHMENT_DELETE = 'customer-document-attachment-delete',

    LOAD_CREATE = 'load-create',
    LOAD_STATUS_UPDATE = 'load-status-update',
    LOAD_OVERVIEW_UPDATE = 'load-overview-update',
    LOAD_DESCRIPTION_UPDATE = 'load-description-update',
    LOAD_ITEM_CREATE = 'load-item-create',
    LOAD_ITEM_UPDATE = 'load-item-update',
    LOAD_ITEM_DELETE = 'load-item-delete',
    LOAD_ITEM_DELIVERY_UPDATE = 'load-item-delivery-update',
    LOAD_FRAGILITY_TYPE_CREATE = 'load-fragility-type-create',
    LOAD_FRAGILITY_TYPE_UPDATE = 'load-fragility-type-update',
    LOAD_FRAGILITY_TYPE_DELET = 'load-fragility-type-delete',
    LOAD_PALLET_TYPE_CREATE = 'load-pallet-type-create',
    LOAD_PALLET_TYPE_UPDATE = 'load-pallet-type-update',
    LOAD_PALLET_TYPE_DELETE = 'load-pallet-type-delete',
    LOAD_DOCUMENT_CREATE = 'load-document-create',
    LOAD_DOCUMENT_UPDATE = 'load-document-update',
    LOAD_DOCUMENT_DELETE = 'load-document-delete',
    LOAD_DOCUMENT_ATTACHMENT_CREATE = 'load-document-attachment-create',
    LOAD_DOCUMENT_ATTACHMENT_DELETE = 'load-document-attachment-delete',
    LOAD_ADDRESS_CREATE = 'load-address-create',
    LOAD_ADDRESS_UPDATE = 'load-address-update',
    LOAD_ADDRESS_DELETE = 'load-address-delete',
    LOAD_DELETE = 'load-delete',

    PERSONAL_CHAT_MESSAGE_RECEIVED = 'personal-chat-message-received',
    PERSONAL_CHAT_MESSAGE_READ = 'personal-chat-message-read',

    ISSUE_CREATE = 'issue-create',
    ISSUE_UPDATE = 'issue-update',
    ISSUE_DELETE = 'issue-delete',
    ISSUE_CHAT_MESSAGE_RECEIVED = 'issue-chat-message-received',
    ISSUE_CHAT_MESSAGE_READ = 'issue-chat-message-read',
    ISSUE_CHAT_USER_ADD = 'issue-chat-user-add',
    ISSUE_CHAT_USER_DELETE = 'issue-chat-user-delete',
}

export interface ISocketCommand {
    command: ESocketCommandType;
    dateTime: number;
    entityId?: string;
}

export interface ISocketPersonalChat {
    conversationId: string;
    message: IConversationMessages;
}