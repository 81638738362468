import FitbitIcon from '@mui/icons-material/Fitbit';
import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { calculateDaysBetweenDates, convertTimestampToDate, getDateAndTimeFormat, getDateFormat } from "../../../helpers/dateHelper";
import { isEmpty, normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { currencyFormat } from '../../../helpers/NumberHelper';
import { IBaseGridActionBtn, IFilter } from '../../../models/CommonModels';
import { ICustomerIdNameResponseDto } from '../../../models/CustomerModels';
import { ELoadStatus, ILoadResponseDto } from '../../../models/LoadModels';
import LoadService from '../../../services/LoadService';
import { RootState } from "../../../store/store";
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import DetailsBtn from '../../Base/DetailsBtnComponent/DetailsBtn';
import FragilityTypeManagementDialog from '../FragilityType/FragilityTypeManagementDialog';
import PalletTypeManagementDialog from '../PalletType/PalletTypeManagementDialog';
import LoadDialog from './LoadDialog';
import LoadFilter from './LoadFilter';

const LoadGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.grid);

    const dueDateStatuses = useRef<ELoadStatus[]>([ELoadStatus.INCOMPLETE, ELoadStatus.PENDING, ELoadStatus.LOADED, ELoadStatus.IN_ROUTE]);

    const [createDialogToggle, setCreateDialogToggle] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);
    const [palletTypeToggle, setPalletTypeToggle] = useState<boolean>(false);
    const [fragilityTypeToggle, setFragilityTypeToggle] = useState<boolean>(false);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchAllByFilter(filter);
    }, []);

    const onDetailsActionBtnClick = useCallback((uuid: string) => {
        const newUrl = normalizeNavigationUrl(uuid);
        navigate(`/${newUrl}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        return LoadService.downloadReport(filter);
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onActionsColumnRender = useCallback((record: any) => {
        const row = record.row;
        return [
            <DetailsBtn onClick={() => onDetailsActionBtnClick(row.uuid)} />
        ];
    }, [onDetailsActionBtnClick]);

    const buildIconOnCenterColumn = useCallback((icon: any) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                {icon}
            </Box>
        );
    }, []);

    const onFavoriteColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: boolean = params.value;
        return (
            buildIconOnCenterColumn(value
                ? <GradeIcon color='action' fontSize='small' />
                : <GradeOutlinedIcon color='action' fontSize='small' />
            )
        );
    }, [buildIconOnCenterColumn]);

    const onCustomerColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ICustomerIdNameResponseDto = params.value;
        return (
            <Tooltip title={value.name.toUpperCase()} sx={{ cursor: 'pointer' }}>
                <Link to={`/customer/${value.uuid}`}>
                    {value.name.toUpperCase()}
                </Link>
            </Tooltip>
        );
    }, []);

    const onStatusColumnRender = useCallback((value: string) => {
        return value.toString().replace('_', ' ');
    }, []);

    const onPickupOrDeliveryColumnRender = useCallback((params: GridRenderCellParams) => {
        const addresses: string[] | null = params.value;
        if (addresses === null || isEmpty(addresses)) {
            return '';
        }

        return (
            addresses.map((address, index) =>
                <Tooltip key={address} title={address} placement={"bottom"}>
                    <span>{index > 0 ? ` | ${address}` : address}</span>
                </Tooltip>
            )
        );
    }, []);

    const getDueDateColor = useCallback((status: ELoadStatus, deliveredBy?: number): string => {
        let dueDateColor: string = 'black';

        if (deliveredBy && dueDateStatuses.current.includes(status)) {
            const deliveredByDate: Date = convertTimestampToDate(deliveredBy);
            const currentDate: Date = new Date();
            const diff: number = calculateDaysBetweenDates(currentDate, deliveredByDate);

            if (diff <= 7 && diff >= 5) {
                dueDateColor = '#ed6c02';
            } else if (diff <= 4) {
                dueDateColor = 'red';
            }
        }

        return dueDateColor;
    }, []);

    const onDueDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value ? getDateFormat(params.value, dateFormat) : '';
        return (
            <Tooltip title={value}>
                <span style={{ color: getDueDateColor(params.row.status, params.value) }}>{value}</span>
            </Tooltip>
        );
    }, [dateFormat, getDueDateColor]);

    const onItemsColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                <Tooltip title={`${t('TOTAL: ')} ${row.totalNumberOfItems}`}>
                    <span style={{ color: 'black' }}>{row.totalNumberOfItems}</span>
                </Tooltip>

                <Tooltip title={`${t('LOADED: ')} ${row.numberOfLoadedItems}`}>
                    <span style={{ color: 'orange' }}>{row.numberOfLoadedItems}</span>
                </Tooltip>

                <Tooltip title={`${t('DELIVERED: ')} ${row.numberOfDeliveredItems}`}>
                    <span style={{ color: 'green' }}>{row.numberOfDeliveredItems}</span>
                </Tooltip>

                <Tooltip title={`${t('PAYED: ')} ${row.numberOfPayedItems}`}>
                    <span style={{ color: 'blue' }}>{row.numberOfPayedItems}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onAmountColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const total: string = currencyFormat(row.totalAmount);
        const payed: string = currencyFormat(row.payedAmount);
        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'right' }}>
                <Tooltip title={`${t('TOTAL: ')} ${total}`}>
                    <span style={{ color: 'black' }}>{total}</span>
                </Tooltip>

                <Tooltip title={`${t('PAYED: ')} ${payed}`}>
                    <span style={{ color: 'blue' }}>{payed}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onUpdatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const updatedBy: string = params.row.updatedBy;
        const updatedDate: number = params.row.updatedDate;
        if (!updatedBy) {
            return '';
        }

        const dateTime: string = getDateAndTimeFormat(updatedDate, dateFormat, timeFormat);
        const tooltip: string = `${updatedBy} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {updatedBy} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns: GridColDef[] = [{
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 50,
        align: 'center',
        resizable: false,
        disableColumnMenu: true,
        getActions: onActionsColumnRender
    }, {
        field: 'favorite',
        headerName: '',
        width: 50,
        align: 'center',
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: onFavoriteColumnRender
    }, {
        field: 'idno',
        headerName: t('ID#'),
        width: 150,
        headerAlign: 'center',
        sortable: false,
        renderCell: onTooltipColumnRender
    }, {
        field: 'customer',
        headerName: t('CUSTOMER'),
        width: 275,
        headerAlign: 'center',
        sortable: false,
        renderCell: onCustomerColumnRender
    }, {
        field: 'pickupAddresses',
        headerName: t('PICKUP'),
        flex: 1,
        minWidth: 200,
        headerAlign: 'center',
        sortable: false,
        renderCell: onPickupOrDeliveryColumnRender
    }, {
        field: 'deliveryAddresses',
        headerName: t('DELIVERY'),
        flex: 1,
        minWidth: 200,
        headerAlign: 'center',
        sortable: false,
        renderCell: onPickupOrDeliveryColumnRender
    }, {
        field: 'deliveredBy',
        headerName: t('DUE DATE'),
        width: 100,
        headerAlign: 'center',
        sortable: false,
        disableColumnMenu: true,
        renderCell: onDueDateColumnRender
    }, {
        field: 'items',
        headerName: t('ITEMS'),
        width: 170,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        disableColumnMenu: true,
        renderCell: onItemsColumnRender
    }, {
        field: 'amount',
        headerName: t('AMOUNT'),
        width: 200,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        disableColumnMenu: true,
        renderCell: onAmountColumnRender
    }, {
        field: 'status',
        headerName: t('STATUS'),
        width: 115,
        headerAlign: 'center',
        sortable: false,
        resizable: false,
        valueFormatter: onStatusColumnRender
    }, {
        field: 'created',
        headerName: t('CREATED'),
        width: 180,
        headerAlign: 'center',
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: onCreatedColumnRender
    }, {
        field: 'updated',
        headerName: t('UPDATED'),
        width: 180,
        headerAlign: 'center',
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: onUpdatedColumnRender
    }];

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialogToggle(createDialogToggle => !createDialogToggle);
    }, []);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(!filterToggle);
    }, [filterToggle]);

    const onPalletTypeToggleHandler = useCallback(() => {
        setPalletTypeToggle(palletTypeToggle => !palletTypeToggle);
    }, []);

    const onFragilityTypeToggleHandler = useCallback(() => {
        setFragilityTypeToggle(fragilityTypeToggle => !fragilityTypeToggle);
    }, []);

    const actionBtns: IBaseGridActionBtn[] = [{
        tooltip: t('Pallet type management'),
        onClick: onPalletTypeToggleHandler,
        icon: <ViewTimelineOutlinedIcon />,
        key: 'pallet-type-action-btn',
        iconBtn: true
    }, {
        tooltip: t('Fragility type management'),
        onClick: onFragilityTypeToggleHandler,
        icon: <FitbitIcon />,
        key: 'fragility-type-action-btn',
        iconBtn: true
    }];

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnTooltip={t('Create load')}
                onAddActionBtnClick={createDialogToggleHandler}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search load(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download load(s)')}
                downloadActionBtnApi={onDownloadActionBtnApiHandler}
                actionBtns={actionBtns}
            />

            {filterToggle &&
                <LoadFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            }

            {createDialogToggle &&
                <LoadDialog
                    open={createDialogToggle}
                    onCloseBtnClick={createDialogToggleHandler}
                />
            }

            {palletTypeToggle &&
                <PalletTypeManagementDialog
                    open={palletTypeToggle}
                    onCloseBtnClick={onPalletTypeToggleHandler}
                />
            }

            {fragilityTypeToggle &&
                <FragilityTypeManagementDialog
                    open={fragilityTypeToggle}
                    onCloseBtnClick={onFragilityTypeToggleHandler}
                />
            }
        </>
    );
}
export default LoadGrid;