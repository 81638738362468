import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useActiveLoad } from "../../../hooks/useActiveLoad";
import { EActiveLoadStatus } from "../../../models/ActiveLoadModels";
import { ECriteriaExpression, ICriteria } from "../../../models/CommonModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import DateRangeField from "../../Base/DateRangeComponent/DateRangeField";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";

interface IProps {
    open: boolean;
    onClose: () => void;
}
const ActiveLoadFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useActiveLoad();
    const { criterias } = useSelector((state: RootState) => state.activeLoadSlice.grid);

    const [idno, setIdno] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [shipDateFrom, setShipDateFrom] = useState<number | undefined>(undefined);
    const [shipDateTo, setShipDateTo] = useState<number | undefined>(undefined);
    const [deliveryDateFrom, setDeliveryDateFrom] = useState<number | undefined>(undefined);
    const [deliveryDateTo, setDeliveryDateTo] = useState<number | undefined>(undefined);
    const [status, setStatus] = useState<EActiveLoadStatus | undefined>(undefined);

    const statusData = useRef<string[]>(
        Object.keys(EActiveLoadStatus)
            .filter(key => isNaN(Number(key)))
            .filter(key => key !== EActiveLoadStatus[EActiveLoadStatus.NONE])
            .map(key => key.toString())
    );

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        setIdno(getValueFromCriterias('idno', criterias) || '');
        setName(getValueFromCriterias('name', criterias) || '');

        const shipDateRangeStr: string = getValueFromCriterias('shipDate', criterias) || '';
        if (isBlank(shipDateRangeStr)) {
            setShipDateFrom(undefined);
            setShipDateTo(undefined);
        } else {
            const shipDateRange: string[] = shipDateRangeStr.split(',');
            setShipDateFrom(Number(shipDateRange[0]));
            setShipDateTo(Number(shipDateRange[1]));
        }

        const deliveryDateRangeStr: string = getValueFromCriterias('deliveryDate', criterias) || '';
        if (isBlank(deliveryDateRangeStr)) {
            setDeliveryDateFrom(undefined);
            setDeliveryDateTo(undefined);
        } else {
            const deliveryDateRange: string[] = deliveryDateRangeStr.split(',');
            setDeliveryDateFrom(Number(deliveryDateRange[0]));
            setDeliveryDateTo(Number(deliveryDateRange[1]));
        }

        const statusStr: string | undefined = getValueFromCriterias('status', criterias);
        setStatus(statusStr as EActiveLoadStatus || EActiveLoadStatus.NONE);
    }, [criterias, getValueFromCriterias]);

    const onChangeIdnoHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIdno(event.target.value);
    }, []);

    const onChangeNameHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const onChangeShipDateRangeHandler = useCallback((fromDate?: number, toDate?: number) => {
        setShipDateFrom(fromDate);
        setShipDateTo(toDate);
    }, []);

    const onChangeDeliveryDateRangeHandler = useCallback((fromDate?: number, toDate?: number) => {
        setDeliveryDateFrom(fromDate);
        setDeliveryDateTo(toDate);
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as EActiveLoadStatus);
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (idno && !isBlank(idno)) {
            newCriterias.push({
                property: 'idno',
                value: idno,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (name && !isBlank(name)) {
            newCriterias.push({
                property: 'name',
                value: name,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (shipDateFrom && shipDateTo) {
            newCriterias.push({
                property: 'shipDate',
                value: `${shipDateFrom},${shipDateTo}`,
                expression: ECriteriaExpression.BETWEEN_DATE
            });
        }

        if (deliveryDateFrom && deliveryDateTo) {
            newCriterias.push({
                property: 'deliveryDate',
                value: `${deliveryDateFrom},${deliveryDateTo}`,
                expression: ECriteriaExpression.BETWEEN_DATE
            });
        }

        if (status) {
            newCriterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        return newCriterias;
    }, [deliveryDateFrom, deliveryDateTo, idno, name, shipDateFrom, shipDateTo, status]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('ID#')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        value={idno}
                        onChange={onChangeIdnoHandler}
                    />

                    <DateRangeField
                        alignDirection='row'
                        label={t('SHIP DATE')}
                        requiredToFrom
                        fromDateLabel={t('FROM')}
                        toDateLabel={t('TO')}
                        toDateValue={shipDateTo}
                        fromDateValue={shipDateFrom}
                        onChange={onChangeShipDateRangeHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('NAME')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        value={name}
                        onChange={onChangeNameHandler}
                    />

                    <DateRangeField
                        alignDirection='row'
                        label={t('DELIVERY DATE')}
                        requiredToFrom
                        fromDateLabel={t('FROM')}
                        toDateLabel={t('TO')}
                        toDateValue={deliveryDateTo}
                        fromDateValue={deliveryDateFrom}
                        onChange={onChangeDeliveryDateRangeHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData.current}
                        value={status}
                        onChange={onChangeStatusHandler}
                    />
                </Stack>
            </Stack>
        );
    }, [deliveryDateFrom, deliveryDateTo, idno, name, onChangeDeliveryDateRangeHandler,
        onChangeIdnoHandler, onChangeNameHandler, onChangeShipDateRangeHandler,
        onChangeStatusHandler, shipDateFrom, shipDateTo, status, t
    ]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default ActiveLoadFilter;