import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../../helpers/generalHelper";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { IFilter } from "../../../../models/CommonModels";
import { ELoadAddressReference, ILoadAddressResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import LoadAddressesDialog from "./LoadAddressesDialog";

interface IProps {
    loadId: string;
}
const LoadAddressesGrid = (props: IProps) => {
    const { loadId } = props;

    const { t } = useTranslation();
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.addressesGrid);
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh, addressesGridRefresh } = useLoad();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [entity, setEntity] = useState<ILoadAddressResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchAddresses(loadId, filter);
    }, [loadId]);

    const onAddActionBtnClickHandler = useCallback(() => {
        setEntity(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClickHandler = useCallback((entity: ILoadAddressResponseDto) => {
        setEntity(entity);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClickHandler = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removeAddress(loadId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Load addresse${rowIds.length > 1 ? 'es' : ''} was successfully deleted.`) });
                    stepRefresh();
                    addressesGridRefresh();
                    gridRefresh();
                }
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressesGridRefresh, loadId, stepRefresh, t]);

    const onTypeOrAliasColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onNameColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadAddressResponseDto = params.row;
        const reference: ELoadAddressReference = row.addressReference;
        let value: string | undefined;

        switch (reference) {
            case ELoadAddressReference.WAREHOUSE: value = row.warehouse?.name; break;
            case ELoadAddressReference.CUSTOMER: value = row.customer?.name; break;
            case ELoadAddressReference.ADDRESS: value = ''; break;
        };

        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onAddressColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadAddressResponseDto = params.row;
        const reference: ELoadAddressReference = row.addressReference;
        let value: string | undefined;

        switch (reference) {
            case ELoadAddressReference.WAREHOUSE: value = row.warehouse?.address.value; break;
            case ELoadAddressReference.CUSTOMER: value = row.customer?.address.value; break;
            case ELoadAddressReference.ADDRESS: value = row.address?.value; break;
        };

        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const columns: GridColDef[] = [{
        field: 'type',
        headerName: t('TYPE'),
        width: 85,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onTypeOrAliasColumnRender
    }, {
        field: 'alias',
        headerName: t('ALIAS'),
        width: 150,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onTypeOrAliasColumnRender
    }, {
        field: 'name',
        headerName: t('NAME'),
        width: 200,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onNameColumnRender
    }, {
        field: 'address',
        headerName: t('ADDRESS'),
        flex: 1,
        minWidth: 200,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onAddressColumnRender
    }];

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setEntity(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        addressesGridRefresh();
    }, [addressesGridRefresh]);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClickHandler}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add address')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClickHandler}
                editActionBtnTooltip={t('Edit address')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClickHandler}
                deleteActionBtnTooltip={t('Delete address(es)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
            />

            {dialogToggle &&
                <LoadAddressesDialog
                    open={dialogToggle}
                    loadId={loadId}
                    entity={entity}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default LoadAddressesGrid;