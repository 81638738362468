import { useCallback, useRef } from "react";
import { useWarehouse } from "../../../../hooks/useWarehouse";
import { IReviewRequestDto } from "../../../../models/ReviewModels";
import WarehouseService from "../../../../services/WarhouseService";
import BaseReview from "../../../Base/BaseReviewComponent/BaseReview";

interface IProps {
    warehouseId: string;
}
const WarehouseReview = (props: IProps) => {
    const { warehouseId } = props;
    const { gridRefresh, stepRefresh } = useWarehouse();
    const maxHeight = useRef<string | number>('300px');

    const fetchApi = useCallback(() => {
        return WarehouseService.fetchReviews(warehouseId);
    }, [warehouseId]);

    const createApi = useCallback((data: IReviewRequestDto) => {
        return WarehouseService.createReview(warehouseId, data);
    }, [warehouseId]);

    const updateApi = useCallback((uuid: string, data: IReviewRequestDto) => {
        return WarehouseService.updateReview(warehouseId, uuid, data);
    }, [warehouseId]);

    const deleteApi = useCallback((uuid: string) => {
        return WarehouseService.removeReview(warehouseId, uuid);
    }, [warehouseId]);

    const onRefreshHandler = useCallback(() => {
        gridRefresh();
        stepRefresh();
    }, [gridRefresh, stepRefresh]);

    return (
        <BaseReview
            maxHeight={maxHeight.current}
            fetchApi={fetchApi}
            createApi={createApi}
            updateApi={updateApi}
            deleteApi={deleteApi}
            onRefresh={onRefreshHandler}
        />
    );
}
export default WarehouseReview;