import { Box, Paper } from "@mui/material";
import CustomerContacts from "./CustomerContacts";

interface IProps {
    customerId: string;
    readonly: boolean;
}
const CustomerContactsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <CustomerContacts {...props} />
            </Box>
        </Paper>
    );
}
export default CustomerContactsStep;