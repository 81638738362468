import AuthUserProfile from "../components/AuthModule/Control/AuthUserProfile";
import PrivateRoutes from "./PrivateRoutes";

interface IProps {
    loaded: boolean;
}
const ProtectedRoutes = (props: IProps) => {
    const { loaded } = props;
    return (
        <>
            <AuthUserProfile />
            {loaded && <PrivateRoutes />}
        </>
    );
}
export default ProtectedRoutes;