export interface IGlobalProfile {
    measureSize: ESizeType
    measureDistance: EDistanceType,
    measureConsumption: EConsumptionType,
    measureMass: EMassType,
    measureEngineVolume: EEngineVolumeType,
    measureEnginePower: EEnginePowerType
}

export interface ISystemPreference {
    keys: IKey[];
}

export interface IKey {
    name: string;
    value: string;
}

export enum EKey {
    GOOGLE_MAP = 'google-maps-common-service-api-key',
    GOOGLE_MAP_ID = 'google-maps-id-key'
}

export enum ESizeType {
    INCH = 'in',
    FOOT = 'ft',
    CENTIMETER = 'cm',
    MILLIMETER = 'mm'
}

export enum EDistanceType {
    NAUTICAL_MILE = 'nm',
    MILE = 'mi',
    KILOMETER = 'km',
    METER = 'm'
}
export enum EConsumptionType {
    MILES_PER_GALLON = 'MPG',
    LITRES_100_KM = 'L/100km'
}

export enum EMassType {
    POUND = 'ib',
    KILOGRAM = 'kg',
    TON = 't'
}

export enum EEngineVolumeType {
    LITER = 'l'
}

export enum EEnginePowerType {
    HORSE_POWER = 'hp'
}

export enum ETimeFormat {
    h_12 = '12h',
    h_24 = '24h'
}

export enum EDateFormat {
    d_yyyy_mm_dd = 'YYYY-MM-DD',
    d_mm_dd_yyyy = 'MM-DD-YYYY',
    d_dd_mm_yyyy = 'DD-MM-YYYY'
}