import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid, IReduxStep } from "../../models/ReduxModels";

interface CustomerState {
    grid: IReduxGrid;
    step: IReduxStep;
};

const initialState: CustomerState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    step: {
        refresh: false
    }
};

export const CustomerSlice = createSlice({
    name: 'customerSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: CustomerState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: CustomerState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        },
        stepRefresh(state: CustomerState) {
            state.step.refresh = !state.step.refresh;
        }
    }
});

export const CustomerActions = CustomerSlice.actions;
export const CustomerReducer = CustomerSlice.reducer;