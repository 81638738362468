interface IProps {
    value: string;
    width: number;
    heigth: number;
}
const FilePdfView = (props: IProps) => {
    const { value, width, heigth } = props;

    return (
        <object
            type='application/pdf'
            width={`${width * 0.7}px`}
            height={`${heigth * 0.7}px`}
            data={`data:application/pdf;base64,${value}`}
        />
    );
}
export default FilePdfView;