import { Box, Paper } from "@mui/material";
import TransportDocument from "./TransportDocument";

interface IProps {
    transportId: string;
}
const TransportDocumentsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '15px 2px 10px 10px', height: 400 }}>
                <TransportDocument {...props} />
            </Box>
        </Paper>
    );
}
export default TransportDocumentsStep;