import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getDateFormat } from "../../../../helpers/dateHelper";
import { removeEmptyFields } from "../../../../helpers/generalHelper";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { ILocation } from "../../../../models/AddressModels";
import { IDocument } from "../../../../models/DocumentModels";
import { EDateFormat } from "../../../../models/ProfileModels";
import UserService from "../../../../services/UserService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DateField from "../../../Base/DateComponent/DateField";
import DocumentTypeSelect from "../../../Base/DocumentTypeSelectComponent/DocumentTypeSelect";
import CountryAutocomplete from "../../../Base/GeolocationComponent/CountryAutocomplete";
import { EUserDocumentType, IUserDocumentRequestDto } from "../../../../models/UserModels";

interface IProps {
    userId: string;
    document?: IDocument;
    open: boolean;

    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const UserDocumentDialog = (props: IProps) => {
    const { open, userId, document, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'user-document-form';
    const isEdit = useRef<boolean>(document !== undefined);

    const [loading, setLoading] = useState(false);
    const [maxDate, setMaxDate] = useState<string | undefined>(isEdit.current && document?.dateOfIssue ? getDateFormat(document.dateOfIssue, EDateFormat.d_yyyy_mm_dd) : undefined);
    const [minDate, setMinDate] = useState<string | undefined>(isEdit.current && document?.expirationDate ? getDateFormat(document.expirationDate, EDateFormat.d_yyyy_mm_dd) : undefined);

    const { register, setError, clearErrors, setValue, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IUserDocumentRequestDto>({
        defaultValues: {
            type: isEdit.current ? document!.type as EUserDocumentType : undefined,
            expirationDate: isEdit.current ? document?.expirationDate : NaN,
            number: isEdit.current ? document?.number : undefined,
            dateOfIssue: isEdit.current ? document?.dateOfIssue : NaN,
            issueCountryId: isEdit.current ? document?.issueCountry?.uuid : undefined,
        }
    });

    const updateData = useCallback((uuid: string, data: IUserDocumentRequestDto) => {
        (async () => {
            const [error, response] = await UserService.updateDocument(userId, uuid, data);
            if (response) {
                displayNotification({ message: t('Document was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [onCloseBtnClick, onSubmitBtnClick, t, userId]);

    const createData = useCallback((data: IUserDocumentRequestDto) => {
        (async () => {
            const [error, response] = await UserService.createDocument(userId, data);
            if (response) {
                displayNotification({ message: t('Document was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [onCloseBtnClick, onSubmitBtnClick, t, userId]);

    const onSubmit = useCallback((data: IUserDocumentRequestDto) => {
        setLoading(true);
        const normalisedData: IUserDocumentRequestDto = removeEmptyFields(data) as unknown as IUserDocumentRequestDto;
        if (document) {
            updateData(document.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [document]);

    const documentTypeData = useRef<string[]>(
        Object.keys(EUserDocumentType)
            .filter(key => isNaN(Number(key)))
            .filter(key => key !== EUserDocumentType[EUserDocumentType.NONE])
            .map(key => key.toString())
    );

    const validateType = useCallback((value: EUserDocumentType) => {
        return EUserDocumentType[value] !== undefined && value !== EUserDocumentType.NONE;
    }, []);

    register('type', { validate: validateType });
    const onDocumentTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as EUserDocumentType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateNumber = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('number', { message: message });
            return false;
        }
        clearErrors('number');
        return true;
    }, [clearErrors, setError, t]);

    register('issueCountryId');
    const onChangeIssueCountryHandler = useCallback((value: ILocation | null) => {
        setValue('issueCountryId', value?.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('dateOfIssue');
    const onChangeDateOfIssueHandler = useCallback((timestamp?: number, dateStr?: string) => {
        setValue('dateOfIssue', timestamp, { shouldDirty: true });
        setMinDate(dateStr);
    }, [setValue]);

    register('expirationDate');
    const onChangeExpirationDateHandler = useCallback((timestamp?: number, dateStr?: string) => {
        setValue('expirationDate', timestamp, { shouldDirty: true });
        setMaxDate(dateStr);
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <DocumentTypeSelect
                        label={t('TYPE')}
                        size='medium'
                        data={documentTypeData.current}
                        required
                        value={getValues('type')}
                        onChange={onDocumentTypeChangeHandler}
                    />

                    <TextField
                        {...register('number', { validate: validateNumber })}
                        autoComplete={'off'}
                        label={t('NUMBER')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.number}
                        helperText={errors.number?.message}
                    />

                    <CountryAutocomplete
                        label={t('COUNTRY')}
                        onChange={onChangeIssueCountryHandler}
                        value={getValues('issueCountryId')}
                    />

                    <Stack spacing={2} direction='row'>
                        <DateField
                            label={t('DATE OF ISSUE')}
                            size='medium'
                            value={getValues('dateOfIssue')}
                            onChange={onChangeDateOfIssueHandler}
                            max={maxDate}
                        />

                        <DateField
                            label={t('EXPIRATION DATE')}
                            size='medium'
                            value={getValues('expirationDate')}
                            onChange={onChangeExpirationDateHandler}
                            min={minDate}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [errors.number, getValues, handleSubmit, maxDate, minDate, onChangeDateOfIssueHandler, onChangeExpirationDateHandler,
        onChangeIssueCountryHandler, onDocumentTypeChangeHandler, onSubmit, register, t, validateNumber]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} DOCUMENT`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    )
}
export default UserDocumentDialog;