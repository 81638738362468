import { Box, MenuItem, TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ObjectToValueArray, compareValue } from "../../../helpers/generalHelper";
import { EDateFormat, ETimeFormat } from "../../../models/ProfileModels";
import { RootState } from "../../../store/store";
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";

interface IProps {
    getNewInterfaceState: (state: any) => void;
    state: any;
}

const DateAndTimeFormat = (props: IProps) => {
    const { getNewInterfaceState, state } = props
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [localState, setLocalState] = useState<any>(state);
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        let valueArr: Array<string> = value.split(' ', 1);
        let dateFormat = valueArr[0] as EDateFormat;
        // setLocalState((st) => { return { ...st, dateFormat } });
    }

    const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        let valueArr: Array<string> = value.split(' ', 1);
        let timeFormat = valueArr[0] as ETimeFormat;
        // setLocalState((st) => { return { ...st, timeFormat } });
    }

    const handleSaveButton = () => {
        getNewInterfaceState(localState);
    }

    const actualTime = (s: string) => {
        let h12_Format = false;
        if (s === ETimeFormat.h_12) {
            h12_Format = true;
        }
        let timeString = new Date(Date.now()).toLocaleTimeString(
            'en-US', { hour12: h12_Format, hour: 'numeric', minute: 'numeric' }
        )
        return ' (' + timeString + ')'
    }

    const actualData = (s: string) => {
        let date = new Date(Date.now())
        const dateFormat = dayjs(date).format(s)
        return ' (' + dateFormat + ')';
    }

    useEffect(() => {
        setLocalState(state);
    }, [state]);

    useEffect(() => {
        setDisabled(compareValue(localState, state));
    }, [localState, state]);

    return (
        <>
            <SCViewList column gap={40}>
                <SCViewList column gap={20} >
                    <TextField
                        select
                        value={localState.dateFormat}
                        inputProps={{ sx: { fontSize: "12px", } }}
                        sx={{ width: "300px" }}
                        onChange={handleChangeDate}
                    >
                        {ObjectToValueArray(EDateFormat).map((option: string, i) =>
                            <MenuItem key={i} value={option}>
                                {option.split('_').join(' ') + actualData(option)}
                            </MenuItem>)}
                    </TextField>
                    <TextField
                        select
                        value={localState.timeFormat}
                        inputProps={{ sx: { fontSize: "12px", } }}
                        sx={{ width: "300px" }}
                        onChange={handleChangeTime}
                    >
                        {ObjectToValueArray(ETimeFormat).map((option: string, i) =>
                            <MenuItem key={i} value={option}>
                                {option.split('_').join(' ') + actualTime(option)}
                            </MenuItem>)}
                    </TextField>
                </SCViewList>

                <SCViewList flexEnd width={90}>
                    <Tooltip
                        title={(hints && !disabled) ? "Save global profile configurations" : ""}
                        placement="top"
                        arrow={true}
                    >
                        <Box>
                            {/* <SecureDialogComponent
                                answerYES={() => handleSaveButton()}
                                text={'SAVE'}
                                iconButton={false}
                                color={EButtonColor.primary}
                                disabled={disabled}
                            /> */}
                        </Box>
                    </Tooltip>
                </SCViewList>
            </SCViewList>
        </>
    )
}

export default DateAndTimeFormat;