import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, styled, Tooltip } from "@mui/material";
import { ReactNode, useCallback } from "react";

const HiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

interface IProps {
    label: string;
    tooltip?: string;
    variant?: 'outlined' | 'contained',
    size?: 'small' | 'medium',
    icon?: ReactNode;
    acceptedFiles?: string;
    onSelect: (file: File) => void;
}
const FileUploadBtn = (props: IProps) => {
    const {
        label, tooltip, variant = 'contained', size = 'medium',
        icon = <CloudUploadIcon />, acceptedFiles = '*', onSelect
    } = props;

    const onSelectHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = event.target.files;
        if (files && files[0]) {
            const file: File = files[0];
            onSelect(file);
        }
    }, [onSelect]);

    return (
        <Tooltip title={tooltip} >
            <Button
                component="label"
                variant={variant}
                startIcon={icon}
                size={size}
            >
                {label}
                <HiddenInput
                    type='file'
                    accept={acceptedFiles}
                    onChange={onSelectHandler}
                />
            </Button>
        </Tooltip>

    );
}
export default FileUploadBtn;