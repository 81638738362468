import { Box } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import BaseDialog from "../../Base/BaseDialogComponent/BaseDialog";
import VehicleGrid from "./VehicleGrid";

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
}
const VehicleManagementDialog = (props: IProps) => {
    const { open, onCloseBtnClick } = props;
    const { t } = useTranslation();

    const onBuildContent = useCallback(() => {
        return <Box height={400}> <VehicleGrid /> </Box>
    }, []);

    return (
        <BaseDialog
            open={open}
            title={t('VEHICLE MANAGEMENT')}
            maxWidth={'xxl'}
            buildContent={onBuildContent}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default VehicleManagementDialog;