import { useCallback, useContext, useEffect } from "react";
import { AppConfig } from "../../../configs/AppConfig";
import { AuthContext } from "../../../context/AuthProvider";
import { IJwtRequestDto, IToken } from "../../../models/AuthModels";
import AuthService from "../../../services/AuthService";

const AuthRefreshToken = () => {
    const { authenticated, token, setToken } = useContext(AuthContext);

    const refreshToken = useCallback((request: IJwtRequestDto) => {
        (async () => {
            const [error, response] = await AuthService.refreshToken(request);
            if (response) {
                const newToken: IToken = response.data.body;
                AuthService.saveTokenToLocalStorage(newToken);
                setToken(newToken);
            }

            if (error) {
                AuthService.logout();
            }
        })();
    }, [setToken]);

    const processAndReturnTimer = useCallback((token: IToken) => {
        const currentTime: number = Date.now();
        const tokenExpireTime = token.expiryDuration;
        const refreshTokenTime = (tokenExpireTime - currentTime) - Number(AppConfig.refreshTokenBefore);
        return setTimeout(() => refreshToken({ refreshToken: token.refreshToken }), refreshTokenTime);
    }, [refreshToken]);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        if (authenticated && token) {
            timer = processAndReturnTimer(token);
        }

        return () => {
            clearTimeout(timer);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processAndReturnTimer, token]);

    return (<></>);
}
export default AuthRefreshToken;