import { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { IFileDetailsRequestDto } from "../../../../models/FileModel";

type MinSizeType = {
    height: number;
    width: number;
};
const defaultMinSize: MinSizeType = { height: 0, width: 0 };
const minCropSize: number = 250;

interface IProps {
    file: File;
    aspect: number;
    disabled: boolean;
    onSelect: (details: IFileDetailsRequestDto) => void;
}
const ImageCrop = (props: IProps) => {
    const { file, aspect, disabled, onSelect } = props;

    const [imageSrc, setImageSrc] = useState<string>('');
    const [crop, setCrop] = useState<Crop>();
    const [minSize, setMinSize] = useState<MinSizeType>(defaultMinSize);

    const imgRef = useRef<HTMLImageElement>(null);

    const readFile = useCallback(() => {
        setCrop(undefined);
        const reader: FileReader = new FileReader();
        reader.addEventListener('load', () =>
            setImageSrc(reader.result ? reader.result.toString() : ''));
        reader.readAsDataURL(file);
    }, [file]);

    useEffect(() => {
        readFile();
    }, [readFile]);

    const adjustEdges = useCallback((value: number, adjustSize?: boolean) => {
        if (value < 0) {
            return 0;
        }

        if (imgRef.current) {
            const adjustedValue: number = value + minCropSize;
            if (adjustSize) {
                if (adjustedValue > imgRef.current.height) {
                    return imgRef.current.height - minCropSize;
                }
            } else {
                if (adjustedValue > imgRef.current.width) {
                    return imgRef.current.width - minCropSize;
                }
            }
        }

        return value;
    }, []);

    const onChangeHandler = useCallback((newCrop: Crop) => {
        const adjustedCrop: Crop = {
            ...newCrop,
            x: adjustEdges(newCrop.x),
            y: adjustEdges(newCrop.y, true)
        };

        setCrop(adjustedCrop);
    }, [adjustEdges]);

    const onCompleteHandler = useCallback((value: PixelCrop) => {
        if (imgRef.current) {
            const naturalWidth: number = imgRef.current.naturalWidth;
            const naturalHeight: number = imgRef.current.naturalHeight;
            const imgWidth: number = imgRef.current.width;
            const imgHeight: number = imgRef.current.height;

            const width = naturalWidth / imgWidth;
            const height = naturalHeight / imgHeight;

            const details: IFileDetailsRequestDto = {
                cropX: Math.floor(value.x * width),
                cropY: Math.floor(value.y * height),
                cropWidth: Math.floor(value.width * width),
                cropHeight: Math.floor(value.height * height)
            };

            onSelect(details);
        }
    }, [onSelect]);

    const onImageLoadHandler = useCallback((event: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height, naturalHeight, naturalWidth } = event.currentTarget;

        setMinSize({
            width: (width / naturalWidth) * minCropSize,
            height: (height / naturalHeight) * minCropSize
        });

        if (aspect) {
            const minSize = (width > height) ? height : width;
            setCrop({ width: minSize, height: minSize, x: 0, y: 0, unit: 'px' });
        } else {
            setCrop({ width: width, height: height, x: 0, y: 0, unit: 'px' });
        }
    }, [aspect]);

    return (
        <>
            {imageSrc &&
                <ReactCrop
                    crop={crop}
                    style={{ width: '100%', height: 'auto' }}
                    onChange={onChangeHandler}
                    onComplete={onCompleteHandler}
                    aspect={aspect}
                    minHeight={minSize.height}
                    minWidth={minSize.width}
                    disabled={disabled}
                >
                    <img
                        ref={imgRef}
                        alt='Crop selected file'
                        src={imageSrc}
                        style={{
                            transform: `scale(1) rotate(0deg)`,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                        onLoad={onImageLoadHandler}
                    />
                </ReactCrop>
            }
        </>
    );
}
export default ImageCrop;