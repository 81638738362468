import AddLinkIcon from '@mui/icons-material/AddLink';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Paper, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { dateToYMD } from "../helpers/dateHelper";
import { ENotificationType } from "../models/EnumGeneral";
import { INotification } from "../models/NotificationModels";
import NotificationApiService from "../services/NotificationApiService";
import NotificationService from "../services/NotificationService";
import { RootState } from "../store/store";

import DraftsIcon from '@mui/icons-material/Drafts';
import EmailIcon from '@mui/icons-material/Email';
import Logo from "../components/Base/LogoComponent/Logo";

const tick = (n: number): string => {
  let d = new Date(n);
  let h = d.getHours().toString();
  let m = d.getMinutes().toString();
  let s = d.getSeconds().toString();
  return "  " + digitsClock(h) + ':' + digitsClock(m) + ':' + digitsClock(s) + "";
}
const digitsClock = (s: string): string => {
  return (s.length < 2) ? '0' + s : s;
}
const newState = () => {
  return {
    userUUID: " ",
    dt: 0,
    text: ["0"],
    state: false,
    type: ENotificationType.ADDITIONAL,
    link: "",
    check: false,
  }
}

const NotificationPage = () => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const { modifyNotification } = useSelector((state: RootState) => state.cnfSlice);
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const [serverNotifications, setServerNotifications] = React.useState<INotification[]>([])
  const [state, setState] = useState<INotification>(newState())
  const [open, setOpen] = React.useState(false);
  const [togglePartLeft, setTogglePartLeft] = React.useState('all');
  const [notification, setNotification] = useState<INotification[]>([]);
  const [leftPart, setLeftPart] = useState<INotification[]>([]);
  const [checked, setChecked] = useState<INotification[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [waitServerResponsee, setWaitServerResponsee] = useState<boolean>(true);

  const handlerSelect = (n: INotification) => {
    if (checked.indexOf(n) > -1) {

      let checkedNotification = [...checked.filter(e => e !== n)];
      let allServerNotifications = [...serverNotifications];

      if (!(checkedNotification.length === allServerNotifications.length)) {
        setCheckAll(false);
      }
      setChecked(checkedNotification);

    } else {
      let checkedNotification = [...checked.filter(e => e !== n), ...[n]];
      let allServerNotifications = [...serverNotifications];

      if (checkedNotification.length === allServerNotifications.length) {
        setCheckAll(true);
      }
      setChecked(checkedNotification);
    }
  }

  const handlerMarkAsReadDialog = (async (notification: INotification) => {
    if (open) {
      setOpen(false);
      let sNotifications = [...serverNotifications];
      const index = sNotifications.findIndex(obj => {
        return obj.uuid === notification.uuid;
      });
      sNotifications[index].state = false;
      if (notification.uuid) {
        let body: Array<String> = [notification.uuid]
        const [error, response] = await NotificationApiService.MarkNotificationAsRead(body)
        // if (response) {
        //   dispatch(modifyNotificationStateCount(true));
        // }
        // if (error) {
        //   dispatch(modifyNotificationState(true))
        // }
      }
    }
  })

  const handlerMarkAsRead = (async () => {
    if (waitServerResponsee) {

      let unreadNotifications = checked.filter((e) => e.state === true)

      let sNotifications = [...serverNotifications];
      for (let item of unreadNotifications) {
        const index = sNotifications.findIndex(obj => {
          return obj.uuid === item.uuid;
        });
        sNotifications[index].state = false;
      }
      setServerNotifications([...sNotifications]);
      let body: Array<String> = []
      let exists: boolean = true;
      for (let item of unreadNotifications) {
        if (item.uuid) {
          exists = false;
          body.push(item.uuid);
        }
      }
      if (!exists) {
        setWaitServerResponsee(true);
        const [error, response] = await NotificationApiService.MarkNotificationAsRead(body)
        if (response) {
          setWaitServerResponsee(true);
        }
        // if (error) {
        //   dispatch(modifyNotificationState(true))
        // }
        // dispatch(modifyNotificationStateCount(true));
      }
    }

  })
  const handlerMarkAsUnreadDialog = (async (notification: INotification) => {
    if (open) {
      setOpen(false);

      let sNotifications = [...serverNotifications];
      const index = sNotifications.findIndex(obj => {
        return obj.uuid === notification.uuid;
      });
      sNotifications[index].state = true;

      if (notification.uuid) {
        let body: Array<String> = [notification.uuid]
        const [error, response] = await NotificationApiService.MarkNotificationAsUnread(body)
        // if (response) {
        //   dispatch(modifyNotificationStateCount(true));
        // }
        // if (error) {
        //   dispatch(modifyNotificationState(true))
        // }
      }
    }
  })
  const handlerMarkAsUnread = (async () => {

    if (waitServerResponsee) {

      let readNotifications = checked.filter((e) => e.state === false && e.priority)


      let sNotifications = [...serverNotifications];
      for (let item of readNotifications) {
        const index = sNotifications.findIndex(obj => {
          return obj.uuid === item.uuid;
        });
        sNotifications[index].state = true;
      }
      setServerNotifications([...sNotifications]);

      let body: Array<String> = []
      let exists: boolean = true;
      for (let item of readNotifications) {
        if (item.uuid) {
          exists = false;
          body.push(item.uuid);
        }
      }
      if (!exists) {
        setWaitServerResponsee(false);
        const [error, response] = await NotificationApiService.MarkNotificationAsUnread(body)
        if (response) {
          setWaitServerResponsee(true);
        }
        // if (error) {
        //   dispatch(modifyNotificationState(true))
        // }
        // dispatch(modifyNotificationStateCount(true));
      }
    }
  })

  const handlerViewDialog = (n: INotification) => {
    setState({ ...n })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeButtons = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue) setTogglePartLeft(newValue);
  };

  useEffect(() => {
    let notifications: INotification[] = NotificationService.getAllNotification();
    setNotification(notifications);
  }, [])

  // useEffect(() => {
  //   dispatch(modifyNotificationState(true));
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (modifyNotification) {
  //       const [, response] = await NotificationApiService.GetNotifications()

  //       if (response) {
  //         let serverResponse: [] = response.data.body;
  //         let notifications: INotification[] = []
  //         serverResponse.map((n: any) => {
  //           notifications.push({ title: n.title, dt: n.dateTime, userUUID: " ", text: [n.message], state: !n.read, priority: n.priority, uuid: n.uuid });
  //         })
  //         notifications.sort((a1, a2) => a2.dt - a1.dt);

  //         setServerNotifications(notifications);
  //         let checkedNotifications: INotification[] = [];
  //         checked.map((e: INotification) => {
  //           checkedNotifications = [...checkedNotifications, ...notifications.filter((n: INotification) => {
  //             if (e.uuid === n.uuid) {
  //               return e;
  //             }
  //           })]
  //         })
  //         if (checkedNotifications) {
  //           setChecked(checkedNotifications);
  //         }
  //       }
  //       dispatch(modifyNotificationState(false));
  //     }
  //   })()
  // }, [modifyNotification])

  useEffect(() => {
    if (notification) {
      let p1 = [...serverNotifications, ...notification].sort((a1, a2) => a2.dt - a1.dt);
      if (togglePartLeft !== "all") {
        if (togglePartLeft !== "priorityNotification") {
          p1 = p1.filter((e) => e.type === togglePartLeft)
        } else {
          p1 = p1.filter((e) => e.priority)
        }
      }
      setLeftPart(p1)
    }
  }, [notification, serverNotifications, togglePartLeft])
  useEffect(() => {
    setChecked([]);
    setCheckAll(false);
  }, [togglePartLeft]);

  const handleChangeCheked = () => {
    setCheckAll(!checkAll);
    if (!checkAll) {
      setChecked([...serverNotifications]);
    } else {
      setChecked([])
    }
  }

  const checkIfNotificationIsSelected = (check: INotification[], n: INotification): boolean => {
    let checkNotification: INotification[] = [];
    checkNotification = check.filter((e: INotification) => { if (e.uuid === n.uuid) return true })

    return (checkNotification.length !== 0);
  }
  return (
    <>

      <div style={{ width: "100%", height: "calc(100vh - 140px)", display: "flex", alignContent: "stretch", gap: "20px" }} >

        <div style={{ flex: 1, padding: 2 }}>
          <div >
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px", height: "40px", gap: "70px" }}>

              <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px", height: "40px", gap: "10px", paddingLeft: "5px" }}>

                <Checkbox
                  disabled={!(leftPart.filter((e) => e.priority).length !== 0)}
                  checked={checkAll}
                  onChange={handleChangeCheked}
                ></Checkbox>

                {(checked.length === 0) && <div style={{ width: "64px" }}></div>}
                {((checked.filter((e) => e.state === true).length === 0) && checked.length > 0) &&
                  <Tooltip
                    title={(hints) ? "Mark as unread" : ""}
                    placement="bottom"
                    arrow={true}
                  >
                    <IconButton
                      onClick={() => handlerMarkAsUnread()}
                      color={'inherit'}
                      size={'small'}
                    >
                      <EmailIcon />
                    </IconButton>
                  </Tooltip>}
                {((checked.filter((e) => e.state === true).length !== 0) && checked.length > 0) &&
                  <Tooltip
                    title={(hints) ? "Mark as read" : ""}
                    placement="bottom"
                    arrow={true}
                  >
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => handlerMarkAsRead()}
                    >
                      <DraftsIcon />
                    </IconButton>
                  </Tooltip>
                }
              </div>

              <ToggleButtonGroup
                color="primary"
                value={togglePartLeft}
                size="large"
                exclusive
                onChange={handleChangeButtons}
              >
                <ToggleButton value="all"  >
                  <ClearAllIcon />
                </ToggleButton>
                <ToggleButton value={"priorityNotification"}>
                  <NotificationsActiveIcon color={'primary'} />
                </ToggleButton>
                <ToggleButton value={ENotificationType.ERROR}>
                  <GppBadIcon color={'error'} />

                </ToggleButton>
                <ToggleButton value={ENotificationType.ADDITIONAL}>
                  <GppMaybeIcon color={'success'} />
                </ToggleButton>

              </ToggleButtonGroup>
            </div>
            <Divider />
            {leftPart.length !== 0 ?
              <div style={{ paddingTop: "5px", paddingRight: "5px", display: "flex", flexDirection: "column", gap: "5px", height: "calc(100vh - 240px)", overflow: "hidden", overflowY: 'scroll' }}>
                {leftPart.map((notifications: INotification, key: number) =>
                  <NotificationElementComponent
                    notification={notifications}
                    viewDialog={handlerViewDialog}
                    selected={checkIfNotificationIsSelected(checked, notifications)}
                    selectItem={handlerSelect}
                    key={key} />
                )}


              </div>
              : <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px dashed silver",
                gap: "5px",
                height: "calc(100vh - 240px)",
                overflow: "hidden",
                overflowY: 'scroll'
              }}>
                <Typography sx={{ color: "silver", fontSize: "25px" }}> There are no notifications </Typography>
              </div>
            }
          </div>
        </div>
      </div >

      < Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth={'lg'}
      >
        <DialogTitle id="responsive-dialog-title">
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", width: "700px" }}>
            <div style={{ display: "flex", alignItems: "center", paddingTop: "40px", paddingRight: "20px", paddingLeft: "20px", marginRight: "10px", marginTop: "10px" }}>
              <Logo width={130} heigth={130} />
            </div>
            <div style={{ minHeight: "230px", paddingTop: "10px", marginLeft: "20px" }}>

              <div style={{ display: "flex", gap: "18px", flexDirection: "column", marginBottom: "30px", height: "70px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "20px", fontSize: "14px" }}>


                  <div>{tick(state.dt)} [{dateToYMD(state.dt).substring(0)}]</div>
                  <div style={{ display: "flex", gap: "5px" }}>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "10px" }}>
                      {/* {state.type === ENotificationType.ADDITIONAL &&
                        <TooltipedIconsHelper
                          text={"Success notification"}
                          icon={<GppMaybeIcon color={'success'} />}
                          tooltipPlacement="top"
                        />
                      }
                      {state.type === ENotificationType.ERROR &&
                        <TooltipedIconsHelper
                          text={"Error notification"}
                          icon={<GppBadIcon color={'error'} />}
                          tooltipPlacement="top"
                        />
                      }
                      {state.priority &&
                        <TooltipedIconsHelper
                          text={"Business notification"}
                          icon={<NotificationsActiveIcon color={'primary'} />}
                          tooltipPlacement="top"
                        />
                      } */}

                    </div>
                    {/* {(state.priority === EPriorityNotification.LOW) &&
                      <TooltipedIconsHelper
                        text={"Low priority"}
                        icon={<KeyboardArrowDownIcon fontSize="large" color={"warning"} sx={{ marginRight: "13px" }} />}
                        tooltipPlacement="top"
                      />
                    } */}

                    {/* {(state.priority === EPriorityNotification.MEDIUM) &&
                      <TooltipedIconsHelper
                        text={"Medium priority"}
                        icon={<HorizontalRuleIcon fontSize="large" color={"warning"} sx={{ marginRight: "13px" }} />}
                        tooltipPlacement="top"
                      />
                    }
                    {(state.priority === EPriorityNotification.HIGH) &&
                      <TooltipedIconsHelper
                        text={"High priority"}
                        icon={<KeyboardArrowUpIcon fontSize="large" color={"warning"} sx={{ marginRight: "13px" }} />}
                        tooltipPlacement="top"
                      />
                    } */}
                  </div>


                </div>
                <div style={{ width: "500px", paddingLeft: "10px", paddingTop: "20px" }}>
                  {state.title && <Typography variant="subtitle1" >{state.title}</Typography>}
                </div>
                <Divider />


                <div>
                  <div style={{ width: "500px", paddingLeft: "10px" }}>
                    {state.text.map((n: string, i: number) => <Typography variant="subtitle2" key={i}> {n} </Typography>)}
                  </div>
                </div>
                <Divider />
              </div>

            </div>
          </div>
          <DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>


          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginRight: "20px", marginTop: "-20px", paddingBottom: "20px" }}>
            <div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {state.state === true && <Button
                color={'warning'}
                variant={'contained'}
                size={'small'}
                onClick={() => handlerMarkAsReadDialog(state)}
                startIcon={<DraftsIcon />}>Mark as Read</Button>}
              {(state.state === false && state.priority) && <Button
                color={'warning'}
                variant={'contained'}
                size={'small'}
                onClick={() => handlerMarkAsUnreadDialog(state)}
                startIcon={<EmailIcon />}>Mark as unread</Button>}
              <Button
                startIcon={<CloseIcon />}
                variant={'contained'}
                size={'small'}
                onClick={handleClose}>Close</Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default NotificationPage;

interface IPropsNotificationElementComponent {
  notification: INotification,
  selected: boolean,
  viewDialog: (n: INotification) => void;
  selectItem: (n: INotification) => void;
}

const NotificationElementComponent = (props: IPropsNotificationElementComponent) => {
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const { dt, text, state, type, link, title, priority } = props.notification;
  const navigate = useNavigate();
  const [ch, setCh] = useState<boolean>(false);

  useEffect(() => {
    setCh(props.selected)
  }, [props.selected])


  const handleOpenDialog = () => {
    props.viewDialog(props.notification);
  }

  const handleSelect = () => {
    props.selectItem(props.notification)
  }

  return (
    <>
      <Paper
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          cursor: "pointer"
        }}
        elevation={0}
        variant="outlined" square
      >
        <div style={{ display: "flex", width: "100%", padding: "5px", gap: "5px", backgroundColor: state ? "#ECEBEB" : "white" }}>
          <Checkbox checked={ch} disabled={!priority} onChange={handleSelect} />  <div style={{ width: "10px" }}></div>

          <div onClick={handleOpenDialog} style={{ display: "flex", gap: "5px", height: "60px", width: "100%" }}>

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", fontSize: "12px" }}>
              <div>{tick(dt)}</div>
              <div style={{ textAlign: "left" }}>{dateToYMD(dt).substring(0)}</div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "5px" }}>
              {type === ENotificationType.ADDITIONAL && <GppMaybeIcon color={'success'} />}
              {type === ENotificationType.ERROR && <GppBadIcon color={'error'} />}
              {type === ENotificationType.REQUARED && <VisibilityIcon color={'warning'} />}
              {priority && <NotificationsActiveIcon color={'primary'} />}
            </div>

            <div style={{
              display: "flex",
              flexDirection: 'column',
              width: "70%",
              alignSelf: "auto",
              padding: "2px",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "flex-start",
              fontSize: "16px",
              paddingLeft: "10px"

            }}>
              {title && <div style={{ fontSize: "16px" }}>
                {title}
              </div>}
              {!title && text.map((e: string, i: number) => <div key={i} >
                <div></div>
                <div> {e}</div>
              </div>)}
            </div>

          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px", alignContent: "center", justifyContent: "center" }}>
            {(link !== '' && link) &&
              <Tooltip
                title={(hints) ? "Navigate to the affected entity" : ""}
                arrow={true}
                placement="top"
              >
                <Button onClick={() => navigate(link)} style={{ borderColor: "1px solid silver" }}><AddLinkIcon color={'disabled'} /></Button>
              </Tooltip>
            }
            {/* {(priority === EPriorityNotification.LOW) &&
              <TooltipedIconsHelper
                text={"Low priority"}
                icon={<KeyboardArrowDownIcon fontSize="large" color={"warning"} sx={{ marginRight: "13px" }} />}
                tooltipPlacement="top"
              />
            } */}

            {/* {(priority === EPriorityNotification.MEDIUM) &&
              <TooltipedIconsHelper
                text={"Medium priority"}
                icon={<HorizontalRuleIcon fontSize="large" color={"warning"} sx={{ marginRight: "13px" }} />}
                tooltipPlacement="top"
              />
            }
            {(priority === EPriorityNotification.HIGH) &&
              <TooltipedIconsHelper
                text={"High priority"}
                icon={<KeyboardArrowUpIcon fontSize="large" color={"warning"} sx={{ marginRight: "13px" }} />}
                tooltipPlacement="top"
              />
            } */}
          </div>
        </div>
      </Paper>
    </>)
}