import { IFilter } from "../models/CommonModels";
import { IReviewRequestDto } from "../models/ReviewModels";
import { IWarehouseContactRequestDto, IWarehouseRequestDto, IWarehouseStatusReputationRequestDto } from "../models/WarehouseModels";
import { API } from "./APIService";

class WarehouseService {

    public static fetchAutocomplete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/autocomplete');
        return [error, response];
    };

    public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'warehouse/all', JSON.stringify(filter));
        return [error, response];
    };

    public static downloadReport = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'warehouse/report', filter, undefined, undefined, 'blob');
        return [error, response];
    };

    public static create = async (data: IWarehouseRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'warehouse', data);
        return [error, response];
    };

    public static fetchOverview = async (warehouseId: string): Promise<any> => {
        const [error, response] = await API('GET', `warehouse/${warehouseId}/overview`);
        return [error, response];
    };

    public static updateOverview = async (warehouseId: string, data: IWarehouseRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `warehouse/${warehouseId}/overview`, data);
        return [error, response];
    };

    public static fetchTypes = async (): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/types');
        return [error, response];
    };

    public static fetchCoverFaceData = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `warehouse/${uuid}/coverFace`);
        return [error, response];
    };

    public static remove = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', `warehouse/${uuid}`);
        return [error, response];
    };

    public static createFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('POST', 'warehouse/favorite', ids);
        return [error, response];
    };

    public static removeFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('DELETE', 'warehouse/favorite', ids);
        return [error, response];
    };

    public static fetchContacts = async (warehouseId: string): Promise<any> => {
        const [error, response] = await API('GET', `warehouse/${warehouseId}/contacts`);
        return [error, response];
    };

    public static updateContacts = async (warehouseId: string, data: IWarehouseContactRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `warehouse/${warehouseId}/contacts`, data);
        return [error, response];
    };

    public static fetchDescription = async (warehouseId: string): Promise<any> => {
        const [error, response] = await API('GET', `warehouse/${warehouseId}/description`);
        return [error, response];
    };

    public static updateDescription = async (warehouseId: string, description?: string): Promise<any> => {
        const [error, response] = await API('PUT', `warehouse/${warehouseId}/description`, description);
        return [error, response];
    };

    public static fetchReviews = async (warehouseId: string): Promise<any> => {
        const [error, response] = await API('GET', `warehouse/${warehouseId}/reviews`);
        return [error, response];
    };

    public static createReview = async (warehouseId: string, data: IReviewRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `warehouse/${warehouseId}/reviews`, data);
        return [error, response];
    };

    public static updateReview = async (warehouseId: string, reviewId: string, data: IReviewRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `warehouse/${warehouseId}/reviews/${reviewId}`, data);
        return [error, response];
    };

    public static removeReview = async (warehouseId: string, reviewId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `warehouse/${warehouseId}/reviews/${reviewId}`);
        return [error, response];
    };

    public static fetchStatusAndReputation = async (warehouseId: string): Promise<any> => {
        const [error, response] = await API('GET', `warehouse/${warehouseId}/statusAndReputation`);
        return [error, response];
    };

    public static updateStatusAndReputation = async (warehouseId: string, data: IWarehouseStatusReputationRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `warehouse/${warehouseId}/statusAndReputation`, data);
        return [error, response];
    };

    public static getFavorites = async (): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/favorite');
        return [error, response];
    };
}

export default WarehouseService;