import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNotification } from "../../../../hooks/useNotification";
import { useTransport } from '../../../../hooks/useTransport';
import { EFileType } from '../../../../models/FileModel';
import { ITransportImageResponseDto } from "../../../../models/TransportModels";
import TransportService from "../../../../services/TransportService";
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import FileViewDialog from '../../../Base/FileComponent/FileViewDialog';
import ImageItem from '../../../Base/FileComponent/Image/ImageItem';
import TransportImagesDialog from './TransportImagesDialog';

interface IProps {
    transportId: string;
    readonly: boolean;
}
const TransportImagesMain = (props: IProps) => {
    const { transportId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [image, setImage] = useState<ITransportImageResponseDto>();
    const [createDialog, setCreateDialog] = useState<boolean>(false);
    const [viewDialog, setViewDialog] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const { gridRefresh, stepRefresh } = useTransport();
    const { t } = useTranslation();
    const { displayNotification } = useNotification();

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.fetchMainImage(transportId);
            if (response) {
                setImage(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [transportId]);

    useEffect(() => {
        retrieveData();
    }, [retrieveData, refresh]);

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialog(dialog => !dialog);
    }, []);

    const refreshGridAndStep = useCallback(() => {
        gridRefresh();
        stepRefresh();
    }, [gridRefresh, stepRefresh]);

    const onSubmitBtnClick = useCallback(() => {
        setRefresh(refresh => !refresh);
        refreshGridAndStep();
    }, [refreshGridAndStep]);

    const onRemoveImageHandler = useCallback(() => {
        if (image) {
            setLoading(true);
            (async () => {
                const [error, response] = await TransportService.removeImage(transportId, image.uuid);
                if (response) {
                    displayNotification({ message: t('Image was successfully deleted.') });
                    setImage(undefined);
                    setLoading(false);
                    refreshGridAndStep();
                }

                if (error) {
                    displayNotification({ type: 'error', message: error?.message });
                    setLoading(false);
                }
            })();
        }
    }, [image, refreshGridAndStep, t, transportId]);

    const viewDialogToggleHandler = useCallback(() => {
        setViewDialog(dialog => !dialog);
    }, []);

    return (
        <>
            <BackdropProgress open={loading || false}>
                <Box display='flex' justifyContent='center'>
                    <ImageItem
                        imageId={image?.uuid}
                        image={image?.thumbnail}
                        original={image?.originalFile}
                        square
                        width={'130px'}
                        addBtnShow
                        addBtnTooltip={t('Add main image')}
                        addBtnClick={createDialogToggleHandler}
                        deleteBtnShow
                        deleteBtnTooltip={t('Delete main image')}
                        deleteBtnClick={onRemoveImageHandler}
                        viewBtnShow
                        viewBtnTooltip={t('View main image')}
                        viewBtnClick={viewDialogToggleHandler}
                        downloadBtnShow
                        downloadBtnTooltip={t('Download main image')}
                    />
                </Box>
            </BackdropProgress>

            {createDialog &&
                <TransportImagesDialog
                    open={createDialog}
                    onCloseBtnClick={createDialogToggleHandler}
                    onSubmitBtnClick={onSubmitBtnClick}
                    type={EFileType.MAIN}
                    transportId={transportId}
                />
            }

            {viewDialog && image &&
                <FileViewDialog
                    fileId={image.uuid}
                    file={image.originalFile}
                    open={viewDialog}
                    navigationBtnsHide
                    onCloseBtnClick={viewDialogToggleHandler}
                />
            }
        </>
    );
}
export default TransportImagesMain;