import { Box, Paper } from "@mui/material";
import CustomerStatusAndReputation from "./CustomerStatusAndReputation";

interface IProps {
    customerId: string;
    readonly: boolean;
}
const CustomerStatusAndReputationStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <CustomerStatusAndReputation {...props} />
            </Box>
        </Paper>
    );
}
export default CustomerStatusAndReputationStep;