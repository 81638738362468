
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { useCallback } from 'react';

interface IProps {
    tooltip?: string;
    onClick: () => void;
}
const DetailsBtn = (props: IProps) => {
    const { tooltip, onClick } = props;
    const theme = useTheme();

    const style = {
        color: theme.palette.action.active,
        "&:hover": {
            color: theme.palette.primary.main
        }
    };

    const onClickHandler = useCallback(() => {
        onClick();
    }, [onClick]);

    return (
        <Tooltip title={tooltip}>
            <IconButton sx={style} size={'small'} onClick={onClickHandler}>
                <AutoAwesomeIcon />
            </IconButton>
        </Tooltip>
    );
}
export default DetailsBtn;