import { EPublicAttachmentType } from "../models/PublicModels";
import { API } from "./APIService";

class PublicService {

    public static fetchMottos = async (): Promise<any> => {
        const [error, response] = await API('GET', 'public/motto');
        return [error, response];
    };

    public static fetchAttachmentFile = async (type: EPublicAttachmentType): Promise<any> => {
        const [error, response] = await API('GET', `public/publicAttachment/${type}`);
        return [error, response];
    };

    public static readAttachmentFile = async (type: EPublicAttachmentType): Promise<any> => {
        const [error, response] = await API('GET', `public/publicAttachment/file/${type}`);
        return [error, response];
    };

    public static fetchServerTime = async (): Promise<any> => {
        const [error, response] = await API('GET', 'public/serverTime');
        return [error, response];
    };
}
export default PublicService;