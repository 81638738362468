import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { EVehicleType } from "../../models/TransportModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: EVehicleType;
    size?: 'medium' | 'small';
    onChange?: (event: SelectChangeEvent) => void;
}
const TransportTypeSelect = (props: IProps) => {
    const { label = 'Type', required = false, size = 'medium', value, onChange } = props;
    const [selectedValue, setSelectedValue] = useState<string>('');

    useEffect(() => {
        setSelectedValue(value ? value.toString() : '');
    }, [value]);

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
    }, [onChange]);

    return (
        <FormControl fullWidth required={required} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                {
                    Object.keys(EVehicleType)
                        .filter(key => isNaN(Number(key)))
                        .filter(key => key !== EVehicleType[EVehicleType.NONE])
                        .map(key => <MenuItem key={key} value={key}>{key.toString().replace('_', ' ')}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
export default TransportTypeSelect;