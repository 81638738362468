import { useCallback } from "react";
import { EExtension } from '../../../models/FileModel';
import BaseDialog from "../BaseDialogComponent/BaseDialog";
import FileView from "./FileView";

interface IProps {
    data: string;
    extension: EExtension;

    open: boolean;
    onCloseBtnClick: () => void;
}
const FileViewOnlyDialog = (props: IProps) => {
    const { data, extension, open, onCloseBtnClick } = props;

    const buildContent = useCallback(() => {
        return (<FileView extension={extension} data={data} />);
    }, [data, extension]);

    return (
        <BaseDialog
            buildContent={buildContent}
            open={open}
            maxWidth='xl'
            onCloseBtnClick={onCloseBtnClick}
            closeBtnColor={'primary'}
            closeBtnVariant={'text'}
            closeBtnIconHide={true}
        />
    );
}
export default FileViewOnlyDialog;