import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGlobalData } from "../../../hooks/useGlobalData";
import { useNotification } from "../../../hooks/useNotification";
import PublicService from "../../../services/PublicService";
import { RootState } from "../../../store/store";

const NetworkHealthCheck = () => {
    const { updateOnline } = useGlobalData();
    const { online } = useSelector((state: RootState) => state.globalDataSlice);
    const { displayNotification } = useNotification();

    const { t } = useTranslation();
    const offlineMsg = useRef<string>(t('Network issue. You are offline now.'));

    const fetchData = useCallback(() => {
        try {
            (async () => {
                const [error, response] = await PublicService.fetchServerTime();
                if (response && !online) {
                    updateOnline(true);
                }

                if (error) {
                    if (online) {
                        updateOnline(false);
                    }
                    displayNotification({ type: 'error', message: offlineMsg.current });
                }
            })();
        } catch (error) {
            if (online) {
                updateOnline(false);
            }
            displayNotification({ type: 'error', message: offlineMsg.current });
        }
    }, [online, updateOnline]);

    useEffect(() => {
        const timer = setInterval(() => {
            fetchData();
        }, 7000);

        return () => clearInterval(timer);
    }, [fetchData]);

    return (<></>);
}
export default NetworkHealthCheck;