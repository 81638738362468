import { Box, Paper } from "@mui/material";
import ActiveLoadDocument from "./ActiveLoadDocument";

interface IProps {
    activeLoadId: string;
}
const ActiveLoadDocumentsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '15px 2px 10px 10px', height: 400 }}>
                <ActiveLoadDocument activeLoadId={props.activeLoadId} />
            </Box>
        </Paper>
    );
}
export default ActiveLoadDocumentsStep;