import { Box, Paper } from "@mui/material";
import { EIssueStatus } from "../../../../models/IssueModels";
import IssueAssignedOn from "./IssueAssignedOn";

interface IProps {
    entityId: string;
    status: EIssueStatus;
}
const IssueAssignedOnStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <IssueAssignedOn {...props} />
            </Box>
        </Paper>
    );
}
export default IssueAssignedOnStep;