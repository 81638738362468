import { Box } from "@mui/material";

interface IProps {
    label: string;
    height: string | number;
}
const NoData = (props: IProps) => {
    const { label, height } = props;

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            height={height}
            alignItems={'center'}
            sx={{ fontSize: '0.875rem' }}
        >
            {label}
        </Box>
    );
}
export default NoData;