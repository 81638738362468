import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid, IReduxStep } from "../../models/ReduxModels";

interface ActiveLoadState {
    grid: IReduxGrid;
    step: IReduxStep;
    paysGrid: IReduxGrid;
    transportsGrid: IReduxGrid;
    usersGrid: IReduxGrid;
};

const initialState: ActiveLoadState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    step: {
        refresh: false
    },
    paysGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    transportsGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    usersGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    }
};

export const ActiveLoadSlice = createSlice({
    name: 'activeLoadSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: ActiveLoadState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: ActiveLoadState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        },
        stepRefresh(state: ActiveLoadState) {
            state.step.refresh = !state.step.refresh;
        },
        paysGridRefresh(state: ActiveLoadState) {
            state.paysGrid.refresh = !state.paysGrid.refresh;
        },
        transportsGridRefresh(state: ActiveLoadState) {
            state.transportsGrid.refresh = !state.transportsGrid.refresh;
        },
        transportsGridUnselectRows(state: ActiveLoadState) {
            state.transportsGrid.unselectRows = !state.transportsGrid.unselectRows;
        },
        usersGridRefresh(state: ActiveLoadState) {
            state.usersGrid.refresh = !state.usersGrid.refresh;
        },
        usersGridUnselectRows(state: ActiveLoadState) {
            state.usersGrid.unselectRows = !state.usersGrid.unselectRows;
        }
    }
});

export const ActiveLoadActions = ActiveLoadSlice.actions;
export const ActiveLoadReducer = ActiveLoadSlice.reducer;