import { IAddressShortResponseDto } from "./AddressModels";
import { IAuthority } from "./PermissionModels";
import { IRoleIdNameResponseDto } from "./RoleModules";

export enum EUserStatus {
    NONE,
    INCOMPLETE = 'INCOMPLETE',
    ACTIVE = 'ACTIVE',
    TEMPORARY = 'TEMPORARY',
    INACTIVE = 'INACTIVE'
}

export enum EUserDocumentType {
    NONE,
    PASSPORT = 'PASSPORT',
    SOCIAL_SECURITY_NUMBER = 'SOCIAL_SECURITY_NUMBER',
    DRIVER_LICENSE = 'DRIVER_LICENSE',
    DRUG_TEST = 'DRUG_TEST',
    MEDICAL_CARD = 'MEDICAL_CARD'
}

export enum EUserPayType {
    NONE,
    RECURRING_PLUS = 'RECURRING_PLUS',
    RECURRING_MINUS = 'RECURRING_MINUS'
}

export enum EUserIncomeType {
    NONE,
    HOURLY = 'HOURLY',
    CENTS_PER_MILE = 'CENTS_PER_MILE',
    PERCENTAGE = 'PERCENTAGE',
    DROP_PICKUPS = 'DROP_PICKUPS'
}

export interface IUserResponseDto {
    uuid: string;
    fullName?: string;
    username: string;
    email: string;
    role: string;
    color: string;
    avatar?: string;
    online: boolean;
    lastActivityDate?: number;
    activeFromDate?: number;
    activeToDate?: number;
    status: EUserStatus;
    favorite: boolean;
    createdBy: string;
    createdDate: number;
    updatedBy?: string;
    updatedDate?: number;
}

export interface IUserCoverFaceResponseDto {
    uuid: string;
    name: string;
    username: string;
    role: string;
    color: string;
    favorite: boolean;
    avatar?: string;
    readonly: boolean;
    online: boolean;
    activeFromDate?: number;
    activeToDate?: number;
    status: EUserStatus;
}

export interface IUserRequestDto {
    email: string;
    roleId: string;
    color: string;
    addressId?: string;
    birthday?: number;
}

export interface IUserDocumentRequestDto {
    type: EUserDocumentType;
    number?: string;
    dateOfIssue?: number;
    expirationDate?: number;
    issueCountryId?: string;
}

export interface IUserStatusResponseDto {
    uuid: string;
    activeFromDate?: number;
    activeToDate?: number;
    status: EUserStatus;
}

export interface IUserStatusRequestDto {
    uuid: string;
    activeFromDate?: number;
    activeToDate?: number;
    status: EUserStatus;
}

export interface IUserOverviewResponseDto {
    uuid: string;
    email: string;
    role: IRoleIdNameResponseDto;
    color: string;
    address?: IAddressShortResponseDto;
    birthday?: number;
}

export interface IUserPayRequestDto {
    incomeId: string;
    type: EUserPayType;
    caption: string;
    amount: number;
}

export interface IUserPayResponseDto {
    uuid: string;
    income: IUserIncomeResponseDto;
    type: EUserPayType;
    caption: string;
    amount: number;
    createdBy: string;
    createdDate: number;
    updatedBy?: string;
    updatedDate?: number;
}

export interface IUserIncomeResponseDto {
    uuid: string;
    type: EUserIncomeType;
    amount: number;
    caption: string;
}

export interface IUserIncomeRequestDto {
    type: EUserIncomeType;
    amount: number;
    caption: string;
}

export interface IUserAutocompleteResponseDto {
    uuid: string;
    fullName: string;
    avatar?: string;
    role: IAuthority;
    status: EUserStatus;
}

export interface IUserShortResponseDto {
    uuid: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
    avatar?: string;
    color?: string;
    fullName: string;
    role?: IAuthority;
}

export interface IUserContactResponseDto {
    email: string;
    additionalEmail?: string;
    phone?: string;
    additionalPhone?: string;
}

export interface IUserContactRequestDto {
    additionalEmail?: string;
    phone?: string;
    additionalPhone?: string;
}

export interface IUserEmployeeRequestDto {
    startDate?: number;
    endDate?: number;
    workload?: number;
}

export interface IUserEmployeeResponseDto {
    startDate?: number;
    endDate?: number;
    workload?: number;
}