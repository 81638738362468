import { Divider, IconButton, Stack, Tooltip } from "@mui/material";
import { useCallback } from 'react';
import { IBaseActionBtn, IFilter } from '../../../models/CommonModels';
import { EButtonColor } from '../../../models/EnumGeneral';
import ButtonConfirmation from '../ActionConfirmationComponent/ButtonConfirmation';
import BaseDownloadBtn from '../BaseDownloadBtnComponent/BaseDownloadBtn';
import BaseFavoriteBtn from "../BaseFavoriteBtnComponent/BaseFavoriteBtn";

interface IProps {
    content: React.ReactNode;

    favoriteBtnHide?: boolean;
    favoriteBtnAddTooltip?: string,
    favoriteBtnRemoveTooltip?: string;
    favoriteBtn?: boolean;
    onFavoriteBtnClick?: () => void;

    downloadBtnTooltip?: string;
    downloadApi: (filter: IFilter) => Promise<any>;

    actionBtns?: IBaseActionBtn[];
}
const BaseStepCoverFace = (props: IProps) => {
    const {
        content, favoriteBtnHide = false, onFavoriteBtnClick, favoriteBtn, downloadApi, actionBtns,
        favoriteBtnAddTooltip = 'Add to favorites', favoriteBtnRemoveTooltip = 'Remove from favorites',
        downloadBtnTooltip = 'Download'
    } = props;

    const onFavoriteBtnClickHandler = useCallback(() => {
        if (onFavoriteBtnClick) {
            onFavoriteBtnClick();
        }
    }, [onFavoriteBtnClick]);

    const onActionBtnClickHandler = useCallback((onClick: () => void) => {
        onClick();
    }, []);

    return (
        <Stack sx={{ width: '100%' }} spacing={1} direction='row'>
            <div style={{ width: '100%', display: 'flex' }}>
                {content}
            </div>

            <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

            {actionBtns &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {actionBtns.map((btn) =>
                        btn.confirmation
                            ?
                            <ButtonConfirmation
                                iconBtn
                                key={btn.key}
                                color={EButtonColor.inherit}
                                onConfirm={() => onActionBtnClickHandler(btn.onClick)}
                                icon={btn.icon}
                                disabled={btn.disabled}
                                tooltip={btn.tooltip}
                            />
                            :
                            < Tooltip key={btn.key} title={btn.tooltip} placement='top' >
                                <span>
                                    <IconButton
                                        disabled={btn.disabled}
                                        onClick={() => onActionBtnClickHandler(btn.onClick)}>
                                        {btn.icon}
                                    </IconButton>
                                </span>
                            </Tooltip >
                    )}
                </div>
            }

            <div style={{ width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                {!favoriteBtnHide &&
                    <BaseFavoriteBtn
                        enabledTooltip
                        disableStyle
                        placementTop
                        addTooltip={favoriteBtnAddTooltip}
                        removeTooltip={favoriteBtnRemoveTooltip}
                        onBtnClick={onFavoriteBtnClickHandler}
                        favorite={favoriteBtn ? favoriteBtn : false}
                    />
                }

                <BaseDownloadBtn
                    downloadApi={downloadApi}
                    tooltipPlacementTop
                    tooltipEnabled
                    tooltip={downloadBtnTooltip}
                />
            </div>
        </Stack>
    );
}
export default BaseStepCoverFace;