export enum EBrandType {
    NONE,
    TRUCK = 'TRUCK',
    TRAILER = 'TRAILER',
    BOX_TRUCK = 'BOX_TRUCK'
}

export interface IBrandResponseDto {
    uuid: string;
    name: string;
    type: EBrandType;
}

export interface IBrandRequestDto {
    uuid?: string;
    name: string;
    type: EBrandType;
}