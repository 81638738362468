import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxGrid, IReduxStep } from "../../models/ReduxModels";
import { ICriteria } from "../../models/CommonModels";

interface IssueState {
    grid: IReduxGrid;
    step: IReduxStep;
    businessGrid: IReduxGrid;   
};

const initialState: IssueState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    step: {
        refresh: false
    },
    businessGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    }
};

export const IssueSlice = createSlice({
    name: 'issueSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: IssueState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: IssueState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        },
        stepRefresh(state: IssueState) {
            state.step.refresh = !state.step.refresh;
        },
        businessGridRefresh(state: IssueState) {
            state.businessGrid.refresh = !state.businessGrid.refresh;
        },
        businessGridUnselectRows(state: IssueState) {
            state.businessGrid.unselectRows = !state.businessGrid.unselectRows;
        }
    }
});

export const IssueActions = IssueSlice.actions;
export const IssueReducer = IssueSlice.reducer;