import { ILoginPageConfig } from "../models/LoginPageModels";
import LocalStorageService from "./LocalStorageService";

class LoginPageService {
    public static getConfig = (): ILoginPageConfig => {
        const defaultConfig: ILoginPageConfig = {
            language: 'en',
            darkMode: false
        };

        return LocalStorageService.exists('login-page-config')
            ? JSON.parse(LocalStorageService.get('login-page-config'))
            : defaultConfig;
    };

    public static saveConfig = (config: ILoginPageConfig): void => {
        LocalStorageService.createOrUpdate('login-page-config', JSON.stringify(config));
    };
}
export default LoginPageService;