import { Box, Paper } from "@mui/material";
import WarehouseStatusAndReputation from "./WarehouseStatusAndReputation";

interface IProps {
    warehouseId: string;
    readonly: boolean;
}
const WarehouseStatusAndReputationStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <WarehouseStatusAndReputation {...props} />
            </Box>
        </Paper>
    );
}
export default WarehouseStatusAndReputationStep;