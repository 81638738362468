export enum EFileType {
    MAIN = 'MAIN',
    ADDITIONAL = 'ADDITIONAL'
}

export enum EExtension {
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    TXT = 'txt',
    PDF = 'pdf'
}

export interface IFileDetailsRequestDto {
    cropX?: number;
    cropY?: number;
    cropWidth?: number;
    cropHeight?: number;
}

export interface IFileDetailsResponseDto {
    url: string;
    value?: string;
    extension: string;
    width?: number;
    height?: number;
}

export interface IImageRequest {
    image: File;
    request: IImageRequestDto;
}

export interface IImageRequestDto {
    type: EFileType;
    fileDetails?: IFileDetailsRequestDto;
}