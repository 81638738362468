import BasePage from '../components/Base/BasePageComponent/BasePage';
import CustomerGrid from '../components/CustomerModule/Grid/CustomerGrid';
import CustomerStep from '../components/CustomerModule/Step/CustomerStep';
import { useCustomer } from '../hooks/useCustomer';

const CustomerPage = () => {
    const { resetState, applyFilter } = useCustomer();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<CustomerGrid />}
            stepSection={<CustomerStep />}
        />
    );
}
export default CustomerPage;