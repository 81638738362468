export enum ENotificationType {
    REQUARED = 'REQUARED',
    ADDITIONAL = 'ADDITIONAL',
    ERROR = 'ERROR'
}

export enum EButtonColor {
    inherit = 'inherit',
    primary = 'primary',
    secondary = 'secondary',
    success = 'success',
    error = 'error',
    info = 'info',
    warning = 'warning'
}