import { Box, Paper } from "@mui/material";
import LoadOverview from "./LoadOverview";

interface IProps {
    loadId: string;
}
const LoadOverviewStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <LoadOverview {...props} />
            </Box>
        </Paper>
    );
}
export default LoadOverviewStep;