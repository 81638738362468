import { Link } from "@mui/material";
import { buildGoogleMapsLink } from "../../../helpers/AddressHelper";
import { ICustomerResponseDto } from "../../../models/CustomerModels";

interface IProps {
    data: ICustomerResponseDto;
}
const CustomerMapsMarker = (props: IProps) => {
    const { data } = props;
    const addressLink: string = buildGoogleMapsLink(data.address.latitude, data.address.longitude);

    return (
        <div>
            <Link href={addressLink} target='_blank' rel='noreferrer'>
                {data.address.fullAddress}
            </Link>
        </div>
    );
}
export default CustomerMapsMarker;