import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { IJwtRequestDto, IToken } from "../models/AuthModels";
import AuthService from "../services/AuthService";

const OAuthPage = () => {
    const { authenticated, setToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const refreshToken = useCallback((request: IJwtRequestDto, oldToken: string) => {
        (async () => {
            const [error, response] = await AuthService.refreshToken(request, oldToken);
            if (response) {
                const newToken: IToken = response.data.body;
                AuthService.saveTokenToLocalStorage(newToken);
                setToken(newToken);
            }

            if (error) {
                setToken(null);
            }

            navigate('/');
        })();
    }, [navigate, setToken]);

    useEffect(() => {
        if (authenticated) {
            navigate('/');
        } else {
            const token: IToken = AuthService.buildTokenFromUrl();
            refreshToken({ refreshToken: token.refreshToken }, token.accessToken);
        }
    }, []);

    return (<></>);
}
export default OAuthPage;