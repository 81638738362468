import { useCallback, useEffect, useState } from 'react';

import { NativeSelect } from '@mui/material';
import i18next from "i18next";
import { ILoginPageConfig } from '../../../models/LoginPageModels';
import LoginPageService from '../../../services/LoginPageService';

const languages = ['en', 'ru'];
const LoginLanguageSelect = () => {
    const [config, setConfig] = useState<ILoginPageConfig>(LoginPageService.getConfig());
    const [language, setLanguage] = useState(config.language);

    useEffect(() => {
        i18next.changeLanguage(language);
    }, [language])

    const onChangeHandler = useCallback((event?: any) => {
        const newLanguage: string = event.target.value;
        const newConfig: ILoginPageConfig = { ...config, language: newLanguage };

        LoginPageService.saveConfig(newConfig);
        setConfig(newConfig);
        setLanguage(newLanguage);
    }, [config]);

    return (
        <div>
            <NativeSelect sx={{ marginTop: 1 }}
                onChange={onChangeHandler}
                defaultValue={language}
            >
                {languages.map(value =>
                    <option key={value} value={value}>
                        {' ' + value.toUpperCase()}
                    </option>
                )}
            </NativeSelect>
        </div>
    );
}
export default LoginLanguageSelect;