import { IActiveLoadDocumentRequestDto, IActiveLoadPayRequestDto, IActiveLoadRequestDto } from "../models/ActiveLoadModels";
import { IFilter } from "../models/CommonModels";
import { IAuthority } from "../models/PermissionModels";
import { EVehicleType } from "../models/TransportModels";
import { API } from "./APIService";

class ActiveLoadService {

    public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'activeLoad/all', JSON.stringify(filter));
        return [error, response];
    }

    public static isIdnoAvailable = async (idno: string): Promise<any> => {
        const [error, response] = await API('GET', `activeLoad/available/idno/${idno}`);
        return [error, response];
    }

    public static create = async (data: IActiveLoadRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'activeLoad', JSON.stringify(data));
        return [error, response];
    }

    public static remove = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', `activeLoad/${uuid}`)
        return [error, response];
    }

    public static fetchCoverFaceData = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `activeLoad/${uuid}/coverFace`);
        return [error, response];
    }

    public static createFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('POST', 'activeLoad/favorite', ids);
        return [error, response];
    }

    public static removeFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('DELETE', 'activeLoad/favorite', ids);
        return [error, response];
    }

    public static fetchDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `activeLoad/${uuid}/documents`);
        return [error, response];
    }

    public static createDocument = async (activeLoadId: string, data: IActiveLoadDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `activeLoad/${activeLoadId}/documents`, data);
        return [error, response];
    }

    public static updateDocument = async (activeLoadId: string, documentId: string, data: IActiveLoadDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `activeLoad/${activeLoadId}/documents/${documentId}`, data);
        return [error, response];
    }

    public static removeDocument = async (activeLoadId: string, documentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `activeLoad/${activeLoadId}/documents/${documentId}`);
        return [error, response];
    }

    public static createDocumentAttachment = async (activeLoadId: string, documentId: string, data: FormData): Promise<any> => {
        const [error, response] = await API('PUT', `activeLoad/${activeLoadId}/documents/${documentId}/attachments`, data, undefined, 'multipart/form-data');
        return [error, response];
    }

    public static removeDocumentAttachment = async (activeLoadId: string, documentId: string, attachmentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `activeLoad/${activeLoadId}/documents/${documentId}/attachments/${attachmentId}`);
        return [error, response];
    }

    public static fetchPays = async (activeLoadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `activeLoad/${activeLoadId}/pays/all`, JSON.stringify(filter));
        return [error, response];
    }

    public static createPay = async (activeLoadId: string, data: IActiveLoadPayRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `activeLoad/${activeLoadId}/pays`, data);
        return [error, response];
    }

    public static updatePay = async (activeLoadId: string, activeLoadPayId: string, data: IActiveLoadPayRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `activeLoad/${activeLoadId}/pays/${activeLoadPayId}`, data);
        return [error, response];
    }

    public static removePay = async (activeLoadId: string, activeLoadPayId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `activeLoad/${activeLoadId}/pays/${activeLoadPayId}`);
        return [error, response];
    }

    public static fetchOverview = async (activeLoadId: string): Promise<any> => {
        const [error, response] = await API('GET', `activeLoad/${activeLoadId}/overview`);
        return [error, response];
    }

    public static updateOverview = async (activeLoadId: string, data: IActiveLoadRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `activeLoad/${activeLoadId}/overview`, data);
        return [error, response];
    }

    public static downloadReport = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'activeLoad/report', filter, undefined, undefined, 'blob');
        return [error, response];
    }

    public static fetchTransports = async (activeLoadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `activeLoad/${activeLoadId}/transports/all`, JSON.stringify(filter));
        return [error, response];
    }

    public static createTransport = async (activeLoadId: string, transportId: string): Promise<any> => {
        const [error, response] = await API('POST', `activeLoad/${activeLoadId}/transports`, transportId);
        return [error, response];
    }

    public static updateTransport = async (activeLoadId: string, activeLoadTransportId: string, transportId: string): Promise<any> => {
        const [error, response] = await API('PUT', `activeLoad/${activeLoadId}/transports/${activeLoadTransportId}`, transportId);
        return [error, response];
    }

    public static removeTransport = async (activeLoadId: string, activeLoadTransportId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `activeLoad/${activeLoadId}/transports/${activeLoadTransportId}`);
        return [error, response];
    }

    public static fetchPendingTransports = async (type: EVehicleType): Promise<any> => {
        const [error, response] = await API('GET', `activeLoad/transports/pending/${type}`);
        return [error, response];
    }

    public static fetchUsers = async (activeLoadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `activeLoad/${activeLoadId}/users/all`, JSON.stringify(filter));
        return [error, response];
    }

    public static createUser = async (activeLoadId: string, userId: string): Promise<any> => {
        const [error, response] = await API('POST', `activeLoad/${activeLoadId}/users`, userId);
        return [error, response];
    }

    public static updateUser = async (activeLoadId: string, activeLoadUserId: string, userId: string): Promise<any> => {
        const [error, response] = await API('PUT', `activeLoad/${activeLoadId}/users/${activeLoadUserId}`, userId);
        return [error, response];
    }

    public static removeUser = async (activeLoadId: string, activeLoadUserId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `activeLoad/${activeLoadId}/users/${activeLoadUserId}`);
        return [error, response];
    }

    public static fetchPendingUsers = async (activeLoadId: string, role: IAuthority): Promise<any> => {
        const [error, response] = await API('GET', `activeLoad/${activeLoadId}/users/pending/${role}`);
        return [error, response];
    }

    public static fetchLastUser = async (activeLoadId: string, role: IAuthority): Promise<any> => {
        const [error, response] = await API('GET', `activeLoad/${activeLoadId}/users/last/${role}`);
        return [error, response];
    }

    public static fetchAutocomplete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'activeLoad/autocomplete');
        return [error, response];
    };
}
export default ActiveLoadService;