import { useRef } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { EExtension } from "../../../models/FileModel";
import FileImageView from "./FileImageView";
import FilePdfView from "./FilePdfView";
import FileTextView from "./FileTextView";

interface IProps {
    data: string;
    extension: EExtension;
}
const FileView = (props: IProps) => {
    const { data, extension } = props;
    const imageExtensions = useRef<EExtension[]>([EExtension.PNG, EExtension.JPG, EExtension.JPEG]);
    const size = useRef(useWindowDimensions());

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            {data && imageExtensions.current.includes(extension) &&
                <FileImageView
                    value={data}
                    width={size.current.width}
                    heigth={size.current.height}
                    extension={extension}
                />
            }

            {
                data && EExtension.TXT.toString() === extension &&
                <FileTextView
                    value={data}
                    width={size.current.width}
                    heigth={size.current.height}
                />
            }

            {
                data && EExtension.PDF.toString() === extension &&
                <FilePdfView
                    value={data}
                    width={size.current.width}
                    heigth={size.current.height}
                />
            }
        </div>
    );
}
export default FileView;