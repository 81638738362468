import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import { useCallback, useState } from "react";
import { IMapMarker } from "../../../models/CommonModels";

interface IProps {
    item: IMapMarker;
}
const MapsMarker = (props: IProps) => {
    const { item } = props;
    const [infowindowToggle, setInfowindowToggle] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();

    const infowindowToggleHandler = useCallback(() => {
        setInfowindowToggle(infowindowToggle => !infowindowToggle);
    }, []);

    return (
        <>
            <AdvancedMarker
                position={{ ...item }}
                onClick={infowindowToggleHandler}
                ref={markerRef}
            />
            {infowindowToggle && (
                <InfoWindow
                    anchor={marker}
                    position={{ ...item }}
                    maxWidth={300}
                    onCloseClick={infowindowToggleHandler}
                    headerContent={item.title && <b style={{ color: 'black' }}>{item.title.toUpperCase()}</b>}
                >
                    {item.content && item.content}
                </InfoWindow>
            )}
        </>
    );
}
export default MapsMarker;