import { Box, Paper } from "@mui/material";
import WarehouseContacts from "./WarehouseContacts";

interface IProps {
    warehouseId: string;
    readonly: boolean;
}
const WarehouseContactsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <WarehouseContacts {...props} />
            </Box>
        </Paper>
    );
}
export default WarehouseContactsStep;