import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { EUserIncomeType } from "../../../../models/UserModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: EUserIncomeType;
    onChange?: (event: SelectChangeEvent) => void;
}
const UserIncomeTypeSelect = (props: IProps) => {
    const { label = 'Type', required = false, onChange } = props;

    const [selectedValue, setSelectedValue] = useState<string>(props.value ? props.value.toString() : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
    }, []);

    return (
        <FormControl fullWidth required={required}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                {
                    Object.keys(EUserIncomeType)
                        .filter(key => isNaN(Number(key)))
                        .filter(key => key !== EUserIncomeType[EUserIncomeType.NONE])
                        .map(key => <MenuItem key={key} value={key}>{key.toString().replaceAll('_', ' ')}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
export default UserIncomeTypeSelect;