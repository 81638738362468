import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { EExtension, IFileDetailsResponseDto } from '../../../models/FileModel';
import FileService from '../../../services/FileService';
import BaseDialog from "../BaseDialogComponent/BaseDialog";
import FileView from "./FileView";

interface IProps {
    fileId: string;
    file: IFileDetailsResponseDto;

    open: boolean;

    navigationBtnsHide?: boolean;

    onNextFileViewBtnClick?: (fileId: string) => void;
    nextFileViewBtnDisabled?: boolean;

    onPrevFileViewBtnClick?: (fileId: string) => void;
    prevFileViewBtnDisabled?: boolean;

    onCloseBtnClick: () => void;
}
const FileViewDialog = (props: IProps) => {
    const {
        file, fileId, navigationBtnsHide = false, nextFileViewBtnDisabled, prevFileViewBtnDisabled,
        open, onNextFileViewBtnClick, onPrevFileViewBtnClick, onCloseBtnClick
    } = props;

    const [data, setData] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const [, response] = await FileService.fetchByUrl(file.url);
            if (response) {
                setData(response.data);
                setLoading(false);
            }
        })()
    }, [file.url]);

    const buildContent = useCallback(() => {
        return (<FileView extension={file.extension as EExtension} data={data} />);
    }, [data, file]);

    const onNextFileViewBtnClickHandler = useCallback(() => {
        if (onNextFileViewBtnClick) {
            onNextFileViewBtnClick(fileId);
        }
    }, [fileId, onNextFileViewBtnClick]);

    const onPrevFileViewBtnClickHandler = useCallback(() => {
        if (onPrevFileViewBtnClick) {
            onPrevFileViewBtnClick(fileId);
        }
    }, [fileId, onPrevFileViewBtnClick]);

    const buildCenterActions = useCallback(() => {
        return (
            <>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                        onClick={onPrevFileViewBtnClickHandler}
                        disabled={prevFileViewBtnDisabled}
                        variant='contained'
                        size='small'>
                        <KeyboardArrowLeftIcon fontSize='small' />
                    </Button>

                    <Button
                        onClick={onNextFileViewBtnClickHandler}
                        disabled={nextFileViewBtnDisabled}
                        variant='contained'
                        size='small'>
                        <KeyboardArrowRightIcon fontSize='small' />
                    </Button>
                </div>
            </>
        );
    }, [nextFileViewBtnDisabled, onNextFileViewBtnClickHandler, onPrevFileViewBtnClickHandler, prevFileViewBtnDisabled]);

    return (
        <BaseDialog
            loading={loading}
            buildContent={buildContent}
            open={open}
            maxWidth='xl'
            buildCenterActions={navigationBtnsHide ? undefined : buildCenterActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnColor={'primary'}
            closeBtnVariant={'text'}
            closeBtnIconHide={true}
        />
    );
}
export default FileViewDialog;