import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { WarehouseActions } from "../store/reducers/warehouseSlice";

export const useWarehouse = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(WarehouseActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(WarehouseActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(WarehouseActions.applyFilter(criteria));
    };

    const stepRefresh = () => {
        dispatch(WarehouseActions.stepRefresh());
    };

    return { resetState, gridRefresh, applyFilter, stepRefresh } as const;
};