import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { ActiveLoadActions } from "../store/reducers/activeLoadSlice";

export const useActiveLoad = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(ActiveLoadActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(ActiveLoadActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(ActiveLoadActions.applyFilter(criteria));
    };

    const stepRefresh = () => {
        dispatch(ActiveLoadActions.stepRefresh());
    };

    const paysGridRefresh = () => {
        dispatch(ActiveLoadActions.paysGridRefresh());
    };

    const transportsGridRefresh = () => {
        dispatch(ActiveLoadActions.transportsGridRefresh());
    };

    const transportsGridUnselectRows = () => {
        dispatch(ActiveLoadActions.transportsGridUnselectRows());
    };

    const usersGridRefresh = () => {
        dispatch(ActiveLoadActions.usersGridRefresh());
    };

    const usersGridUnselectRows = () => {
        dispatch(ActiveLoadActions.usersGridUnselectRows());
    };

    return {
        resetState, gridRefresh, applyFilter, stepRefresh, paysGridRefresh,
        transportsGridRefresh, transportsGridUnselectRows, usersGridRefresh,
        usersGridUnselectRows
    } as const;
};