import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { convertTimestampToDateStrWithFormatOrEmpty } from '../../../../helpers/dateHelper';
import { useNotification } from "../../../../hooks/useNotification";
import { IItemDetails } from '../../../../models/CommonModels';
import { IUserEmployeeResponseDto } from '../../../../models/UserModels';
import UserService from '../../../../services/UserService';
import { RootState } from '../../../../store/store';
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import ItemsDetails from "../../../Base/ItemsDetailsComponent/ItemsDetails";
import UserEmployeeDialog from './UserEmployeeDialog';

interface IProps {
    userId: string;
}
const UserEmployee = (props: IProps) => {
    const { userId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [data, setData] = useState<IUserEmployeeResponseDto>();

    const { t } = useTranslation();
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { displayNotification } = useNotification();
    const [dialog, setDialog] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.fetchEmployee(userId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [userId]);

    useEffect(() => {
        retrieveData();
    }, [refresh, retrieveData]);

    const convertDateToString = useCallback((value?: number) => {
        return convertTimestampToDateStrWithFormatOrEmpty(value, dateFormat);
    }, [dateFormat]);

    const items: IItemDetails[] = [{
        id: `${data?.startDate}-startDate`,
        primary: t('EMPLOYMENT DATE'),
        secondary: convertDateToString(data?.startDate)
    }, {
        id: `${data?.endDate}-endDate`,
        primary: t('TERMINATION DATE'),
        secondary: convertDateToString(data?.endDate)
    }, {
        id: `${data?.workload}-workload`,
        primary: t('WORKLOAD (HOURS)'),
        secondary: data?.workload
    }];

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BackdropProgress open={loading || false}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={t('EDIT')} placement='top' >
                        <span>
                            <Button
                                onClick={dialogToggleHandler}
                                size='small'
                                variant='outlined'>
                                <EditIcon fontSize='small' />&nbsp;{t('EDIT')}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <ItemsDetails data={items} />
            </BackdropProgress>

            {dialog &&
                <UserEmployeeDialog
                    open={dialog}
                    entity={data}
                    userId={userId}
                    onCloseBtnClick={dialogToggleHandler}
                    onSubmitBtnClick={refreshHandler}
                />
            }
        </>
    );
}
export default UserEmployee;