import { SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { useActiveLoad } from "../../../../hooks/useActiveLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { IActiveLoadTransportRequestDto, IActiveLoadTransportResponseDto } from "../../../../models/ActiveLoadModels";
import { EVehicleType, ITransportShortResponseDto } from "../../../../models/TransportModels";
import ActiveLoadService from "../../../../services/ActiveLoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import TransportTypeSelect from "../../../TransportModule/TransportTypeSelect";
import ActiveLoadTransportAutocomplete from "./ActiveLoadTransportAutocomplete";

interface IProps {
    activeLoadId: string;
    activeLoadTransport?: IActiveLoadTransportResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const ActiveLoadTransportsDialog = (props: IProps) => {
    const { open, activeLoadId, activeLoadTransport, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useActiveLoad();
    const formId: string = 'active-load-transports-form';

    const isEdit = useRef<boolean>(activeLoadTransport !== undefined);
    const [type, setType] = useState<EVehicleType>(activeLoadTransport ? activeLoadTransport.type : EVehicleType.NONE);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, handleSubmit, formState: { isDirty, isValid } } = useForm<IActiveLoadTransportRequestDto>({
        defaultValues: {
            transportId: isEdit.current ? activeLoadTransport?.transport.uuid : ''
        }
    });

    const updateData = useCallback((uuid: string, transportId: string) => {
        (async () => {
            const [error, response] = await ActiveLoadService.updateTransport(activeLoadId, uuid, transportId);
            if (response) {
                displayNotification({ message: t('Active load transport was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [activeLoadId, t]);

    const createData = useCallback((transportId: string) => {
        (async () => {
            const [error, response] = await ActiveLoadService.createTransport(activeLoadId, transportId);
            if (response) {
                displayNotification({ message: t('Active load transport was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                stepRefresh();
                gridRefresh();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [activeLoadId, t]);

    const onSubmit = useCallback((data: IActiveLoadTransportRequestDto) => {
        setLoading(true);
        if (activeLoadTransport) {
            updateData(activeLoadTransport.uuid, data.transportId);
        } else {
            createData(data.transportId);
        }
    }, [activeLoadTransport, createData, updateData]);

    const validateTransportField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('transportId', { validate: validateTransportField });
    const onTransportChangeHandler = useCallback((value: ITransportShortResponseDto | null) => {
        setValue('transportId', value ? value.uuid : '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setType(event.target.value as EVehicleType);
    }, []);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TransportTypeSelect
                        required
                        label={t('TYPE')}
                        value={type}
                        onChange={onTypeChangeHandler}
                    />

                    <ActiveLoadTransportAutocomplete
                        required
                        label={t('TRANSPORT')}
                        type={type}
                        value={getValues('transportId')}
                        transport={activeLoadTransport?.transport}
                        onChange={onTransportChangeHandler}
                    />
                </Stack>
            </form>
        );
    }, [activeLoadTransport?.transport, getValues, handleSubmit, onSubmit, onTransportChangeHandler, onTypeChangeHandler, t, type]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} TRANSPORT`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default ActiveLoadTransportsDialog;