import { Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { emailRegex } from "../../../../helpers/ValidatorHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { useUser } from "../../../../hooks/useUser";
import { IUserContactRequestDto, IUserContactResponseDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";

interface IProps {
    open: boolean;
    userId: string;
    entity: IUserContactResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const UserContactsDialog = (props: IProps) => {
    const { open, userId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useUser();
    const formId: string = 'user-contacts-form';

    const [loading, setLoading] = useState(false);

    const { register, setError, handleSubmit, formState: { isValid, isDirty } } = useForm<IUserContactRequestDto>({
        defaultValues: {
            additionalEmail: entity?.additionalEmail,
            phone: entity?.phone,
            additionalPhone: entity.additionalPhone
        }
    });

    const onSubmit = useCallback((data: IUserContactRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.updateContacts(userId, data);
            if (response) {
                displayNotification({ message: t('User contacts was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [userId, gridRefresh, onCloseBtnClick, onSubmitBtnClick, stepRefresh, t]);

    const validateEmailField = useCallback((value?: string) => {
        if (value && !emailRegex.test(value)) {
            const message: string = t('Invalid email address.');
            setError('additionalEmail', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validatePhoneField = useCallback((value?: string) => {
        if (value && isBlank(value)) {
            const message: string = t('Invalid phone.');
            setError('phone', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validateAdditionalPhoneField = useCallback((value?: string) => {
        if (value && isBlank(value)) {
            const message: string = t('Invalid phone.');
            setError('additionalPhone', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <TextField
                        {...register('additionalEmail', { validate: validateEmailField })}
                        autoComplete='off'
                        label={t('ADDITIONAL EMAIL')}
                        type='email'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                    />

                    <TextField
                        {...register('phone', { validate: validatePhoneField })}
                        autoComplete='off'
                        label={t('PHONE')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                    />

                    <TextField
                        {...register('additionalPhone', { validate: validateAdditionalPhoneField })}
                        autoComplete='off'
                        label={t('ADDITIONAL PHONE')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                    />
                </Stack>
            </form>
        );
    }, [
        handleSubmit, onSubmit, register, t, validateAdditionalPhoneField,
        validateEmailField, validatePhoneField
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t('EDIT CONTACTS')}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default UserContactsDialog;