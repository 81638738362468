import { Box, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { convertTimestampToDateStrWithFormatOrEmpty, covertDateStrToTimestampOrUndefined } from "../../../helpers/dateHelper";
import { isBlank } from "../../../helpers/textHelper";
import { EDateFormat } from "../../../models/ProfileModels";

interface IProps {
    label?: string;

    fromDateLabel?: string;
    fromDateValue?: number;

    toDateLabel?: string;
    toDateValue?: number;

    errorMessage?: string;
    size?: 'small' | 'medium';
    alignDirection?: 'row' | 'column';

    required?: boolean;
    requiredToFrom?: boolean;

    onChange?: (fromDate?: number, toDate?: number, error?: string) => void;
}
const DateRangeField = (props: IProps) => {
    const {
        label = 'DATE RANGE', fromDateLabel = 'FROM', toDateLabel = 'TO', size = 'small',
        alignDirection = 'column', required = false, requiredToFrom = false, onChange
    } = props;

    const [fromDateValue, setFromDateValue] = useState<string>('');
    const [toDateValue, setToDateValue] = useState<string>('');

    useEffect(() => {
        setFromDateValue(convertTimestampToDateStrWithFormatOrEmpty(props.fromDateValue, EDateFormat.d_yyyy_mm_dd));
        setToDateValue(convertTimestampToDateStrWithFormatOrEmpty(props.toDateValue, EDateFormat.d_yyyy_mm_dd));
    }, [props.fromDateValue, props.toDateValue]);

    const processChangeEvent = useCallback((fromDate: string | undefined, toDate: string | undefined) => {
        if (onChange) {
            const from: number | undefined = covertDateStrToTimestampOrUndefined(fromDate);
            const to: number | undefined = covertDateStrToTimestampOrUndefined(toDate);
            onChange(from, to);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeFromDateHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setFromDateValue(value);

        processChangeEvent(value, toDateValue);
    }, [processChangeEvent, toDateValue]);

    const onChangeToDateHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        setToDateValue(value);

        processChangeEvent(fromDateValue, value);
    }, [fromDateValue, processChangeEvent]);

    return (
        <Box sx={{ width: '100%' }}>
            <Stack spacing={0.5} direction={alignDirection} >
                <TextField
                    label={`${label} ${fromDateLabel}${required ? ' *' : ''}`}
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    error={requiredToFrom && !isBlank(toDateValue) && isBlank(fromDateValue)}
                    size={size}
                    value={fromDateValue}
                    slotProps={{ htmlInput: { max: toDateValue } }}
                    onChange={onChangeFromDateHandler}
                />

                <TextField
                    label={`${label} ${toDateLabel}${required ? ' *' : ''}`}
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    error={requiredToFrom && !isBlank(fromDateValue) && isBlank(toDateValue)}
                    size={size}
                    value={toDateValue}
                    slotProps={{ htmlInput: { min: fromDateValue } }}
                    onChange={onChangeToDateHandler}
                />
            </Stack>
        </Box>
    );
}
export default DateRangeField;