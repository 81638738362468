import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useIssue } from "../../../hooks/useIssue";
import { ECriteriaExpression, EPriority, ICriteria } from "../../../models/CommonModels";
import { EIssueStatus, EIssueType } from "../../../models/IssueModels";
import { IUserAutocompleteResponseDto } from "../../../models/UserModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import NumberField from "../../Base/NumberFieldComponent/NumberField";
import PrioritySelect from "../../Base/PrioritySelectComponent/PrioritySelect";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";
import UserAutocomplete from "../../UserModule/UserAutocomplete";
import IssueTypeSelect from "../IssueTypeSelect";

const statusData: string[] = Object.keys(EIssueStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== EIssueStatus[EIssueStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    onClose: () => void;
}
const IssueFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useIssue();
    const { criterias } = useSelector((state: RootState) => state.issueSlice.grid);

    const [idno, setIdno] = useState<number | undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [type, setType] = useState<EIssueType | undefined>(undefined);
    const [priority, setPriority] = useState<EPriority | undefined>(undefined);
    const [executedBy, setExecutedBy] = useState<string | undefined>(undefined);
    const [assignedOn, setAssignedOn] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState<EIssueStatus | undefined>(undefined);

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        setIdno(getValueFromCriterias('idno', criterias) || NaN);
        setTitle(getValueFromCriterias('title', criterias) || '');

        const typeStr: string | undefined = getValueFromCriterias('type', criterias);
        setType(typeStr as EIssueType);

        const priorityStr: string | undefined = getValueFromCriterias('priority', criterias);
        setPriority(priorityStr as unknown as EPriority);

        setExecutedBy(getValueFromCriterias('executedBy.uuid', criterias) || undefined);
        setAssignedOn(getValueFromCriterias('assignedOn.uuid', criterias) || undefined);

        const statusStr: string | undefined = getValueFromCriterias('status', criterias);
        setStatus(statusStr as EIssueStatus);
    }, [criterias, getValueFromCriterias]);

    const onChangeIdnoHandler = useCallback((value?: number) => {
        setIdno(value);
    }, []);

    const onChangeTitleHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }, []);

    const onChangeTypeHandler = useCallback((event?: SelectChangeEvent) => {
        setType(event?.target.value as unknown as EIssueType);
    }, []);

    const onChangePriorityHandler = useCallback((event?: SelectChangeEvent) => {
        setPriority(event?.target.value as unknown as EPriority);
    }, []);

    const onChangeExecutedByHandler = useCallback((newValue: IUserAutocompleteResponseDto | null) => {
        setExecutedBy(newValue ? newValue.uuid : undefined);
    }, []);

    const onChangeAssignedOnHandler = useCallback((newValue: IUserAutocompleteResponseDto | null) => {
        setAssignedOn(newValue ? newValue.uuid : undefined);
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as EIssueStatus);
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (idno && !isNaN(idno)) {
            newCriterias.push({
                property: 'idno',
                value: idno,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (title && !isBlank(title)) {
            newCriterias.push({
                property: 'title',
                value: title,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (type && type !== EIssueType[EIssueType.NONE]) {
            newCriterias.push({
                property: 'type',
                value: type,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (priority) {
            newCriterias.push({
                property: 'priority',
                value: priority,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (executedBy) {
            newCriterias.push({
                property: 'executedBy.uuid',
                value: executedBy,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (assignedOn) {
            newCriterias.push({
                property: 'assignedOn.uuid',
                value: assignedOn,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (status && status !== EIssueStatus[EIssueStatus.NONE]) {
            newCriterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        return newCriterias;
    }, [assignedOn, executedBy, idno, priority, status, title, type]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <NumberField
                        label={t('ID#')}
                        allowNegative={false}
                        value={idno}
                        size='small'
                        onChange={onChangeIdnoHandler}
                    />

                    <PrioritySelect
                        label={t('PRIORITY')}
                        value={priority}
                        size='small'
                        onChange={onChangePriorityHandler}
                    />

                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData}
                        value={status}
                        size='small'
                        onChange={onChangeStatusHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        label={t('TITLE')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        value={title}
                        size='small'
                        onChange={onChangeTitleHandler}
                    />

                    <UserAutocomplete
                        label={t('ASSIGNED ON')}
                        value={assignedOn}
                        size='small'
                        onChange={onChangeAssignedOnHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <IssueTypeSelect
                        label={t('TYPE')}
                        value={type}
                        size='small'
                        onChange={onChangeTypeHandler}
                    />

                    <UserAutocomplete
                        label={t('EXECUTED BY')}
                        value={executedBy}
                        size='small'
                        onChange={onChangeExecutedByHandler}
                    />
                </Stack>
            </Stack>
        );
    }, [
        t, idno, onChangeIdnoHandler, priority, onChangePriorityHandler, status,
        onChangeStatusHandler, title, onChangeTitleHandler, assignedOn,
        onChangeAssignedOnHandler, type, onChangeTypeHandler, executedBy,
        onChangeExecutedByHandler
    ]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default IssueFilter;