import { IConversationRequestParams } from "../models/PersonalChatModels";
import { API } from "./APIService";

class PersonalChatService {
    public static getAllConversations = async (): Promise<any> => {
        const [error, response] = await API('GET', 'chat/personal/conversations');
        return [error, response];
    }
    public static getConversationMessages = async (params: IConversationRequestParams): Promise<any> => {
        const [error, response] = await API('GET', `chat/personal/conversations/${params.conversationId}/messages/${params.startDate}/${params.endDate}`);
        return [error, response];
    }
    public static getCountOfUnreadMess = async (): Promise<any> => {
        const [error, response] = await API('GET', 'chat/personal/conversations/messages/unread/count');
        return [error, response];
    }
    public static getConversationPartner = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'chat/personal/conversations/' + uuid);
        return [error, response];
    }
    public static getMessageById = async (params: any): Promise<any> => {
        const [error, response] = await API('GET', `chat/personal/conversations/${params.conversationId}/messages/${params.messageId}`);
        return [error, response];
    }
    public static markMessageAsRead = async (body: any, conversationId: string): Promise<any> => {
        const [error, response] = await API('PUT', `chat/personal/conversations/${conversationId}/messages/read`, body);
        return [error, response];
    }
    public static sendMessage = async (body: any, conversationId: string): Promise<any> => {
        const [error, response] = await API('POST', `chat/personal/conversations/${conversationId}/messages`, body);
        return [error, response];
    }
    public static createConversation = async (body: string): Promise<any> => {
        const [error, response] = await API('POST', 'chat/personal/conversations', body);
        return [error, response];
    }
}
export default PersonalChatService;