import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ESocketCommandType, ISocketCommand } from "../../../models/SocketModels";
import { modifyChatMessageReadRefresh, modifyChatRefresh } from "../../../store/reducers/cnfSlice";
import { AppDispatch } from "../../../store/store";

interface IProps {
    command: ISocketCommand;
}
const SocketCommand = (props: IProps) => {
    const { command } = props;
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const cmd: string = getCommandValue(command.command);
        switch (cmd) {
            case ESocketCommandType.PERSONAL_CHAT_MESSAGE_RECEIVED: return personalChatReceivedHandler();
            case ESocketCommandType.PERSONAL_CHAT_MESSAGE_READ: return personalChatReadHandler();
            case ESocketCommandType.USER_ONLINE_REFRESH: return console.info('User refresh');
            default: console.error(`Unsuported command [ ${command.command} ]`);
        }
    }, [command]);

    const getCommandValue = useCallback((value: ESocketCommandType): string => {
        return Object.entries(ESocketCommandType).find(([key]) => key === value)?.[1] || '';
    }, []);

    const personalChatReadHandler = useCallback((): void => {
        if (command?.entityId) {
            dispatch(modifyChatMessageReadRefresh(command.entityId))
        }
    }, []);

    const personalChatReceivedHandler = useCallback((): void => {
        if (command?.entityId) {
            dispatch(modifyChatRefresh(command.entityId));
        }
    }, []);

    return (<></>);
}
export default SocketCommand;