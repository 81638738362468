import { Box, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../helpers/generalHelper";
import { useNotification } from '../../../hooks/useNotification';
import { useTransport } from "../../../hooks/useTransport";
import { IFilter } from '../../../models/CommonModels';
import { EVehicleType, IVehicleResponseDto } from "../../../models/TransportModels";
import TransportService from "../../../services/TransportService";
import { RootState } from "../../../store/store";
import BaseCrudGrid from '../../Base/BaseCrudGridComponent/BaseCrudGrid';
import VehicleDialog from "./VehicleDialog";

const VehicleGrid = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { vehicleGridRefresh } = useTransport();

    const { measureSize, measureMass, measureConsumption, measureEnginePower, measureEngineVolume } = useSelector((state: RootState) => state.userProfileSlice.profile.userPreference);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.transportSlice.vehicleGrid);

    const [dialogToggle, setDialogToggle] = useState(false);
    const [vehicle, setVehicle] = useState<IVehicleResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return TransportService.fetchVehiclesByFilter(filter);
    }, []);

    const onAddActionBtnClick = useCallback(() => {
        setVehicle(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((vehicle: IVehicleResponseDto) => {
        setVehicle(vehicle);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await TransportService.removeVehicle(rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Vehicle${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    vehicleGridRefresh();
                }
            })();
        }
    }, [vehicleGridRefresh, t]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setVehicle(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        vehicleGridRefresh();
    }, [vehicleGridRefresh]);

    const onNameColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IVehicleResponseDto = params.row;
        const value: string = `${row.brand.name} ${row.model} ${row.year}`;
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onTypeColumnRender = useCallback((value: string) => {
        return value.toString().replace('_', ' ');
    }, []);

    const onSizeColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IVehicleResponseDto = params.row;
        const width: string = `${row.width} ${measureSize}`;
        const height: string = `${row.height} ${measureSize}`;
        const length: string = `${row.length} ${measureSize}`;

        return (
            <Box sx={{ display: 'flex', gap: '12px' }}>
                <Tooltip title={`${t('LENGTH: ')} ${length}`}>
                    <span>{length}</span>
                </Tooltip>

                <span>{'x'}</span>

                <Tooltip title={`${t('WIDTH: ')} ${width}`}>
                    <span>{width}</span>
                </Tooltip>

                <span>{'x'}</span>

                <Tooltip title={`${t('HEIGHT: ')} ${height}`}>
                    <span>{height}</span>
                </Tooltip>
            </Box>
        );
    }, [measureSize, t]);

    const onWeightColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IVehicleResponseDto = params.row;
        const weight: string = `${row.weight} ${measureMass}`;
        const grossWeight: string = `${row.grossWeight} ${measureMass}`;
        const loadingWeight: string = `${row.loadingWeight} ${measureMass}`;
        const grossCombinationWeight: string = `${row.grossCombinationWeight} ${measureMass}`;

        return (
            <Box sx={{ display: 'flex', gap: '12px' }}>
                <Tooltip title={`${t('WEIGHT: ')} ${weight}`}>
                    <span>{weight}</span>
                </Tooltip>

                <span>{'|'}</span>

                <Tooltip title={`${t('GROSS WEIGHT: ')} ${grossWeight}`}>
                    <span>{grossWeight}</span>
                </Tooltip>

                <span>{'|'}</span>

                <Tooltip title={`${t('LOADING WEIGHT: ')} ${loadingWeight}`}>
                    <span>{loadingWeight}</span>
                </Tooltip>

                <span>{'|'}</span>

                <Tooltip title={`${t('GROSS COMBINATION WEIGHT: ')} ${grossCombinationWeight}`}>
                    <span>{grossCombinationWeight}</span>
                </Tooltip>
            </Box>
        );
    }, [measureMass, t]);

    const onFuelColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IVehicleResponseDto = params.row;
        const value: string = row.type === EVehicleType.TRAILER
            ? ''
            : `${row.fuelType?.toString()} [ ${row.fuelConsumption} ${measureConsumption} ]`;

        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [measureConsumption]);

    const onEngineColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IVehicleResponseDto = params.row;
        const value: string = row.type === EVehicleType.TRAILER
            ? ''
            : `${row.engineCapacity} ${measureEngineVolume} [ ${row.enginePower} ${measureEnginePower} ]`;

        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [measureEnginePower, measureEngineVolume]);

    const columns: GridColDef[] = [{
        field: 'name',
        headerName: t('NAME'),
        flex: 1,
        minWidth: 250,
        headerAlign: 'center',
        sortable: false,
        renderCell: onNameColumnRender
    }, {
        field: 'type',
        headerName: t('TYPE'),
        width: 120,
        headerAlign: 'center',
        sortable: false,
        valueFormatter: onTypeColumnRender
    }, {
        field: 'size',
        headerName: t('SIZE'),
        width: 280,
        headerAlign: 'center',
        sortable: false,
        renderCell: onSizeColumnRender
    }, {
        field: 'weight',
        headerName: t('WEIGHT'),
        width: 420,
        headerAlign: 'center',
        sortable: false,
        renderCell: onWeightColumnRender
    }, {
        field: 'fuel',
        headerName: t('FUEL'),
        width: 190,
        headerAlign: 'center',
        sortable: false,
        renderCell: onFuelColumnRender
    }, {
        field: 'transmissionType',
        headerName: t('TRANSMISSION'),
        width: 140,
        headerAlign: 'center',
        sortable: false
    }, {
        field: 'engine',
        headerName: t('ENGINE'),
        width: 170,
        headerAlign: 'center',
        sortable: false,
        renderCell: onEngineColumnRender
    }];

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
            />

            {dialogToggle &&
                <VehicleDialog
                    open={dialogToggle}
                    vehicle={vehicle}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default VehicleGrid;