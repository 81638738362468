import ActiveLoadGrid from '../components/ActiveLoadModule/Grid/ActiveLoadGrid';
import ActiveLoadStep from '../components/ActiveLoadModule/Step/ActiveLoadStep';
import BasePage from '../components/Base/BasePageComponent/BasePage';
import { useActiveLoad } from '../hooks/useActiveLoad';

const ActiveLoadPage = () => {
    const { resetState, applyFilter } = useActiveLoad();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<ActiveLoadGrid />}
            stepSection={<ActiveLoadStep />}
        />
    );
}
export default ActiveLoadPage;