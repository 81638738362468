import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Paper, Stack, Step, StepButton, StepContent, Stepper, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { normalizeNavigationHomeUrl, normalizeNavigationUrl } from '../../../helpers/generalHelper';
import { IStepItem } from '../../../models/CommonModels';
import { EButtonColor } from "../../../models/EnumGeneral";
import ButtonConfirmation from '../ActionConfirmationComponent/ButtonConfirmation';
import BackdropProgress from '../BackdropComponent/BackdropProgress';

interface IProps {
    retrieveDataApi: (id: string) => Promise<any>;
    refresh: boolean;

    buildCoverFace: (data: any) => React.ReactNode;
    buildItems: (data: any) => IStepItem[];

    onDeleteBtnClick?: (uuid: string) => void;
    deleteBtnHide?: boolean;
    deleteBtnTooltip?: string;

    closeBtnTooltip?: string;
}
const BaseStep = (props: IProps) => {
    const {
        retrieveDataApi, buildCoverFace, buildItems, onDeleteBtnClick, refresh,
        deleteBtnHide = false, deleteBtnTooltip = 'Delete item',
        closeBtnTooltip = 'Close and return to the main list'
    } = props;

    const { id, step } = useParams();
    const isAsStr: string = id as string;

    const navigate = useNavigate();
    const unselectedStep: number = -1;

    const [activeStep, setActiveStep] = useState<number>(step ? Number(step) - 1 : unselectedStep);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await retrieveDataApi(isAsStr);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                setLoading(false);
            }
        })();
    }, [retrieveDataApi, isAsStr]);

    useEffect(() => {
        fetchData();
    }, [fetchData, refresh]);

    const onChangeStepHandler = useCallback((step: number) => {
        let newUrl: string;
        if (step === activeStep) {
            setActiveStep(unselectedStep);
            newUrl = normalizeNavigationUrl(isAsStr, unselectedStep);
        } else {
            setActiveStep(step);
            newUrl = normalizeNavigationUrl(isAsStr, step + 1);
        }
        navigate(`/${newUrl}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, isAsStr, unselectedStep]);

    const onDeleteBtnClickHandler = useCallback(() => {
        if (onDeleteBtnClick) {
            onDeleteBtnClick(isAsStr);
        }
    }, [isAsStr, onDeleteBtnClick]);

    const onCloseButtonHandler = useCallback(() => {
        const newUrl: string = normalizeNavigationHomeUrl(true);
        navigate(`/${newUrl}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BackdropProgress open={(loading && !step) || false}>
            {data &&
                <Paper sx={{ height: '89vh', width: '100%', backgroundColor: 'white' }}>
                    <Paper sx={{ padding: '10px 15px 10px 15px' }}>
                        <Box>
                            <Stack spacing={1} direction='row'>
                                {buildCoverFace(data)}

                                <div style={{ flex: '1 0 0' }} />

                                <Tooltip title={closeBtnTooltip} placement='top'>
                                    <IconButton onClick={onCloseButtonHandler}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    </Paper>

                    <Box sx={{ padding: '10px', maxHeight: '77vh', overflowY: 'scroll' }}>
                        <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
                            {
                                buildItems(data).map((item, index) =>
                                    <Step
                                        key={item.title}
                                        completed={false}
                                        disabled={item.disabled}
                                    >
                                        <StepButton
                                            sx={{ '&:hover': { backgroundColor: 'rgb(242 242 242 / 87%)' } }}
                                            color='inherit'
                                            onClick={() => onChangeStepHandler(index)}
                                        >
                                            {item.title}
                                        </StepButton>
                                        <StepContent>
                                            {item.content}
                                        </StepContent>
                                    </Step>
                                )
                            }
                        </Stepper>
                    </Box>

                    <Paper sx={{ width: '100%', bottom: 0, position: 'absolute' }} >
                        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', padding: '12px' }}>
                            {!deleteBtnHide &&
                                <ButtonConfirmation
                                    variant='contained'
                                    onConfirm={onDeleteBtnClickHandler}
                                    icon={<DeleteIcon fontSize='medium' />}
                                    color={EButtonColor.error}
                                    disabled={data.readonly}
                                    tooltip={deleteBtnTooltip}
                                />
                            }
                        </Box>
                    </Paper>
                </Paper>
            }
        </BackdropProgress>
    );
}
export default BaseStep;