import { Box, Paper } from "@mui/material";
import UserPaysGrid from "./UserPaysGrid";

interface IProps {
    userId: string,
}
const UserPaysStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: 2, height: 300 }}>
                <UserPaysGrid userId={props.userId} />
            </Box>
        </Paper>
    );
}
export default UserPaysStep;