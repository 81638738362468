import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid, IReduxStep } from "../../models/ReduxModels";

interface TransportState {
    grid: IReduxGrid;
    step: IReduxStep;
    vehicleGrid: IReduxGrid;
};

const initialState: TransportState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    step: {
        refresh: false
    },
    vehicleGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    }
};

export const TransportSlice = createSlice({
    name: 'transportSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: TransportState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: TransportState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        },
        stepRefresh(state: TransportState) {
            state.step.refresh = !state.step.refresh;
        },
        vehicleGridRefresh(state: TransportState) {
            state.vehicleGrid.refresh = !state.vehicleGrid.refresh;
        }
    }
});

export const TransportActions = TransportSlice.actions;
export const TransportReducer = TransportSlice.reducer;