import { EExtension } from "./FileModel";

export enum EPublicAttachmentType {
    HELP = 'HELP',
    PRIVACY = 'PRIVACY'
}

export interface IPublicAttachment {
    value: string;
    extension: EExtension;
}