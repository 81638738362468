import { Box, Paper } from "@mui/material";
import WarehouseReview from "./WarehouseReview";

interface IProps {
    warehouseId: string;
}
const WarehouseReviewsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <WarehouseReview {...props} />
            </Box>
        </Paper>
    );
}
export default WarehouseReviewsStep;