import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWarehouse } from '../../../hooks/useWarehouse';
import { ECriteriaExpression, ICriteria, IFilter } from '../../../models/CommonModels';
import { EWarehouseStatus, IWarehouseCoverFaceResponseDto } from '../../../models/WarehouseModels';
import WarehouseService from '../../../services/WarhouseService';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';
import RatingView from '../../Base/RatingComponent/RatingView';
import ReputationView from '../../Base/ReputationComponent/ReputationView';

interface IProps {
    data: IWarehouseCoverFaceResponseDto;
}
const WarehouseStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { gridRefresh, stepRefresh } = useWarehouse();

    const content: React.ReactNode = <Box flex={1}>
        <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
            <ReputationView
                value={data.reputationLevel}
                showText={false}
                minWidth='0px'
                width='24px'
                imageSize='medium'
            />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>{data.name}</b>
                </Typography>

                <Typography sx={{ fontSize: '13px', color: `${data.status === EWarehouseStatus.INCOMPLETE ? 'red' : 'black'}` }}>
                    <b>{data.status.toString().replace('_', ' ')}</b>
                </Typography>
            </Stack>

            <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography sx={{ fontSize: '14px' }}>
                    <b>{data.types.map(item => item.name).join(', ').replaceAll('_', ' ')}</b>
                </Typography>

                <RatingView value={data.reviewMark} />
            </Stack>
        </Stack>
    </Box>;

    const onFavoriteBtnClickHandler = useCallback(() => {
        (async () => {
            const ids: string[] = [data.uuid];
            if (data.favorite) {
                WarehouseService.removeFavorites(ids);
            } else {
                WarehouseService.createFavorites(ids);
            }
            gridRefresh();
            stepRefresh();
        })();
    }, [data.favorite, data.uuid, gridRefresh, stepRefresh]);

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return WarehouseService.downloadReport(filter);
    }, [data.uuid]);

    return (
        <BaseStepCoverFace
            content={content}

            favoriteBtnAddTooltip={t('Add to favorites')}
            favoriteBtnRemoveTooltip={t('Remove from favorites')}
            favoriteBtn={data.favorite}
            onFavoriteBtnClick={onFavoriteBtnClickHandler}

            downloadBtnTooltip={t('Download warehouse')}
            downloadApi={onDownloadApiHandler}
        />
    );
}
export default WarehouseStepCoverFace;