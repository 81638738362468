import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Avatar, Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputBase, styled, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDateAndTimeFormat, minusDaysFromDate } from "../../../helpers/dateHelper";
import { isBlank } from "../../../helpers/textHelper";
import { IFileDetailsResponseDto } from "../../../models/FileModel";
import { IConversationMessages, IConversationRequestParams, IConversations } from "../../../models/PersonalChatModels";
import PersonalChatService from "../../../services/PersonalChatService";
import { modifyChatAutoRead, modifyChatConversationId, modifyChatLastMessage, modifyChatRefresh } from "../../../store/reducers/cnfSlice";
import { RootState } from "../../../store/store";
import style from './PersonalChatComponent.module.scss';

interface IProps {
    chat: IConversations | null;
    close: () => void;
    updateConversationIdInList: (id: string) => void
}

const PersonalChatComponent = (props: IProps) => {
    const { chat, updateConversationIdInList } = props;

    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { conversationId, autoRead, lastMessage, refreshRead, readMessageSocket } = useSelector((state: RootState) => state.cnfSlice.chat);

    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const firstUnreadMessageRef = useRef<HTMLDivElement | null>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const filePick = useRef<HTMLInputElement | null>(null);

    const [chatHistory, setChatHistory] = useState<IConversationMessages[]>([]);

    const [currentMessage, setCurrentMessage] = useState<string>('');
    const [photoCaption, setPhotoCaption] = useState<string>('');
    const [imgSrc, setImgSrc] = useState('');
    const [fileToUpload, setFileToUpload] = useState<any>(null);

    const [openImgPreview, setOpenImgPreview] = useState<boolean>(false);
    const [existOldMessage, setExistOldMessage] = useState<boolean>(true);

    const [isFirstRequest, setIsFirstRequest] = useState(false);
    const [open, setOpen] = useState(false);
    const [viewFiles, setViewFile] = useState<IFileDetailsResponseDto>();

    const [onPreviewFileDetails, setOnPreviewFileDetails] = useState({ name: '', extension: '' })

    const [readMessage, setReadMessage] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            setCurrentMessage('');
            setPhotoCaption('');
            setFileToUpload(null);
            setImgSrc('');
            if (chat && conversationId !== '*') {
                let params: IConversationRequestParams
                const endDate: Date = new Date(Date.now());
                const startDate: Date = minusDaysFromDate(endDate, 3);
                params = {
                    conversationId: conversationId,
                    startDate: startDate.getTime(),
                    endDate: endDate.getTime()
                }
                const [error, response] = await PersonalChatService.getConversationMessages(params);
                if (response) {
                    let value = response.data.body;
                    setChatHistory(value);
                    setIsFirstRequest(true);
                }
                // if (error) { dispatch(setLastError(error)) }
            } else {
                setChatHistory([]);
            }
        })()
    }, [chat]);

    useEffect(() => {
        if (chatHistory.length !== 0) {
            let a = readMessageSocket.split(',');
            if (a[0] === conversationId) {
                let tempArr = [...chatHistory];
                for (let i = 1; i < a.length; i++) {
                    let j = 0;
                    let bool = true;
                    while (bool && j < tempArr.length) {
                        if (tempArr[j].uuid === a[i]) {

                            tempArr[j].read = true;
                            bool = false
                        }
                        j++;
                    }
                }
                setChatHistory(tempArr)
            }
        }

    }, [refreshRead]);

    useEffect(() => {
        if (lastMessage && lastMessage.message.senderId === chat?.partnerId) {
            if (conversationId === '*') {
                dispatch(modifyChatConversationId(lastMessage.conversationId));
                updateConversationIdInList(lastMessage.conversationId);
            }
            if (autoRead || chatHistory.length == 0) {
                setChatHistory((st) => [...st, { ...lastMessage.message, read: true }])
                handleReadSingleMessage(lastMessage.message.uuid, lastMessage.conversationId);
            } else {
                setChatHistory((st) => [...st, lastMessage.message])
            }
            dispatch(modifyChatLastMessage(null));

        }
    }, [lastMessage]);

    useEffect(() => {
        if (chat && readMessage) {
            let unreadMessages: string[] = [];
            let isFirstRead = false;
            let tempArray = [...chatHistory];
            let i = tempArray.length - 1;

            while (!isFirstRead && i >= 0) {
                if (!tempArray[i].senderMessage) {
                    if (tempArray[i].read) {
                        isFirstRead = true;
                    } else {
                        unreadMessages.push(tempArray[i].uuid);
                    }
                }
                i--;
            }
            (async () => {


                if (unreadMessages.length !== 0) {
                    const [error, response] = await PersonalChatService.markMessageAsRead(unreadMessages, conversationId);
                    if (response) {
                        let temp = chatHistory.map((item: IConversationMessages) => {
                            if (unreadMessages.includes(item.uuid)) {
                                return { ...item, read: true, firstUnreadMessage: false };
                            }
                            return item;
                        });
                        dispatch(modifyChatRefresh('*'))
                        setChatHistory(temp);
                    }
                    // if (error) {
                    //     dispatch(setLastError(error))
                    // }
                }
                setReadMessage(false);
            })()
        }

    }, [readMessage])

    useEffect(() => {
        if (!chatContainerRef.current) return;

        const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
        const tolerance = 250;
        const isNearBottom = scrollHeight - scrollTop - clientHeight <= tolerance;

        if (chatHistory.length !== 0) {
            triggerScroll();
            if (clientHeight === scrollHeight) {
                setReadMessage(true);
            }
            if (firstUnreadMessageRef.current) {
                firstUnreadMessageRef.current.scrollIntoView({ behavior: 'auto' });
            } else if ((isFirstRequest || isNearBottom) && messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
                setIsFirstRequest(false);
            }
        }

    }, [chatHistory]);

    const handleReadSingleMessage = (uuid: string, convId: string) => {
        (async () => {

            const [error, response] = await PersonalChatService.markMessageAsRead([uuid], convId);
            if (response) {
            }
            // if (error) {
            //     dispatch(setLastError(error))
            // }
        })()
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handlePick = () => {
        setPhotoCaption('');
        filePick.current?.click()
    }

    const uploadHandler = (event: any) => {
        let file = event.target.files[0];
        if (!file) return;
        setOnPreviewFileDetails({ name: file.name, extension: file.type })
        const reader: any = new FileReader()
        reader.addEventListener('load', () =>
            setImgSrc(reader.result.toString() || ''),
        )
        reader.readAsDataURL(event.target.files[0])
        setFileToUpload(file)
        setOpenImgPreview(true);
        event.target.value = '';
    }

    const getOldMessages = () => {
        (async () => {
            if ((existOldMessage && chatHistory.length >= 1 && chat && conversationId !== '*')) {
                let params: IConversationRequestParams
                const endDate: Date = new Date(chatHistory[0].createdDate - 1);
                const startDate: Date = minusDaysFromDate(endDate, 3);
                params = {
                    conversationId: conversationId,
                    startDate: startDate.getTime(),
                    endDate: endDate.getTime()
                }
                const [error, response] = await PersonalChatService.getConversationMessages(params);
                if (response) {
                    let value = response.data.body;
                    if (value.length === 0) {
                        setExistOldMessage(false);
                    } else {
                        setChatHistory((st) => [...value, ...st]);
                    }
                }
                // if (error) { dispatch(setLastError(error)) }
            }
        })()
    }

    const sendMediaToServer = () => {
        (async () => {
            if (chat) {
                let conversationIdParam = conversationId;
                if (conversationIdParam === '*') {
                    const [, response] = await PersonalChatService.createConversation(chat.partnerId);
                    if (response) {
                        conversationIdParam = response.data.response.entityId;
                        dispatch(modifyChatConversationId(conversationIdParam));
                        updateConversationIdInList(conversationIdParam);
                    }
                }
                const formData = new FormData();
                formData.append('message', photoCaption);
                formData.append('file', fileToUpload);
                const [error, response] = await PersonalChatService.sendMessage(formData, conversationIdParam);
                if (response) {
                    setOpenImgPreview(false);
                    let messUuid = response.data.response.entityId;
                    getMessage(messUuid, conversationId)
                }
                if (error) { }
            }
        })()
    }

    const handleSendMessage = () => {
        if (!chat?.deletedUser) {
            if (chat) {
                (async () => {
                    if (!isBlank(currentMessage)) {
                        let conversationIdParam = conversationId;
                        if (conversationId === '*') {
                            const [, response] = await PersonalChatService.createConversation(chat.partnerId);
                            if (response) {
                                conversationIdParam = response.data.response.entityId;
                                dispatch(modifyChatConversationId(conversationIdParam));
                                updateConversationIdInList(conversationIdParam);
                            }
                        }

                        const formData = new FormData();
                        formData.append('message', currentMessage);
                        const [error, resp] = await PersonalChatService.sendMessage(formData, conversationIdParam);
                        if (resp) {
                            let messUuid = resp.data.response.entityId;
                            let newMess = { senderId: null, message: currentMessage, createdDate: Date.now(), read: false, senderMessage: true, uuid: messUuid, firstUnreadMessage: false }
                            setChatHistory((st) => [...st, newMess]);
                            setCurrentMessage('');
                            if (messagesEndRef.current) {
                                messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
                            }
                        }
                        // if (error) { dispatch(setLastError(error)) }
                    }
                })()
            }
        }
    }

    const getMessage = (uuid: string, convId: string) => {
        (async () => {
            let params: any = {
                messageId: uuid,
                conversationId: convId
            }
            const [error, response] = await PersonalChatService.getMessageById(params);
            if (response) {
                setChatHistory((st) => [...st, response.data.body]);
            }
            if (error) { }
        })()
    }

    const triggerScroll = () => {
        if (chatContainerRef.current) {

            const scrollEvent = new Event('scroll');
            chatContainerRef.current.dispatchEvent(scrollEvent);
        }
    };

    const handleMessage = (event: any) => {
        const message = event.target.value;
        if (message.length < 4096 && !chat?.deletedUser)
            setCurrentMessage(message);
    }

    const handlePhotoCaption = (event: any) => {
        const message = event.target.value;
        setPhotoCaption(message);
    }


    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            if (!event.shiftKey) {
                event.preventDefault();
                handleSendMessage();
            }
        }
    };

    const handleScroll = () => {
        if (!chatContainerRef.current) return;

        const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
        const tolerance = 1.5;
        const isAtBottom = scrollHeight - scrollTop - clientHeight <= tolerance;

        const isAtTop = scrollTop === 0;

        const tolerance2 = 250;
        const isNearBottom = scrollHeight - scrollTop - clientHeight <= tolerance2;

        const scrollBarActive = (scrollHeight > clientHeight);


        if (!isNearBottom && autoRead) {
            dispatch(modifyChatAutoRead(false));
        }
        if (isNearBottom && !autoRead) {
            dispatch(modifyChatAutoRead(true));
        }

        if (isAtTop && existOldMessage && !isFirstRequest && scrollBarActive) {
            getOldMessages();
        }

        if (isAtBottom) {
            let chatHistoryLength = chatHistory.length
            if (chatHistoryLength !== 0 && !chatHistory[chatHistoryLength - 1].read) {
                setReadMessage(true);
            }
        }
    };

    const handleView = (e: IFileDetailsResponseDto | null) => {
        setOpen(true);
        if (e) setViewFile(e);
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", maxWidth: "650px", width: "100%", gap: "5px" }}>
                <div style={{ width: "100%", height: "49px", display: "flex", flexDirection: "column" }}>
                    <div style={{ width: "100%", height: "35px", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "15px", paddingLeft: "25px" }}>
                            {chat?.avatar
                                ? (chat?.avatar) &&
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant={chat?.online ? "dot" : "standard"}
                                >
                                    <Avatar sx={{ width: "30px", height: "30px" }} alt="" src={"data:image/png;base64," + chat?.avatar} />

                                </StyledBadge>

                                : <></>
                            }
                            <Typography
                                variant="subtitle1"
                            >
                                {chat?.fullName}
                            </Typography>
                        </div>
                        <div>
                            <IconButton
                                color="inherit"
                                onClick={props.close}
                            ><CloseIcon sx={{ width: "20px" }} /></IconButton>
                        </div>
                    </div>
                    <Divider sx={{ paddingTop: "14px" }} />
                </div>
                <div
                    ref={chatContainerRef}
                    onScroll={handleScroll}
                    className={style.messagesBox}
                >
                    {chatHistory.map((mess: IConversationMessages, i: number) => {

                        const refProp = mess.firstUnreadMessage && !mess.senderMessage ? firstUnreadMessageRef : null;
                        return (
                            <div ref={refProp} key={i} style={{ width: "100%", display: "flex", justifyContent: `${mess.senderMessage ? 'end' : 'start'}` }}>

                                <div className={`${style.message}  ${mess.senderMessage ? style['outgoing-message'] : (mess.read ? style['incoming-read-message'] : style['incoming-unread-message'])}`}>

                                    {mess.thumbnail &&
                                        <div style={{ width: "180px", padding: "5px" }} onClick={() => { handleView(mess.originalFile ? mess.originalFile : null) }}>
                                            <img
                                                style={{ objectFit: "cover", width: "100%" }}
                                                src={"data:image/png;base64," + mess.thumbnail.value}
                                            />
                                        </div>
                                    }
                                    {(mess.originalFile && !mess.thumbnail) &&
                                        <div style={{ padding: "5px" }}>
                                            <Button
                                                color={'info'}
                                                variant='outlined'
                                                size={'small'}
                                                sx={{ width: "35px", padding: "5px" }}
                                                onClick={() => { handleView(mess.originalFile ? mess.originalFile : null) }}
                                            >
                                                {mess.originalFile.extension.toLocaleLowerCase() === 'pdf' && <PictureAsPdfIcon fontSize='small' />}
                                                {mess.originalFile.extension.toLocaleLowerCase() === 'txt' && <FilterNoneIcon fontSize='small' />}
                                            </Button>
                                        </div>
                                    }
                                    {mess.message}
                                    <div className={style.messageDetails}>
                                        {getDateAndTimeFormat(mess.createdDate, dateFormat, timeFormat)}
                                        <div style={{ fontSize: "14px" }} >
                                            {
                                                mess.senderMessage ?
                                                    (
                                                        (chatHistory[chatHistory.length - 1].read || !chatHistory[chatHistory.length - 1].senderMessage)
                                                            ? <DoneAllIcon fontSize="inherit" />
                                                            : <CheckIcon fontSize="inherit" />
                                                    )
                                                    : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })

                    }
                    <div ref={messagesEndRef} />
                </div>
                <div style={{ minHeight: "35px", maxHeight: "110px" }}>
                    <div style={{ display: "flex", border: "1px solid silver" }}>
                        <Button
                            sx={{ alignItems: "end", width: "35px" }}
                            disabled={chat?.deletedUser}
                            color="inherit"
                            onClick={handlePick}
                        >
                            <AttachFileIcon fontSize='small' />
                        </Button>
                        <input type="file" onChange={uploadHandler}
                            accept=".png, .jpg, .jpeg, .txt, .pdf"
                            ref={filePick}
                            style={{
                                width: "0px",
                                height: "0px",
                                margin: "0px",
                                padding: "0px",
                                overflow: "hidden"
                            }} />
                        <div className={style.chat} >
                            <InputBase
                                autoFocus
                                type="text"
                                placeholder={'Write a message...'}
                                value={currentMessage}
                                onChange={handleMessage}
                                multiline
                                onKeyDown={handleKeyDown}
                                style={{ width: "100%", paddingLeft: "10px", maxHeight: "110px", alignItems: "baseline" }}
                            />

                        </div>
                        {!isBlank(currentMessage) && < Button
                            sx={{ alignItems: "end" }}
                            endIcon={<SendIcon />}
                            onClick={handleSendMessage}
                        />}
                        {isBlank(currentMessage) && < Button
                            sx={{ alignItems: "end" }}
                            endIcon={<KeyboardVoiceIcon />}
                            onClick={handleSendMessage}
                        />}


                    </div>
                </div>
            </div>

            <Dialog
                open={openImgPreview}
                onClose={() => { setOpenImgPreview(false) }}
            >
                <DialogTitle>{['image/png', 'image/jpg', 'image/jpeg'].includes(onPreviewFileDetails.extension.toLocaleLowerCase()) ? `SEND IMAGE` : `SEND FILE`}</DialogTitle>
                <DialogContent>
                    {['image/png', 'image/jpg', 'image/jpeg'].includes(onPreviewFileDetails.extension.toLocaleLowerCase()) &&
                        <img
                            src={imgSrc}
                            style={{
                                width: '100%',
                                height: '82%',
                                objectFit: 'cover',
                            }}
                        />}
                    {onPreviewFileDetails.extension.toLocaleLowerCase() === 'application/pdf' &&
                        <div>
                            <PictureAsPdfIcon fontSize='large' />
                            <Typography variant="subtitle2" fontSize={18}>{onPreviewFileDetails.name}</Typography>
                        </div>}
                    {onPreviewFileDetails.extension.toLocaleLowerCase() === 'text/plain' &&
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <TextSnippetIcon fontSize='large' />
                            <Typography variant="subtitle2" fontSize={18}>{onPreviewFileDetails.name}</Typography>
                        </div>
                    }
                    <div style={{ padding: "5px" }}>
                        <TextField
                            value={photoCaption}
                            onChange={handlePhotoCaption}
                            label="Caption"
                            sx={{ width: "100%" }}
                            variant="standard"
                        />

                    </div>

                </DialogContent>
                <DialogActions>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", paddingLeft: "15px" }}>
                        <Button onClick={() => { setOpenImgPreview(false) }}>
                            Cancel
                        </Button>
                        <Button onClick={sendMediaToServer}>
                            Send
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "75%",
                            minHeight: "83%",
                        },
                    },
                }}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {'PREVIEW'}
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100%" }}>
                        {/* {viewFiles &&
                            <FileViewComponent ent={viewFiles} width={viewFiles.width} heigth={viewFiles.height} />} */}
                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        marginBottom: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "20px",
                        paddingRight: "20px"
                    }}>
                    {/* {viewFiles &&
                        // <FileDownloadComponent fileName={'chat' + '.' + viewFiles.extension} ent={viewFiles} />
                    } */}

                    <Button onClick={handleClose} >Close</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
export default PersonalChatComponent;


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },

}));