import CancelIcon from '@mui/icons-material/Cancel';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { Button, Divider, IconButton, Paper, Snackbar, Tooltip, Typography, } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import GridPermissionComponent from '../components/PermissionModule/GridPermissionComponent';
import PermissionButtonBarComponent from '../components/PermissionModule/PermissionButtonBarComponent';
import { ENotificationType } from '../models/EnumGeneral';
import { INotification } from '../models/NotificationModels';
import { modifyLeftFlip } from '../store/reducers/cnfSlice';
import { AppDispatch, RootState } from '../store/store';

const styled = {
    icon: { fontSize: "28px", cursor: "pointer" }
}

export default function PermissionPage() {


    const { hints, leftFlip } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    // const [allRoles, setAllRoles] = useState<TRoles>(null);
    const [reset, setReset] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");
    const location = useLocation().pathname;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [textSnackbar, setTextSnackbar] = useState('');
    const snackMsg = (text: string) => {

        let n: INotification = {
            userUUID: " ",
            dt: 0,
            text: [text],
            state: false,
            type: ENotificationType.ADDITIONAL,
            link: location
        }
        // dispatch(addNotification(n))
        setTextSnackbar(text);
        setOpenSnackbar(true);
    }
    const hendleShowLeft = () => {
        dispatch(modifyLeftFlip());
    }

    // useEffect(() => {
    //     (async () => {
    //         const [error, data] = await StafService.getAllRole();
    //         if (data) { setAllRoles(data); }
    //         // if (error) { dispatch(setLastError(error)); }
    //     })()
    // }, [dispatch])

    const handleGetRole = (s: string) => {
        setFilter(s);
    }
    const handleReset = () => {
        setReset(!reset);
    }
    const handleCloseSnackbar = () => { setOpenSnackbar(false); }
    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message=""
                anchorOrigin={{ vertical: "top", horizontal: 'center' }}
                action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
            >




                <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>

                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
                        <GppMaybeIcon color={'success'} fontSize={'large'} />
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "250px",
                        justifyContent: "center"
                    }}>
                        <div>
                            <Typography variant='subtitle2' >
                                {textSnackbar}
                            </Typography>
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
                    </div>

                </Paper>
            </Snackbar>

            <div style={{ width: "100%", height: "calc(100vh - 140px)", display: "flex", alignContent: "stretch", gap: "20px" }} >
                {leftFlip &&
                    <Paper sx={{ flex: 1, maxWidth: "60px", textAlign: "center", padding: 1, paddingTop: "5px", paddingBottom: "2px" }}>
                        <div style={{ marginBottom: "2px" }} >
                            <Tooltip
                                title={(hints) ? t("staf") : ''}
                                placement={"top"}
                                arrow={true}
                            >
                                <IconButton onClick={hendleShowLeft} >
                                    <MultipleStopIcon sx={styled.icon} color={"primary"} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <Divider></Divider>
                    </Paper>
                }

                {(!leftFlip) &&
                    <Paper style={{ flex: 1, maxWidth: "240px", padding: "5px", paddingTop: "0px", }}>
                        <PermissionButtonBarComponent reset={handleReset} snackMsg={snackMsg} />
                        {/* <ListRolesComponentPermission allRoles={allRoles} getRole={handleGetRole} reset={reset} /> */}
                    </Paper>}

                {(!leftFlip) &&
                    <Paper sx={{ flex: 1, padding: 2, width: "auto", paddingTop: "2px", paddingBottom: "2px", paddingRight: "10px", }}>
                        <GridPermissionComponent snackMsg={snackMsg} filter={filter} reset={reset} />
                    </Paper>
                }


            </div>
        </>
    );
}