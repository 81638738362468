import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { EReputationLevel } from "../../../models/CommonModels";

interface IProps {
    value: EReputationLevel;
    showText?: boolean;
    width?: string;
    minWidth?: string;
    imageSize?: 'medium' | 'small'
}
const ReputationView = (props: IProps) => {
    const { value, showText = true, width = '100%', minWidth = '100px', imageSize = 'small' } = props;
    const [iconsMap, setIconsMap] = useState<Map<EReputationLevel, any>>();

    useEffect(() => {
        const iconsMap: Map<EReputationLevel, any> = new Map<EReputationLevel, any>([
            [EReputationLevel.VIP, <LocalPoliceOutlinedIcon fontSize={imageSize} color='primary' />],
            [EReputationLevel.STANDARD, <VerifiedUserOutlinedIcon fontSize={imageSize} color='success' />],
            [EReputationLevel.POOR, <GppBadOutlinedIcon fontSize={imageSize} color='error' />]
        ]);
        setIconsMap(iconsMap);
    }, [imageSize]);

    return (
        <Box width={width} sx={{ minWidth }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                {iconsMap?.get(value)}
                {showText &&
                    <Typography variant='body2'>{value}</Typography>
                }
            </div>
        </Box>
    );
}
export default ReputationView;