import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../helpers/generalHelper";
import { useLoad } from '../../../hooks/useLoad';
import { useNotification } from '../../../hooks/useNotification';
import { IFilter } from '../../../models/CommonModels';
import { IFragilityTypeResponseDto } from "../../../models/LoadModels";
import LoadService from '../../../services/LoadService';
import { RootState } from "../../../store/store";
import BaseCrudGrid from '../../Base/BaseCrudGridComponent/BaseCrudGrid';
import FragilityTypeDialog from "./FragilityTypeDialog";

const FragilityTypeGrid = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { fragilityTypeGridRefresh } = useLoad();

    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.fragilityTypeGrid);

    const [dialogToggle, setDialogToggle] = useState(false);
    const [fragilityType, setFragilityType] = useState<IFragilityTypeResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchFragilityTypes(filter);
    }, []);

    const onAddActionBtnClick = useCallback(() => {
        setFragilityType(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((fragilityType: IFragilityTypeResponseDto) => {
        setFragilityType(fragilityType);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removeFragilityType(rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Fragility type${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    fragilityTypeGridRefresh();
                }
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fragilityTypeGridRefresh, t]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setFragilityType(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        fragilityTypeGridRefresh();
    }, [fragilityTypeGridRefresh]);

    const onNameOrCaptionColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const columns: GridColDef[] = [{
        field: 'name',
        headerName: t('NAME'),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        headerAlign: 'center',
        renderCell: onNameOrCaptionColumnRender
    }, {
        field: 'caption',
        headerName: t('CAPTION'),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 2,
        headerAlign: 'center',
        renderCell: onNameOrCaptionColumnRender
    }];

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
            />

            {dialogToggle &&
                <FragilityTypeDialog
                    open={dialogToggle}
                    fragilityType={fragilityType}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default FragilityTypeGrid;