import { Box, Paper } from "@mui/material";
import { EActiveLoadStatus } from "../../../../models/ActiveLoadModels";
import ActiveLoadTransportsGrid from "./ActiveLoadTransportsGrid";

interface IProps {
    activeLoadId: string;
    activeLoadStatus: EActiveLoadStatus;
}
const ActiveLoadTransportsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 300 }}>
                <ActiveLoadTransportsGrid
                    activeLoadId={props.activeLoadId}
                    activeLoadStatus={props.activeLoadStatus}
                />
            </Box>
        </Paper>
    );
}
export default ActiveLoadTransportsStep;