import { EVehicleType, ITransportShortResponseDto } from "./TransportModels";
import { IUserShortResponseDto } from "./UserModels";
import { IWarehouseIdNameAddressResponseDto } from "./WarehouseModels";

export enum EActiveLoadStatus {
  NONE,
  INCOMPLETE = 'INCOMPLETE',
  PENDING = 'PENDING',
  IN_ROUTE = 'IN_ROUTE',
  DELIVERED = 'DELIVERED',
  INVOICE = 'INVOICE',
  PAYED = 'PAYED'
}

export enum EActiveLoadDocumentType {
  NONE,
  DELIVERY_CONFIRMATION = 'DELIVERY_CONFIRMATION',
  PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION',
  INVOICE = 'INVOICE'
}

export enum EActiveLoadPayType {
  NONE,
  DEDUCTIONS_ADVANCES = 'DEDUCTIONS_ADVANCES',
  ADDITIONAL = 'ADDITIONAL'
}

export enum EActiveLoadPayStatus {
  NONE,
  OPEN = 'OPEN',
  PAYED = 'PAYED'
}

export enum EActiveLoadTransportStatus {
  NONE,
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export enum EActiveLoadUserStatus {
  NONE,
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export interface IActiveLoadResponseDto {
  uuid: string;
  idno: string;
  name?: string;
  shipDate?: number;
  deliveryDate?: number;
  transports: ITransportShortResponseDto[];
  users: IUserShortResponseDto[];
  loads?: Array<any>;
  amount: number;
  progress: any;
  pickupAddresses?: Array<string>;
  pickupWarehouses?: Array<IWarehouseIdNameAddressResponseDto>;
  deliveryAddresses?: Array<string>;
  deliveryWarehouses?: Array<IWarehouseIdNameAddressResponseDto>;
  status: EActiveLoadStatus;
  favorite: boolean;
  createdBy: string;
  createdDate: number;
  updatedBy?: string;
  updatedDate?: number;
}

export interface IActiveLoadRequestDto {
  idno: string;
  name?: string;
  shipDate?: number;
  deliveryDate?: number;
}

export interface IActiveLoadOverviewResponseDto {
  uuid: string;
  idno: string;
  name?: string;
  shipDate?: number;
  deliveryDate?: number;
}

export interface IActiveLoadCoverFaceResponseDto {
  uuid: string;
  idno: string;
  name?: string;
  status: EActiveLoadStatus;
  readonly: boolean;
  favorite: boolean;
  metadata: {
    items: boolean;
    transports: boolean;
  };
}

export interface IActiveLoadDocumentRequestDto {
  type: EActiveLoadDocumentType;
  number?: string;
  caption?: string;
}

export interface IActiveLoadPayRequestDto {
  type: EActiveLoadPayType;
  caption: string;
  amount: number;
  status: EActiveLoadPayStatus;
}

export interface IActiveLoadPayResponseDto {
  uuid: string;
  type: EActiveLoadPayType;
  caption: string;
  amount: number;
  status: EActiveLoadPayStatus;
  createdBy: string;
  createdDate: number;
  updatedBy?: string;
  updatedDate?: number;
}

export interface IActiveLoadTransportRequestDto {
  transportId: string;
}

export interface IActiveLoadTransportResponseDto {
  uuid: string;
  type: EVehicleType;
  transport: ITransportShortResponseDto;
  status: EActiveLoadTransportStatus;
  createdBy: string;
  createdDate: number;
}

export interface IActiveLoadUserResponseDto {
  uuid: string;
  user: IUserShortResponseDto;
  involvedActiveLoads: number;
  status: EActiveLoadUserStatus;
  createdBy: string;
  createdDate: number;
}

export interface IActiveLoadUserRequestDto {
  userId: string;
}

export interface IActiveLoadPendingUserResponseDto {
  user: IUserShortResponseDto;
  involvedActiveLoads: number;
}

export interface IActiveLoadAutocompleteResponseDto {
  uuid: string;
  idno: string;
  name?: string;
}

export interface IActiveLoadShortResponseDto {
  uuid: string;
  idno: string;
  name?: string;
  shipDate?: number;
  deliveryDate?: number;
}