import { Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { IFragilityTypeRequestDto, IFragilityTypeResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";

interface IProps {
    fragilityType?: IFragilityTypeResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const PalletTypeDialog = (props: IProps) => {
    const { open, fragilityType, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'fragility-type-form';

    const isEdit = useRef<boolean>(fragilityType !== undefined);
    const [loading, setLoading] = useState(false);

    const { register, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IFragilityTypeRequestDto>({
        defaultValues: {
            name: isEdit.current ? fragilityType?.name : '',
            caption: isEdit.current ? fragilityType?.caption : ''
        }
    });

    const updateData = useCallback((uuid: string, data: IFragilityTypeRequestDto) => {
        (async () => {
            const [error, response] = await LoadService.updateFragilityType(uuid, data);
            if (response) {
                displayNotification({ message: t('Fragility type was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: IFragilityTypeRequestDto) => {
        (async () => {
            const [error, response] = await LoadService.createFragilityType(data);
            if (response) {
                displayNotification({ message: t('Fragility type was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: IFragilityTypeRequestDto) => {
        setLoading(true);
        if (fragilityType) {
            updateData(fragilityType.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, fragilityType, updateData]);

    const validateNameOrCaption = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('name', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TextField
                        {...register('name', {
                            required: true,
                            validate: validateNameOrCaption
                        })}
                        required
                        autoFocus
                        autoComplete='off'
                        label={t('NAME')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />

                    <TextField
                        {...register('caption', {
                            required: true,
                            validate: validateNameOrCaption
                        })}
                        required
                        error={!!errors.caption}
                        label={t('CAPTION')}
                        multiline
                        rows={3}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 255 } }}
                        helperText={errors.caption?.message}
                    />
                </Stack>
            </form>
        );
    }, [errors.caption, errors.name, handleSubmit, onSubmit, register, t, validateNameOrCaption]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} FRAGILITY TYPE`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default PalletTypeDialog;