import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationHomeUrl } from "../../../helpers/generalHelper";
import { useNotification } from "../../../hooks/useNotification";
import { useUser } from "../../../hooks/useUser";
import { IStepItem } from "../../../models/CommonModels";
import { IUserCoverFaceResponseDto } from "../../../models/UserModels";
import UserService from "../../../services/UserService";
import { RootState } from "../../../store/store";
import BaseStep from "../../Base/BaseStepComponent/BaseStep";
import UserContactsStep from "./Contacts/UserContactsStep";
import UserDescriptionStep from "./Description/UserDescriptionStep";
import UserDocumentsStep from "./Documents/UserDocumentsStep";
import UserEmployeeStep from "./Employee/UserEmployeeStep";
import UserIncomeStep from "./Incomes/UserIncomeStep";
import UserOverviewStep from "./Overview/UserOverviewStep";
import UserPaysStep from "./Pays/UserPaysStep";
import UserStatusStep from "./Status/UserStatusStep";
import UserStepCoverFace from "./UserStepCoverFace";

const UserStep = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const navigate = useNavigate();
    const { refresh } = useSelector((state: RootState) => state.userSlice.step);
    const { gridRefresh } = useUser();

    const onRetrieveDataApiHandler = useCallback((id: string) => {
        return UserService.fetchCoverFaceData(id);
    }, []);

    const onBuildCoverFaceHandler = useCallback((data: IUserCoverFaceResponseDto): React.ReactNode => {
        return <UserStepCoverFace data={data} />;
    }, []);

    const onBuildItemsHandler = useCallback((data: IUserCoverFaceResponseDto): IStepItem[] => {
        const id: string = data.uuid;
        const readonly: boolean = data.readonly;
        return [{
            title: t('OVERVIEW'),
            content: <UserOverviewStep userId={id} readonly={readonly} />
        }, {
            title: t('CONTACTS'),
            content: <UserContactsStep userId={id} />
        }, {
            title: t('EMPLOYEE'),
            content: <UserEmployeeStep userId={id} />
        }, {
            title: t('INCOMES'),
            content: <UserIncomeStep userId={id} />
        }, {
            title: t('PAYS'),
            content: <UserPaysStep userId={id} />
        }, {
            title: t('DESCRIPTION'),
            content: <UserDescriptionStep userId={id} />
        }, {
            title: t('DOCUMENTS'),
            content: <UserDocumentsStep userId={id} />
        }, {
            title: t('STATUS'),
            content: <UserStatusStep userId={id} readonly={readonly} />
        }];
    }, [t]);

    const onDeleteBtnClickHandler = useCallback((uuid: string) => {
        (async () => {
            const [error] = await UserService.remove(uuid);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
            } else {
                const newUrl: string = normalizeNavigationHomeUrl(true);
                navigate(`/${newUrl}`);
                displayNotification({ message: t('User was successfully deleted.') });
                gridRefresh();
            }
        })();
    }, [t]);

    return (
        <BaseStep
            retrieveDataApi={onRetrieveDataApiHandler}
            refresh={refresh}
            buildCoverFace={onBuildCoverFaceHandler}
            buildItems={onBuildItemsHandler}
            onDeleteBtnClick={onDeleteBtnClickHandler}
            deleteBtnTooltip={t('Delete item')}
            closeBtnTooltip={t('Close and return to the main list')}
        />
    );
}
export default UserStep;