import BasePage from '../components/Base/BasePageComponent/BasePage';
import WarehouseGrid from '../components/WarehouseModule/Grid/WarehouseGrid';
import WarehouseStep from '../components/WarehouseModule/Step/WarehouseStep';
import { useWarehouse } from '../hooks/useWarehouse';

const WarehousePage = () => {
    const { resetState, applyFilter } = useWarehouse();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<WarehouseGrid />}
            stepSection={<WarehouseStep />}
        />
    );
}
export default WarehousePage;