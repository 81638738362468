import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../helpers/generalHelper";
import { useLoad } from '../../../hooks/useLoad';
import { useNotification } from '../../../hooks/useNotification';
import { IFilter } from '../../../models/CommonModels';
import { IPalletTypeResponseDto } from "../../../models/LoadModels";
import LoadService from '../../../services/LoadService';
import { RootState } from "../../../store/store";
import BaseCrudGrid from '../../Base/BaseCrudGridComponent/BaseCrudGrid';
import PalletTypeDialog from "./PalletTypeDialog";

const PalletTypeGrid = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { palletTypeGridRefresh } = useLoad();

    const { measureSize, measureMass } = useSelector((state: RootState) => state.userProfileSlice.profile.userPreference);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.palletTypeGrid);

    const [dialogToggle, setDialogToggle] = useState(false);
    const [palletType, setPalletType] = useState<IPalletTypeResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchPalletTypes(filter);
    }, []);

    const onAddActionBtnClick = useCallback(() => {
        setPalletType(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((palletType: IPalletTypeResponseDto) => {
        setPalletType(palletType);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removePalletType(rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Pallet type${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    palletTypeGridRefresh();
                }
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [palletTypeGridRefresh, t]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setPalletType(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        palletTypeGridRefresh();
    }, [palletTypeGridRefresh]);

    const onNameColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onSizeColumnRender = useCallback((params: GridRenderCellParams) => {
        return `${params.value} ${measureSize}`
    }, [measureSize]);

    const onMassColumnRender = useCallback((params: GridRenderCellParams) => {
        return `${params.value} ${measureMass}`
    }, [measureMass]);

    const columns: GridColDef[] = [{
        field: 'name',
        headerName: t('NAME'),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        headerAlign: 'center',
        renderCell: onNameColumnRender
    }, {
        field: 'height',
        headerName: t('HEIGHT'),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 100,
        headerAlign: 'center',
        renderCell: onSizeColumnRender
    }, {
        field: 'width',
        headerName: t('WIDTH'),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 100,
        headerAlign: 'center',
        renderCell: onSizeColumnRender
    }, {
        field: 'length',
        headerName: t('LENGTH'),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 100,
        headerAlign: 'center',
        renderCell: onSizeColumnRender
    }, {
        field: 'weight',
        headerName: t('WEIGHT'),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 100,
        headerAlign: 'center',
        renderCell: onMassColumnRender
    }];

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
            />

            {dialogToggle &&
                <PalletTypeDialog
                    open={dialogToggle}
                    palletType={palletType}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default PalletTypeGrid;