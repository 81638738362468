import BasePage from '../components/Base/BasePageComponent/BasePage';
import UserGrid from '../components/UserModule/Grid/UserGrid';
import UserStep from '../components/UserModule/Step/UserStep';
import { useUser } from '../hooks/useUser';

const UserPage = () => {
    const { resetState, applyFilter } = useUser();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<UserGrid />}
            stepSection={<UserStep />}
        />
    );
}
export default UserPage;