import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateAndTimeFormat } from "../../../../helpers/dateHelper";
import { isEmpty } from "../../../../helpers/generalHelper";
import { roundBorderWithColorGreen, roundBorderWithColorRed } from "../../../../helpers/gridHelper";
import { currencyFormat, percentFormat } from "../../../../helpers/NumberHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { useUser } from "../../../../hooks/useUser";
import { IFilter } from "../../../../models/CommonModels";
import { EUserIncomeType, EUserPayType, IUserIncomeResponseDto, IUserPayResponseDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import UserPaysDialog from "./UserPaysDialog";

interface IProps {
    userId: string;
}
const UserPaysGrid = (props: IProps) => {
    const { userId } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.userSlice.paysGrid);
    const { paysGridRefresh } = useUser();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [pay, setPay] = useState<IUserPayResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return UserService.fetchPays(userId, filter);
    }, [userId]);

    const onAddActionBtnClick = useCallback(() => {
        setPay(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((pay: IUserPayResponseDto) => {
        setPay(pay);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await UserService.removePay(userId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`User pay${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    paysGridRefresh();
                }
            })();
        }
    }, [userId]);

    const onIncomeColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: IUserIncomeResponseDto = params.value;
        const amount: string = value.type === EUserIncomeType.PERCENTAGE
            ? percentFormat(value.amount)
            : currencyFormat(value.amount);
        const shortValue: string = value.type.toString().replaceAll('_', ' ');
        const longValue: string = `${shortValue} [ ${value.caption} ${amount} ]`;

        return (
            <Tooltip title={longValue}>
                <span>{shortValue}</span>
            </Tooltip>
        );
    }, []);

    const onTypeColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: EUserPayType = params.value as unknown as EUserPayType;
        const shortValue: string = `RECUR. ${EUserPayType.RECURRING_MINUS === value ? '-' : '+'}`;

        return (
            <Tooltip title={value.toString().replace('_', ' ')}>
                <span style={EUserPayType.RECURRING_MINUS === value ? roundBorderWithColorRed : roundBorderWithColorGreen}>
                    {shortValue}
                </span>
            </Tooltip>
        );
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onAmountColumnRender = useCallback((value: number) => {
        return currencyFormat(value)
    }, []);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onUpdatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const updatedBy: string = params.row.updatedBy;
        const updatedDate: number = params.row.updatedDate;
        if (!updatedBy) {
            return '';
        }

        const dateTime: string = getDateAndTimeFormat(updatedDate, dateFormat, timeFormat);
        const tooltip: string = `${updatedBy} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {updatedBy} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns: GridColDef[] = [{
        field: 'income',
        headerName: `${t('INCOME')}`,
        width: 135,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'left',
        renderCell: onIncomeColumnRender
    }, {
        field: 'type',
        headerName: `${t('TYPE')}`,
        width: 105,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onTypeColumnRender
    }, {
        field: 'caption',
        headerName: `${t('CAPTION')}`,
        flex: 1,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onTooltipColumnRender
    }, {
        field: 'amount',
        headerName: `${t('AMOUNT')}`,
        width: 100,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        type: 'number',
        valueFormatter: onAmountColumnRender
    }, {
        field: 'created',
        headerName: `${t('CREATED')}`,
        width: 100,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onCreatedColumnRender
    }, {
        field: "updated",
        headerName: `${t('UPDATED')}`,
        width: 100,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: onUpdatedColumnRender
    }];

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setPay(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        paysGridRefresh();
    }, [paysGridRefresh]);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
            />

            {dialogToggle &&
                <UserPaysDialog
                    open={dialogToggle}
                    userId={userId}
                    pay={pay}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default UserPaysGrid;