import { useJsApiLoader } from "@react-google-maps/api";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { EKey } from "../../../models/ProfileModels";
import { RootState } from "../../../store/store";

const libraries: any = ['places'];

const AuthInitConfig = () => {
    const { keys } = useSelector((state: RootState) => state.userProfileSlice.profile.systemPreference);

    const key = useRef<string>(
        keys.find((key) => key.name === EKey.GOOGLE_MAP.toString())?.value || ''
    );

    useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: key.current,
        libraries: libraries
    });

    return (<></>);
}
export default AuthInitConfig;