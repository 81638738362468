import { Autocomplete, AutocompleteRenderInputParams, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../helpers/generalHelper";
import { EWarehouseStatus, IWarehouseAutocompleteResponseDto } from "../../models/WarehouseModels";
import WarehouseService from "../../services/WarhouseService";

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    disabled?: boolean;
    size?: 'medium' | 'small';
    showStatus?: boolean;
    showMark?: boolean;
    showAddress?: boolean;
    disableInactiveItems?: boolean;
    onInit?: (value: IWarehouseAutocompleteResponseDto | null) => void;
    onChange?: (newValue: IWarehouseAutocompleteResponseDto | null) => void;
}
const WarehouseAutocomplete = (props: IProps) => {
    const {
        size = 'medium', disabled, label = 'Warehouses', required = false, onChange, onInit,
        value, showStatus = false, showAddress = false, showMark = false, disableInactiveItems = false
    } = props;

    const [data, setData] = useState<IWarehouseAutocompleteResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<IWarehouseAutocompleteResponseDto | null>(null);

    useEffect(() => {
        if (isEmpty(data)) {
            (async () => {
                const [, response] = await WarehouseService.fetchAutocomplete();
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [data]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: IWarehouseAutocompleteResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }

            if (onInit) {
                onInit(selectedValue);
            }
        }
    }, [value, data, onInit, selectedValue]);

    const onChangeHandler = useCallback((event: any, newValue: IWarehouseAutocompleteResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: IWarehouseAutocompleteResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography variant='body2'>{item.name}</Typography>
                {(showStatus || showMark) &&
                    <Typography variant='caption' sx={{ fontSize: 10 }}>
                        {showStatus && `[ STATUS: ${item.status} ]`}
                        {showMark && item.reviewMark > 0 && `[ REVIEW: ${item.reviewMark} ]`}
                    </Typography>
                }
                {showAddress &&
                    <Typography variant='caption' sx={{ fontSize: 10 }}>
                        <Divider />
                        {item.address.value}
                    </Typography>
                }
            </div>
        );
    }, [showAddress, showMark, showStatus]);

    const onBuildOptionLabel = useCallback((item: IWarehouseAutocompleteResponseDto) => {
        return item.name;
    }, []);

    const onBuildOptionKey = useCallback((item: IWarehouseAutocompleteResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    const getOptionDisabledHandler = useCallback((item: IWarehouseAutocompleteResponseDto) => {
        return disableInactiveItems
            ? item.status === EWarehouseStatus.INACTIVE || item.status === EWarehouseStatus.INCOMPLETE
            : false;
    }, [disableInactiveItems]);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionDisabled={getOptionDisabledHandler}
            disabled={disabled}
        />
    );
}
export default WarehouseAutocomplete;