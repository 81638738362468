import { Client } from '@stomp/stompjs';
import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { AppConfig } from '../../../configs/AppConfig';
import { AuthContext } from '../../../context/AuthProvider';
import { ISocketCommand, ISocketPersonalChat } from '../../../models/SocketModels';
import { RootState } from '../../../store/store';
import SocketCommand from './SocketCommand';
import SocketPersonalChat from './SocketPersonalChat';

const AuthSocket = () => {
    const { token } = useContext(AuthContext);
    const { userProfile } = useSelector((state: RootState) => state.userProfileSlice.profile);
    const [command, setCommand] = useState<ISocketCommand>();
    const [personalChatMessage, setPersonalChatMessage] = useState<ISocketPersonalChat>();

    useEffect(() => {
        client.activate();

        return () => {
            client.deactivate();
        };
    }, []);

    const onConnect = useCallback(() => {
        onCommandSubscribe(client);
        onPersonalChatSubscribe(client);
    }, []);

    const client = new Client({
        brokerURL: `${AppConfig.socketURL}?Authorization=Bearer ${token?.accessToken}`,
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        onConnect: onConnect
    });

    const onCommandSubscribe = useCallback((client: Client) => {
        client.subscribe(`${AppConfig.socketChannel}${userProfile.email}/command`, (response) => {
            if (response.body) {
                setCommand(JSON.parse(response.body));
            }
        });
    }, [userProfile.email]);

    const onPersonalChatSubscribe = useCallback((client: Client) => {
        client.subscribe(`${AppConfig.socketChannel}${userProfile.email}/chat/personal`, (response) => {
            if (response.body) {
                setPersonalChatMessage(JSON.parse(response.body));
            }
        });
    }, [userProfile.email]);

    return (
        <>
            {command && <SocketCommand command={command} />}
            {personalChatMessage && <SocketPersonalChat message={personalChatMessage} />}
        </>
    );
}
export default AuthSocket;