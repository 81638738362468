import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { EUserIncomeType, IUserIncomeRequestDto, IUserIncomeResponseDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import CurrencyField from "../../../Base/CurrencyFieldComponent/CurrencyField";
import UserIncomeTypeSelect from "./UserIncomeTypeSelect";

interface IProps {
    userId: string;
    income?: IUserIncomeResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const UserIncomeDialog = (props: IProps) => {
    const { open, userId, income, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'user-income-form';
    const isEdit = useRef<boolean>(income !== undefined);

    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IUserIncomeRequestDto>({
        defaultValues: {
            type: isEdit.current ? income?.type : EUserIncomeType.NONE,
            amount: isEdit.current ? income?.amount : NaN,
            caption: isEdit.current ? income?.caption : ''
        }
    });

    const updateData = useCallback((uuid: string, data: IUserIncomeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.updateIncome(userId, uuid, data);
            if (response) {
                displayNotification({ message: t('User income was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [userId, t]);

    const createData = useCallback((data: IUserIncomeRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await UserService.createIncome(userId, data);
            if (response) {
                displayNotification({ message: t('User income was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [userId, t]);

    const onSubmit = useCallback((data: IUserIncomeRequestDto) => {
        if (income) {
            updateData(income.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, income, updateData]);

    const validateIncomeTypeField = useCallback((value: EUserIncomeType) => {
        return EUserIncomeType[value] !== undefined && value !== EUserIncomeType.NONE;
    }, []);

    register('type', { validate: validateIncomeTypeField });
    const onIncomeTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as EUserIncomeType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAmountField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('amount', { validate: validateAmountField });
    const onAmountChangeHandler = useCallback((value: number) => {
        setValue('amount', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateCaption = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('caption', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <UserIncomeTypeSelect
                        required
                        label={t('TYPE')}
                        value={getValues('type')}
                        onChange={onIncomeTypeChangeHandler}
                    />

                    <TextField
                        {...register('caption', {
                            required: true,
                            validate: validateCaption
                        })}
                        required
                        autoComplete='off'
                        label={t('CAPTION')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.caption}
                        helperText={errors.caption?.message}
                    />

                    <Stack direction="row" spacing={2}>
                        <CurrencyField
                            required
                            label={t("AMOUNT")}
                            allowNegative={false}
                            value={getValues('amount')}
                            percent={getValues('type') === EUserIncomeType.PERCENTAGE}
                            onChange={onAmountChangeHandler}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        errors.caption, getValues, handleSubmit, onAmountChangeHandler, onIncomeTypeChangeHandler,
        onSubmit, register, t, validateCaption
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} INCOME`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default UserIncomeDialog;