import { IFilter } from "../models/CommonModels";
import { ELoadAddressType, IFragilityTypeRequestDto, ILoadAddressRequestDto, ILoadDocumentRequestDto, ILoadItemRequestDto, ILoadRequestDto, IPalletTypeRequestDto } from "../models/LoadModels";
import { API } from "./APIService";

class LoadService {

    public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'load/all', JSON.stringify(filter));
        return [error, response];
    };

    public static downloadReport = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'load/report', filter, undefined, undefined, 'blob');
        return [error, response];
    };

    public static fetchCoverFaceData = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${uuid}/coverFace`);
        return [error, response];
    };

    public static create = async (data: ILoadRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'load', JSON.stringify(data));
        return [error, response];
    };

    public static fetchOverview = async (loadId: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${loadId}/overview`);
        return [error, response];
    };

    public static updateOverview = async (loadId: string, data: ILoadRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/overview`, data);
        return [error, response];
    };

    public static isIdnoAvailable = async (idno: string): Promise<any> => {
        const [error, response] = await API('GET', `load/available/idno/${idno}`);
        return [error, response];
    };

    public static createFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('POST', 'load/favorite', ids);
        return [error, response];
    };

    public static removeFavorites = async (ids: string[]): Promise<any> => {
        const [error, response] = await API('DELETE', 'load/favorite', ids);
        return [error, response];
    };

    public static fetchDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${uuid}/documents`);
        return [error, response];
    };

    public static createDocument = async (loadId: string, data: ILoadDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/documents`, data);
        return [error, response];
    };

    public static updateDocument = async (loadId: string, documentId: string, data: ILoadDocumentRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/documents/${documentId}`, data);
        return [error, response];
    };

    public static removeDocument = async (loadId: string, documentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/documents/${documentId}`);
        return [error, response];
    };

    public static createDocumentAttachment = async (loadId: string, documentId: string, data: FormData): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/documents/${documentId}/attachments`, data, undefined, 'multipart/form-data');
        return [error, response];
    };

    public static removeDocumentAttachment = async (loadId: string, documentId: string, attachmentId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/documents/${documentId}/attachments/${attachmentId}`);
        return [error, response];
    };

    public static fetchPalletTypes = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/palletTypes/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static createPalletType = async (data: IPalletTypeRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'load/palletTypes', data);
        return [error, response];
    };

    public static updatePalletType = async (palletTypeId: string, data: IPalletTypeRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/palletTypes/${palletTypeId}`, data);
        return [error, response];
    };

    public static fetchPalletTypesAutocomplete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/palletTypes/autocomplete');
        return [error, response];
    };

    public static removePalletType = async (palletTypeId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/palletTypes/${palletTypeId}`);
        return [error, response];
    };

    public static fetchFragilityTypes = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/fragilityTypes/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static fetchFragilityTypesAutocomplete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/fragilityTypes/autocomplete');
        return [error, response];
    };

    public static createFragilityType = async (data: IFragilityTypeRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'load/fragilityTypes', data);
        return [error, response];
    };

    public static updateFragilityType = async (fragilityTypeId: string, data: IFragilityTypeRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/fragilityTypes/${fragilityTypeId}`, data);
        return [error, response];
    };

    public static removeFragilityType = async (fragilityTypeId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/fragilityTypes/${fragilityTypeId}`);
        return [error, response];
    };

    public static fetchDescription = async (loadId: string): Promise<any> => {
        const [error, response] = await API('GET', `load/${loadId}/description`);
        return [error, response];
    };

    public static updateDescription = async (loadId: string, description?: string): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/description`, description);
        return [error, response];
    };

    public static fetchAddresses = async (loadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/addresses/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static createAddress = async (loadId: string, data: ILoadAddressRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/addresses`, data);
        return [error, response];
    };

    public static updateAddress = async (loadId: string, loadAddressId: string, data: ILoadAddressRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/addresses/${loadAddressId}`, data);
        return [error, response];
    };

    public static removeAddress = async (loadId: string, loadAddressId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/addresses/${loadAddressId}`);
        return [error, response];
    };

    public static fetchAddressesAutocomplete = async (loadId: string, type: ELoadAddressType): Promise<any> => {
        const [error, response] = await API('GET', `load/${loadId}/addresses/${type}/autocomplete`);
        return [error, response];
    };

    public static fetchItems = async (loadId: string, filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/items/all`, JSON.stringify(filter));
        return [error, response];
    };

    public static createItem = async (loadId: string, data: ILoadItemRequestDto): Promise<any> => {
        const [error, response] = await API('POST', `load/${loadId}/items`, data);
        return [error, response];
    };

    public static updateItem = async (loadId: string, loadItemId: string, data: ILoadItemRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', `load/${loadId}/items/${loadItemId}`, data);
        return [error, response];
    };

    public static removeItem = async (loadId: string, loadItemId: string): Promise<any> => {
        const [error, response] = await API('DELETE', `load/${loadId}/items/${loadItemId}`);
        return [error, response];
    };

    public static isItemIdnoAvailable = async (idno: string): Promise<any> => {
        const [error, response] = await API('GET', `load/available/item/idno/${idno}`);
        return [error, response];
    };

}
export default LoadService;