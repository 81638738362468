import { Stack } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { IReviewRequestDto, IReviewResponseDto } from "../../../models/ReviewModels";
import BaseCrudDialog from "../BaseCrudDialogComponent/BaseCrudDialog";
import RatingView from "../RatingComponent/RatingView";
import TextareaField from "../TextareaFieldComponent/TextareaField";

interface IProps {
    open: boolean;
    entity?: IReviewResponseDto;

    createApi: (data: IReviewRequestDto) => Promise<any>;
    updateApi: (uuid: string, data: IReviewRequestDto) => Promise<any>;
    
    onCreateBtnClick: () => void;
    onUpdateBtnClick: () => void;
    onCloseBtnClick: () => void;
}
const BaseReviewDialog = (props: IProps) => {
    const { open, entity, createApi, updateApi, onCreateBtnClick, onUpdateBtnClick, onCloseBtnClick } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = `review-form`;
    const isEdit = useRef<boolean>(entity !== undefined);

    const { register, setValue, getValues, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IReviewRequestDto>({
        defaultValues: {
            mark: isEdit.current ? entity?.mark : 0,
            message: isEdit.current ? entity?.message : ''
        }
    });

    const updateData = useCallback((uuid: string, data: IReviewRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await updateApi(uuid, data);
            if (response) {
                displayNotification({ message: t('Review was successfully updated.') });

                onUpdateBtnClick();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onCloseBtnClick, onUpdateBtnClick, t, updateApi]);

    const createData = useCallback((data: IReviewRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await createApi(data);
            if (response) {
                displayNotification({ message: t('Review was successfully created.') });

                onCreateBtnClick();
                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createApi, onCloseBtnClick, onCreateBtnClick, t]);

    const onSubmit = useCallback((data: IReviewRequestDto) => {
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, entity, updateData]);

    const validateMarkField = useCallback((value: number) => {
        return value > 0 && value <= 5;
    }, []);

    register('mark', { validate: validateMarkField });
    const onMarkChangeHandler = useCallback((value: number) => {
        setValue('mark', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateMessageField = useCallback((value: string) => {
        return !isBlank(value) && value.length >= 3;
    }, []);

    register('message', { validate: validateMessageField });
    const onMessageChangeHandler = useCallback((value: string) => {
        setValue('message', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <RatingView
                        value={getValues('mark')}
                        readonly={false}
                        size='medium'
                        precision={false}
                        onChange={onMarkChangeHandler}
                    />

                    <TextareaField
                        label={t('MESSAGE')}
                        showCount
                        minLength={3}
                        maxLength={255}
                        required
                        rows={6}
                        value={getValues('message')}
                        onChange={onMessageChangeHandler}
                    />
                </Stack>
            </form>
        );
    }, [formId, getValues, handleSubmit, onMarkChangeHandler, onMessageChangeHandler, onSubmit, t]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} REVIEW`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default BaseReviewDialog;