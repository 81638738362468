import { TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { covertDateStrToTimestampOrUndefined, getDateFormat } from "../../../helpers/dateHelper";
import { EDateFormat } from "../../../models/ProfileModels";

interface IProps {
    label?: string;
    value?: number;
    size?: 'small' | 'medium';
    min?: string;
    max?: string;
    required?: boolean;
    onChange?: (timestamp?: number, dateStr?: string) => void;
}
const DateField = (props: IProps) => {
    const { label = 'DATE', size = 'small', max, min, required = false, onChange } = props;
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        setValue(props.value ? getDateFormat(props.value, EDateFormat.d_yyyy_mm_dd) : '');
    }, [props.value]);

    const onChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;

        if (onChange) {
            const timeStamp: number | undefined = covertDateStrToTimestampOrUndefined(value);
            onChange(timeStamp, value);
        }

        setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TextField
            label={label}
            type='date'
            required={required}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size={size}
            value={value}
            slotProps={{ htmlInput: { max: max, min: min } }}
            onChange={onChangeHandler}
        />
    );
}
export default DateField;