import { Alert } from "@mui/material";
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generateNumberBetween } from "../../../helpers/NumberHelper";
import { isEmpty } from "../../../helpers/generalHelper";
import { IMotto } from "../../../models/IMotto";
import PublicService from "../../../services/PublicService";
import { RootState } from "../../../store/store";

const LogoMotto = () => {
    const [motto, setMotto] = useState<IMotto>();
    const { t } = useTranslation();
    const defaultMotto = t('WELCOME TO KLMB TRANSPORTATION PLATFORM');
    const { online } = useSelector((state: RootState) => state.globalDataSlice);

    const fetchData = useCallback(() => {
        (async () => {
            const [, response] = await PublicService.fetchMottos();
            if (response) {
                const mottos: IMotto[] = response.data.body;
                if (!isEmpty(mottos)) {
                    const index: number = generateNumberBetween(0, mottos.length - 1);
                    setMotto(mottos[index]);
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (online) {
            fetchData();
        } else {
            setMotto(undefined);
        }
    }, [fetchData, online])

    return (
        <Alert icon={false} severity="info">
            <div style={{ textAlign: "center", width: "600px" }}>
                {motto
                    ?
                    <>
                        {motto.text}
                        <p style={{ textAlign: "center", margin: "0px" }}>
                            <b>{motto.author}</b>
                        </p>
                    </>
                    : defaultMotto
                }
            </div>
        </Alert>
    );
}
export default LogoMotto;