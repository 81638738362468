import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { useCallback } from 'react';

interface IProps {
    enabledTooltip?: boolean;
    disableStyle?: boolean;
    placementTop?: boolean;
    addTooltip?: string
    removeTooltip?: string;
    favorite: boolean;
    onBtnClick: () => void;
}
const BaseFavoriteBtn = (props: IProps) => {
    const {
        favorite, onBtnClick, enabledTooltip = false, disableStyle = false,
        placementTop = false, addTooltip = 'Add to favorites',
        removeTooltip = 'Remove from favorites'
    } = props;

    const theme = useTheme();

    const onBtnClickHandler = useCallback(() => {
        onBtnClick();
    }, [onBtnClick]);

    const iconStyle = {
        color: theme.palette.action.active,
        "&:hover": {
            color: theme.palette.primary.main
        }
    };

    return (
        <Tooltip
            title={enabledTooltip ? favorite ? removeTooltip : addTooltip : ''}
            placement={placementTop ? 'top' : 'bottom'}
        >
            <IconButton
                size={'small'}
                onClick={onBtnClickHandler}
                sx={disableStyle ? {} : iconStyle}
            >
                {favorite ? <GradeIcon /> : <GradeOutlinedIcon />}
            </IconButton>
        </Tooltip>
    );
}
export default BaseFavoriteBtn;