import { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { deleteFavoritesAudit } from "../../store/reducers/favoritesSlice";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { modifyAuditFilter, modifyAuditRefresh, modifyAuditValues, modifyQuery } from "../../store/reducers/auditSlice";
import { IFavoritesAudit, valueState } from "../../models/AuditModels";

const AuditFavoritesComponent = () => {

    const [expanded, setExpanded] = useState<string | false>('favorites');
    const dispatch = useDispatch<AppDispatch>();
    // const { auth } = useSelector((state: RootState) => state.cnfSlice);
    const { audits } = useSelector((state: RootState) => state.favoritesSlice);
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handlerDelete = (u: IFavoritesAudit) => {
        if (u.uuid) {
            dispatch(deleteFavoritesAudit([u]))
        }
    }

    const handlerDeleteAll = () => {
        // if (auth) {
        //     dispatch(deleteFavoritesAudit(audits))
        // }
    }

    const hendlerLink = (u: IFavoritesAudit) => {

        if (u.value) {
            let valori = JSON.parse(u.value) as valueState;
            if (valori) {
                dispatch(modifyAuditFilter(valori.filter));
                dispatch(modifyAuditValues(valori.valueFilter));
                dispatch(modifyAuditRefresh());
            }
        }

        dispatch(modifyQuery());

    }

    useEffect(() => {
        if (audits.length > 0) {
            setExpanded('favorites');
        }
    }, [audits])

    return (
        <>
            {audits.length > 0 &&
                <Accordion expanded={expanded === 'favorites'} onChange={handleChange('favorites')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <QueryStatsIcon />
                        <Typography variant={'subtitle2'} sx={{ width: '33%', flexShrink: 0, marginLeft: "10px" }}>CRITERIAS</Typography>

                    </AccordionSummary>
                    <AccordionDetails>

                        <Divider />
                        {audits.length > 0 &&
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px" }} >
                                <div></div>
                                <Tooltip
                                    title={(hints) ? 'Remove all criterias' : ''}
                                    arrow
                                    placement="right"
                                >
                                    <Button onClick={handlerDeleteAll} sx={{ margin: "5px" }} size='small' endIcon={<DeleteSweepIcon />}>
                                        Remove all
                                    </Button>
                                </Tooltip>
                            </div>}
                        <Divider />
                        {audits &&
                            audits.map((u: any, i) =>
                                <div key={i} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px" }}>
                                    <div
                                        onClick={() => hendlerLink(u)}
                                        className="dataItem"
                                        style={{
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "start",
                                            gap: "15px",
                                            paddingBottom: "3px",
                                            paddingTop: "3px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            minWidth: "200px",
                                            overflow: "hidden"
                                        }}
                                    >

                                        <Typography variant={"caption"}>{u.name}</Typography>
                                    </div>
                                    <div>
                                        <Tooltip
                                            title={'Remove from criterias'}
                                            arrow
                                            placement="right"
                                        >

                                            <IconButton aria-label="fingerprint" color='primary' onClick={() => handlerDelete(u)}>
                                                <LinkOffIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </div>
                                </div>
                            )
                        }
                    </AccordionDetails>
                </Accordion>}
        </>
    )
}

export default AuditFavoritesComponent;