import CategoryIcon from '@mui/icons-material/Category';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { calculateDaysBetweenDates, convertTimestampToDate, convertTimestampToDateStrWithFormatOrEmpty } from '../../../helpers/dateHelper';
import { useLoad } from '../../../hooks/useLoad';
import { ECriteriaExpression, ICriteria, IFilter } from '../../../models/CommonModels';
import { ELoadStatus, ILoadCoverFaceResponseDto } from '../../../models/LoadModels';
import LoadService from '../../../services/LoadService';
import { RootState } from '../../../store/store';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';

interface IProps {
    data: ILoadCoverFaceResponseDto;
}
const LoadStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { gridRefresh, stepRefresh } = useLoad();
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const dueDateStatuses = useRef<ELoadStatus[]>([ELoadStatus.INCOMPLETE, ELoadStatus.PENDING, ELoadStatus.LOADED, ELoadStatus.IN_ROUTE]);

    const convertDateToString = useCallback((value?: number) => {
        return convertTimestampToDateStrWithFormatOrEmpty(value, dateFormat);
    }, [dateFormat]);

    const getDueDateColor = useCallback((): string => {
        let dueDateColor: string = 'black';

        if (data.deliveredBy && dueDateStatuses.current.includes(data.status)) {
            const deliveredBy: Date = convertTimestampToDate(data.deliveredBy);
            const currentDate: Date = new Date();
            const diff: number = calculateDaysBetweenDates(currentDate, deliveredBy);

            if (diff <= 7 && diff >= 5) {
                dueDateColor = '#ed6c02';
            } else if (diff <= 4) {
                dueDateColor = 'red';
            }
        }

        return dueDateColor;
    }, [data.deliveredBy, data.status]);

    const content: React.ReactNode = <Box flex={1}>
        <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
            <CategoryIcon style={{ color: 'rgb(106, 104, 104)' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>{data.idno}</b>
                </Typography>

                <Typography sx={{ fontSize: '13px', color: `${data.status === ELoadStatus.INCOMPLETE ? 'red' : 'black'}` }}>
                    <b>{data.status.toString().replace('_', ' ')}</b>
                </Typography>
            </Stack>

            {data.deliveredBy &&
                <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />
            }

            {data.deliveredBy &&
                <Stack direction='column' spacing={0.5}>
                    <Typography sx={{ color: getDueDateColor() }}>
                        <b>{t('DUE DATE: ')}{convertDateToString(data.deliveredBy)}</b>
                    </Typography>

                    <Typography sx={{ fontSize: '13px' }}>
                        <b>&nbsp;</b>
                    </Typography>
                </Stack>
            }
        </Stack>
    </Box>;

    const onFavoriteBtnClickHandler = useCallback(() => {
        (async () => {
            const ids: string[] = [data.uuid];
            if (data.favorite) {
                LoadService.removeFavorites(ids);
            } else {
                LoadService.createFavorites(ids);
            }
            gridRefresh();
            stepRefresh();
        })();
    }, [data.favorite, data.uuid, gridRefresh, stepRefresh]);

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return LoadService.downloadReport(filter);
    }, [data.uuid]);

    return (
        <BaseStepCoverFace
            content={content}

            favoriteBtnAddTooltip={t('Add to favorites')}
            favoriteBtnRemoveTooltip={t('Remove from favorites')}
            favoriteBtn={data.favorite}
            onFavoriteBtnClick={onFavoriteBtnClickHandler}

            downloadBtnTooltip={t('Download load')}
            downloadApi={onDownloadApiHandler}
        />
    );
}
export default LoadStepCoverFace;