import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { EActiveLoadPayStatus, EActiveLoadPayType, IActiveLoadPayRequestDto, IActiveLoadPayResponseDto } from "../../../../models/ActiveLoadModels";
import ActiveLoadService from "../../../../services/ActiveLoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import CurrencyField from "../../../Base/CurrencyFieldComponent/CurrencyField";
import StatusSelect from "../../../Base/StatusSelectComponent/StatusSelect";
import ActiveLoadPaysTypeSelect from "./ActiveLoadPaysTypeSelect";

interface IProps {
    activeLoadId: string;
    pay?: IActiveLoadPayResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const ActiveLoadPaysDialog = (props: IProps) => {
    const { open, activeLoadId, pay, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'active-load-pays-form';

    const isEdit = useRef<boolean>(pay !== undefined);
    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IActiveLoadPayRequestDto>({
        defaultValues: {
            type: isEdit.current ? pay?.type : EActiveLoadPayType.NONE,
            caption: isEdit.current ? pay?.caption : '',
            amount: isEdit.current ? pay?.amount : NaN,
            status: isEdit.current ? pay?.status : EActiveLoadPayStatus.NONE
        }
    });

    const updateData = useCallback((uuid: string, data: IActiveLoadPayRequestDto) => {
        (async () => {
            const [error, response] = await ActiveLoadService.updatePay(activeLoadId, uuid, data);
            if (response) {
                displayNotification({ message: t('Active load pay was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [activeLoadId]);

    const createData = useCallback((data: IActiveLoadPayRequestDto) => {
        (async () => {
            const [error, response] = await ActiveLoadService.createPay(activeLoadId, data);
            if (response) {
                displayNotification({ message: t('Active load pay was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [activeLoadId]);

    const onSubmit = useCallback((data: IActiveLoadPayRequestDto) => {
        setLoading(true);
        if (pay) {
            updateData(pay.uuid, data);
        } else {
            createData(data);
        }
    }, [pay]);

    const validateTypeField = useCallback((value: EActiveLoadPayType) => {
        return EActiveLoadPayType[value] !== undefined;
    }, []);

    register('type', { validate: validateTypeField });
    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as EActiveLoadPayType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAmountField = useCallback((value: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('amount', { validate: validateAmountField });
    const onAmountChangeHandler = useCallback((value: number) => {
        setValue('amount', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateCaption = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('caption', { message: message });
            return false;
        }
        return true;
    }, [setError]);

    const statusData = useRef<string[]>(
        Object.keys(EActiveLoadPayStatus)
            .filter(key => isNaN(Number(key)))
            .filter(key => key !== EActiveLoadPayStatus[EActiveLoadPayStatus.NONE])
            .map(key => key.toString())
    );

    const validateStatusField = useCallback((value: EActiveLoadPayStatus) => {
        return EActiveLoadPayStatus[value] !== undefined && value !== EActiveLoadPayStatus.NONE;
    }, []);

    register('status', { validate: validateStatusField });
    const onStatusChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('status', event.target.value as EActiveLoadPayStatus, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <ActiveLoadPaysTypeSelect
                        required
                        label={t('TYPE')}
                        value={getValues('type')}
                        onChange={onTypeChangeHandler}
                    />

                    <TextField
                        {...register('caption', {
                            required: true,
                            validate: validateCaption
                        })}
                        required
                        autoComplete='off'
                        label={t('CAPTION')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 160 } }}
                        error={!!errors.caption}
                        helperText={errors.caption?.message}
                    />

                    <Stack direction="row" spacing={2}>
                        <CurrencyField
                            required
                            label={t("AMOUNT")}
                            value={getValues('amount')}
                            onChange={onAmountChangeHandler}
                        />

                        <StatusSelect
                            required
                            fullWidth
                            label={t('STATUS')}
                            value={getValues('status')}
                            size='medium'
                            onChange={onStatusChangeHandler}
                            data={statusData.current}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [errors.caption, getValues, handleSubmit, onAmountChangeHandler, onStatusChangeHandler, onSubmit,
        onTypeChangeHandler, register, t, validateCaption
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} PAY`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default ActiveLoadPaysDialog;