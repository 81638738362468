import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { useTransport } from "../../../hooks/useTransport";
import { ECriteriaExpression, ICriteria } from "../../../models/CommonModels";
import { EBrandType, IBrandResponseDto } from "../../../models/MerchandiseModels";
import { ETransportStatus, EVehicleType } from "../../../models/TransportModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import BrandAutocomplete from "../../Base/BrandComponent/BrandAutocomplete";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";
import TransportTypeSelect from "../TransportTypeSelect";

interface IProps {
    open: boolean;
    onClose: () => void;
}
const TransportFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useTransport();
    const { criterias } = useSelector((state: RootState) => state.transportSlice.grid);

    const [type, setType] = useState<EVehicleType | undefined>(undefined);
    const [brand, setBrand] = useState<string>('');
    const [model, setModel] = useState<string>('');
    const [vin, setVin] = useState<string>('');
    const [licensePlate, setLicensePlate] = useState<string>('');
    const [number, setNumber] = useState<string>('');
    const [status, setStatus] = useState<ETransportStatus | undefined>(undefined);

    const statusData = useRef<string[]>(
        Object.keys(ETransportStatus)
            .filter(key => isNaN(Number(key)))
            .filter(key => key !== ETransportStatus[ETransportStatus.NONE])
            .map(key => key.toString())
    );

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        const typeStr: string | undefined = getValueFromCriterias('vehicle.type', criterias);
        setType(typeStr as EVehicleType || EVehicleType.NONE);

        setBrand(getValueFromCriterias('vehicle.brand.name', criterias) || '');
        setModel(getValueFromCriterias('vehicle.model', criterias) || '');
        setVin(getValueFromCriterias('vin', criterias) || '');
        setLicensePlate(getValueFromCriterias('licensePlate', criterias) || '');
        setNumber(getValueFromCriterias('transportNumber', criterias) || '');

        const statusStr: string | undefined = getValueFromCriterias('status', criterias);
        setStatus(statusStr as ETransportStatus || ETransportStatus.NONE);
    }, [criterias, getValueFromCriterias]);

    const onChangeTypeHandler = useCallback((event?: SelectChangeEvent) => {
        setType(event?.target.value as unknown as EVehicleType);
    }, []);

    const onChangeBrandHandler = useCallback((value: IBrandResponseDto | null) => {
        setBrand(value?.name || '');
    }, []);

    const onChangeModelHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setModel(event.target.value);
    }, []);

    const onChangeVinHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setVin(event.target.value);
    }, []);

    const onChangeLicensePlateHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setLicensePlate(event.target.value);
    }, []);

    const onChangeNumberHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNumber(event.target.value);
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as ETransportStatus);
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (type) {
            newCriterias.push({
                property: 'vehicle.type',
                value: type.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (brand && !isBlank(brand)) {
            newCriterias.push({
                property: 'vehicle.brand.name',
                value: brand,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (model && !isBlank(model)) {
            newCriterias.push({
                property: 'vehicle.model',
                value: model,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (vin && !isBlank(vin)) {
            newCriterias.push({
                property: 'vin',
                value: vin,
                expression: ECriteriaExpression.EQUALS_IGNORE_CASE
            });
        }

        if (licensePlate && !isBlank(licensePlate)) {
            newCriterias.push({
                property: 'licensePlate',
                value: licensePlate,
                expression: ECriteriaExpression.EQUALS_IGNORE_CASE
            });
        }

        if (number && !isBlank(number)) {
            newCriterias.push({
                property: 'transportNumber',
                value: number,
                expression: ECriteriaExpression.EQUALS_IGNORE_CASE
            });
        }

        if (status) {
            newCriterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        return newCriterias;
    }, [brand, licensePlate, model, number, status, type, vin]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <TransportTypeSelect
                        label={t('TYPE')}
                        size='small'
                        value={type}
                        onChange={onChangeTypeHandler}
                    />

                    <TextField
                        size='small'
                        label={t('VIN')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 21 } }}
                        value={vin}
                        onChange={onChangeVinHandler}
                    />

                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData.current}
                        value={status}
                        onChange={onChangeStatusHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <BrandAutocomplete
                        label={t('BRAND')}
                        size='small'
                        type={type as unknown as EBrandType}
                        value={brand}
                        onChange={onChangeBrandHandler}
                    />

                    <TextField
                        size='small'
                        label={t('LICENSE PLATE')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 15 } }}
                        value={licensePlate}
                        onChange={onChangeLicensePlateHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('MODEL')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        value={model}
                        onChange={onChangeModelHandler}
                    />

                    <TextField
                        size='small'
                        label={t('NUMBER')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 15 } }}
                        value={number}
                        onChange={onChangeNumberHandler}
                    />
                </Stack>
            </Stack>
        );
    }, [
        brand, licensePlate, model, number, onChangeBrandHandler, onChangeLicensePlateHandler,
        onChangeModelHandler, onChangeNumberHandler, onChangeStatusHandler, onChangeTypeHandler,
        onChangeVinHandler, status, t, type, vin
    ]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default TransportFilter;