import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { LoadActions } from "../store/reducers/loadSlice";

export const useLoad = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(LoadActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(LoadActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(LoadActions.applyFilter(criteria));
    };

    const stepRefresh = () => {
        dispatch(LoadActions.stepRefresh());
    };

    const palletTypeGridRefresh = () => {
        dispatch(LoadActions.palletTypeGridRefresh());
    };

    const fragilityTypeGridRefresh = () => {
        dispatch(LoadActions.fragilityTypeGridRefresh());
    };

    const addressesGridRefresh = () => {
        dispatch(LoadActions.addressesGridRefresh());
    };

    const itemsGridRefresh = () => {
        dispatch(LoadActions.itemsGridRefresh());
    };

    return {
        resetState, gridRefresh, applyFilter, stepRefresh, palletTypeGridRefresh,
        fragilityTypeGridRefresh, addressesGridRefresh, itemsGridRefresh
    } as const;
};