import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { EPriority } from "../../../models/CommonModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: EPriority;
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    onChange?: (event: SelectChangeEvent) => void;
}
const PrioritySelect = (props: IProps) => {
    const { label = 'Priority', fullWidth = true, required, onChange, size = 'medium' } = props;
    const [selectedValue, setSelectedValue] = useState<string>(props.value ? props.value.toString() : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
    }, [onChange]);

    return (
        <FormControl fullWidth={fullWidth} required={required} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                {
                    Object.keys(EPriority)
                        .filter(key => !isNaN(Number(key)))
                        .map(key => Number(key))
                        .filter(key => key !== EPriority.NONE)
                        .map(key => <MenuItem key={key} value={key}>{EPriority[key]}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
export default PrioritySelect;