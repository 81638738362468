import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PageviewIcon from '@mui/icons-material/Pageview';
import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useCallback, useState } from 'react';
import { IDocumentAttachment } from '../../../models/DocumentModels';
import { EButtonColor } from '../../../models/EnumGeneral';
import FileService from '../../../services/FileService';
import ButtonConfirmation from '../ActionConfirmationComponent/ButtonConfirmation';
import FileViewDialog from '../FileComponent/FileViewDialog';

interface IProps {
    data: IDocumentAttachment[];
    onDeleteBtnClick: (uuid: string) => void;
}
const BaseDocumentItemAttachments = (props: IProps) => {
    const { data, onDeleteBtnClick } = props;

    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [currentAttachment, setCurrentAttachment] = useState<IDocumentAttachment | undefined>();
    const [prevFileViewBtnDisabled, setPrevFileViewBtnDisabled] = useState<boolean>(true);
    const [nextFileViewBtnDisabled, setNextFileViewBtnDisabled] = useState<boolean>(true);

    const closePreviewDialogHandler = useCallback(() => {
        setOpenPreviewDialog(false);
        setCurrentAttachment(undefined);
    }, []);

    const findAttachmentIndex = useCallback((attachmentId: string) => {
        return data.findIndex(item => item.uuid === attachmentId);
    }, [data]);

    const setPrevNextFileViewBtnDisabled = useCallback((attachment: IDocumentAttachment) => {
        const currentIndex: number = findAttachmentIndex(attachment.uuid);
        setPrevFileViewBtnDisabled(currentIndex === 0);
        setNextFileViewBtnDisabled(currentIndex === data.length - 1);
    }, [data.length, findAttachmentIndex]);

    const openPreviewDialogHandler = useCallback((attachment: IDocumentAttachment) => {
        setCurrentAttachment(attachment);
        setPrevNextFileViewBtnDisabled(attachment);
        setOpenPreviewDialog(true);
    }, [setPrevNextFileViewBtnDisabled]);

    const onDownloadBtnClickHandler = useCallback((attachment: IDocumentAttachment) => {
        (async () => {
            const elements = attachment.fileDetails.url.split('/');
            const uuid = elements[elements.length - 1];
            FileService.downloadByUrl(`file/download/${uuid}`);
        })();
    }, []);

    const onDeleteBtnClickHandler = useCallback((attachment: IDocumentAttachment) => {
        onDeleteBtnClick(attachment.uuid);
    }, [onDeleteBtnClick]);

    const onNextFileViewBtnClickHandler = useCallback((fileId: string) => {
        const currentIndex: number = findAttachmentIndex(fileId);
        if (currentIndex < data.length - 1) {
            const nextIndex = currentIndex + 1;
            const attachment: IDocumentAttachment = data[nextIndex];
            setCurrentAttachment(attachment);
            setPrevNextFileViewBtnDisabled(attachment);
        }
    }, [data, findAttachmentIndex, setPrevNextFileViewBtnDisabled]);

    const onPrevFileViewBtnClickHandler = useCallback((fileId: string) => {
        const currentIndex: number = findAttachmentIndex(fileId);
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            const attachment: IDocumentAttachment = data[prevIndex];
            setCurrentAttachment(attachment);
            setPrevNextFileViewBtnDisabled(attachment);
        }
    }, [data, findAttachmentIndex, setPrevNextFileViewBtnDisabled]);

    return (
        <>
            <List>
                {
                    data && data.map((item) =>
                        <ListItem
                            key={`document-attachment-${item.uuid}`}
                            divider
                            disablePadding
                            secondaryAction={
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <IconButton
                                        color='info'
                                        edge='end'
                                        aria-label='preview'
                                        onClick={() => openPreviewDialogHandler(item)}
                                    >
                                        <PageviewIcon />
                                    </IconButton>
                                    <IconButton
                                        color='info'
                                        edge='end'
                                        aria-label='download'
                                        onClick={() => onDownloadBtnClickHandler(item)}
                                    >
                                        <DownloadIcon />
                                    </IconButton>

                                    <ButtonConfirmation
                                        iconBtn
                                        size='medium'
                                        onConfirm={() => onDeleteBtnClickHandler(item)}
                                        icon={<DeleteIcon fontSize='small' />}
                                        color={EButtonColor.error}
                                    />
                                </div>
                            }
                        >
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                                <InsertDriveFileIcon />
                            </ListItemIcon>
                            <ListItemText primary={`File ${item.fileDetails.extension}`} />
                        </ListItem>
                    )
                }
            </List>

            {openPreviewDialog && currentAttachment &&
                <FileViewDialog
                    fileId={currentAttachment.uuid}
                    file={currentAttachment.fileDetails}
                    open={openPreviewDialog}
                    onNextFileViewBtnClick={onNextFileViewBtnClickHandler}
                    nextFileViewBtnDisabled={nextFileViewBtnDisabled}
                    onPrevFileViewBtnClick={onPrevFileViewBtnClickHandler}
                    prevFileViewBtnDisabled={prevFileViewBtnDisabled}
                    onCloseBtnClick={closePreviewDialogHandler}
                />
            }
        </>
    );
}
export default BaseDocumentItemAttachments;