export enum PrivilegeType {
    WRITE = 'WRITE',
    READ = 'READ'
 }

 export enum IAuthority {
   NONE,
	OWNER = 'OWNER',
	ADMINISTRATOR = 'ADMINISTRATOR',
	ACCOUNTANT = 'ACCOUNTANT',
	DISPATCHER = 'DISPATCHER',
	DISPATCHER_ADVANCED = 'DISPATCHER_ADVANCED',
	DRIVER = 'DRIVER'
}

 export interface IPrivilege { 
    id?: string,
    uuid?: string, 
    name: string,
    caption: string,
    type:PrivilegeType,
    allowDelete?:boolean,
    selected?:boolean,
    roleName?: string,
 };

 export interface RoleLinks {
   uuid: string,
   name: string,
   privileges: IPrivilege[]
};