import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../../helpers/generalHelper";
import { currencyFormat, percentFormat } from "../../../../helpers/NumberHelper";
import { EUserIncomeType, IUserIncomeResponseDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";

interface IProps {
    userId: string;
    label?: string;
    required?: boolean;
    value?: string;
    size?: 'medium' | 'small';
    onChange?: (newValue: IUserIncomeResponseDto | null) => void;
}
const UserIncomeAutocomplete = (props: IProps) => {
    const { value, userId, label = 'Income', required = false, size = 'medium', onChange } = props;

    const [selectedValue, setSelectedValue] = useState<IUserIncomeResponseDto | null>(null);
    const [data, setData] = useState<IUserIncomeResponseDto[]>([]);

    useEffect(() => {
        if (isEmpty(data)) {
            (async () => {
                const [, response] = await UserService.fetchIncomesAutocomplete(userId);
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [data, userId]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: IUserIncomeResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }
        }
    }, [value, data]);

    const onChangeHandler = useCallback((event: any, newValue: IUserIncomeResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const adjustAmount = useCallback((item: IUserIncomeResponseDto) => {
        const amount: string = item.type === EUserIncomeType.PERCENTAGE
            ? percentFormat(item.amount)
            : currencyFormat(item.amount);
        return `${item.type.toString().replaceAll('_', ' ')} [ ${item.caption} ${amount} ]`;
    }, []);

    const onBuildRenderOption = useCallback((props: object, item: IUserIncomeResponseDto) => {
        return (
            <div {...props} key={item.uuid}>
                {adjustAmount(item)}
            </div>
        );
    }, [adjustAmount]);

    const onBuildOptionLabel = useCallback((item: IUserIncomeResponseDto) => {
        return adjustAmount(item);
    }, [adjustAmount]);

    const onBuildOptionKey = useCallback((item: IUserIncomeResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default UserIncomeAutocomplete;