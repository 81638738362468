import { Box, Paper } from "@mui/material";
import TransportDescription from "./TransportDescription";

interface IProps {
    transportId: string;
}
const TransportDescriptionStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <TransportDescription {...props} />
            </Box>
        </Paper>
    );
}
export default TransportDescriptionStep;