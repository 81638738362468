import { Box, Paper } from "@mui/material";
import CustomerDocument from "./CustomerDocument";

interface IProps {
    customerId: string;
}
const CustomerDocumentsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '15px 2px 10px 10px', height: 400 }}>
                <CustomerDocument customerId={props.customerId} />
            </Box>
        </Paper>
    );
}
export default CustomerDocumentsStep;