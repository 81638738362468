import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDateAndTimeFormat, getDateFormat } from "../../../helpers/dateHelper";
import { normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { curency } from "../../../helpers/textHelper";
import { IFilter } from '../../../models/CommonModels';
import { ITransportShortResponseDto } from '../../../models/TransportModels';
import { IUserShortResponseDto } from '../../../models/UserModels';
import ActiveLoadService from "../../../services/ActiveLoadService";
import { RootState } from "../../../store/store";
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import DetailsBtn from '../../Base/DetailsBtnComponent/DetailsBtn';
import ActiveLoadDialog from "./ActiveLoadDialog";
import ActiveLoadFilter from "./ActiveLoadFilter";

const ActiveLoadGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.activeLoadSlice.grid);

    const [createDialogToggle, setCreateDialogToggle] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return ActiveLoadService.fetchAllByFilter(filter);
    }, []);

    const onDetailsActionBtnClick = useCallback((uuid: string) => {
        const newUrl = normalizeNavigationUrl(uuid);
        navigate(`/${newUrl}`);
    }, []);

    const onDownloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        return ActiveLoadService.downloadReport(filter);
    }, []);

    const onDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value ? getDateFormat(params.value, dateFormat) : '';
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [dateFormat]);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onActionsColumnRender = useCallback((record: any) => {
        const row = record.row;
        return [
            <DetailsBtn onClick={() => onDetailsActionBtnClick(row.uuid)} />
        ];
    }, [onDetailsActionBtnClick]);

    const buildIconOnCenterColumn = useCallback((icon: any) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                {icon}
            </Box>
        );
    }, []);

    const onFavoriteColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: boolean = params.value;
        return (
            buildIconOnCenterColumn(value
                ? <GradeIcon color='action' fontSize='small' />
                : <GradeOutlinedIcon color='action' fontSize='small' />
            )
        );
    }, [buildIconOnCenterColumn]);

    const onAmountColumnRender = useCallback((value: number) => {
        return value > 0 ? `${value} ${curency}` : '';
    }, []);

    const onStatusColumnRender = useCallback((value: string) => {
        return value.toString().replace('_', ' ');
    }, []);

    const onTransportColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ITransportShortResponseDto[] = params.value;
        const transports: string[] = value.map(transport =>
            `${transport.brand} ${transport.model} [ ${transport.transportNumber} ]`
        );
        const transportValue: string = transports.join(', ');

        return (
            <Tooltip title={transportValue} placement={"bottom"}>
                <span>{transportValue}</span>
            </Tooltip>
        );
    }, []);

    const onDriverColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: IUserShortResponseDto[] = params.value;
        const drivers: string[] = value.map(driver => driver.fullName);
        const driverValue: string = drivers.join(', ');

        return (
            <Tooltip title={driverValue} placement={"bottom"}>
                <span>{driverValue}</span>
            </Tooltip>
        );
    }, []);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onUpdatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const updatedBy: string = params.row.updatedBy;
        const updatedDate: number = params.row.updatedDate;
        if (!updatedBy) {
            return '';
        }

        const dateTime: string = getDateAndTimeFormat(updatedDate, dateFormat, timeFormat);
        const tooltip: string = `${updatedBy} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {updatedBy} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns: GridColDef[] = [{
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 50,
        align: 'center',
        resizable: false,
        disableColumnMenu: true,
        getActions: onActionsColumnRender
    }, {
        field: 'favorite',
        headerName: '',
        width: 50,
        align: 'center',
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: onFavoriteColumnRender
    }, {
        field: 'idno',
        headerName: t('ID#'),
        width: 100,
        headerAlign: 'center',
        resizable: false,
        sortable: false,
        renderCell: onTooltipColumnRender
    }, {
        field: 'name',
        headerName: t('NAME'),
        width: 150,
        headerAlign: 'center',
        sortable: false,
        renderCell: onTooltipColumnRender
    }, {
        field: 'shipDate',
        headerName: t('SHIP DATE'),
        width: 120,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: onDateColumnRender
    }, {
        field: 'deliveryDate',
        headerName: t('DELIVERY DATE'),
        width: 120,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: onDateColumnRender
    }, {
        field: 'pickup',
        headerName: t('PICKUP'),
        flex: 1,
        minWidth: 170,
        headerAlign: 'center',
        sortable: false
    }, {
        field: 'delivery',
        headerName: t('DELIVERY'),
        flex: 1,
        minWidth: 170,
        headerAlign: 'center',
        sortable: false
    }, {
        field: 'customer',
        headerName: t('CUSTOMER & LOAD'),
        width: 320,
        headerAlign: 'center',
        sortable: false
    }, {
        field: 'transports',
        headerName: t('TRANSPORT'),
        width: 250,
        headerAlign: 'center',
        sortable: false,
        renderCell: onTransportColumnRender
    }, {
        field: 'drivers',
        headerName: t('DRIVER'),
        width: 250,
        headerAlign: 'center',
        sortable: false,
        renderCell: onDriverColumnRender
    }, {
        field: 'progress',
        headerName: t('PROGRESS'),
        width: 115,
        headerAlign: 'center',
        sortable: false,
        resizable: false
    }, {
        field: 'amount',
        headerName: t('AMOUNT'),
        width: 135,
        type: 'number',
        headerAlign: 'center',
        sortable: false,
        valueFormatter: onAmountColumnRender
    }, {
        field: 'status',
        headerName: t('STATUS'),
        width: 115,
        headerAlign: 'center',
        sortable: false,
        resizable: false,
        valueFormatter: onStatusColumnRender
    }, {
        field: 'created',
        headerName: t('CREATED'),
        width: 180,
        headerAlign: 'center',
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: onCreatedColumnRender
    }, {
        field: 'updated',
        headerName: t('UPDATED'),
        width: 180,
        headerAlign: 'center',
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: onUpdatedColumnRender
    }];

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialogToggle(createDialogToggle => !createDialogToggle);
    }, []);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(!filterToggle);
    }, [filterToggle]);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnTooltip={t('Create active load')}
                onAddActionBtnClick={createDialogToggleHandler}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search active load(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download active load(s)')}
                downloadActionBtnApi={onDownloadActionBtnApiHandler}
            />

            {filterToggle &&
                <ActiveLoadFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            }

            {createDialogToggle &&
                <ActiveLoadDialog
                    open={createDialogToggle}
                    onCloseBtnClick={createDialogToggleHandler}
                />
            }
        </>
    );
}
export default ActiveLoadGrid;