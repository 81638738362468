import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid, IReduxStep } from "../../models/ReduxModels";

interface LoadState {
    grid: IReduxGrid;
    step: IReduxStep;
    palletTypeGrid: IReduxGrid;
    fragilityTypeGrid: IReduxGrid;
    addressesGrid: IReduxGrid;
    itemsGrid: IReduxGrid;
};

const initialState: LoadState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    step: {
        refresh: false
    },
    palletTypeGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    fragilityTypeGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    addressesGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    itemsGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    }
};

export const LoadSlice = createSlice({
    name: 'loadSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: LoadState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: LoadState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        },
        stepRefresh(state: LoadState) {
            state.step.refresh = !state.step.refresh;
        },
        palletTypeGridRefresh(state: LoadState) {
            state.palletTypeGrid.refresh = !state.palletTypeGrid.refresh;
        },
        fragilityTypeGridRefresh(state: LoadState) {
            state.fragilityTypeGrid.refresh = !state.fragilityTypeGrid.refresh;
        },
        addressesGridRefresh(state: LoadState) {
            state.addressesGrid.refresh = !state.addressesGrid.refresh;
        },
        itemsGridRefresh(state: LoadState) {
            state.itemsGrid.refresh = !state.itemsGrid.refresh;
        }
    }
});

export const LoadActions = LoadSlice.actions;
export const LoadReducer = LoadSlice.reducer;