import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid, IReduxStep } from "../../models/ReduxModels";

interface UserState {
    grid: IReduxGrid;
    step: IReduxStep;
    paysGrid: IReduxGrid;
    incomeGrid: IReduxGrid;
};

const initialState: UserState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    step: {
        refresh: false
    },
    paysGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    incomeGrid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    }
};

export const UserSlice = createSlice({
    name: 'issueSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: UserState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: UserState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        },
        stepRefresh(state: UserState) {
            state.step.refresh = !state.step.refresh;
        },
        paysGridRefresh(state: UserState) {
            state.paysGrid.refresh = !state.paysGrid.refresh;
        },
        incomeGridRefresh(state: UserState) {
            state.incomeGrid.refresh = !state.incomeGrid.refresh;
        }
    }
});

export const UserActions = UserSlice.actions;
export const UserReducer = UserSlice.reducer;