import { Avatar, Box } from "@mui/material";
import { isBlank } from "../../../helpers/textHelper";
import NoAvatarComponent from "./NoAvatarComponent";

const borderStyle = {
	border: '1px dashed silver',
	padding: '5px'
};

interface IProps {
	data?: string;
	online?: boolean;
	width?: string | number;
	height?: string | number;
	variant?: 'circular' | 'square';
	border?: boolean;
}
const AvatarComponent = (props: IProps) => {
	const { data, online, width = '30px', height = '30px', variant = 'circular', border = false } = props;

	const style = {
		width: width,
		height: height
	};

	return (
		isBlank(data)
			?
			<NoAvatarComponent square={variant === 'square'} width={width} height={height} />
			:
			<Box sx={border ? borderStyle : {}}>
				<Avatar variant={variant} sx={style} alt='' src={`data:image/png;base64,${data}`} />
			</Box>
	);
}
export default AvatarComponent;