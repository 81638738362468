import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationHomeUrl } from "../../../helpers/generalHelper";
import { useActiveLoad } from "../../../hooks/useActiveLoad";
import { useNotification } from "../../../hooks/useNotification";
import { IActiveLoadCoverFaceResponseDto } from "../../../models/ActiveLoadModels";
import { IStepItem } from "../../../models/CommonModels";
import ActiveLoadService from "../../../services/ActiveLoadService";
import { RootState } from "../../../store/store";
import BaseStep from "../../Base/BaseStepComponent/BaseStep";
import ActiveLoadStepCoverFace from './ActiveLoadStepCoverFace';
import ActiveLoadDocumentsStep from "./Documents/ActiveLoadDocumentsStep";
import ActiveLoadIssuesStep from "./Issues/ActiveLoadIssuesStep";
import ActiveLoadItemsStep from "./Items/ActiveLoadItemsStep";
import ActiveLoadOverviewStep from "./Overview/ActiveLoadOverviewStep";
import ActiveLoadPathsStep from "./Paths/ActiveLoadPathsStep";
import ActiveLoadPaysStep from "./Pays/ActiveLoadPaysStep";
import ActiveLoadTransportsStep from "./Transport/ActiveLoadTransportsStep";
import ActiveLoadUsersStep from "./Users/ActiveLoadUsersStep";

const ActiveLoadStep = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const navigate = useNavigate();
    const { refresh } = useSelector((state: RootState) => state.activeLoadSlice.step);
    const { gridRefresh } = useActiveLoad();

    const onRetrieveDataApiHandler = useCallback((id: string) => {
        return ActiveLoadService.fetchCoverFaceData(id);
    }, []);

    const onBuildCoverFaceHandler = useCallback((data: IActiveLoadCoverFaceResponseDto): React.ReactNode => {
        return <ActiveLoadStepCoverFace data={data} />;
    }, []);

    const onBuildItemsHandler = useCallback((data: IActiveLoadCoverFaceResponseDto): IStepItem[] => {
        const id: string = data.uuid;
        const readonly: boolean = data.readonly;
        return [{
            title: t('OVERVIEW'),
            content: <ActiveLoadOverviewStep activeLoadId={id} readonly={readonly} />
        }, {
            title: t('ITEMS'),
            content: <ActiveLoadItemsStep />
        }, {
            title: t('PATHS'),
            content: <ActiveLoadPathsStep />,
            disabled: !data.metadata.items
        }, {
            title: t('TRANSPORTS'),
            content: <ActiveLoadTransportsStep
                activeLoadId={id}
                activeLoadStatus={data.status}
            />
        }, {
            title: t('USERS'),
            content: <ActiveLoadUsersStep
                activeLoadId={id}
                activeLoadStatus={data.status}
            />,
            disabled: !data.metadata.transports
        }, {
            title: t('PAYS'),
            content: <ActiveLoadPaysStep activeLoadId={id} />
        }, {
            title: t('ISSUES'),
            content: <ActiveLoadIssuesStep activeLoadId={id} />
        }, {
            title: t('DOCUMENTS'),
            content: <ActiveLoadDocumentsStep activeLoadId={id} />
        }];
    }, [t]);

    const onDeleteBtnClickHandler = useCallback((uuid: string) => {
        (async () => {
            const [error] = await ActiveLoadService.remove(uuid);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
            } else {
                const newUrl: string = normalizeNavigationHomeUrl(true);
                navigate(`/${newUrl}`);
                displayNotification({ message: t('Active load was successfully deleted.') });
                gridRefresh();
            }
        })();
    }, [t]);

    return (
        <BaseStep
            retrieveDataApi={onRetrieveDataApiHandler}
            refresh={refresh}
            buildCoverFace={onBuildCoverFaceHandler}
            buildItems={onBuildItemsHandler}
            onDeleteBtnClick={onDeleteBtnClickHandler}
            deleteBtnTooltip={t('Delete item')}
            closeBtnTooltip={t('Close and return to the main list')}
        />
    );
}
export default ActiveLoadStep;