class LocalStorageService {
    public static get = (key: string): any => {
        return localStorage.getItem(key);
    };

    public static createOrUpdate = (key: string, value: any): void => {
        return localStorage.setItem(key, value);
    };

    public static remove = (key: string): void => {
        return localStorage.removeItem(key);
    };

    public static exists = (key: string): boolean => {
        return LocalStorageService.get(key) !== null;
    };
}
export default LocalStorageService;