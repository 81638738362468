import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { EReputationLevel } from "../../../models/CommonModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: EReputationLevel;
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    onChange?: (event: SelectChangeEvent) => void;
}
const ReputationSelect = (props: IProps) => {
    const { label = 'Reputation', required = false, fullWidth, onChange, size = 'small', value } = props;
    const [selectedValue, setSelectedValue] = useState<string>('');

    useEffect(() => {
        setSelectedValue(value ? value.toString() : '');
    }, [value]);

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
    }, [onChange]);

    return (
        <FormControl fullWidth={fullWidth} required={required} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                {
                    Object.keys(EReputationLevel)
                        .filter(key => isNaN(Number(key)))
                        .filter(key => key !== EReputationLevel[EReputationLevel.NONE])
                        .map(key => <MenuItem key={key} value={key}>{key.toString()}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
export default ReputationSelect;