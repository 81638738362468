import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { useWarehouse } from "../../../hooks/useWarehouse";
import { ECriteriaExpression, EReputationLevel, ICriteria } from "../../../models/CommonModels";
import { EWarehouseStatus, IWarehouseTypeResponseDto } from "../../../models/WarehouseModels";
import { RootState } from "../../../store/store";
import BaseFilter from "../../Base/BaseFilterComponent/BaseFilter";
import LocationAutocomplete from "../../Base/GeolocationComponent/LocationAutocomplete";
import ZipcodeAutocomplete from "../../Base/GeolocationComponent/ZipcodeAutocomplete";
import ReputationSelect from "../../Base/ReputationComponent/ReputationSelect";
import StatusSelect from "../../Base/StatusSelectComponent/StatusSelect";
import WarehouseTypeAutocomplete from "../WarehouseTypeAutocomplete";

interface IProps {
    open: boolean;
    onClose: () => void;
}
const WarehouseFilter = (props: IProps) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { applyFilter } = useWarehouse();
    const { criterias } = useSelector((state: RootState) => state.warehouseSlice.grid);

    const [name, setName] = useState<string>('');
    const [status, setStatus] = useState<EWarehouseStatus | undefined>(undefined);
    const [reputation, setReputation] = useState<EReputationLevel | undefined>(undefined);
    const [types, setTypes] = useState<string[] | undefined>(undefined);
    const [zipcode, setZipcode] = useState<string>('');
    const [location, setLocation] = useState<string>('');

    const statusData = useRef<string[]>(
        Object.keys(EWarehouseStatus)
            .filter(key => isNaN(Number(key)))
            .filter(key => key !== EWarehouseStatus[EWarehouseStatus.NONE])
            .map(key => key.toString())
    );

    const getValueFromCriterias = useCallback((fieldName: string, criterias: ICriteria[]) => {
        return criterias.find((item) => item.property === fieldName)?.value;
    }, []);

    const onInitContentHandler = useCallback(() => {
        setName(getValueFromCriterias('name', criterias) || '');

        const statusStr: string | undefined = getValueFromCriterias('status', criterias);
        setStatus(statusStr as EWarehouseStatus);

        const reputationStr: string | undefined = getValueFromCriterias('reputationLevel', criterias);
        setReputation(reputationStr as EReputationLevel);

        setZipcode(getValueFromCriterias('address.zipcode', criterias) || '');
        setLocation(getValueFromCriterias('address.location.fullName', criterias) || '');

        const typesStr: string | undefined = getValueFromCriterias('types.uuid', criterias);
        setTypes(typesStr as unknown as string[]);
    }, [criterias, getValueFromCriterias]);

    const onChangeNameHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as EWarehouseStatus);
    }, []);

    const onChangeReputationHandler = useCallback((event?: SelectChangeEvent) => {
        setReputation(event?.target.value as unknown as EReputationLevel);
    }, []);

    const onChangeTypesHandler = useCallback((newValue: IWarehouseTypeResponseDto[]) => {
        setTypes(newValue.map(item => item.uuid));
    }, []);

    const onChangeZipcodeHandler = useCallback((value: string | null) => {
        setZipcode(value || '');
    }, []);

    const onChangeLocationHandler = useCallback((value: string | null) => {
        setLocation(value || '');
    }, []);

    const onBuildCriteriaHandler = useCallback((): ICriteria[] => {
        const newCriterias: ICriteria[] = [];

        if (name && !isBlank(name)) {
            newCriterias.push({
                property: 'name',
                value: name,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (status && status !== EWarehouseStatus[EWarehouseStatus.NONE]) {
            newCriterias.push({
                property: 'status',
                value: status.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (reputation && reputation !== EReputationLevel[EReputationLevel.NONE]) {
            newCriterias.push({
                property: 'reputationLevel',
                value: reputation.toString(),
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (zipcode && !isBlank(zipcode)) {
            newCriterias.push({
                property: 'address.zipcode',
                value: zipcode,
                expression: ECriteriaExpression.EQUALS
            });
        }

        if (location && !isBlank(location)) {
            newCriterias.push({
                property: 'address.location.fullName',
                value: location,
                expression: ECriteriaExpression.LIKE
            });
        }

        if (types && !isEmpty(types)) {
            const value: string = typeof types === 'string' ? types : types.join(',');
            newCriterias.push({
                property: 'types.uuid',
                value: value,
                expression: ECriteriaExpression.IN_OR
            });
        }

        return newCriterias;
    }, [location, name, reputation, status, types, zipcode]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={3} direction='row'>
                <Stack width='100%' direction='column' spacing={3}>
                    <TextField
                        size='small'
                        label={t('NAME')}
                        autoComplete='off'
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        value={name}
                        onChange={onChangeNameHandler}
                    />

                    <WarehouseTypeAutocomplete
                        label={t('TYPE')}
                        size='small'
                        value={types}
                        onChange={onChangeTypesHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <ReputationSelect
                        label={t('REPUTATION')}
                        value={reputation}
                        onChange={onChangeReputationHandler}
                    />

                    <ZipcodeAutocomplete
                        size='small'
                        label={t('ZIPCODE')}
                        value={zipcode}
                        onChange={onChangeZipcodeHandler}
                    />
                </Stack>

                <Stack width='100%' direction='column' spacing={3}>
                    <StatusSelect
                        label={t('STATUS')}
                        data={statusData.current}
                        value={status}
                        onChange={onChangeStatusHandler}
                    />

                    <LocationAutocomplete
                        size='small'
                        label={t('LOCATION')}
                        value={location}
                        onChange={onChangeLocationHandler}
                    />
                </Stack>
            </Stack>
        );
    }, [
        location, name, onChangeLocationHandler, onChangeNameHandler, onChangeReputationHandler,
        onChangeStatusHandler, onChangeTypesHandler, onChangeZipcodeHandler, reputation,
        status, t, types, zipcode
    ]);

    const onApplyHandler = useCallback((newCriteria: ICriteria[]) => {
        applyFilter(newCriteria);
    }, [applyFilter]);

    return (
        <BaseFilter
            open={open}
            onClose={onClose}
            title={t('FILTERS')}
            initContent={onInitContentHandler}
            buildContent={onBuildContent}
            buildCriteria={onBuildCriteriaHandler}
            applyBtnLabel={t('APPLY')}
            onApply={onApplyHandler}
            resetBtnLabel={t('RESET')}
        />
    );
}
export default WarehouseFilter;