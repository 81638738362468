import { Box, Paper } from "@mui/material";
import { EActiveLoadStatus } from "../../../../models/ActiveLoadModels";
import ActiveLoadUsersGrid from "./ActiveLoadUsersGrid";

interface IProps {
    activeLoadId: string;
    activeLoadStatus: EActiveLoadStatus;
}
const ActiveLoadUsersStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 300 }}>
                <ActiveLoadUsersGrid
                    activeLoadId={props.activeLoadId}
                    activeLoadStatus={props.activeLoadStatus}
                />
            </Box>
        </Paper>
    );
}
export default ActiveLoadUsersStep;