import { Box, Paper } from "@mui/material";
import CustomerReview from "./CustomerReview";

interface IProps {
    customerId: string;
}
const CustomerReviewsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <CustomerReview {...props} />
            </Box>
        </Paper>
    );
}
export default CustomerReviewsStep;