import { TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";

interface IProps {
    label?: string;
    units?: string;
    scale?: number;
    thousandSeparator?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowNegative?: boolean;
    size?: 'small' | 'medium',
    value?: number;
    onChange?: (value: number) => void;
}
const NumberField = (props: IProps) => {
    const {
        label = 'Amount', units = '', scale = 2, thousandSeparator = true, required = false,
        disabled = false, allowNegative = true, size = 'medium', onChange
    } = props;

    const [value, setValue] = useState<number>(props.value || NaN);

    const onChangeHandler = useCallback((values: NumberFormatValues) => {
        const value: number = values.floatValue !== undefined ? values.floatValue : NaN;

        if (onChange) {
            onChange(value);
        }

        setValue(value);
    }, [onChange]);

    return (
        <NumericFormat
            fullWidth
            label={label}
            required={required}
            disabled={disabled}
            customInput={TextField}
            variant='outlined'
            thousandSeparator={thousandSeparator}
            size={size}
            value={value}
            decimalScale={scale}
            autoComplete='off'
            onValueChange={onChangeHandler}
            allowNegative={allowNegative}
            slotProps={{ input: { startAdornment: <span>{units}&nbsp;</span> } }}
        />
    );
}
export default NumberField;