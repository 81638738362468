import { AppConfig } from "../configs/AppConfig";
import { IJwtRequestDto, IToken } from "../models/AuthModels";
import { API, FetchApi } from "./APIService";
import LocalStorageService from "./LocalStorageService";

class AuthService {
    public static buildTokenFromUrl = (): IToken => {
        const params = window.location.search,
            urlParams = new URLSearchParams(params),
            accessToken = urlParams.get('accessToken'),
            refreshToken = urlParams.get('refreshToken'),
            tokenType = urlParams.get('tokenType'),
            expiryDuration = urlParams.get('expiryDuration');

        return {
            accessToken: accessToken ? accessToken : '',
            expiryDuration: expiryDuration ? Number(expiryDuration) : 0,
            tokenType: tokenType ? tokenType : '',
            refreshToken: refreshToken ? refreshToken : ''
        };
    };

    public static refreshToken = async (data: IJwtRequestDto, oldToken?: string): Promise<any> => {
        const [error, response] = await API('PUT', 'auth/refreshToken', data, undefined, undefined, undefined, oldToken);
        return [error, response];
    };

    public static getTokenFromLocalStorage = (): IToken | null => {
        return LocalStorageService.exists('token') ? JSON.parse(LocalStorageService.get('token')) : null;
    };

    public static saveTokenToLocalStorage = (token: IToken): void => {
        LocalStorageService.createOrUpdate('token', JSON.stringify(token));
    };

    public static isTokenValid = (token: IToken | null): boolean => {
        if (!token) {
            return false;
        }

        const dateNow: number = Date.now();
        return token.expiryDuration > dateNow;
    };

    public static login = (): void => {
        window.location.href = `${AppConfig.baseURL}oauth2/google`;
    };

    public static logout = (): void => {
        FetchApi('POST', 'auth/logout');
        LocalStorageService.remove('token');
        window.location.href = '/login';
    };
}
export default AuthService;