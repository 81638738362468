import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ISocketPersonalChat } from "../../../models/SocketModels";
import { modifyChatLastMessage } from "../../../store/reducers/cnfSlice";
import { AppDispatch } from "../../../store/store";

interface IProps {
    message: ISocketPersonalChat;
}
const SocketPersonalChat = (props: IProps) => {
    const { message } = props;
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        lastMessageHandler(message);
    }, [message]);

    const lastMessageHandler = useCallback((message: ISocketPersonalChat): void => {
        dispatch(modifyChatLastMessage(message));
    }, []);

    return (<></>);
}
export default SocketPersonalChat;