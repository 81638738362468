import { Box, Paper } from "@mui/material";
import { EIssueType } from "../../../../models/IssueModels";
import IssueBusinessGrid from "../../../IssueModule/Business/IssueBusinessGrid";

interface IProps {
    transportId: string;
}
const TransportIssuesStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px', height: 300 }}>
                <IssueBusinessGrid
                    type={EIssueType.TRANSPORT}
                    businessItemId={props.transportId}
                />
            </Box>
        </Paper>
    );
}
export default TransportIssuesStep;