import { Rating } from "@mui/material";
import { useCallback } from "react";

interface IProps {
    value: number;
    size?: 'small' | 'medium' | 'large';
    readonly?: boolean;
    precision?: boolean;
    onChange?: (value: number) => void;
}
const RatingView = (props: IProps) => {
    const { value, size = 'small', readonly = true, precision = true, onChange } = props;

    const onChangeHandler = useCallback((event: React.SyntheticEvent, value: number | null) => {
        if (onChange && value) {
            onChange(value);
        }
    }, [onChange]);

    return (
        <Rating
            key={value}
            value={value}
            size={size}
            precision={precision ? 0.1 : 1}
            readOnly={readonly}
            onChange={onChangeHandler}
        />
    );
}
export default RatingView;