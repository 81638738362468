import { List, ListItem, ListItemText } from "@mui/material";
import { IItemDetails } from "../../../models/CommonModels";

interface IProps {
    data: IItemDetails[];
}
const ItemsDetails = (props: IProps) => {
    const { data } = props;
    return (
        <List>
            {
                data && data.filter((item) => item.hide === undefined || item.hide === false).map((item) =>
                    <ListItem
                        key={`document-item-details-${item.id}-${item.primary}`}
                        divider
                        disablePadding
                    >
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '13px' }}
                            primary={item.primary}
                            secondaryTypographyProps={{ fontSize: '13px', textAlign: item.secondaryTextAlign }}
                            secondary={item.secondary ? item.secondary : 'N/A'}
                        />
                    </ListItem>
                )
            }
        </List>
    );
}
export default ItemsDetails;