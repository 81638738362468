import { IFilter } from "../models/CommonModels";
import { EVehicleType, ITransportDocumentRequestDto, ITransportRequestDto, ITransportStatusRequestDto, IVehicleRequestDto } from "../models/TransportModels";
import { API } from "./APIService";

class TransportService {

  public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
    const [error, response] = await API('POST', 'transport/all', JSON.stringify(filter));
    return [error, response];
  };

  public static downloadReport = async (filter: IFilter): Promise<any> => {
    const [error, response] = await API('POST', 'transport/report', filter, undefined, undefined, 'blob');
    return [error, response];
  };

  public static fetchVehiclesByFilter = async (filter: IFilter): Promise<any> => {
    const [error, response] = await API('POST', 'transport/vehicles/all', JSON.stringify(filter));
    return [error, response];
  };

  public static removeVehicle = async (vehicleId: string): Promise<any> => {
    const [error, response] = await API('DELETE', `transport/vehicles/${vehicleId}`,);
    return [error, response];
  };

  public static createVehicle = async (data: IVehicleRequestDto): Promise<any> => {
    const [error, response] = await API('POST', 'transport/vehicles', data);
    return [error, response];
  };

  public static updateVehicle = async (vehicleId: string, data: IVehicleRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `transport/vehicles/${vehicleId}`, data);
    return [error, response];
  };

  public static fetchVehicleAutocomplete = async (type: EVehicleType): Promise<any> => {
    const [error, response] = await API('GET', `transport/vehicles/type/${type}/autocomplete`);
    return [error, response];
  };

  public static create = async (data: ITransportRequestDto): Promise<any> => {
    const [error, response] = await API('POST', 'transport', JSON.stringify(data));
    return [error, response];
  };

  public static fetchOverview = async (transportId: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/${transportId}/overview`);
    return [error, response];
  };

  public static updateOverview = async (transportId: string, data: ITransportRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `transport/${transportId}/overview`, data);
    return [error, response];
  };

  public static isVinAvailable = async (vin: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/available/vin/${vin}`);
    return [error, response];
  };

  public static isLicensePlateAvailable = async (licensePlate: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/available/licensePlate/${licensePlate}`);
    return [error, response];
  };

  public static isNumberAvailable = async (number: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/available/number/${number}`);
    return [error, response];
  };

  public static fetchCoverFaceData = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/${uuid}/coverFace`);
    return [error, response];
  };

  public static remove = async (uuid: string): Promise<any> => {
    const [error, response] = await API('DELETE', `transport/${uuid}`);
    return [error, response];
  };

  public static createFavorites = async (ids: string[]): Promise<any> => {
    const [error, response] = await API('POST', 'transport/favorite', ids);
    return [error, response];
  };

  public static removeFavorites = async (ids: string[]): Promise<any> => {
    const [error, response] = await API('DELETE', 'transport/favorite', ids);
    return [error, response];
  };

  public static fetchDocuments = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/${uuid}/documents`);
    return [error, response];
  };

  public static createDocument = async (transportId: string, data: ITransportDocumentRequestDto): Promise<any> => {
    const [error, response] = await API('POST', `transport/${transportId}/documents`, data);
    return [error, response];
  };

  public static updateDocument = async (transportId: string, documentId: string, data: ITransportDocumentRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', `transport/${transportId}/documents/${documentId}`, data);
    return [error, response];
  };

  public static removeDocument = async (transportId: string, documentId: string): Promise<any> => {
    const [error, response] = await API('DELETE', `transport/${transportId}/documents/${documentId}`);
    return [error, response];
  };

  public static createDocumentAttachment = async (transportId: string, documentId: string, data: FormData): Promise<any> => {
    const [error, response] = await API('PUT', `transport/${transportId}/documents/${documentId}/attachments`, data, undefined, 'multipart/form-data');
    return [error, response];
  };

  public static removeDocumentAttachment = async (transportId: string, documentId: string, attachmentId: string): Promise<any> => {
    const [error, response] = await API('DELETE', `transport/${transportId}/documents/${documentId}/attachments/${attachmentId}`);
    return [error, response];
  };

  public static fetchDescription = async (transportId: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/${transportId}/description`);
    return [error, response];
  };

  public static updateDescription = async (transportId: string, description?: string): Promise<any> => {
    const [error, response] = await API('PUT', `transport/${transportId}/description`, description);
    return [error, response];
  };

  public static fetchStatus = async (transportId: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/status/${transportId}`);
    return [error, response];
  };

  public static updateStatus = async (data: ITransportStatusRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'transport/status', data);
    return [error, response];
  };

  public static fetchMainImage = async (transportId: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/${transportId}/images/main`);
    return [error, response];
  };

  public static fetchAdditionalImages = async (transportId: string): Promise<any> => {
    const [error, response] = await API('GET', `transport/${transportId}/images/additional`);
    return [error, response];
  };

  public static createImage = async (transportId: string, data: FormData): Promise<any> => {
    const [error, response] = await API('POST', `transport/${transportId}/images`, data, null, 'multipart/form-data');
    return [error, response];
  };

  public static removeImage = async (transportId: string, imageId: string): Promise<any> => {
    const [error, response] = await API('DELETE', `transport/${transportId}/images/${imageId}`);
    return [error, response];
  };

  public static fetchAutocomplete = async (): Promise<any> => {
    const [error, response] = await API('GET', 'transport/autocomplete');
    return [error, response];
};
}
export default TransportService;