import { Divider, Link } from "@mui/material";
import { buildGoogleMapsLink } from "../../../helpers/AddressHelper";
import { IWarehouseResponseDto } from "../../../models/WarehouseModels";

interface IProps {
    data: IWarehouseResponseDto;
}
const WarehouseMapsMarker = (props: IProps) => {
    const { data } = props;
    const addressLink: string = buildGoogleMapsLink(data.address.latitude, data.address.longitude);

    return (
        <div>
            <Link href={addressLink} target='_blank' rel='noreferrer'>
                {data.address.fullAddress}
            </Link>

            <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />

            <div>
                {data.types.map(item => item.name.replaceAll('_', ' ').toUpperCase()).join(' | ')}
            </div>
        </div>
    );
}
export default WarehouseMapsMarker;