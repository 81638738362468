import BasePage from '../components/Base/BasePageComponent/BasePage';
import TransportGrid from '../components/TransportModule/Grid/TransportGrid';
import TransportStep from '../components/TransportModule/Step/TransportStep';
import { useTransport } from '../hooks/useTransport';

const TransportPage = () => {
    const { resetState, applyFilter } = useTransport();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<TransportGrid />}
            stepSection={<TransportStep />}
        />
    );
}
export default TransportPage;