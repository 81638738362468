import { Box, Container, Paper, Stack, styled } from "@mui/material";
import Logo from "../../Base/LogoComponent/Logo";
import LoginDarkLightSwitch from "./LoginDarkLigthSwith";
import LoginGoogleButton from "./LoginGoogleButton";
import LoginHelpPrivacy from "./LoginHelpPrivacy";
import LoginLanguageSelect from "./LoginLanguageSelect";
import LogoMotto from "./LoginMotto";

const styles_container = {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    userSelect: "none",
    width: 700
}));

const styles_box = {
    display: "flex",
    justifyContent: "space-between"
}

const LoginComponent = () => {
    return (
        <Container sx={styles_container}>
            <Stack spacing={2}>
                <Item>
                    <LogoMotto />
                </Item>
                <Item>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                        <Logo width={300} heigth={300} />
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                        <LoginGoogleButton />
                    </Box>
                    <br /><br /><br />
                </Item>
                <Item>
                    <Box sx={styles_box}>
                        <Box sx={{ width: 200, display: "flex", justifyContent: "space-between" }}>
                            <LoginLanguageSelect />
                            <LoginDarkLightSwitch />
                        </Box>
                        <LoginHelpPrivacy />
                    </Box>
                </Item>
            </Stack>
        </Container>
    );
}
export default LoginComponent;