import GoogleIcon from '@mui/icons-material/Google';
import { Button, LinearProgress, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import AuthService from '../../../services/AuthService';
import PublicService from '../../../services/PublicService';
import { RootState } from '../../../store/store';
import { buildClock } from '../../../helpers/dateHelper';

const LoginGoogleButton = () => {
    const online = useSelector((state: RootState) => state.globalDataSlice.online);
    const { t } = useTranslation();
    const [timestamp, setTimestamp] = useState<number>(0);
    const [time, setTime] = useState<string>('');
    const [buttonPress, setButtonPress] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);

    const offlineInit = useCallback(() => {
        const localTime: number = Date.now();
        setTimestamp(localTime);
        setTime(buildClock(localTime));
        setDisabled(true)
    }, []);

    const onlineInit = useCallback(() => {
        (async () => {
            const [error, response] = await PublicService.fetchServerTime();
            if (response) {
                const serverTime: number = response.data.body.serverTime;
                setTimestamp(serverTime);
                setTime(buildClock(serverTime));
                setDisabled(false)
            }

            if (error) {
                offlineInit();
            }
        })();
    }, []);

    useEffect(() => {
        if (online) {
            onlineInit();
        } else {
            offlineInit();
        }
    }, [online]);

    useEffect(() => {
        const frequency: number = 1000;
        const timer = setInterval(() => {
            setTimestamp(value => value + frequency);
            setTime(buildClock(timestamp));
        }, frequency);
        return () => clearInterval(timer);
    }, [timestamp]);

    const onButtonClickHandler = () => {
        try {
            setButtonPress(true);
            AuthService.login();
        } catch (error) {
            setButtonPress(false);
        }
    };

    return (
        <>
            {buttonPress
                ?
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                    <LinearProgress sx={{ width: '200px' }} />
                    <Typography sx={{ fontSize: '11px' }}>{t('Login ...')}</Typography>
                </div>
                :
                <Button
                    disabled={disabled}
                    variant='contained'
                    startIcon={<GoogleIcon />}
                    size='large'
                    onClick={onButtonClickHandler}
                >
                    {t("button_google")} {time}
                </Button>
            }
        </>
    );
}
export default LoginGoogleButton;