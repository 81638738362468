import { Box, Paper } from "@mui/material";
import UserDocument from "./UserDocument";

interface IProps {
    userId: string;
}
const UserDocumentsStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '15px 2px 10px 10px', height: 400 }}>
                <UserDocument userId={props.userId} />
            </Box>
        </Paper>
    );
}
export default UserDocumentsStep;