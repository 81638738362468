import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { useIssue } from "../../../hooks/useIssue";
import { useNotification } from "../../../hooks/useNotification";
import { IActiveLoadAutocompleteResponseDto } from "../../../models/ActiveLoadModels";
import { EPriority } from "../../../models/CommonModels";
import { EIssueStatus, EIssueType, IIssueOverviewResponseDto, IIssueRequestDto } from "../../../models/IssueModels";
import { ITransportAutocompleteResponseDto } from "../../../models/TransportModels";
import { IUserAutocompleteResponseDto } from "../../../models/UserModels";
import IssueService from "../../../services/IssueService";
import ActiveLoadAutocomplete from "../../ActiveLoadModule/ActiveLoadAutocomplete";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import PrioritySelect from "../../Base/PrioritySelectComponent/PrioritySelect";
import TransportAutocomplete from "../../TransportModule/TransportAutocomplete";
import UserAutocomplete from "../../UserModule/UserAutocomplete";
import IssueTypeSelect from "../IssueTypeSelect";

interface IProps {
    open: boolean;
    entity?: IIssueOverviewResponseDto;
    status?: EIssueStatus;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const IssueDialog = (props: IProps) => {
    const { open, entity, status, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const navigate = useNavigate();
    const { gridRefresh, stepRefresh } = useIssue();
    const formId: string = 'issue-form';

    const [loading, setLoading] = useState(false);

    const { register, setValue, getValues, setError, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<IIssueRequestDto>({
        defaultValues: {
            title: entity ? entity.title : '',
            cause: entity ? entity.cause : '',
            priority: entity ? entity.priority : EPriority.LOW,
            assignedOnId: entity && entity.assignedOn ? entity.assignedOn.uuid : undefined,
            type: entity ? entity.type : EIssueType.NONE,
            businessItemId: entity ? entity.businessItemId : undefined
        }
    });

    const createData = useCallback((data: IIssueRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await IssueService.create(data);
            if (response) {
                displayNotification({ message: t('Issue was successfully created.') });

                const uuid = response.data.response.entityId;
                const newUrl: string = normalizeNavigationUrl(uuid);
                navigate(`/${newUrl}`);

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const updateData = useCallback((uuid: string, data: IIssueRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await IssueService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('Issue was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: IIssueRequestDto) => {
        if (entity) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity]);

    const validateTypeField = useCallback((value: EIssueType) => {
        return value !== EIssueType.NONE;
    }, []);

    register('type', { validate: validateTypeField });
    const onChangeTypeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('type', event.target.value as EIssueType, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateBusinessItemIdField = useCallback((value?: string) => {
        return getValues('type') === EIssueType.STANDARD ? true : !isBlank(value);
    }, [getValues]);

    register('businessItemId', { validate: validateBusinessItemIdField });

    const onChangeTransportHandler = useCallback((value: ITransportAutocompleteResponseDto | null) => {
        setValue('businessItemId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onChangeActiveLoadHandler = useCallback((value: IActiveLoadAutocompleteResponseDto | null) => {
        setValue('businessItemId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validatePriorityField = useCallback((value: EPriority) => {
        return EPriority[value] !== undefined && value !== EPriority.NONE;
    }, []);

    register('priority', { validate: validatePriorityField });
    const onPriorityChangeHandler = useCallback((event: SelectChangeEvent) => {
        setValue('priority', Number(event.target.value), {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAssignedOnField = useCallback((value?: string) => {
        if (entity && status === EIssueStatus.IN_PROGRESS) {
            return value !== undefined;
        }
        return true;
    }, [entity, status]);

    register('assignedOnId', { validate: validateAssignedOnField });
    const onAssignedOnChangeHandler = useCallback((value: IUserAutocompleteResponseDto | null) => {
        setValue('assignedOnId', value?.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateTitle = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('title', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validateCause = useCallback((value: string) => {
        if (isBlank(value)) {
            const message: string = t('Invalid value.');
            setError('cause', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <IssueTypeSelect
                        label={t('TYPE')}
                        value={getValues('type')}
                        onChange={onChangeTypeHandler}
                    />

                    {getValues('type') === EIssueType.TRANSPORT &&
                        <TransportAutocomplete
                            label={t('TRANSPORT')}
                            value={getValues('businessItemId')}
                            onChange={onChangeTransportHandler}
                        />
                    }

                    {getValues('type') === EIssueType.ACTIVE_LOAD &&
                        <ActiveLoadAutocomplete
                            label={t('ACTIVE LOAD')}
                            value={getValues('businessItemId')}
                            onChange={onChangeActiveLoadHandler}
                        />
                    }

                    <TextField
                        {...register('title', {
                            required: true,
                            validate: validateTitle
                        })}
                        required
                        label={t('TITLE')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 100 } }}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                    />

                    <TextField
                        {...register('cause', {
                            required: true,
                            validate: validateCause
                        })}
                        required
                        error={!!errors.cause}
                        label={t('CAUSE')}
                        multiline
                        rows={5}
                        slotProps={{ htmlInput: { minLength: 1 } }}
                        helperText={errors.cause?.message}
                    />

                    <Stack direction="row" spacing={2}>
                        <div style={{ width: '200px' }}>
                            <PrioritySelect
                                required
                                label={t('PRIORITY')}
                                value={getValues('priority')}
                                onChange={onPriorityChangeHandler}
                            />
                        </div>

                        <UserAutocomplete
                            label={t('ASSIGNED ON')}
                            value={getValues('assignedOnId')}
                            onChange={onAssignedOnChangeHandler}
                            showStatus
                            disableInactiveItems
                            required={entity && status === EIssueStatus.IN_PROGRESS}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        entity, status, errors.cause, errors.title, getValues, handleSubmit,
        onAssignedOnChangeHandler, onChangeActiveLoadHandler,
        onChangeTransportHandler, onChangeTypeHandler, onPriorityChangeHandler,
        onSubmit, register, t, validateCause, validateTitle
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(entity ? `EDIT ISSUE #${entity.idno}` : 'CREATE ISSUE')}
            maxWidth={'sm'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default IssueDialog;