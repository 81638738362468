import { IAddressRequestDto, IAddressResponseDto } from "../models/AddressModels";
import { API } from "./APIService";

class AddressService {
    public static fetchAllCountries = async (): Promise<any> => {
        const [error, response] = await API("GET", "address/location/country");
        return [error, response];
    };

    public static fetchAllZipcodes = async (): Promise<any> => {
        const [error, response] = await API("GET", "address/zipcode");
        return [error, response];
    };

    public static fetchAllLocations = async (): Promise<any> => {
        const [error, response] = await API("GET", "address/location");
        return [error, response];
    };

    public static fetchOrCreate = async (data: IAddressRequestDto): Promise<any> => {
        const [error, response] = await API("POST", "address", JSON.stringify(data));
        let result: IAddressResponseDto | undefined;
        if (response) {
            result = response.data.body;
        }
        return [error, result];
    };

    public static getAddressById = async (uuid: string): Promise<any> => {
        const [error, response] = await API("GET", "address/" + uuid);
        let result: IAddressResponseDto | undefined;
        if (response) {
            result = response.data.body;
        }
        return [error, result];
    };
}
export default AddressService;
