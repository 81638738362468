import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Divider, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useDispatch } from "react-redux";
import { IPrivilege, PrivilegeType, RoleLinks } from "../../models/PermissionModels";
import PermissionService from "../../services/PermissionsService";
import { AppDispatch } from "../../store/store";

interface IProps {
  filter: string | null,
  reset: boolean,
  snackMsg: (text: string) => void,
}
interface unlinkState {
  roleId: string,
  privilegeId: string,
  privilegeName: string,
  roleName: string,
  type: PrivilegeType,
}
export default function GridPermissionComponent(props: IProps) {
  const { filter, reset } = props
  const [pageC, setPageC] = React.useState(0);
  const [pageSizeC, setPageSizeC] = React.useState(25);

  const [totalRows, setTotalRows] = React.useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const [localReset, setLocalReset] = React.useState<boolean>(false);
  const [linkPrivilege, setLinkPrivilege] = React.useState<RoleLinks[]>([]);
  const [pastSelectedPrivilege, setPastSelectedPrivilege] = React.useState<IPrivilege[]>([])
  const [selectedPrivilege, setSelectedPrivilege] = React.useState<IPrivilege[]>([])
  const [search, setSeacrh] = React.useState<string>("");
  const [unlinkState, setUnlinkState] = React.useState<unlinkState>();

  React.useEffect(() => {
    (async () => {
      const [error, data] = await PermissionService.getAllRoleLinks();
      if (data) {
        let arr = data.data.body as RoleLinks[];
        setLinkPrivilege(arr);
        setTotalRows(data.data.total);
      }
      if (error) {
      }

    })()
    setSeacrh("");
  }, [reset, localReset])

  React.useEffect(() => {
    if (linkPrivilege) {
      let a: IPrivilege[] = []
      for (let item of linkPrivilege) {
        if (item.privileges.length !== 0) {
          for (let itemP of item.privileges) {
            a.push({ name: itemP.name, caption: itemP.caption, id: item.uuid + " " + itemP.uuid, roleName: item.name, type: itemP.type })
          }
        }
      }
      setSelectedPrivilege(a);
      setPastSelectedPrivilege(a);
    }
  }, [linkPrivilege])

  React.useEffect(() => {
    let a: IPrivilege[] = [];
    let b: string = (filter && filter.length !== 0) ? filter : "ALL"
    for (let item of pastSelectedPrivilege) {
      if (item.caption.toUpperCase().indexOf(search.toUpperCase()) > -1) {
        if (item.roleName) {
          if (b.toUpperCase() === "ALL") {
            a.push(item);
          } else if (b.toUpperCase() === "DISPATCHER" && item.roleName.toUpperCase() === "DISPATCHER") {
            a.push(item)
          } else if (item.roleName.toUpperCase().indexOf(b.toUpperCase()) > -1 && b.toUpperCase() !== "DISPATCHER") {
            a.push(item)
          }
        }
      }
    }

    setSelectedPrivilege(a);

  }, [search, filter])
  React.useEffect(() => {
    (async () => {
      if (unlinkState) {
        const [error, response] = await PermissionService.unlinkPrivilege({ roleId: unlinkState.roleId, privilegeId: unlinkState.privilegeId });
        if (response) {
          props.snackMsg("Privilege " + unlinkState.privilegeName + " with type " + unlinkState.type + " was removed from role " + unlinkState.roleName)
        }
        // if (error) {
        //   dispatch(setLastError(error));
        // }
      }
      setLocalReset(!localReset);
    })()

  }, [unlinkState])

  const handlerPageSize = (newPageSize: number) => {
    setPageSizeC(newPageSize);
  }

  const handlerPage = (newPage: number) => {
    setPageC(newPage);
  }

  const handleSearch = (e: any) => {
    let s: string = e.target.value as string;
    setSeacrh(s);
  }
  const handleResetSeacrhBar = () => {
    setSeacrh("");
  }

  const handleUnlink = (cell: any) => {
    let b = cell.id.split(" ");
    setUnlinkState({
      roleId: b[0],
      roleName: cell.row.roleName,
      privilegeName: cell.row.caption,
      privilegeId: b[1],
      type: cell.row.type
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'roleName', headerName: 'role'.toUpperCase(), width: 220,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              {cellValues.row.roleName &&
                <Tooltip
                  title={cellValues.row.roleName.toLocaleUpperCase()}
                  placement={"bottom"}
                  arrow={true}
                >
                  <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.roleName} </Typography>
                </Tooltip>
              }
            </div>
          </>
        );
      }
    },
    {
      field: "privilegeName", headerName: 'privilege'.toUpperCase(), sortable: true, width: 450,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              {cellValues.row.caption &&
                <Tooltip
                  title={String(cellValues.row.caption).toLocaleUpperCase()}
                  placement={"bottom"}
                  arrow={true}
                >
                  <Typography variant={'body2'} fontSize={'11px'}>{String(cellValues.row.caption).toLocaleUpperCase()} </Typography>
                </Tooltip>
              }
            </div>
          </>
        );
      }
    },

    {
      field: "type", headerName: 'type'.toUpperCase(), sortable: true, width: 150,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              {cellValues.row.type &&
                <Tooltip
                  title={String(cellValues.row.type).toLocaleUpperCase()}
                  placement={"bottom"}
                  arrow={true}
                >
                  <Typography variant={'body2'} fontSize={'11px'}>{String(cellValues.row.type).toLocaleUpperCase()} </Typography>
                </Tooltip>
              }
            </div>
          </>
        );
      }
    },
    {
      field: "2", headerName: 'ACTION', width: 100, sortable: false, filterable: false, disableColumnMenu: true,
      renderCell: (cellValues: any) => {


        return (
          <>
            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", padding: 0 }}>
              <Tooltip
                title="Unlink privilege"
                arrow={true}
                placement="top"

              >
                <Box>

                  {/* <SecureDialogComponent
                    answerYES={() => {
                      handleUnlink(cellValues);
                    }}
                    text={''}
                    icon={<DeleteForeverIcon fontSize='small' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                    color={EButtonColor.error}
                    iconButton={true}
                  /> */}

                </Box>

              </Tooltip>


            </div>
          </>
        );
      }
    },


  ];
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>

        <div style={{ display: "flex", gap: "10px", width: "40%" }}>
          <div style={{ display: "flex", width: "80%", backgroundColor: search ? ((selectedPrivilege.length === 0) ? "#f9e4e7" : "white") : "white", fontWeight: "normal" }}>

            <TextField
              value={search}
              inputProps={{ sx: { fontSize: "12px" } }}
              style={{ width: "100%" }}
              label="Search by privilege"
              autoComplete="off"
              onChange={handleSearch}
              size={"small"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize={'small'} />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {search &&
            <Tooltip
              title={"Reset search field"}
              arrow={true}
              placement={"top"}
            >
              <Button
                variant="contained"
                onClick={handleResetSeacrhBar}
              >
                <RotateLeftIcon
                  fontSize="small"
                />
              </Button>
            </Tooltip>
          }
        </div>


      </div>
      <Divider sx={{ paddingTop: "10px" }} />
      <div style={{ height: "92%", paddingTop: "10px" }}>


        <DataGrid
          density={'compact'}
          rows={selectedPrivilege}
          columns={columns}

          // rowsPerPageOptions={[5, 10, 15, 20, 25]}

          // onPageSizeChange={handlerPageSize}
          // onPageChange={handlerPage}

          rowCount={selectedPrivilege.length}

        // page={pageC}
        // pageSize={pageSizeC}


        />
      </div>

    </>
  )

}

