import { TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { ECurrencyChar } from "../../../models/CommonModels";

interface IProps {
    label?: string;
    currency?: ECurrencyChar;
    percent?: boolean;
    scale?: number;
    thousandSeparator?: boolean;
    required?: boolean;
    allowNegative?: boolean;
    value: number;
    onChange?: (value: number) => void;
}
const CurrencyField = (props: IProps) => {
    const {
        label = 'Amount', currency = ECurrencyChar.USD, percent = false, scale = 2,
        thousandSeparator = true, required = false, allowNegative = true, onChange
    } = props;

    const [value, setValue] = useState<number>(props.value);

    const onChangeHandler = useCallback((values: NumberFormatValues) => {
        const value: number = values.floatValue !== undefined ? values.floatValue : NaN;

        if (onChange) {
            onChange(value);
        }

        setValue(value);
    }, [onChange]);

    const isAllowedHandler = useCallback((values: NumberFormatValues) => {
        return values.floatValue && percent ? values.floatValue <= 100 : true;
    }, [percent]);

    return (
        <NumericFormat
            fullWidth
            label={label}
            required={required}
            customInput={TextField}
            variant='outlined'
            thousandSeparator={thousandSeparator}
            value={value}
            decimalScale={scale}
            autoComplete='off'
            onValueChange={onChangeHandler}
            allowNegative={allowNegative}
            isAllowed={isAllowedHandler}
            slotProps={{ input: { startAdornment: <span>{percent ? '%' : currency}&nbsp;</span> } }}
        />
    );
}
export default CurrencyField;