import { Box, Paper } from "@mui/material";
import { EIssueStatus } from "../../../../models/IssueModels";
import IssueOverview from "./IssueOverview";

interface IProps {
    entityId: string;
    status: EIssueStatus;
}
const IssueOverviewStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <IssueOverview {...props} />
            </Box>
        </Paper>
    );
}
export default IssueOverviewStep;