import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Divider, Tooltip } from "@mui/material";
import BackdropProgress from '../BackdropComponent/BackdropProgress';
import styles from './BaseDocument.module.scss';

interface IProps {
    loading?: boolean;
    addBtnLabel?: string;
    addBtnTooltip?: string;
    onAddBtnClick: () => void;

    maxHeight: string | number;
    content: React.ReactNode;
}
const BaseDocument = (props: IProps) => {
    const {
        loading, content, addBtnLabel = 'DOCUMENT', addBtnTooltip = 'Create document',
        onAddBtnClick, maxHeight
    } = props;

    return (
        <Box className={styles.document}>
            <Tooltip title={addBtnTooltip} arrow={true} placement='top'>
                <Button
                    variant='contained'
                    size={'small'}
                    startIcon={<AddIcon fontSize={'small'} />}
                    onClick={onAddBtnClick}
                >
                    {addBtnLabel}
                </Button>
            </Tooltip>

            <Divider className={styles.divider} />

            <BackdropProgress open={loading || false}>
                <Box className={styles.content} sx={{ height: maxHeight, maxHeight: maxHeight }}>
                    <Box className={styles.items}>
                        {content}
                    </Box>
                </Box>
            </BackdropProgress>
        </Box>
    );
}
export default BaseDocument;