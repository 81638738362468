import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertTimestampToDateStrWithFormatOrEmpty } from '../../../helpers/dateHelper';
import { useUser } from '../../../hooks/useUser';
import { ECriteriaExpression, ICriteria, IFilter } from '../../../models/CommonModels';
import { EUserStatus, IUserCoverFaceResponseDto } from '../../../models/UserModels';
import UserService from '../../../services/UserService';
import { RootState } from '../../../store/store';
import AvatarComponent from '../../Base/AvatarComponent/AvatarComponent';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';

interface IProps {
    data: IUserCoverFaceResponseDto;
}
const UserStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { gridRefresh, stepRefresh } = useUser();
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const convertDateToString = useCallback((value?: number) => {
        return convertTimestampToDateStrWithFormatOrEmpty(value, dateFormat);
    }, [dateFormat]);

    const getActiveStatusDate = useCallback((): string => {
        if (data.activeFromDate && data.activeToDate) {
            return `${convertDateToString(data.activeFromDate)} - ${convertDateToString(data.activeToDate)}`;
        }

        return data.activeFromDate ? `${t('FROM ')} ${convertDateToString(data.activeFromDate)}` : `${t('TO ')} ${convertDateToString(data.activeToDate)}`;
    }, [convertDateToString, data.activeFromDate, data.activeToDate, t]);

    const content: React.ReactNode = <Box flex={1}>
        <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
            <AvatarComponent data={data.avatar} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>{data.name}</b>
                </Typography>

                <Typography sx={{ fontSize: '13px', color: `${data.status === EUserStatus.INCOMPLETE ? 'red' : 'black'}` }}>
                    <b>{data.status.toString().replace('_', ' ')}{data.status === EUserStatus.TEMPORARY ? ` ${getActiveStatusDate()}` : ''}</b>
                </Typography>
            </Stack>

            <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>{data.role.toString().replaceAll('_', ' ')}</b>
                </Typography>

                <Typography sx={{ fontSize: '13px' }}>
                    <b>&nbsp;</b>
                </Typography>
            </Stack>
        </Stack>
    </Box>;

    const onFavoriteBtnClickHandler = useCallback(() => {
        (async () => {
            const ids: string[] = [data.uuid];
            if (data.favorite) {
                UserService.removeFavorites(ids);
            } else {
                UserService.createFavorites(ids);
            }
            gridRefresh();
            stepRefresh();
        })();
    }, [data.favorite, data.uuid, gridRefresh, stepRefresh]);

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return UserService.downloadReport(filter);
    }, [data.uuid]);

    return (
        <BaseStepCoverFace
            content={content}

            favoriteBtnAddTooltip={t('Add to favorites')}
            favoriteBtnRemoveTooltip={t('Remove from favorites')}
            favoriteBtn={data.favorite}
            onFavoriteBtnClick={onFavoriteBtnClickHandler}

            downloadBtnTooltip={t('Download user')}
            downloadApi={onDownloadApiHandler}
        />
    );
}
export default UserStepCoverFace;