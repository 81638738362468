import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveLoad } from '../../../hooks/useActiveLoad';
import { EActiveLoadStatus, IActiveLoadCoverFaceResponseDto } from '../../../models/ActiveLoadModels';
import { ECriteriaExpression, ICriteria, IFilter } from '../../../models/CommonModels';
import ActiveLoadService from '../../../services/ActiveLoadService';
import BaseStepCoverFace from '../../Base/BaseStepComponent/BaseStepCoverFace';

interface IProps {
    data: IActiveLoadCoverFaceResponseDto;
}
const ActiveLoadStepCoverFace = (props: IProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const { gridRefresh, stepRefresh } = useActiveLoad();

    const content: React.ReactNode = <Box flex={1}>
        <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
            <AccountTreeIcon style={{ color: 'rgb(106, 104, 104)' }} />

            <Stack direction='column' spacing={0.5}>
                <Typography>
                    <b>{data.idno}</b>
                </Typography>

                <Typography sx={{ marginTop: '5px', fontSize: '13px', color: `${data.status === EActiveLoadStatus.INCOMPLETE ? 'red' : 'black'}` }}>
                    <b>{data.status.toString().replace('_', ' ')}</b>
                </Typography>
            </Stack>

            {data.name &&
                <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px', marginRight: '10px' }} />
            }

            {data.name &&
                <Stack direction='column' spacing={0.5}>
                    <Typography>
                        <b>{data.name}</b>
                    </Typography>

                    <Typography sx={{ fontSize: '13px' }}>
                        <b>&nbsp;</b>
                    </Typography>
                </Stack>
            }
        </Stack>
    </Box>;

    const onFavoriteBtnClickHandler = useCallback(() => {
        (async () => {
            const ids: string[] = [data.uuid];
            if (data.favorite) {
                ActiveLoadService.removeFavorites(ids);
            } else {
                ActiveLoadService.createFavorites(ids);
            }
            gridRefresh();
            stepRefresh();
        })();
    }, [data.favorite, data.uuid, gridRefresh, stepRefresh]);

    const onDownloadApiHandler = useCallback((filter: IFilter) => {
        const criterias: ICriteria[] = [{
            property: "uuid",
            value: data.uuid,
            expression: ECriteriaExpression.EQUALS
        }];
        filter.criteria = criterias;

        return ActiveLoadService.downloadReport(filter);
    }, [data.uuid]);

    return (
        <BaseStepCoverFace
            content={content}

            favoriteBtnAddTooltip={t('Add to favorites')}
            favoriteBtnRemoveTooltip={t('Remove from favorites')}
            favoriteBtn={data.favorite}
            onFavoriteBtnClick={onFavoriteBtnClickHandler}

            downloadBtnTooltip={t('Download active load')}
            downloadApi={onDownloadApiHandler}
        />
    );
}
export default ActiveLoadStepCoverFace;