import { Autocomplete, AutocompleteRenderInputParams, createFilterOptions, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { EBrandType, IBrandResponseDto } from "../../../models/MerchandiseModels";
import MerchandiseService from "../../../services/MerchandiseService";

const filter = createFilterOptions<IBrandResponseDto>();

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    disabled?: boolean;
    size?: 'medium' | 'small';
    type: EBrandType;
    onInit?: (value: IBrandResponseDto | null) => void;
    onChange?: (newValue: IBrandResponseDto | null) => void;
}
const BrandAutocomplete = (props: IProps) => {
    const { size = 'medium', disabled, label = 'Brand', required = false, onChange, onInit, value, type } = props;

    const [data, setData] = useState<IBrandResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<IBrandResponseDto | null>(null);

    useEffect(() => {
        setData([]);
        setSelectedValue(null);

        if (EBrandType[type] !== undefined && type !== EBrandType.NONE) {
            (async () => {
                const [, response] = await MerchandiseService.fetchBrands(type);
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [type]);

    useEffect(() => {
        const defaultSelectedValue: IBrandResponseDto = {
            uuid: '',
            name: value || '',
            type: EBrandType.NONE
        };

        if (!isEmpty(data)) {
            if (value) {
                let selectedValue: IBrandResponseDto | null = data.find((item) => item.name === value) || null;
                if (!selectedValue) {
                    data.push(defaultSelectedValue);
                    selectedValue = defaultSelectedValue;
                }
                setSelectedValue(selectedValue);
            } else {
                setSelectedValue(null);
            }

            if (onInit) {
                onInit(selectedValue);
            }
        } else {
            data.push(defaultSelectedValue);
            setSelectedValue(defaultSelectedValue);
        }
    }, [value, data, onInit, selectedValue]);

    const onChangeHandler = useCallback((event: any, newValue: IBrandResponseDto | null | string) => {
        const newSelectedValue: IBrandResponseDto | null = typeof newValue === 'string'
            ? { uuid: '', name: newValue, type: type }
            : newValue;

        if (!isBlank(newSelectedValue?.name) && type !== EBrandType.NONE) {
            if (newSelectedValue && isBlank(newSelectedValue.uuid)) {
                data.push(newSelectedValue);
            }

            setSelectedValue(newSelectedValue);
            if (onChange) {
                onChange(newSelectedValue);
            }
        }
    }, [data, onChange, type]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: IBrandResponseDto) => {
        return (
            <Typography {...props} key={item.uuid} variant='body2'>{item.name}</Typography>
        );
    }, []);

    const onBuildOptionLabel = useCallback((item: IBrandResponseDto | string) => {
        return typeof item === 'string' ? item : item.name;
    }, []);

    const onBuildOptionKey = useCallback((item: IBrandResponseDto | string) => {
        return typeof item === 'string' ? item : item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    const onFilterOptions = useCallback((options: any, params: any) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        const isExisting: boolean = options.some((option: IBrandResponseDto) => inputValue === option.name);
        if (!isBlank(inputValue) && !isExisting) {
            filtered.push({
                uuid: '',
                name: inputValue,
                type: type
            });
        }

        return filtered;
    }, [type]);

    return (
        <Autocomplete
            freeSolo
            handleHomeEndKeys
            clearOnBlur
            selectOnFocus
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={onFilterOptions}
            disabled={disabled}
        />
    );
}
export default BrandAutocomplete;