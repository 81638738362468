import { Box, Paper } from "@mui/material";
import WarehouseDescription from "./WarehouseDescription";

interface IProps {
    warehouseId: string;
}
const WarehouseDescriptionStep = (props: IProps) => {
    return (
        <Paper sx={{ marginTop: '10px' }}>
            <Box sx={{ padding: '10px' }}>
                <WarehouseDescription {...props} />
            </Box>
        </Paper>
    );
}
export default WarehouseDescriptionStep;