import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationUrl, removeEmptyFields } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { useTransport } from "../../../hooks/useTransport";
import { EVehicleType, ITransportOverviewResponseDto, ITransportRequestDto, IVehicleAutocompleteResponseDto } from "../../../models/TransportModels";
import TransportService from "../../../services/TransportService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import TransportTypeSelect from "../TransportTypeSelect";
import VehicleAutocomplete from "../Vehicle/VehicleAutocomplete";

interface IProps {
    open: boolean;
    entity?: ITransportOverviewResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const TransportDialog = (props: IProps) => {
    const { open, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const isEdit = useRef<boolean>(entity !== undefined);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useTransport();
    const formId: string = 'transport-form';

    const [loading, setLoading] = useState(false);
    const [type, setType] = useState<EVehicleType>(entity ? entity?.vehicle.type : EVehicleType.NONE);

    const { register, setValue, getValues, setError, clearErrors, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<ITransportRequestDto>({
        defaultValues: {
            vehicleId: isEdit.current ? entity?.vehicle.uuid : '',
            vin: isEdit.current ? entity?.vin : '',
            licensePlate: isEdit.current ? entity?.licensePlate : '',
            transportNumber: isEdit.current ? entity?.transportNumber : '',
            color: isEdit.current ? entity?.color : ''
        }
    });

    const updateData = useCallback((uuid: string, data: ITransportRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('Transport was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [t]);

    const createData = useCallback((data: ITransportRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await TransportService.create(data);
            if (response) {
                displayNotification({ message: t('Transport was successfully created.') });

                const uuid = response.data.response.entityId;
                const newUrl: string = normalizeNavigationUrl(uuid);
                navigate(`/${newUrl}`);

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [t]);

    const onSubmit = useCallback((data: ITransportRequestDto) => {
        const normalisedData: ITransportRequestDto = removeEmptyFields(data) as unknown as ITransportRequestDto;
        if (entity) {
            updateData(entity.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [createData, entity, updateData]);

    const onTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        setType(event.target.value as EVehicleType);
    }, []);

    const validateVehicleField = useCallback((value?: string) => {
        return !isBlank(value);
    }, []);

    register('vehicleId', { validate: validateVehicleField });
    const onVehicleChangeHandler = useCallback((value: IVehicleAutocompleteResponseDto | null) => {
        setValue('vehicleId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateVinField = useCallback((value: string) => {
        if (isBlank(value)) {
            return false;
        }

        if (!isEdit.current || (entity?.vin !== value)) {
            (async () => {
                const [, response] = await TransportService.isVinAvailable(value);
                if (response) {
                    const available: boolean = response.data.body;
                    if (!available) {
                        const message: string = t('The VIN is in use.');
                        setError('vin', { message: message });
                        return false;
                    }
                }
            })();
        }

        clearErrors('vin');
        return true;
    }, [clearErrors, entity?.vin, setError, t]);

    const validateLicensePlateField = useCallback((value: string) => {
        if (isBlank(value)) {
            return false;
        }

        if (!isEdit.current || (entity?.licensePlate !== value)) {
            (async () => {
                const [, response] = await TransportService.isLicensePlateAvailable(value);
                if (response) {
                    const available: boolean = response.data.body;
                    if (!available) {
                        const message: string = t('The license plate is in use.');
                        setError('licensePlate', { message: message });
                        return false;
                    }
                }
            })();
        }

        clearErrors('licensePlate');
        return true;
    }, [clearErrors, entity?.licensePlate, setError, t]);

    const validateNumberField = useCallback((value: string) => {
        if (isBlank(value)) {
            return false;
        }

        if (!isEdit.current || (entity?.transportNumber !== value)) {
            (async () => {
                const [, response] = await TransportService.isNumberAvailable(value);
                if (response) {
                    const available: boolean = response.data.body;
                    if (!available) {
                        const message: string = t('The number is in use.');
                        setError('transportNumber', { message: message });
                        return false;
                    }
                }
            })();
        }

        clearErrors('transportNumber');
        return true;
    }, [clearErrors, entity?.transportNumber, setError, t]);

    const validateColorField = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('color', { message: message });
            return false;
        }
        clearErrors('color');
        return true;
    }, [clearErrors, setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <TransportTypeSelect
                        required
                        label={t('TYPE')}
                        value={type}
                        onChange={onTypeChangeHandler}
                    />

                    <VehicleAutocomplete
                        required
                        label={t('VEHICLE')}
                        type={type}
                        showDetails
                        value={getValues('vehicleId')}
                        onChange={onVehicleChangeHandler}
                    />

                    <TextField
                        {...register('vin', { validate: validateVinField })}
                        label={t('VIN')}
                        required
                        fullWidth
                        slotProps={{htmlInput: {minLength: 1, maxLength: 21}}}
                        autoComplete='off'
                        error={!!errors.vin}
                        helperText={errors.vin?.message}
                    />

                    <TextField
                        {...register('licensePlate', { validate: validateLicensePlateField })}
                        label={t('LICENSE PLATE')}
                        required
                        fullWidth
                        slotProps={{htmlInput: {minLength: 1, maxLength: 15}}}
                        autoComplete='off'
                        error={!!errors.licensePlate}
                        helperText={errors.licensePlate?.message}
                    />

                    <TextField
                        {...register('transportNumber', { validate: validateNumberField })}
                        label={t('NUMBER')}
                        required
                        fullWidth
                        slotProps={{htmlInput: {minLength: 1, maxLength: 15}}}
                        autoComplete='off'
                        error={!!errors.transportNumber}
                        helperText={errors.transportNumber?.message}
                    />

                    <TextField
                        {...register('color', { validate: validateColorField })}
                        label={t('COLOR')}
                        fullWidth
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 15 } }}
                        autoComplete='off'
                        error={!!errors.color}
                        helperText={errors.color?.message}
                    />
                </Stack>
            </form>
        );
    }, [
        errors.color, errors.licensePlate, errors.transportNumber, errors.vin, getValues,
        handleSubmit, onSubmit, onTypeChangeHandler, onVehicleChangeHandler, register, t,
        type, validateColorField, validateLicensePlateField, validateNumberField, validateVinField
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} TRANSPORT`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default TransportDialog;