import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICriteria } from "../../models/CommonModels";
import { IReduxGrid, IReduxStep } from "../../models/ReduxModels";

interface WarehouseState {
    grid: IReduxGrid;
    step: IReduxStep;
};

const initialState: WarehouseState = {
    grid: {
        refresh: false,
        unselectRows: false,
        criterias: []
    },
    step: {
        refresh: false
    }
};

export const WarehouseSlice = createSlice({
    name: 'warehouseSlice',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        gridRefresh(state: WarehouseState) {
            state.grid.refresh = !state.grid.refresh;
        },
        applyFilter(state: WarehouseState, action: PayloadAction<ICriteria[]>) {
            state.grid.criterias = action.payload;
        },
        stepRefresh(state: WarehouseState) {
            state.step.refresh = !state.step.refresh;
        }
    }
});

export const WarehouseActions = WarehouseSlice.actions;
export const WarehouseReducer = WarehouseSlice.reducer;