import { configureStore } from '@reduxjs/toolkit'
import { ActiveLoadReducer } from './reducers/activeLoadSlice'
import auditSlice from './reducers/auditSlice'
import cnfSlice from './reducers/cnfSlice'
import { CustomerReducer } from './reducers/customerSlice'
import favoritesSlice from './reducers/favoritesSlice'
import { GlobalDataReducer } from './reducers/globalDataSlice'
import { IssueReducer } from './reducers/issueSlice'
import { LoadReducer } from './reducers/loadSlice'
import { NotificationReducer } from './reducers/notificationSlice'
import { TransportReducer } from './reducers/transportSlice'
import { UserProfileReducer } from './reducers/userProfileSlice'
import { UserReducer } from './reducers/userSlice'
import { WarehouseReducer } from './reducers/warehouseSlice'

export const store = configureStore({
  reducer: {
    cnfSlice,
    favoritesSlice,
    auditSlice,
    notification: NotificationReducer,
    globalDataSlice: GlobalDataReducer,
    userProfileSlice: UserProfileReducer,
    transportSlice: TransportReducer,
    warehouseSlice: WarehouseReducer,
    customerSlice: CustomerReducer,
    loadSlice: LoadReducer,
    activeLoadSlice: ActiveLoadReducer,
    issueSlice: IssueReducer,
    userSlice: UserReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch