import { createContext, ReactNode, useEffect, useState } from "react";
import { IToken } from "../models/AuthModels";
import AuthService from "../services/AuthService";

type Props = {
    children?: ReactNode;
}

type IAuthContext = {
    authenticated: boolean;
    token: IToken | null;
    setToken: (newToken: IToken | null) => void;
}

const initialAuthContext: IAuthContext = {
    authenticated: false,
    token: null,
    setToken: () => { }
}

const AuthContext = createContext<IAuthContext>(initialAuthContext);

const AuthProvider = ({ children }: Props) => {
    const [token, setToken] = useState<IToken | null>(AuthService.getTokenFromLocalStorage());
    const [authenticated, setAuthenticated] = useState<boolean>(AuthService.isTokenValid(token));

    useEffect(() => {
        const validToken: boolean = AuthService.isTokenValid(token);
        setAuthenticated(validToken);

        if (token && !validToken) {
            AuthService.logout();
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ authenticated, token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};
export { AuthContext, AuthProvider };
