import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, AutocompleteRenderGetTagProps, AutocompleteRenderOptionState, Checkbox, Chip, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from '../../helpers/generalHelper';
import { IWarehouseTypeResponseDto } from "../../models/WarehouseModels";
import WarehouseService from '../../services/WarhouseService';

interface IProps {
    label?: string;
    required?: boolean;
    value?: string[];
    size?: 'medium' | 'small';
    onInit?: (value: IWarehouseTypeResponseDto[]) => void;
    onChange?: (newValue: IWarehouseTypeResponseDto[]) => void;
}
const WarehouseTypeAutocomplete = (props: IProps) => {
    const { size = 'medium', label = 'Warehouse Type', required = false, onChange, onInit, value } = props;

    const [data, setData] = useState<IWarehouseTypeResponseDto[]>([]);
    const [selectedValues, setSelectedValues] = useState<IWarehouseTypeResponseDto[]>([]);

    useEffect(() => {
        if (isEmpty(data)) {
            (async () => {
                const [, response] = await WarehouseService.fetchTypes();
                if (response) {
                    setData(response.data.body);
                }
            })();
        }
    }, [data]);

    useEffect(() => {
        if (!isEmpty(data) && (value && !isEmpty(value))) {
            const selectedValues: IWarehouseTypeResponseDto[] = data.filter((item) => value.includes(item.uuid));

            setSelectedValues(selectedValues);
            if (onInit) {
                onInit(selectedValues);
            }

        }
    }, [data, onInit, value]);

    const onChangeHandler = useCallback((event: any, newValue: IWarehouseTypeResponseDto[]) => {
        setSelectedValues(newValue);

        if (onChange) {
            onChange(newValue);
        }

    }, [onChange]);

    const onBuildOptionLabel = useCallback((item: IWarehouseTypeResponseDto) => {
        return item.name;
    }, []);

    const onBuildOptionKey = useCallback((item: IWarehouseTypeResponseDto) => {
        return item.uuid;
    }, []);

    const onBuildRenderOption = useCallback((params: any, option: IWarehouseTypeResponseDto, state: AutocompleteRenderOptionState) => {
        const { key, ...rest } = params;
        return (
            <li key={option.uuid} {...rest}>
                <Checkbox
                    key={`ch-${params}`}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={state.selected}
                />
                {option.name.replaceAll('_', ' ')}
            </li>
        );
    }, []);

    const onBuildRenderInput = useCallback((params: any) => {
        return (
            <TextField {...params}
                fullWidth
                autoComplete='off'
                label={label}
                required={required}
                slotProps={{
                    input: { ...params.InputProps, required: isEmpty(selectedValues) }
                }}
            />
        );
    }, [label, required, selectedValues]);

    const onRenderChip = useCallback((item: IWarehouseTypeResponseDto, params: any) => {
        const { key, ...tagProps } = params;
        return <Chip
            variant='outlined'
            color='default'
            size='small'
            key={key}
            label={item.name.replaceAll('_', ' ')}
            {...tagProps}
        />;
    }, []);

    const onRenderTags = useCallback((value: IWarehouseTypeResponseDto[], getTagProps: AutocompleteRenderGetTagProps) => {
        return value.map((item: IWarehouseTypeResponseDto, index: number) => onRenderChip(item, getTagProps({ index })));
    }, [onRenderChip]);

    const isOptionEqualToValue = useCallback((option: IWarehouseTypeResponseDto, value: IWarehouseTypeResponseDto) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            size={size}
            options={data}
            value={selectedValues}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            renderTags={onRenderTags}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default WarehouseTypeAutocomplete;