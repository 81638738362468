import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import QuizIcon from '@mui/icons-material/Quiz';
import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EExtension } from '../../../models/FileModel';
import { EPublicAttachmentType, IPublicAttachment } from '../../../models/PublicModels';
import PublicService from '../../../services/PublicService';
import FileViewOnlyDialog from '../../Base/FileComponent/FileViewOnlyDialog';

const LoginHelpPrivacy = () => {
    const [helpFile, setHelpFile] = useState<IPublicAttachment>();
    const [privacyFile, setPrivacyFile] = useState<IPublicAttachment>();
    const [data, setData] = useState<{ value: string; extension: EExtension; }>({
        value: '',
        extension: EExtension.JPEG
    });
    const [dialogToggle, setDialogToggle] = useState<boolean>(false);

    const { t } = useTranslation();

    const fetchHelpData = useCallback(() => {
        (async () => {
            const [, response] = await PublicService.fetchAttachmentFile(EPublicAttachmentType.HELP);
            if (response) {
                setHelpFile(response.data.body);
            }
        })();
    }, []);

    const fetchPrivacyData = useCallback(() => {
        (async () => {
            const [, response] = await PublicService.fetchAttachmentFile(EPublicAttachmentType.PRIVACY);
            if (response) {
                setPrivacyFile(response.data.body);
            }
        })();
    }, []);

    useEffect(() => {
        fetchHelpData();
        fetchPrivacyData();
    }, [fetchHelpData, fetchPrivacyData])

    const onReadData = useCallback((type: EPublicAttachmentType, extension: EExtension) => {
        (async () => {
            const [, response] = await PublicService.readAttachmentFile(type);
            if (response) {
                setData({ value: response.data, extension: extension });
                setDialogToggle(true);
            }
        })();
    }, []);

    const onDialogToggleHandler = useCallback(() => {
        setDialogToggle(!dialogToggle);
    }, [dialogToggle]);

    return (
        <>
            <Box sx={{ width: 300, display: "flex", justifyContent: "flex-end" }}>
                {helpFile &&
                    <Button
                        color="info"
                        startIcon={<QuizIcon />}
                        onClick={() => onReadData(EPublicAttachmentType.HELP, helpFile.extension)}
                        size="large"
                    >
                        {t('HELP')}
                    </Button>
                }
                {privacyFile &&
                    <Button
                        color="info"
                        startIcon={<PictureAsPdfIcon />}
                        onClick={() => onReadData(EPublicAttachmentType.PRIVACY, privacyFile.extension)}
                        size="large"
                    >
                        {t("PRIVACY")}
                    </Button>
                }
            </Box>

            {dialogToggle &&
                <FileViewOnlyDialog
                    data={data.value}
                    extension={data.extension}
                    open={dialogToggle}
                    onCloseBtnClick={onDialogToggleHandler}
                />
            }
        </>
    );
}
export default LoginHelpPrivacy;