import { Navigate, Route, Routes } from "react-router-dom";
import AuthInitConfig from "../components/AuthModule/Control/AuthInitConfig";
import AuthRefreshToken from "../components/AuthModule/Control/AuthRefreshToken";
import AuthSocket from "../components/AuthModule/Socket/AuthSocket";
import ActiveLoadPage from "../pages/ActiveLoadPage";
import AuditPage from "../pages/AuditPage";
import CustomerPage from "../pages/CustomerPage";
import IssuePage from "../pages/IssuePage";
import LayoutPage from "../pages/LayoutPage";
import LoadPage from "../pages/LoadPage";
import LogoutPage from "../pages/LogoutPage";
import NotificationPage from "../pages/NotificationPage";
import PermissionPage from "../pages/PermissionsPage";
import RoutePage from "../pages/RoutePage";
import SettingsPage from "../pages/SettingsPage";
import TransportPage from "../pages/TransportPage";
import UserPage from "../pages/UserPage";
import WarehousePage from "../pages/WarehousePage";

const PrivateRoutes = () => {
    return (
        <>
            <AuthRefreshToken />
            <AuthSocket />
            <AuthInitConfig />

            <Routes>
                <Route path="/" element={<LayoutPage />}>
                    <Route index element={<div></div>} />
                    <Route path="logout" element={<LogoutPage />} />
                    <Route path="settings/:id" element={<SettingsPage />} />
                    <Route path="audit" element={<AuditPage />} />
                    <Route path="user" element={<UserPage />} />
                    <Route path="user/:id" element={<UserPage />} />
                    <Route path='user/:id/:step' element={<UserPage />} />
                    <Route path="transport" element={<TransportPage />} />
                    <Route path="transport/:id" element={<TransportPage />} />
                    <Route path='transport/:id/:step' element={<TransportPage />} />
                    <Route path='load' element={<LoadPage />} />
                    <Route path='load/:id' element={<LoadPage />} />
                    <Route path='load/:id/:step' element={<LoadPage />} />
                    <Route path='activeLoad' element={<ActiveLoadPage />} />
                    <Route path='activeLoad/:id' element={<ActiveLoadPage />} />
                    <Route path='activeLoad/:id/:step' element={<ActiveLoadPage />} />
                    <Route path="notification" element={<NotificationPage />} />
                    <Route path="issue" element={<IssuePage />} />
                    <Route path="issue/:id" element={<IssuePage />} />
                    <Route path="issue/:id/:step" element={<IssuePage />} />
                    <Route path="customer" element={<CustomerPage />} />
                    <Route path="customer/:id" element={<CustomerPage />} />
                    <Route path='customer/:id/:step' element={<CustomerPage />} />
                    <Route path="warehouse" element={<WarehousePage />} />
                    <Route path="warehouse/:id" element={<WarehousePage />} />
                    <Route path='warehouse/:id/:step' element={<WarehousePage />} />
                    <Route path="route" element={<RoutePage />} />
                    <Route path="route/:id" element={<RoutePage />} />
                    <Route path="permissions" element={<PermissionPage />} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </>
    );
}
export default PrivateRoutes;