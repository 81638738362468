import { Button, IconButton, Popover, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { generateNumber } from "../../../helpers/generalHelper";
import { EButtonColor } from "../../../models/EnumGeneral";

export interface IProps {
    icon?: JSX.Element;
    label?: string;
    color?: EButtonColor;
    disabled?: boolean;
    tooltip?: string;
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    message?: string;
    iconBtn?: boolean;
    onConfirm: () => void;
}
const ButtonConfirmation = (props: IProps) => {
    const {
        disabled, onConfirm, tooltip, variant = 'contained', size = 'small',
        color = EButtonColor.info, icon, label, iconBtn = false,
        message = 'To confirm the action, enter the red text in the field below'
    } = props;

    const [confirmationArea, setConfirmationArea] = useState<HTMLButtonElement | null>(null);
    const [code, setCode] = useState<string>('');
    const [value, setValue] = useState<string>('');

    const onClickHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setCode(generateNumber(100, 9999).toString());
        setConfirmationArea(event.currentTarget);
    }, []);

    const closeConfirmationArea = useCallback(() => {
        setConfirmationArea(null);
        setValue('');
    }, []);

    const onValueChangeHandler = useCallback((event: any) => {
        const value = event.target.value;
        setValue(value);
        if (value.trim() === code) {
            onConfirm();
            closeConfirmationArea();
        }
    }, [closeConfirmationArea, code, onConfirm]);

    return (
        <>
            < Tooltip title={tooltip} placement='top' >
                <span>
                    {
                        iconBtn
                            ?
                            <IconButton
                                size={size}
                                color={color}
                                disabled={disabled}
                                onClick={onClickHandler}
                            >
                                {icon && icon}
                            </IconButton>
                            :
                            <Button
                                size={size}
                                variant={variant}
                                color={color}
                                disabled={disabled}
                                onClick={onClickHandler}
                            >
                                {icon && icon}
                                &nbsp;
                                {label && label}
                            </Button>
                    }
                </span>
            </Tooltip >

            <Popover
                open={Boolean(confirmationArea)}
                anchorEl={confirmationArea}
                onClose={closeConfirmationArea}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
            >
                <div style={{ display: 'flex', width: '180px', padding: '20px', flexDirection: 'column', gap: '10px' }}>
                    <div>
                        <span style={{ fontSize: '12px' }}>{message}: <b style={{ color: 'red' }}>{code}</b></span>
                    </div>
                    <div>
                        <input value={value} onChange={onValueChangeHandler} autoFocus style={{ width: '100px' }} />
                    </div>
                </div>
            </Popover>
        </>
    );
}
export default ButtonConfirmation;