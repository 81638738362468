import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { EIssueType } from "../../models/IssueModels";

interface IProps {
    label?: string;
    required?: boolean;
    value?: EIssueType;
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    onChange?: (event: SelectChangeEvent) => void;
}
const IssueTypeSelect = (props: IProps) => {
    const { value, label = 'Type', fullWidth, required, onChange, size = 'medium' } = props;
    const [selectedValue, setSelectedValue] = useState<string>(value ? value.toString() : '');

    const onChangeHandler = useCallback((event: SelectChangeEvent) => {
        setSelectedValue(event.target.value);

        if (onChange) {
            onChange(event);
        }
    }, [onChange]);

    return (
        <FormControl fullWidth={fullWidth} required={required} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} label={label} onChange={onChangeHandler}>
                <MenuItem value=''><em>None</em></MenuItem>
                {
                    Object.keys(EIssueType)
                        .filter(key => isNaN(Number(key)))
                        .filter(key => key !== EIssueType[EIssueType.NONE])
                        .map(key => <MenuItem key={key} value={key}>{key.toString().replaceAll('_', ' ')}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
export default IssueTypeSelect;