import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Tooltip } from "@mui/material";
import { useCallback } from 'react';
import BackdropProgress from '../BackdropComponent/BackdropProgress';
import RichTextEditor from '../RichTextEditorComponent/RichTextEditor';

interface IProps {
    data: string | undefined;
    loading: boolean;
    maxHeight: string | number;
    editBtnLabel: string;
    editBtnTooltip: string;
    onEditBtnClick: () => void;
}
const BaseDescription = (props: IProps) => {
    const { data, loading, maxHeight, editBtnLabel, editBtnTooltip, onEditBtnClick } = props;

    const onEditBtnClickHandler = useCallback(() => {
        onEditBtnClick();
    }, [onEditBtnClick]);

    return (
        <BackdropProgress open={loading || false}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title={editBtnTooltip} placement='top' >
                    <span>
                        <Button
                            onClick={onEditBtnClickHandler}
                            size='small'
                            variant='outlined'>
                            <EditIcon fontSize='small' />&nbsp;{editBtnLabel}
                        </Button>
                    </span>
                </Tooltip>
            </Box>

            <Box
                marginTop={1.5}
                sx={{
                    height: maxHeight,
                    maxHeight: maxHeight,
                    overflowY: 'scroll'
                }}
            >
                <RichTextEditor value={data} readOnly />
            </Box>
        </BackdropProgress>
    );
}
export default BaseDescription;