import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, { getGeocode, Suggestion } from "use-places-autocomplete";
import { isEmpty } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { IAddressRequestDto } from "../../../models/AddressModels";

interface IProps {
    onChange: (address: IAddressRequestDto | null) => void;
}
const AddressAutocomplete = (props: IProps) => {
    const { onChange } = props;

    const { t } = useTranslation();
    const { ready, suggestions: { data }, setValue, clearSuggestions } = usePlacesAutocomplete({ debounce: 300 });

    const [selectedValue, setSelectedValue] = useState<Suggestion | null>(null);

    const defaultAddress = useRef<IAddressRequestDto>({
        location: {
            country: '',
            state: '',
            county: '',
            city: '',
            village: ''
        },
        street: '',
        zipcode: '',
        latitude: NaN,
        longitude: NaN
    });

    const buildAddressDto = useCallback((addressField: any, addressDto: IAddressRequestDto) => {
        const types = addressField.types;
        const value = addressField.long_name;

        if (types.includes('country')) {
            addressDto.location.country = value;
        }

        if (types.includes('administrative_area_level_2')) {
            addressDto.location.state = value;
        }

        if (types.includes('administrative_area_level_1')) {
            addressDto.location.county = value;
        }

        if (types.includes('locality')) {
            addressDto.location.city = value;
        }

        if (types.includes('vilage')) {
            addressDto.location.village = value;
        }

        if (types.includes('route')) {
            addressDto.street = isBlank(addressDto.street)
                ? value
                : `${value} ${addressDto.street}`;
        }

        if (types.includes('street_number')) {
            addressDto.street = isBlank(addressDto.street)
                ? value
                : `${addressDto.street} ${value}`;
        }

        if (types.includes('postal_code')) {
            addressDto.zipcode = value;
        }
    }, []);

    const buildResult = useCallback((item: any) => {
        const addressParts = item.address_components;
        if (!Array.isArray(addressParts)) {
            return defaultAddress.current;
        }

        const addressDto: IAddressRequestDto = { ...defaultAddress.current };
        addressParts.forEach((component: any) => buildAddressDto(component, addressDto));

        addressDto.latitude = item.geometry.location.lat();
        addressDto.longitude = item.geometry.location.lng();

        return addressDto;
    }, [buildAddressDto]);

    const onChangeHandler = useCallback((event: React.SyntheticEvent, value: Suggestion | null) => {
        setSelectedValue(value);

        if (value) {
            (async () => {
                const result = await getGeocode({ placeId: value?.place_id });
                if (result && !isEmpty(result)) {
                    const address: IAddressRequestDto = buildResult(result[0]);
                    onChange(address);
                    clearSuggestions();
                }
            })();
        } else {
            onChange(null);
        }

    }, [buildResult, clearSuggestions, onChange]);

    const onFilterOptionsHandler = useCallback((value: any) => {
        return value;
    }, []);

    const onInputChangeHandler = useCallback((event: any, newValue: string) => {
        onChange(null);
        setValue(newValue);
    }, [onChange, setValue]);

    const onBuildOptionLabel = useCallback((item: Suggestion) => {
        return item.description;
    }, []);

    const onBuildOptionKey = useCallback((item: Suggestion) => {
        return `address-item-${item.place_id}`;
    }, []);

    const isOptionEqualToValue = useCallback((option: Suggestion, value: Suggestion) => {
        return option.place_id === value.place_id
    }, []);

    const onBuildRenderOption = useCallback((props: object, item: Suggestion) => {
        return (
            <div {...props} key={`address-item-${item.place_id}`}>
                <Typography variant='body2'>{item.description}</Typography>
            </div>
        );
    }, []);

    const onRenderInputHandler = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={t('ADDRESS')}
                placeholder={t('Enter the desired address...')}
                disabled={!ready}
                autoComplete='off'
            />
        );
    }, [ready, t]);

    return (
        <Autocomplete
            value={selectedValue}
            getOptionLabel={onBuildOptionLabel}
            fullWidth
            size='medium'
            options={data}
            includeInputInList
            filterOptions={onFilterOptionsHandler}
            onChange={onChangeHandler}
            onInputChange={onInputChangeHandler}
            renderInput={onRenderInputHandler}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default AddressAutocomplete;