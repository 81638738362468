import { useCallback, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthProvider";
import AuthService from "../services/AuthService";

const LogoutPage = () => {
    const { authenticated, setToken } = useContext(AuthContext);

    const processLogout = useCallback(() => {
        setToken(null);
        AuthService.logout();
    }, [setToken]);

    useEffect(() => {
        if (authenticated) {
            processLogout();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<></>);
}
export default LogoutPage;