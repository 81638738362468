import BasePage from '../components/Base/BasePageComponent/BasePage';
import IssueGrid from '../components/IssueModule/Grid/IssueGrid';
import IssueStep from '../components/IssueModule/Step/IssueStep';
import { useIssue } from '../hooks/useIssue';

const IssuePage = () => {
    const { resetState, applyFilter } = useIssue();

    return (
        <BasePage
            onApplyFilter={applyFilter}
            onResetState={resetState}
            mainSection={<IssueGrid />}
            stepSection={<IssueStep />}
        />
    );
}
export default IssuePage;