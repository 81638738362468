import SettingsIcon from "@mui/icons-material/Settings";
import { IMenuElement } from "../../models/IMenuElement";
import MenuComponent from "./MenuComponent";

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CategoryIcon from '@mui/icons-material/Category';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RouteIcon from '@mui/icons-material/Route';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import WarehouseIcon from '@mui/icons-material/Warehouse';

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "../../store/store";

export const menuContent: Array<IMenuElement> = [{
    id: 1,
    name: 'Dashboard',
    description: 'Dashboard',
    to: '/',
    icon: <DashboardIcon />,
    short: '1',
    disabled: true
}, {
    id: 2,
    name: 'Invoice',
    description: 'Invoice management',
    to: 'invoice',
    icon: <RequestPageIcon />,
    short: '2',
    disabled: true
}, {
    id: 3,
    name: 'Route',
    description: 'Route management',
    to: 'route',
    icon: <RouteIcon />,
    short: '3',
    disabled: true
}, {
    id: 4,
    name: 'Active Load',
    description: 'Active load management',
    to: 'activeLoad',
    icon: <AccountTreeIcon />,
    short: '4',
    disabled: false
}, {
    id: 5,
    name: 'Load',
    description: 'Load management',
    to: 'load',
    icon: <CategoryIcon />,
    short: '5',
    disabled: false
}, {
    id: 6,
    name: 'Issue',
    description: 'Issue management',
    to: 'issue',
    icon: <PlaylistAddCheckIcon />,
    short: '6',
    disabled: false
}, {
    id: 7,
    name: 'Customer',
    description: 'Customer management',
    to: 'customer',
    icon: <BusinessCenterIcon />,
    short: '7',
    disabled: false
}, {
    id: 8,
    name: 'Warehouse',
    description: 'Warehouse management',
    to: 'warehouse',
    icon: <WarehouseIcon />,
    short: '8',
    disabled: false
}, {
    id: 9,
    name: 'Transport',
    description: 'Transport management',
    to: 'transport',
    icon: <LocalShippingIcon />,
    short: '9',
    disabled: false
}, {
    id: 10,
    name: 'User',
    description: 'User management',
    to: 'user',
    icon: <PeopleAltIcon />,
    short: '10',
    disabled: false
}, {
    id: 11,
    name: 'Audit',
    description: 'Audit management',
    to: 'audit',
    icon: <SettingsBackupRestoreIcon />,
    short: '11',
    disabled: true
}, {
    id: 12,
    name: 'Permissions',
    description: 'Permissions management',
    to: 'permissions',
    icon: <SecurityIcon />,
    short: '12',
    disabled: true
}, {
    id: 13,
    name: 'Settings',
    description: 'Settings',
    to: 'settings/personalProfile',
    icon: <SettingsIcon />,
    short: '13',
    disabled: true,
    submenu: [{
        id: 0,
        name: 'PERSONAL PROFILE',
        description: 'Personal profile management',
        to: '/settings/personalProfile',
        icon: <SettingsIcon />,
        short: '13',
        disabled: false
    }, {
        id: 1,
        name: 'GLOBAL PROFILE',
        description: 'Global profile management',
        to: '/settings/globalProfile',
        icon: <SettingsIcon />,
        short: '14',
        disabled: false
    }, {
        id: 2,
        name: 'HELP & PRIVACY',
        description: 'Help and privacy management',
        to: '/settings/helpAndPrivacy',
        icon: <SettingsIcon />,
        short: '15',
        disabled: false
    }, {
        id: 3,
        name: 'QUOTES',
        description: 'Quotes management',
        to: '/settings/quotes',
        icon: <SettingsIcon />,
        short: '16',
        disabled: false
    }]
}, {
    id: 14,
    name: 'Logout',
    description: 'Logout',
    to: 'logout',
    icon: <LogoutIcon />,
    short: '14',
    disabled: false
}];

export default function HeadMenuComponent() {
    const { menuState, hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const navigate = useNavigate();
    const location = useLocation().pathname
    const [path, setPath] = useState<string>('');

    useEffect(() => {
        const path: Array<string> = (location.split('/').filter((e) => e !== ''));
        (path[0] === '') ? setPath('/') : setPath(path[0]);
    }, [location]);

    const onClickHandler = useCallback((path: string) => {
        navigate(path);
    }, [navigate]);

    return (
        <>
            {
                menuContent.map((menu: IMenuElement, index: number) => (
                    <MenuComponent
                        disable={menu.disabled || false}
                        key={index}
                        hints={hints}
                        onClick={onClickHandler}
                        active={menu.to.startsWith(path)}
                        bigSize={menuState}
                        menu={menu}
                    />
                ))
            }
        </>
    );
}