import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../../../hooks/useNotification";
import { IItemDetails } from '../../../../models/CommonModels';
import { ICustomerOverviewResponseDto } from '../../../../models/CustomerModels';
import CustomerService from '../../../../services/CustomerService';
import BackdropProgress from "../../../Base/BackdropComponent/BackdropProgress";
import ItemsDetails from "../../../Base/ItemsDetailsComponent/ItemsDetails";
import CustomerDialog from '../../Grid/CustomerDialog';

interface IProps {
    customerId: string;
    readonly: boolean;
}
const CustomerOverview = (props: IProps) => {
    const { customerId } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [data, setData] = useState<ICustomerOverviewResponseDto>();

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [dialog, setDialog] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await CustomerService.fetchOverview(customerId);
            if (response) {
                setData(response.data.body);
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    useEffect(() => {
        retrieveData();
    }, [refresh, retrieveData]);

    const items: IItemDetails[] = [{
        id: `${data?.uuid}-name`,
        primary: t('NAME'),
        secondary: data?.name
    }, {
        id: `${data?.uuid}-idno`,
        primary: t('ID#'),
        secondary: data?.idno
    }, {
        id: `${data?.uuid}-address`,
        primary: t('ADDRESS'),
        secondary: data?.address.value
    }];

    const dialogToggleHandler = useCallback(() => {
        setDialog(dialog => !dialog);
    }, []);

    const refreshHandler = useCallback(() => {
        setRefresh(refresh => !refresh);
    }, []);

    return (
        <>
            <BackdropProgress open={loading || false}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={t('EDIT')} placement='top' >
                        <span>
                            <Button
                                onClick={dialogToggleHandler}
                                size='small'
                                variant='outlined'>
                                <EditIcon fontSize='small' />&nbsp;{t('EDIT')}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <ItemsDetails data={items} />
            </BackdropProgress>

            {dialog &&
                <CustomerDialog
                    open={dialog}
                    onCloseBtnClick={dialogToggleHandler}
                    entity={data}
                    onSubmitBtnClick={refreshHandler}
                />
            }
        </>
    );
}
export default CustomerOverview;