import { Stack, TextField } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getDateFormat } from "../../../helpers/dateHelper";
import { normalizeNavigationUrl, removeEmptyFields } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { useActiveLoad } from "../../../hooks/useActiveLoad";
import { useNotification } from "../../../hooks/useNotification";
import { IActiveLoadOverviewResponseDto, IActiveLoadRequestDto } from "../../../models/ActiveLoadModels";
import { EDateFormat } from "../../../models/ProfileModels";
import ActiveLoadService from "../../../services/ActiveLoadService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import DateField from "../../Base/DateComponent/DateField";
import IdnoGenerator from "../../Base/IdnoGeneratorComponent/IdnoGenerator";

interface IProps {
    open: boolean;
    activeLoad?: IActiveLoadOverviewResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const ActiveLoadDialog = (props: IProps) => {
    const { open, activeLoad, onCloseBtnClick, onSubmitBtnClick } = props;

    const isEdit = useRef<boolean>(activeLoad !== undefined);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useActiveLoad();
    const formId: string = 'active-load-form';

    const [loading, setLoading] = useState(false);
    const [maxShipDate, setMaxShipDate] = useState<string | undefined>(isEdit.current && activeLoad?.deliveryDate ? getDateFormat(activeLoad.deliveryDate, EDateFormat.d_yyyy_mm_dd) : undefined);
    const [minDeliveryDate, setMinDeliveryDate] = useState<string | undefined>(isEdit.current && activeLoad?.shipDate ? getDateFormat(activeLoad.shipDate, EDateFormat.d_yyyy_mm_dd) : undefined);

    const { register, setValue, getValues, setError, clearErrors, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IActiveLoadRequestDto>({
        defaultValues: {
            idno: isEdit.current ? activeLoad?.idno : '',
            name: isEdit.current ? activeLoad?.name || '' : '',
            shipDate: isEdit.current ? activeLoad?.shipDate : NaN,
            deliveryDate: isEdit.current ? activeLoad?.deliveryDate : NaN
        }
    });

    const onCheckWithServerApi = useCallback((value: string) => {
        return ActiveLoadService.isIdnoAvailable(value);
    }, []);

    const updateData = useCallback((uuid: string, data: IActiveLoadRequestDto) => {
        (async () => {
            const [error, response] = await ActiveLoadService.updateOverview(uuid, data);
            if (response) {
                displayNotification({ message: t('Active load was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, []);

    const createData = useCallback((data: IActiveLoadRequestDto) => {
        (async () => {
            const [error, response] = await ActiveLoadService.create(data);
            if (response) {
                displayNotification({ message: t('Active load was successfully created.') });

                const uuid = response.data.response.entityId;
                const newUrl: string = normalizeNavigationUrl(uuid);
                navigate(`/${newUrl}`);

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, []);

    const onSubmit = useCallback((data: IActiveLoadRequestDto) => {
        setLoading(true);
        const normalisedData: IActiveLoadRequestDto = removeEmptyFields(data) as unknown as IActiveLoadRequestDto;
        if (activeLoad) {
            updateData(activeLoad.uuid, normalisedData);
        } else {
            createData(normalisedData);
        }
    }, [activeLoad]);

    const validateIdnoField = useCallback((value: string) => {
        return !isBlank(value) && !errors.idno;
    }, [errors.idno]);

    register('idno', { validate: validateIdnoField });
    const onChangeIdnoHandler = useCallback((value: string, error?: string) => {
        if (error) {
            setError('idno', { message: error });
        } else {
            clearErrors('idno');
        }

        setValue('idno', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [clearErrors, setError, setValue]);

    const validateNameField = useCallback((value?: string) => {
        if (value && value.length > 0 && isBlank(value)) {
            const message: string = t('Only blank spaces are not allowed.');
            setError('name', { message: message });
            return false;
        }
        clearErrors('name');
        return true;
    }, [clearErrors, setError, t]);

    register('shipDate');
    const onChangeShipDateHandler = useCallback((timestamp?: number, dateStr?: string) => {
        setValue('shipDate', timestamp, { shouldDirty: true });
        setMinDeliveryDate(dateStr);
    }, [setValue]);

    register('deliveryDate');
    const onChangeDeliveryDateHandler = useCallback((timestamp?: number, dateStr?: string) => {
        setValue('deliveryDate', timestamp, { shouldDirty: true });
        setMaxShipDate(dateStr);
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <IdnoGenerator
                        required
                        autoFocus
                        value={getValues('idno')}
                        label={t('ID#')}
                        generateBtnTooltip={t('GENERATE')}
                        errorMessage={t('The value is used. Choose another value.')}
                        checkWithServerApi={onCheckWithServerApi}
                        onChange={onChangeIdnoHandler}
                    />

                    <TextField
                        {...register('name', { validate: validateNameField })}
                        label={t('NAME')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        autoComplete='off'
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />

                    <Stack spacing={2} direction='row'>
                        <DateField
                            label={t('SHIP DATE')}
                            size='medium'
                            value={getValues('shipDate')}
                            onChange={onChangeShipDateHandler}
                            max={maxShipDate}
                        />

                        <DateField
                            label={t('DELIVERY DATE')}
                            size='medium'
                            value={getValues('deliveryDate')}
                            onChange={onChangeDeliveryDateHandler}
                            min={minDeliveryDate}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [errors.name, getValues, handleSubmit, maxShipDate, minDeliveryDate,
        onChangeDeliveryDateHandler, onChangeIdnoHandler, onChangeShipDateHandler,
        onCheckWithServerApi, onSubmit, register, t, validateNameField
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${isEdit.current ? 'EDIT' : 'CREATE'} ACTIVE LOAD`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default ActiveLoadDialog;