import { useCallback, useEffect } from "react";
import { useUserProfile } from "../../../hooks/useUserProfile";
import UserProfileService from "../../../services/UserProfileService";
import { IUserProfilePreferenceResponseDto } from "../../../models/UserProfileModels";

const AuthUserProfile = () => {
    const { updateProfile } = useUserProfile();

    const fetchAndSetUserProfile = useCallback(() => {
        (async () => {
            const [, response] = await UserProfileService.fetch();
            if (response) {
                const profile: IUserProfilePreferenceResponseDto = response.data.body;
                updateProfile(profile);
            }
        })();
    }, [updateProfile]);

    useEffect(() => {
        fetchAndSetUserProfile();
    }, []);

    return (<></>);
}
export default AuthUserProfile;