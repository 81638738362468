import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFileDetailsRequestDto } from "../../../models/FileModel";
import BaseCrudDialog from "../BaseCrudDialogComponent/BaseCrudDialog";
import ImageCrop from "./Image/ImageCrop";
import FileUploadBtn from "./Upload/FileUploadBtn";

interface IProps {
    title: string;
    formId: string;
    acceptedFiles?: string;
    aspect?: number;

    saveBtnDisabled: boolean;

    loading: boolean;
    open: boolean;
    onSelect: (file: File, details: IFileDetailsRequestDto) => void;
    onSubmit: (data: any) => void;
    onCloseBtnClick: () => void;
}
const FileUploadDialog = (props: IProps) => {
    const {
        title, formId, acceptedFiles = '*', aspect = 0, saveBtnDisabled, loading,
        open, onSelect, onSubmit, onCloseBtnClick
    } = props;

    const { t } = useTranslation();

    const [file, setFile] = useState<File>();
    const [imageDetails, setImageDetails] = useState<IFileDetailsRequestDto>();

    useEffect(() => {
        if (file && imageDetails) {
            onSelect(file, imageDetails);
        }
    }, [file, imageDetails, onSelect]);

    const onSelectFileHandler = useCallback((file: File) => {
        setFile(file);
    }, []);

    const onSelectCropHandler = useCallback((details: IFileDetailsRequestDto) => {
        setImageDetails(details);
    }, []);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={onSubmit}>
                <FileUploadBtn
                    label={t('UPLOAD FILE')}
                    acceptedFiles={acceptedFiles}
                    onSelect={onSelectFileHandler}
                />

                {file &&
                    <Box marginTop={2}>
                        <ImageCrop
                            file={file}
                            aspect={aspect}
                            disabled={loading}
                            onSelect={onSelectCropHandler}
                        />
                    </Box>
                }
            </form>
        );
    }, [acceptedFiles, aspect, file, formId, loading, onSelectCropHandler, onSelectFileHandler, onSubmit, t]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={title}
            maxWidth={'sm'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={saveBtnDisabled}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default FileUploadDialog;