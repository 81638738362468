import { useDispatch } from "react-redux";
import { IUserProfilePreferenceResponseDto } from "../models/UserProfileModels";
import { UserProfileActions } from "../store/reducers/userProfileSlice";

export const useUserProfile = () => {
    const dispatch = useDispatch();

    const updateProfile = (profile: IUserProfilePreferenceResponseDto) => {
        dispatch(UserProfileActions.updateProfile(profile));
    };

    return { updateProfile } as const;
};